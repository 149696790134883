import {urlApi, urlPath, get, new_update} from '../api';

const url = urlApi + urlPath.researches

export const getData = async(token, extra="") => {
	return await get(url, token, extra)
}

export const update_research_data = (data, id, token) => {
	return new_update(data, `${url}/${id}`, token);
}

export const validate_research_data = (data) => {
	return new Promise((resolve, reject) => {
		let success = true;
		let errors = [];

		let sides = ['left', 'right'];
		let types = ['a', 'b'] // a: macula. b: disco

		for (let side of sides) {
			for (let type of types) {
				if (isNaN(data[`focusing_${side}_eye_${type}`]) || (data[`focusing_${side}_eye_${type}`] < 0 || data[`focusing_${side}_eye_${type}`] > 5)) {
					success = false;
					errors.push(`Enfoque (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): El valor debe ser numerico entre 0 y 5.`);
				}
				if (isNaN(data[`contrast_${side}_eye_${type}`]) || (data[`contrast_${side}_eye_${type}`] < 0 || data[`contrast_${side}_eye_${type}`] > 5)) {
					success = false;
					errors.push(`Contraste (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): El valor debe ser numerico entre 0 y 5.`);
				}
				if (isNaN(data[`glow_${side}_eye_${type}`]) || (data[`glow_${side}_eye_${type}`] < 0 || data[`glow_${side}_eye_${type}`] > 5)) {
					success = false;
					errors.push(`Brillo (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): El valor debe ser numerico entre 0 y 5.`);
				}
				if (isNaN(data[`artifacts_${side}_eye_${type}`]) || (data[`artifacts_${side}_eye_${type}`] < 0 || data[`artifacts_${side}_eye_${type}`] > 5)) {
					success = false;
					errors.push(`Artefactos (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): El valor debe ser numerico entre 0 y 5.`);
				}
				if (isNaN(data[`optical_disc_${side}_eye_${type}`]) || (data[`optical_disc_${side}_eye_${type}`] < 0 || data[`optical_disc_${side}_eye_${type}`] > 5)) {
					success = false;
					errors.push(`Disco optico (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): El valor debe ser numerico entre 0 y 5.`);
				}
				if (isNaN(data[`taint_${side}_eye_${type}`]) || (data[`taint_${side}_eye_${type}`] < 0 || data[`taint_${side}_eye_${type}`] > 5)) {
					success = false;
					errors.push(`Macula (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): El valor debe ser numerico entre 0 y 5.`);
				}
				if (isNaN(data[`upper_arch_${side}_eye_${type}`]) || (data[`upper_arch_${side}_eye_${type}`] < 0 || data[`upper_arch_${side}_eye_${type}`] > 5)) {
					success = false;
					errors.push(`Arcada superior (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): El valor debe ser numerico entre 0 y 5.`);
				}
				if (isNaN(data[`lower_arch_${side}_eye_${type}`]) || (data[`lower_arch_${side}_eye_${type}`] < 0 || data[`lower_arch_${side}_eye_${type}`] > 5)) {
					success = false;
					errors.push(`Arcada inferior (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): El valor debe ser numerico entre 0 y 5.`);
				}
				if (typeof data[`waterfall_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Catarata (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`hemorrhage_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Hemorragia vítrea (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`injury_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Lesiones predisponentes (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`breaking_off_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Desprendimiento de la retina por ruptura (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`hypertensive_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Retinopatía hipertensiva (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`membrane_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Membrana epirretiniana (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`macular_hole_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Agujero macular (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`drusen_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Drusas/Degeneración macular asociada a la edad (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`corroidal_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Nevo coroideo (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`excavation_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Excavación de nervio óptico amplia (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`myopia_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Miopía degenerativa (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`occlusion_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Oclusión vascular (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`hyalosis_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Hialosis asteroidea (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`vascular_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Otros trastornos vasculares (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`retina_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Otros trastornos de la retina (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
				if (typeof data[`optic_nerve_${side}_eye_${type}`] !== 'boolean') {
					success = false;
					errors.push(`Otros trastornos del nervio óptico (Ojo ${translate_side(side)} centrado en ${translate_type(type)}): Valor no valido`);
				}
			}
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}

const translate_side = (side) => {
	switch (side) {
		case 'left':
			return 'izquierdo';
		case 'right':
			return 'derecho';
	}
}

const translate_type = (type) => {
	switch (type) {
		case 'a':
			return 'mácula';
		case 'b':
			return 'disco';
	}
}