// Images
import ic_visibility from "../images/ic_visibility.svg";
import ic_visibility_off from "../images/ic_visibility_off.svg";
import img_logo_icon from "../images/img_logo_icon.svg";
import img_logo_small from "../images/img_logo_small.svg";
import img_logo_splash from "../images/img_logo_splash.svg";
import img_success from "../images/img_success.svg";
import img_warning from "../images/img_warning.svg";
import ic_next_white from "../images/ic_next_white.svg";
import img_logo_white_bar from "../images/img_logo_white_bar.svg";
import ic_card from "../images/ic_card.svg";
import ic_patient from "../images/ic_patient.svg";
import ic_request from "../images/ic_request.svg";
import ic_delete from "../images/ic_delete.svg";
import ic_edit from "../images/ic_edit.svg";
import ic_back from "../images/ic_back.svg";
import ic_next from "../images/ic_next.svg";
import ic_search from "../images/ic_search.svg";
import ic_unfold from "../images/ic_unfold.svg";
import ic_medical_center from "../images/ic_medical_center.svg";
import ic_patients from "../images/ic_patients.svg";
import ic_users from "../images/ic_users.svg";
import ic_image from "../images/ic_image.svg";
import ic_check from "../images/ic_check.svg";
import ic_uncheck from "../images/ic_uncheck.svg";
import ic_research from "../images/ic_research.svg";
import ic_calendar from "../images/ic_calendar.svg";
import ic_select from "../images/ic_select.svg";
import img_picture_next from "../images/img_picture_next.svg";
import img_picture_back from "../images/img_picture_back.svg";
import ic_checked_disabled from "../images/ic_checked_disabled.svg";
import ic_checked from "../images/ic_checked.svg";
import ic_no_checked from "../images/ic_no_checked.svg";
import ic_download from "../images/ic_download.svg";
import ic_reports from "../images/ic_reports.svg";
import ic_search_performance from "../images/ic_search_performance.svg";
import ic_folder from "../images/ic_folder.svg";
import ic_folder_managed from "./../images/ic_folder_managed.svg";
import ic_visivility from "./../images/ic_visivility.svg";
import ic_symptoms from "./../images/ic_symptoms.svg";
import ic_table_rows from "./../images/ic_table_rows.svg";
import ic_account from "./../images/ic_account.svg";
import img_eyebit_lab from './../images/img_eyebit_lab.png';
import user from "../images/user.png";

export const Ic_visibility = ic_visibility;
export const Ic_visibility_off = ic_visibility_off;
export const Img_logo_icon = img_logo_icon;
export const Img_logo_small = img_logo_small;
export const Img_logo_splash = img_logo_splash;
export const Img_success = img_success;
export const Img_warning = img_warning;
export const Ic_next_white = ic_next_white;
export const Img_logo_white_bar = img_logo_white_bar;
export const Ic_card = ic_card;
export const Ic_patient = ic_patient;
export const Ic_request = ic_request;
export const Ic_edit = ic_edit;
export const Ic_delete = ic_delete;
export const Ic_back = ic_back;
export const Ic_next = ic_next;
export const Ic_search = ic_search;
export const Ic_unfold = ic_unfold;
export const Ic_medical_center = ic_medical_center;
export const Ic_patients = ic_patients;
export const Ic_users = ic_users;
export const Ic_image = ic_image;
export const Ic_check = ic_check;
export const Ic_uncheck = ic_uncheck;
export const Ic_research = ic_research;
export const Ic_calendar = ic_calendar;
export const Ic_select = ic_select;
export const Img_picture_next = img_picture_next;
export const Img_picture_back = img_picture_back;
export const Ic_checked_disabled = ic_checked_disabled;
export const Ic_checked = ic_checked;
export const Ic_no_checked = ic_no_checked;
export const Ic_folder = ic_folder;
export const Ic_folder_managed = ic_folder_managed;
export const Ic_visivility = ic_visivility;
export const Ic_symptoms = ic_symptoms;
export const Ic_table_rows = ic_table_rows;
export const Ic_account = ic_account;
export const Img_eyebit_lab = img_eyebit_lab;

export const User = user;

export const Ic_download = ic_download;
export const Ic_reports = ic_reports;
export const Ic_search_performance = ic_search_performance;

//
// ─── TOAST TEXT ─────────────────────────────────────────────────────────────────
//
export const toastText = {
	update:			"Datos actualizados.",
	add:			"Datos agregados.",
	delete:			"Datos eliminados.",
	warning:		"Parace que surgio un problema.",
	void:			"Parace que faltan campos por llenar.",
	password:		"Las contraseñas coinciden.",
	failLogin:		"No se pudo iniciar sesión, verifica tus datos.",
	inputNotValid:	"Parece que algo anda mal con: ",
};

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//
export const stateData = {
  state1: "DOWNLOADING",
  state2: "DOWNLOADED",
};

//
// ─── VIEW ───────────────────────────────────────────────────────────────────────
//
export const StateView = {
  state1: "VIEW_DATA",
  state2: "CREATE_DATA",
  state3: "EDIT_DATA",
  state4: "DELETE_DATA",
};

//
// ─── STATE OF REDUX ─────────────────────────────────────────────────────────────
//
export const reduxState = {
  drawer: "UPDATE_DRAWER",
  view: "CHANGE_VIEW",
  update_menu: "UPDATE_MENU",
  login: "LOGIN",
  refresh: "REFRESH",
  get_hospitals: "GET_HOSPITALS",
  update_hospitals: "UPDATE_HOSPITALS",
  get_all_hospitals: "GET_ALL_HOSPITALS",
  get_users: "GET_USERS",
  update_users: "UPDATE_USERS",
  get_patients: "GET_PATIENTS",
  update_patients: "UPDATE_PATIENTS",
  get_request: "GET_REQUEST",
  update_request: "UPDATE_REQUEST",
  status_request: "STATUS_REQUST",
  type_request: "TYPE_REQUEST",
  get_all_patients: "GET_ALL_PATIENTS",
  get_grader: "GET_GRADER",
  update_grader: "UPDATE_GRADER",
  get_research: "GET_RESEARCH",
  update_research: "UPDATE_RESEARCH",
  status_research: "STATUS_RESEARCH",
  type_research: "TYPE_RESEARCH",

  get_heaters: "GET_HEATERS",
  update_heaters: "UPDATE_HEATERS",
  get_quotations: "GET_QUOTATIONS",
  update_quotations: "UPDATE_QUOTATIONS",

  get_investigation_datasets: "GET_INVESTIGATION_DATASETS",
  update_investigation_datasets: "UPDATE_INVESTIGATION_DATASETS",
  update_investigation_request: "UPDATE_INVESTIGATION_REQUEST",

  selfUserUpdate: 'SELF_USER_UPDATE',
};

//
// ─── ROLES ──────────────────────────────────────────────────────────────────────
//
export const UserRoles = ['ADMINISTRADOR', 'FOTÓGRAFO', 'GRADUADOR', 'INVESTIGADOR', 'GRADUADOR INVESTIGADOR'];
export const Roles = ["Administrador", "Fotografo", "Graduador"];
export const groupList = ["a", "b"];

export const UserSex = [
  { id: "MALE", name: "Masculino" },
  { id: "FEMALE", name: "Femenino" },
];

export const UserStatusCivil = ["Soltero", "Casado", "Divorciado"];

export const typeDiabetes = ["Tipo 1", "Tipo 2", "Gestacional"];

export const hospital_study_types = ['1 Campo', '2 Campos', '3 Campos', '4 Campos', '5 Campos', '6 Campos', '7 Campos'];

export const levelRetinopathy = [ // This variable could be removed, check it out
  { id: "0", name: "R0" },
  { id: "1", name: "R1" },
  { id: "2", name: "R2" },
  { id: "3", name: "R3" },
  { id: "4", name: "R4" },
];

export const typeEdema = [ // This variable could be removed, check it out
  { id: "0", name: "Sin edema macular" },
  { id: "1", name: "Con edema macular" },
];

export const artifacsData = [ // This variable could be removed, check it out
  { id: 1, name: "1 - Visibles" },
  { id: 2, name: "2 - No visibles" },
];

export const sharpnessData = [ // This variable could be removed, check it out
  { id: 1, name: "1 - Nítido" },
  { id: 2, name: "2 - No nítido" },
];

export const visionFieldData = [ // This variable could be removed, check it out
  { id: 1, name: "1 - Macula y disco optico visible(s)" },
  { id: 2, name: "2 - Macula y disco optico no visible(s)" },
];

export const evaluationData = [ // This variable could be removed, check it out
  { id: 1, name: "1 - Evaluable" },
  { id: 2, name: "2 - No evaluable" },
];

// Test hospitals
export const hospitalsTest = [
  {
    id: "1",
    name: "Hospital San Jose",
    state: "Jalisco",
    municipality: "Zapopan",
    colony: "Chapalita",
    address: "Av Guadalupe 523",
    contact: "Raphael Galindo",
    phone: "332138519",
    email: "raphael.ghz@gmail.com",
  },
];

// Test Users
export const usersTest = [
  {
    id: "1",
    name: "Jonathan",
    lastName: "Castro",
    email: "jonathan.casa.000@gmail.com",
    rol: "fotografo",
    hospital: "Hospital San Jose",
    image: user,
  },
];

// Test Patients
export const patientTest = [
  {
    id: "1",
    name: "Jonathan",
    lastName: "Castro",
    email: "jonathan.casa.000@gmail.com",
    phone: "3312345678",
    address: "Santa ana",
    birthday: "2021-03-05T16:46:47.000000Z",
    civilStatus: "Soltero",
    curp: "QWERTYUIO12345",
    files: "qwerty",
  },
];

// Test Request
export const requestTest = [
  { id: "1", name: "Jonathan", lastName: "Castro", email: "jonathan.casa.000@gmail.com", phone: "3312345678" },
];

export const translate_inputs = {
	address:				'Direccion',
	area:					'Colonia',
	birthdate:				'Fecha de nacimiento',
	contact:				'Nombre del ontacto',
	curp:					'CURP',
	civilStatus:			'Estado Civil',
	email:					'Correo electronico',
	gender:					'Genero',
	grader_id:				'Tu perfil de medico investigador. Reportalo con soporte',
	id:						'El identificador',
	investigation_request:	'Esta solicitud de graduacion. Reportalo con soporte',
	lastName:				'Apellido(s)',
	macular_edema:			'El nivel de edema macular enviado',
	municipality:			'Municipio',
	name:					'Nombre',
	phone:					'Numero telefonico',
	record:					'Expediente',
	retinopathy_level:		'El nivel de retinopatia enviado',
	state:					'Estado',
	user_rol:				'Tus permisos',
	assessment_type:		'Tipo de valoración',
}
