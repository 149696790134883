import { useState, useEffect } from "react";
import { Container, Grid, IconButton } from "@material-ui/core";
import { connect } from "react-redux";

// Redux
import { changeView, cleanData } from "../../store/redux/actions/Global";
// Components
import DragImage from "./DragImage";
import ButtonDownload from "./ButtonDownload";
import Modal from "./ModalData";
import Input from "../global/Input";
import Select from "../global/Select";

// Values
import { StateView, Ic_back, Ic_next } from "values";
// Pdf
import { createPdf } from "utils/pdf";
// Api
import { printedRequest } from "../../utils/request";
// Utils
import { formatDate3 } from "../../utils";

const Response = ({ stateView, patients, token, dataEdit }) => {
  const [blockData, setBlockData] = useState("disabled");
  const [status_edit, setStatusEdit] = useState(stateView === StateView.state3 ? true : false);
  const [status_add, setStatusAdd] = useState(stateView === StateView.state2 ? true : false);

  // Modal
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [position, setPosition] = useState(0);
  const [studyType, setStudyType] = useState(2);

  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [personalData, setPersonalData] = useState("");

  const [r_p_l_l, setColor1] = useState("");
  const [r_p_l_r, setColor2] = useState("");

  const [defaultColor, setDefaultColor] = useState("#87F0AF");
  const [message, setMessage] = useState("");

  // const [r_p_l_l]     = useState(Number(dataEdit.retinopathy_level_left)===0||Number(dataEdit.retinopathy_level_left)===1?'#87F0AF':Number(dataEdit.retinopathy_level_left)===2?'#F9CB4C':'#E96262')
  // const [r_p_l_r]     = useState(Number(dataEdit.retinopathy_level_right)===0||Number(dataEdit.retinopathy_level_right)===1?'#87F0AF':Number(dataEdit.retinopathy_level_right)===2?'#F9CB4C':'#E96262')

  const filterData = (id) => {
    let obj = {};
    patients.forEach((i) => {
      if (i.id == id) {
        obj = i;
      }
    });
    setPersonalData(obj);
  };

  const downLoadPdf = async () => {
    await printedRequest(dataEdit.id, token);
    createPdf(dataEdit, personalData);
  };

  const setNewColor = () => {
    setColor1(
      parseInt(dataEdit.retinopathy_level_left) === 0 || parseInt(dataEdit.retinopathy_level_left) === 1
        ? "#87F0AF"
        : parseInt(dataEdit.retinopathy_level_left) === 2
        ? "#F9CB4C"
        : "#E96262"
    );
    setColor2(
      parseInt(dataEdit.retinopathy_level_right) === 0 || parseInt(dataEdit.retinopathy_level_right) === 1
        ? "#87F0AF"
        : parseInt(dataEdit.retinopathy_level_right) === 2
        ? "#F9CB4C"
        : "#E96262"
    );
    generateMessage(dataEdit.retinopathy_level_left, dataEdit.retinopathy_level_left);
  };

  const nextPage = () => {
    const sum = position + 1;
    if (sum < studyType) {
      setPosition(sum);
    }
  };

  const backPage = () => {
    const subtraction = position - 1;
    if (subtraction >= 0) {
      setPosition(subtraction);
    }
  };

  useEffect(() => {
    setPosition(0);
    filterData(dataEdit.patientId);
    setStudyType(dataEdit.study_type);
    setNewColor();
  }, [dataEdit.id]);

  const generateMessage = (value_left, value_right) => {
    let obj = { m1: "", m2: "", color: "#87F0AF" };

    const message1 = "Revalorar en 12 meses.";
    // const message2 = 'Revalorar en 12 meses.'
    const message3 = "Revalorar en 6 meses(o referir al oftalmólogo si no es factible).";
    const message4 = "Referir al oftalmólogo.";
    const message5 = "Referir al oftalmólogo.";
    const message6 = "Revalorar en 12 meses del otro ojo.";
    const message7 = "1) Falla técnica - Tomar imágenes de nuevo.";
    const message8 = "2) Hallazgo fisiológico que ni permita ver el fondo de ojo - Referir al oftalmólogo.";

    let highest_level = 0;
    if (parseInt(value_left) >= parseInt(value_right)) {
      highest_level = parseInt(value_left);
    } else {
      highest_level = parseInt(value_right);
    }

    if (highest_level === 0 || highest_level === 1) {
      obj = { m1: message1, m2: "", color: "#87F0AF" };
    } else if (highest_level === 2) {
      obj = { m1: message3, m2: "", color: "#F9CB4C" };
    } else if (highest_level === 3) {
      obj = { m1: message4, m2: "", color: "#E96262" };
    } else if (highest_level === 4) {
      obj = { m1: message5, m2: "", color: "#E96262" };
    } else if (highest_level === 5) {
      obj = { m1: message6, m2: "", color: "#E96262" };
    } else if (highest_level === 6) {
      obj = { m1: message7, m2: message8, color: "#E96262" };
    }

    setDefaultColor(obj.color);
    setMessage(`${obj.m1} ${obj.m2}`);
  };
  return (
    <div className="card_info">
      <div style={{ height: 30 }} />

      <Modal
        open={open1}
        closeModal={setOpen1}
        data={
          <div>
            <p style={{ margin: 0, marginTop: 15, fontSize: 18, fontWeight: 600 }}>{"Datos Personales"}</p>
            <div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20 }}>
              <Container>
                <Input title={"Nombre"} value={dataEdit.patient && dataEdit.patient.name} blockData={"disabled"} />
                <Input
                  title={"Apellido"}
                  value={dataEdit.patient && dataEdit.patient.last_name}
                  blockData={"disabled"}
                />
                <Input title={"Correo"} value={dataEdit.patient && dataEdit.patient.email} blockData={"disabled"} />
                <Input title={"Teléfono"} value={dataEdit.patient && dataEdit.patient.phone} blockData={"disabled"} />
                <Input
                  title={"Domicilio"}
                  value={dataEdit.patient && dataEdit.patient.address}
                  blockData={"disabled"}
                />

                <Select
                  title={"Fecha de nacimiento"}
                  list={[formatDate3(dataEdit.patient && `${dataEdit.patient.birthdate}`)]}
                  blockData={"disabled"}
                />
                <Select
                  title={"Estado civil"}
                  list={[dataEdit.patient && dataEdit.patient.civil_status]}
                  blockData={"disabled"}
                />
                <Select
                  title={"Sexo"}
                  list={[dataEdit.patient && dataEdit.patient.gender === "MALE" ? "Masculino" : "Femenino"]}
                  blockData={"disabled"}
                />

                <Input title={"Curp"} value={dataEdit.patient && dataEdit.patient.curp} blockData={"disabled"} />
                <Input
                  title={"Expediente"}
                  value={dataEdit.patient && dataEdit.patient.record}
                  blockData={"disabled"}
                />
              </Container>
            </div>
          </div>
        }
      />
      <Modal
        open={open2}
        closeModal={setOpen2}
        data={
          <div>
            <p style={{ margin: 0, marginTop: 15, fontSize: 18, fontWeight: 600 }}>{"Datos Clínicos"}</p>
            <div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20 }}>
              <Container>
                <Input title={"Estatura (cm)"} value={dataEdit.height} blockData={"disabled"} />
                <Input title={"Peso (kg)"} value={dataEdit.weight} blockData={"disabled"} />
                <Input title={"Cintura (cm)"} value={dataEdit.waist} blockData={"disabled"} />
                <Input title={"Glucosa (mg/dl)"} value={dataEdit.glucose} blockData={"disabled"} />
                <Input title={"Frecuencia Cardiaca (latidos/min)"} value={dataEdit.heart_rate} blockData={"disabled"} />
                <Input
                  title={"Frecuencia Respiratoria (resp/min)"}
                  value={dataEdit.breathing_frequency}
                  blockData={"disabled"}
                />
                <Input title={"Temperatura (°C)"} value={dataEdit.temperature} blockData={"disabled"} />
                <Input title={"Perimetro Cefalico"} value={dataEdit.cephalic_perimeter} blockData={"disabled"} />
                <Input title={"Perimetro Toracico"} value={dataEdit.thoracic_perimeter} blockData={"disabled"} />
              </Container>
            </div>
            <div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20 }}>
              <Container>
                <Input title={"Tensión Arterial (mmHg)"} value={dataEdit.blood_pressure} blockData={"disabled"} />
                <Input title={"LDL (mmol/L)"} value={dataEdit.ldl} blockData={"disabled"} />
                <Input title={"Trigliceridos (mmol/L)"} value={dataEdit.triglycerides} blockData={"disabled"} />
                <Input title={"Colesterols (mg/dl)"} value={dataEdit.cholesterol} blockData={"disabled"} />
                <Input
                  title={"Hemoglobina glucosilada HbA1C (%)"}
                  value={dataEdit.glycated_hemoglobin}
                  blockData={"disabled"}
                />
              </Container>
            </div>

            <div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20 }}>
              <Container>
                <Input title={"Diabetes"} value={dataEdit.diabetes} blockData={"disabled"} />
              </Container>
            </div>
          </div>
        }
      />

      <div className="card_scroll" style={{ marginLeft: 10 }}>
        <Container>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginRight: 20 }}>
            <IconButton onClick={() => backPage()}>
              <img alt="" src={Ic_back} />
            </IconButton>
            <IconButton onClick={() => nextPage()}>
              <img alt="" src={Ic_next} />
            </IconButton>
          </div>
          {
            position === 0 &&
            <div>
              <Grid container>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.optical_disk_centered_right_eye}
                    setImage={setImage}
                    text={"Ojo Derecho"}
                    option={1}
                    blockData={blockData}
                    />
                </Grid>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.optical_disk_centered_left_eye}
                    setImage={setImage}
                    text={"Ojo Izquierdo"}
                    option={2}
                    blockData={blockData}
                    />
                </Grid>
              </Grid>
            </div>
          }

          {
            (studyType >= 2 && position === 1) &&
            <div>
              <Grid container>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.macula_centered_right_eye}
                    setImage={setImage}
                    text={"Ojo Derecho"}
                    option={1}
                    blockData={blockData}
                    />
                </Grid>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.macula_centered_left_eye}
                    setImage={setImage}
                    text={"Ojo Izquierdo"}
                    option={2}
                    blockData={blockData}
                    />
                </Grid>
              </Grid>
            </div>
          }

          {
            (studyType >= 3 && position === 2) &&
            <div>
              <Grid container>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.temporal_macula_right_eye}
                    setImage={setImage}
                    text={"Ojo Derecho"}
                    option={1}
                    blockData={blockData}
                    />
                </Grid>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.temporal_macula_left_eye}
                    setImage={setImage}
                    text={"Ojo Izquierdo"}
                    option={2}
                    blockData={blockData}
                    />
                </Grid>
              </Grid>
            </div>
          }

          {
            (studyType >= 4 && position === 3) &&
            <div>
              <Grid container>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.upper_temporal_tangential_right_eye}
                    setImage={setImage}
                    text={"Ojo Derecho"}
                    option={1}
                    blockData={blockData}
                    />
                </Grid>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.upper_temporal_tangential_left_eye}
                    setImage={setImage}
                    text={"Ojo Izquierdo"}
                    option={2}
                    blockData={blockData}
                    />
                </Grid>
              </Grid>
            </div>
          }
          {
            (studyType >= 5 && position === 4) &&
            <div>
              <Grid container>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.lower_temporal_tangential_right_eye}
                    setImage={setImage}
                    text={"Ojo Derecho"}
                    option={1}
                    blockData={blockData}
                    />
                </Grid>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.lower_temporal_tangential_left_eye}
                    setImage={setImage}
                    text={"Ojo Izquierdo"}
                    option={2}
                    blockData={blockData}
                    />
                </Grid>
              </Grid>
            </div>
          }
          {
            (studyType >= 6 && position === 5) &&
            <div>
              <Grid container>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.upper_nasal_tangential_right_eye}
                    setImage={setImage}
                    text={"Ojo Derecho"}
                    option={1}
                    blockData={blockData}
                    />
                </Grid>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.upper_nasal_tangential_left_eye}
                    setImage={setImage}
                    text={"Ojo Izquierdo"}
                    option={2}
                    blockData={blockData}
                    />
                </Grid>
              </Grid>
            </div>
          }
          {
            (studyType >= 7 && position === 6) &&
            <div>
              <Grid container>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.lower_nasal_tangential_right_eye}
                    setImage={setImage}
                    text={"Ojo Derecho"}
                    option={1}
                    blockData={blockData}
                    />
                </Grid>
                <Grid item sm={6}>
                  <DragImage
                    image={dataEdit.lower_nasal_tangential_left_eye}
                    setImage={setImage}
                    text={"Ojo Izquierdo"}
                    option={2}
                    blockData={blockData}
                    />
                </Grid>
              </Grid>
            </div>
          }
          <div style={{ height: 30 }} />

          <div style={{ display: dataEdit.not_graduable === 1 ? "none" : "block" }}>
            <Grid container>
              <Grid item sm={6}>
                <div style={{ textAlign: "center" }}>
                  <p style={{ margin: 0 }}>{"OJO DERECHO"}</p>
                  <div
                    style={{
                      background: r_p_l_r,
                      paddingTop: 20,
                      paddingBottom: 10,
                      textAlign: "center",
                      width: "95%",
                    }}
                  >
                    <p style={{ margin: 0, fontWeight: 600, fontSize: 60 }}>{`R${dataEdit.retinopathy_level_right}`}</p>
                  </div>
                  <p style={{ fontSize: 18, margin: 0 }}>
                    {dataEdit.macular_edema_right ? "CON EDEMA MACULAR" : "SIN EDEMA MACULAR"}
                  </p>
                </div>
              </Grid>
              <Grid item sm={6}>
                <div style={{ textAlign: "center" }}>
                  <p style={{ margin: 0 }}>{"OJO IZQUIERDO"}</p>
                  <div
                    style={{
                      background: r_p_l_l,
                      paddingTop: 20,
                      paddingBottom: 10,
                      textAlign: "center",
                      width: "95%",
                    }}
                  >
                    <p style={{ margin: 0, fontWeight: 600, fontSize: 60 }}>{`R${dataEdit.retinopathy_level_left}`}</p>
                  </div>
                  <p style={{ fontSize: 18, margin: 0 }}>
                    {dataEdit.macular_edema_left ? "CON EDEMA MACULAR" : "SIN EDEMA MACULAR"}
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>

          <div
            style={{
              background: "#d50b5a",
              paddingTop: 20,
              paddingBottom: 10,
              marginTop: 20,
              textAlign: "center",
              width: "97.5%",
              display: dataEdit.not_graduable ? "block" : "none",
            }}
          >
            <p style={{ margin: 0, fontWeight: 600, fontSize: 38, color: "#fff" }}>{"NO GRADUABLE"}</p>
          </div>

          <div
            style={{
              borderLeft: `5px solid ${defaultColor}`,
              background: "#f7f8fb",
              width: "65%",
              padding: "1px 0",
              marginTop: 20,
              display: dataEdit.not_graduable ? "none" : "block",
            }}
          >
            <Container>
              <p>{message}</p>
            </Container>
          </div>

          <div style={{ width: "97.5%", marginTop: 30 }}>
            <p style={{ margin: 0, fontWeight: 600 }}>{"Comentarios"}</p>
            {dataEdit.mapGraders && dataEdit.mapGraders.map((i) => <p>{i.coments}</p>)}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "97.5%",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <div>
              <p
                style={{ margin: 0, color: "#648CFA", fontWeight: 600, cursor: "pointer" }}
                onClick={() => setOpen1(true)}
              >
                {"Ver datos personales"}
              </p>
              <p
                style={{ margin: 0, color: "#648CFA", fontWeight: 600, cursor: "pointer" }}
                onClick={() => setOpen2(true)}
              >
                {"Ver datos clínicos"}
              </p>
            </div>
            <div>
              <p style={{ margin: 0, fontSize: 18 }}>{dataEdit.patient_name}</p>
              <p style={{ margin: 0 }}>{dataEdit.patient_email}</p>
              <p style={{ margin: 0 }}>{dataEdit.patient_phone}</p>
            </div>
          </div>

          <ButtonDownload onSubmit={downLoadPdf} />
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  stateView: state.Global.stateView,
  dataEdit: state.Request.dataEdit,
  patients: state.Patients.allData,
  token: state.PanelUser.token,
});

export default connect(mapStateToProps, {})(Response);
