import ReactLoading from "react-loading";

// Values
import { Ic_next_white } from "values";

const ButtonGrade = ({ loading1, loading2, onClick1, onClick2 }) => {
  return (
    <div style={{ position: "absolute", bottom: 20, width: "100%" }}>
      <button
        className="button_grade"
        onClick={onClick1}
        style={{
          background: "#648CFA",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 15,
          width: "95%",
        }}
      >
        {"Guardar"}
        {!loading1 ? (
          <img alt="" src={Ic_next_white} style={{ position: "absolute", right: 15, top: 15 }} />
        ) : (
          <div style={{ position: "absolute", right: 15, top: 10 }}>
            <ReactLoading type={"spinningBubbles"} color={"white"} style={{ fill: "white", height: 30, width: 30 }} />
          </div>
        )}
      </button>
    </div>
  );
};

export default ButtonGrade;
