import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
// Redux
import {updateUsers} from '../../store/redux/actions/Users';
// Components
import Header from '../global/Header';
import Tables from './Tables';
import FormProfile from './FormProfile';
import FormPassword from './FormPassword';

import {me} from './../../utils/login';

const Account = ({token, updateUsers}) => {

	const [menuOption, setMenuOption] = useState('profile');

	const get_user_info = (token) => {
		me(token)
		.then(response => { return response.json(); })
		.then(response => {
			updateUsers(response.data);
		})
		.catch(error => {
			console.log(error);
		});
	}

	useEffect(() => {
		if (token) {
			get_user_info(token);
		}
	})

	return (
		<div style={{display:'flex', position:'absolute', top:20, left:180}}>

			<div className="container_info">
				<Header title={'Mi cuenta'} add={false} />
				<Tables 
				   menuOption={menuOption}
				   setMenuOption={setMenuOption} />
			</div>

			<div className="container_card">
				{
					menuOption === 'profile' && <FormProfile />
				}
				{
					menuOption === 'password' && <FormPassword />
				}
			</div>
		</div>
	);
};

const mapStateToProps = state =>({
	stateView:	state.Global.stateView,
	token:		state.PanelUser.token,
})

export default connect(mapStateToProps, {updateUsers})(Account)
