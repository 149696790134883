import jsPDF from "jspdf";
import { logo } from "../../images/logo";
import { formatDate3 } from "utils";

const colors = {
  green: "#87F0AF",
  yellow: "#F9CB4C",
  red: "#E96262",
  purple: "#566592",
  gray: "#f7f8fb",
  white: "#ffffff",
  black: "#000000",
  dark_gray: "#d8d8d8",
  light_black: "#2d2d2d",
};

const text = {
  title_level: "Nivel Retinopatía",
  title_info_patient: "INFORMACIÓN DEL PACIENTE",
  not_grader: "NO GRADUABLE",
  male: "HOMBRE",
  female: "MUJER",
  level0: "R0",
  level1: "R1",
  level2: "R2",
  level3: "R3",
  level4: "R4",
  with_edema: "CON EDEMA MACULAR",
  without_edema: "SIN EDEMA MACULAR",
  message1: "Revalorar en 12 meses.",
  message2: "Revalorar en 12 meses.",
  message3: "Revalorar en 6 meses(o referir al oftalmólogo si no es factible).",
  message4: "Referir al oftalmólogo.",
  message5: "Referir al oftalmólogo.",
  message6: "Revalorar en 12 meses del otro ojo.",
  message7: "Imagen no graduable. Referir a un especialista.",
  message8:
    "Los resultados mostrados en el presente informe son de carácter meramente informativo y bajo ningún criterio podrán ser considerados como un diagnóstico para el paciente.",

  // message1:           'Revisión dentro de 3-6 meses con un oftalmólogo para evaluación de retinopatía',
  // message2:           'diabética referible.',
  // message3:           'Revisión inmediata con un oftalmólogo para evaluación de retinopatía diabética con',
  // message4:           'riesgo de pérdida de la visión.',
  title_comments: "Comentarios",
  left_eye: "OJO IZQUIERDO",
  right_eye: "OJO DERECHO",
};

const templateNotGradable = (pdf) => {
  // Rectangle gray not-grader
  pdf.setFillColor(colors.dark_gray);
  pdf.rect(35, 140, 140, 30, "F");

  // Not Gradable
  pdf.setFontSize(40);
  pdf.setTextColor(colors.black);
  pdf.text(50, 160, text.not_grader);
  pdf.text(50, 160, text.not_grader);
  pdf.text(50, 160, text.not_grader);
  pdf.text(50, 160, text.not_grader);
  pdf.text(50, 160, text.not_grader);
};

const levelRetinopathy = (pdf, patient) => {
  // Rectangle left eye
  pdf.setFillColor(patient.color_left);
  pdf.rect(25, 165, 60, 37, "F");

  // Rectangle right eye
  pdf.setFillColor(patient.color_right);
  pdf.rect(125, 165, 60, 37, "F");

  // Rectangle gray info
  pdf.setFillColor(colors.gray);
  pdf.rect(25, 217, 160, 20, "F");

  if (patient.highest_level <= 1) {
    // Rectangle a side
    pdf.setFillColor(colors.green);
    pdf.rect(25, 217, 3, 20, "F");
  }
  if (patient.highest_level === 2) {
    // Rectangle a side
    pdf.setFillColor(colors.yellow);
    pdf.rect(25, 217, 3, 20, "F");
  }
  if (patient.highest_level >= 3) {
    // Rectangle a side
    pdf.setFillColor(colors.red);
    pdf.rect(25, 217, 3, 20, "F");
  }

  // Level Left
  pdf.setFontSize(40);
  pdf.setTextColor(colors.black);
  pdf.text(47, 185, patient.levelLeft);
  pdf.text(47, 185, patient.levelLeft);
  pdf.text(47, 185, patient.levelLeft);

  // Level Right
  pdf.setFontSize(40);
  pdf.setTextColor(colors.black);
  pdf.text(147, 185, patient.levelRight);
  pdf.text(147, 185, patient.levelRight);
  pdf.text(147, 185, patient.levelRight);

  // Level left
  pdf.setFontSize(10);
  pdf.setTextColor(colors.black);
  pdf.text(43, 162, text.left_eye);

  // Level Right
  pdf.setFontSize(10);
  pdf.setTextColor(colors.black);
  pdf.text(143, 162, text.right_eye);

  // Edema Left
  pdf.setFontSize(12);
  pdf.setTextColor(colors.black);
  if (patient.macularEdemaLeft) {
    pdf.text(30, 208, text.with_edema);
    pdf.text(30, 208, text.with_edema);
    pdf.text(30, 208, text.with_edema);
  } else {
    pdf.text(30, 208, text.without_edema);
  }

  // Edema Left
  pdf.setFontSize(12);
  pdf.setTextColor(colors.black);
  if (patient.macularEdemaRight) {
    pdf.text(132, 208, text.with_edema);
    pdf.text(132, 208, text.with_edema);
    pdf.text(132, 208, text.with_edema);
  } else {
    pdf.text(132, 208, text.without_edema);
  }

  // Message Alert
  pdf.setFontSize(10);
  pdf.setTextColor(colors.black);
  if (patient.highest_level <= 1) {
    pdf.text(35, 225, text.message1);
    // pdf.text(35, 231, text.message2)
  } else if (patient.highest_level === 2) {
    pdf.text(35, 225, text.message3);
  } else if (patient.highest_level === 3) {
    pdf.text(35, 225, text.message4);
  } else if (patient.highest_level === 4) {
    pdf.text(35, 225, text.message5);
  } else if (patient.highest_level === 5) {
    pdf.text(35, 225, text.message6);
  } else {
    // Message Alert
    pdf.text(35, 225, text.message7);
    pdf.text(35, 231, "");
  }
};

const comments = (pdf, commentGraders) => {
  pdf.setFontSize(15);
  pdf.setTextColor(colors.black);
  pdf.text(10, 253, text.title_comments);
  pdf.text(10, 253, text.title_comments);
  pdf.text(10, 253, text.title_comments);

  pdf.setFontSize(10);
  pdf.setTextColor(colors.black);
  let position = 260; // 223
  commentGraders.forEach((i) => {
    pdf.text(10, position, i);
    position += 5;
  });

  // pdf.text(10, 260, 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisi diam. Morbi in volutpat libero, eu convallis lacus.')
  // pdf.text(10, 265, 'In pretium diam sed enim mattis aliquet non ac tortor. Etiam eget ex lorem. Duis finibus metus nisi, mollis tincidunt ex')
  // pdf.text(10, 270, 'placerat eu. Etiam condimentum gravida felis sit amet porttitor. Vestibulum laoreet semper aliquam. Sed porta ut dolor')
  // pdf.text(10, 275, 'ut vestibulum. Ut iaculis felis in urna mattis accumsan. Suspendisse potenti. Nam eget orci ac massa finibus finibus.')

  pdf.setFontSize(7);
  pdf.text(10, 290, text.message8);
};

export const createPdf = (data, personalData) => {
  const notGradable = data.notGradable;

  const r_l_l = Number(data.retinopathy_level_left);
  const r_l_r = Number(data.retinopathy_level_right);

  const name = data.patient_name ? data.patient_name : "";
  const lastName = data.patient_last_name ? data.patient_last_name : "";
  const email = data.patient_email ? data.patient_email : "";
  const phone = data.patient_phone ? data.patient_phone : "";
  const birthdate = data.patient.birthdate ? data.patient.birthdate : "";
  const gender = data.patient.gender === "MALE" ? text.male : text.female;
  const record = data.patient.record ? data.patient.record : "";
  const date = data.updated_at ? data.updated_at : "";
  const created_at = data.created_at ? data.created_at : "";
  const completed_date = data.completed_date ? data.completed_date : "";

  const pictureLeftEyeBBase64 = data.optical_disk_centered_left_eye_b64;
  const pictureRightEyeBBase64 = data.optical_disk_centered_right_eye_b64;
  const pictureLeftEyeABase64 = data.macula_centered_left_eye_b64 ?? '';
  const pictureRightEyeABase64 = data.macula_centered_right_eye_b64 ?? '';

  const macularEdemaLeft = data.macular_edema_left;
  const macularEdemaRight = data.macular_edema_right;

  let commentGraders = [];

  let levelLeft;
  let levelRight;

  if (r_l_l === 0) {
    levelLeft = "R0";
  }
  if (r_l_l === 1) {
    levelLeft = "R1";
  }
  if (r_l_l === 2) {
    levelLeft = "R2";
  }
  if (r_l_l === 3) {
    levelLeft = "R3";
  }
  if (r_l_l === 4) {
    levelLeft = "R4";
  }
  if (r_l_l === 5) {
    levelLeft = "R5";
  }
  if (r_l_l === 6) {
    levelLeft = "R6";
  }

  if (r_l_r === 0) {
    levelRight = "R0";
  }
  if (r_l_r === 1) {
    levelRight = "R1";
  }
  if (r_l_r === 2) {
    levelRight = "R2";
  }
  if (r_l_r === 3) {
    levelRight = "R3";
  }
  if (r_l_r === 4) {
    levelRight = "R4";
  }
  if (r_l_r === 5) {
    levelRight = "R5";
  }
  if (r_l_r === 6) {
    levelRight = "R6";
  }

  // const levelLeft     = r_l_l===0?'R0':r_l_l===1?'R1':r_l_l===2?'R2':r_l_l===3?'R3':r_l_l===4?'R4'?r_l_l===5?'R5':'R6':'':''
  // const levelRight    = r_l_r===0?'R0':r_l_r===1?'R1':r_l_r===2?'R2':r_l_r===3?'R3':'R4'
  const color_left = r_l_l === 0 ? colors.green : r_l_l === 1 ? colors.green : r_l_l === 2 ? colors.yellow : colors.red;
  const color_right =
    r_l_r === 0 ? colors.green : r_l_r === 1 ? colors.green : r_l_r === 2 ? colors.yellow : colors.red;

  data.mapGraders &&
    data.mapGraders.forEach((i) => {
      commentGraders.push(i.coments);
    });

  let highest_level = 0;
  if (r_l_l >= r_l_r) {
    highest_level = r_l_l;
  } else {
    highest_level = r_l_r;
  }

  const patient = {
    levelLeft,
    levelRight,
    color_left,
    color_right,
    highest_level,
    macularEdemaLeft,
    macularEdemaRight,
  };

  let pdf = new jsPDF("p", "mm", "a4");

  pdf.setFontSize(16);
  pdf.setTextColor(colors.black);
  pdf.setFillColor(colors.purple);

  // Rectangle purple
  pdf.setFillColor(colors.purple);
  pdf.rect(0, 65, 220, 10, "F");

  // Ractangle gray info
  pdf.setFillColor(colors.gray);
  pdf.rect(0, 75, 220, 37, "F");

  // Is Gradable
  if (notGradable) {
    templateNotGradable(pdf);
  } else {
    levelRetinopathy(pdf, patient);
    comments(pdf, commentGraders);
  }

  // Info rectangle gray
  pdf.setFontSize(16);
  pdf.setTextColor(colors.black);
  pdf.text(10, 90, name + " " + lastName);
  pdf.text(10, 90, name + " " + lastName);
  pdf.setFontSize(12);
  pdf.setTextColor(colors.light_black);
  pdf.text(10, 97, email);
  pdf.text(10, 103, phone);
  pdf.text(160, 97, formatDate3(birthdate));
  pdf.text(160, 103, gender);
  pdf.text(160, 103, gender);
  pdf.text(160, 103, gender);

  // Title Info Patient
  pdf.setFontSize(16);
  pdf.setTextColor(colors.white);
  pdf.text(10, 72, text.title_info_patient);

  // Code - Date
  pdf.setFontSize(10);
  pdf.setTextColor(colors.light_black);
  pdf.setTextColor(colors.light_black);
  pdf.setTextColor(colors.light_black);
  // pdf.text(140, 50, record+' - '+` ${formatDate3(date)}`)
  pdf.text(183, 40, record);
  pdf.text(140, 45, `Creado - ${created_at !== "0000-00-00 00:00:00" ? formatDate3(created_at) : ""}`);
  pdf.text(140, 50, `Finalizado - ${completed_date !== "0000-00-00 00:00:00" ? formatDate3(completed_date) : ""}`);

  // Title level
  pdf.setFontSize(20);
  pdf.setTextColor(colors.black);
  pdf.text(140, 36, text.title_level);
  pdf.text(140, 36, text.title_level);
  pdf.text(140, 36, text.title_level);
  pdf.text(140, 36, text.title_level);

  // ─── IMAGES ─────────────────────────────────────────────────────────────────────
  pdf.addImage(logo, "PNG", 13, 10);

  // if(!not_gradable){

  if (data.study_type == 1) {
    // ─── EYE 1 ──────────────────────────────────────────────────────────────────────
    pdf.addImage(pictureLeftEyeBBase64, "PNG", 59, 115);
    // ─── EYE 2 ──────────────────────────────────────────────────────────────────────
    pdf.addImage(pictureRightEyeBBase64, "PNG", 111, 115);
  } else {
    // ─── EYE 1 ──────────────────────────────────────────────────────────────────────
    pdf.addImage(pictureLeftEyeBBase64, "PNG", 5, 115);
    // ─── EYE 2 ──────────────────────────────────────────────────────────────────────
    pdf.addImage(pictureLeftEyeABase64, "PNG", 59, 115);
    
    // ─── EYE 3 ──────────────────────────────────────────────────────────────────────
    pdf.addImage(pictureRightEyeBBase64, "PNG", 111, 115);
    // ─── EYE 4 ──────────────────────────────────────────────────────────────────────
    pdf.addImage(pictureRightEyeABase64, "PNG", 163, 115);
  }

  // }

  pdf.save("solicitud.pdf");
};
