import {useState, useEffect}   from 'react';
import {Ic_image} from 'values';

const DragImage = ({image, blockData, setImage, option='', text, width='100%', height=230}) => {
    const [img, setImg] = useState(image)
    const handleImage = (imag) => {
        const url = window.URL.createObjectURL(imag.files[0])
        setImage(url)
        setImage(imag.files[0])
    }

    useEffect(() =>{
        if(image){
            if(typeof image==='object'){     
                const url = window.URL.createObjectURL(image)
                setImg(url)
            } else{
                setImg(image)
            }
        } else{
            setImg('')
        }
    },[image])
    return (
        <div>
            <label htmlFor={blockData === 'disabled' ? '' : `file-upload${option}`} style={{cursor:blockData==='disabled'?'default':'pointer', position:'relative'}}>
                <div className="container_drag_image_request" style={{width:'100%', height:'calc(95vh - 30px)', background:'#000'}}>
                    {
                        img?
                            <img alt="" src={img} className="drag_image_request" />
                        :
                            <div>
                                <img alt="" src={img ? img : Ic_image} style={{width:130, height:130}} />
                            </div>
                    }
                </div>
            </label>
            <input id={`file-upload${option}`} type="file" accept="image/*" style={{display:'none'}} onChange={e => handleImage(e.target) } />
        </div>
    );
};

export default DragImage;