import {urlApi, urlPath, get, update, post, deleteRegister, new_update} from '../api';
const url = urlApi + urlPath.request

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const startGrader = async(id, token) =>{
    const auxUrl = urlApi + urlPath.request

    const obj = {}

    const request = {
        method:"PATCH",
        body: JSON.stringify(obj),
        headers:{
            'token': token,
            'Content-Type': 'application/json'
        }
    }

    await fetch(auxUrl+'/'+id+'/start', request)
    .then(async(res) =>{
        console.log('start request:', res);
    })
}

export const updateRequestData = async(
	id, rlOpticalDiskCenteredRight, rlOpticalDiskCenteredLeft, rlMaculaCenteredRight, rlMaculaCenteredLeft,
	rlTemporalMaculaRight, rlTemporalMaculaLeft, rlUpTempTangentialRight, rlUpTempTangentialLeft, rlLoTempTangentialRight,
	rlLoTempTangentialLeft, rlUpNasalTangentialRight, rlUpNasalTangentialLeft, rlLoNasalTangentialRight, rlLoNasalTangentialLeft,
	meOpticalDiskCenteredRight, meOpticalDiskCenteredLeft, meMaculaCenteredRight, meMaculaCenteredLeft, meTemporalMaculaRight,
	meTemporalMaculaLeft, meUpTempTangentialRight, meUpTempTangentialLeft, meLoTempTangentialRight, meLoTempTangentialLeft,
	meUpNasalTangentialRight, meUpNasalTangentialLeft, meLoNasalTangentialRight, meLoNasalTangentialLeft, study_type, coments, graderId, token
) => {

	const obj = {
		rl_optical_disk_centered_right_eye:		rlOpticalDiskCenteredRight,
		rl_optical_disk_centered_left_eye:		rlOpticalDiskCenteredLeft,
		rl_macula_centered_right_eye:			study_type >= 2 ? rlMaculaCenteredRight : null,
		rl_macula_centered_left_eye:			study_type >= 2 ? rlMaculaCenteredLeft : null,
		rl_temporal_macula_right_eye:			study_type >= 3 ? rlTemporalMaculaRight : null,
		rl_temporal_macula_left_eye:			study_type >= 3 ? rlTemporalMaculaLeft : null,
		rl_upper_temporal_tangential_right_eye:	study_type >= 4 ? rlUpTempTangentialRight : null,
		rl_upper_temporal_tangential_left_eye:	study_type >= 4 ? rlUpTempTangentialLeft : null,
		rl_lower_temporal_tangential_right_eye:	study_type >= 5 ? rlLoTempTangentialRight : null,
		rl_lower_temporal_tangential_left_eye:	study_type >= 5 ? rlLoTempTangentialLeft : null,
		rl_upper_nasal_tangential_right_eye:	study_type >= 6 ? rlUpNasalTangentialRight : null,
		rl_upper_nasal_tangential_left_eye:		study_type >= 6 ? rlUpNasalTangentialLeft : null,
		rl_lower_nasal_tangential_right_eye:	study_type >= 7 ? rlLoNasalTangentialRight : null,
		rl_lower_nasal_tangential_left_eye:		study_type >= 7 ? rlLoNasalTangentialLeft : null,

		me_optical_disk_centered_right_eye:		meOpticalDiskCenteredRight,
		me_optical_disk_centered_left_eye:		meOpticalDiskCenteredLeft,
		me_macula_centered_right_eye:			study_type >= 2 ? meMaculaCenteredRight : null,
		me_macula_centered_left_eye:			study_type >= 2 ? meMaculaCenteredLeft : null,
		me_temporal_macula_right_eye:			study_type >= 3 ? meTemporalMaculaRight : null,
		me_temporal_macula_left_eye:			study_type >= 3 ? meTemporalMaculaLeft : null,
		me_upper_temporal_tangential_right_eye:	study_type >= 4 ? meUpTempTangentialRight : null,
		me_upper_temporal_tangential_left_eye:	study_type >= 4 ? meUpTempTangentialLeft : null,
		me_lower_temporal_tangential_right_eye:	study_type >= 5 ? meLoTempTangentialRight : null,
		me_lower_temporal_tangential_left_eye:	study_type >= 5 ? meLoTempTangentialLeft : null,
		me_upper_nasal_tangential_right_eye:	study_type >= 6 ? meUpNasalTangentialRight : null,
		me_upper_nasal_tangential_left_eye:		study_type >= 6 ? meUpNasalTangentialLeft : null,
		me_lower_nasal_tangential_right_eye:	study_type >= 7 ? meLoNasalTangentialRight : null,
		me_lower_nasal_tangential_left_eye:		study_type >= 7 ? meLoNasalTangentialLeft : null,
		coments,
		graderId
	}

	return new_update(obj, `${url}/${id}`, token);
}

export const updateData2 = async(id, token) =>{
    const auxId = `${id}/${urlPath.requestNotGradable}`
    const obj = {}
    return await update(obj, url, auxId, token)
}

export const validateRequestData = (
	rlOpticalDiskCenteredRight, rlOpticalDiskCenteredLeft, rlMaculaCenteredRight, rlMaculaCenteredLeft, rlTemporalMaculaRight,
	rlTemporalMaculaLeft, rlUpTempTangentialRight, rlUpTempTangentialLeft, rlLoTempTangentialRight, rlLoTempTangentialLeft,
	rlUpNasalTangentialRight, rlUpNasalTangentialLeft, rlLoNasalTangentialRight, rlLoNasalTangentialLeft,
	meOpticalDiskCenteredRight, meOpticalDiskCenteredLeft, meMaculaCenteredRight, meMaculaCenteredLeft, meTemporalMaculaRight,
	meTemporalMaculaLeft, meUpTempTangentialRight, meUpTempTangentialLeft, meLoTempTangentialRight, meLoTempTangentialLeft,
	meUpNasalTangentialRight, meUpNasalTangentialLeft, meLoNasalTangentialRight, meLoNasalTangentialLeft,
) => {
	return new Promise((resolve, reject) => {
		let success = true;
		let errors = [];

		if (isNaN(rlOpticalDiskCenteredRight) || rlOpticalDiskCenteredRight < 0 || rlOpticalDiskCenteredRight > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo derecho centrada en disco no es valido.');
		}
		if (isNaN(rlOpticalDiskCenteredLeft) || rlOpticalDiskCenteredLeft < 0 || rlOpticalDiskCenteredLeft > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo izquierdo centrada en disco no es valido.');
		}
		if (isNaN(rlMaculaCenteredRight) || rlMaculaCenteredRight < 0 || rlMaculaCenteredRight > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo derecho centrada en macula no es valido.');
		}
		if (isNaN(rlMaculaCenteredLeft) || rlMaculaCenteredLeft < 0 || rlMaculaCenteredLeft > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo izquierdo centrada en macula no es valido.');
		}
		if (isNaN(rlTemporalMaculaRight) || rlTemporalMaculaRight < 0 || rlTemporalMaculaRight > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo derecho temporal a la macula no es valido.');
		}
		if (isNaN(rlTemporalMaculaLeft) || rlTemporalMaculaLeft < 0 || rlTemporalMaculaLeft > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo izquierdo temporal a la macula no es valido.');
		}
		if (isNaN(rlUpTempTangentialRight) || rlUpTempTangentialRight < 0 || rlUpTempTangentialRight > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo derecho tangencial temporal superior no es valido.');
		}
		if (isNaN(rlUpTempTangentialLeft) || rlUpTempTangentialLeft < 0 || rlUpTempTangentialLeft > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo izquierdo tangencial temporal superior no es valido.');
		}
		if (isNaN(rlLoTempTangentialRight) || rlLoTempTangentialRight < 0 || rlLoTempTangentialRight > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo derecho tangencial temporal inferior no es valido.');
		}
		if (isNaN(rlLoTempTangentialLeft) || rlLoTempTangentialLeft < 0 || rlLoTempTangentialLeft > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo izquierdo tangencial temporal inferior no es valido.');
		}
		if (isNaN(rlUpNasalTangentialRight) || rlUpNasalTangentialRight < 0 || rlUpNasalTangentialRight > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo derecho tangencial nasal superior no es valido.');
		}
		if (isNaN(rlUpNasalTangentialLeft) || rlUpNasalTangentialLeft < 0 || rlUpNasalTangentialLeft > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo izquierdo tangencial nasal superior no es valido.');
		}
		if (isNaN(rlLoNasalTangentialRight) || rlLoNasalTangentialRight < 0 || rlLoNasalTangentialRight > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo derecho tangencial nasal inferior no es valido.');
		}
		if (isNaN(rlLoNasalTangentialLeft) || rlLoNasalTangentialLeft < 0 || rlLoNasalTangentialLeft > 6) {
			success = false;
			errors.push('Nivel de retinopatia en imagen del ojo izquierdo tangencial nasal inferior no es valido.');
		}

		if (!['M0', 'M1', 'M2'].includes(meOpticalDiskCenteredRight)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo derecho centrada en disco no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meOpticalDiskCenteredLeft)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo izquierdo centrada en disco no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meMaculaCenteredRight)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo derecho centrada en macula no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meMaculaCenteredLeft)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo izquierdo centrada en macula no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meTemporalMaculaRight)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo derecho temporal a la macula no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meTemporalMaculaLeft)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo izquierdo temporal a la macula no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meUpTempTangentialRight)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo derecho tangencial temporal superior no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meUpTempTangentialLeft)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo izquierdo tangencial temporal superior no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meLoTempTangentialRight)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo derecho tangencial temporal inferior no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meLoTempTangentialLeft)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo izquierdo tangencial temporal inferior no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meUpNasalTangentialRight)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo derecho tangencial nasal superior no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meUpNasalTangentialLeft)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo izquierdo tangencial nasal superior no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meLoNasalTangentialRight)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo derecho tangencial nasal inferior no es valido.');
		}
		if (!['M0', 'M1', 'M2'].includes(meLoNasalTangentialLeft)) {
			success = false;
			errors.push('Nivel de edema macular en imagen del ojo izquierdo tangencial nasal inferior no es valido.');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}