import { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// Redux
import { changeView, cleanData } from "../../store/redux/actions/Global";
// Components
import Input from "../global/Input";
import ButtonForm from "../global/ButtonForm";
import ModalGlobal from "../global/ModalGlobal";
import Select from "../global/Select";
import SelectV2 from "../global/SelectV2";
import DatePickers from "../global/DatePickers";
// Values
import { StateView, UserSex, UserStatusCivil, toastText } from "../../values";
// Api
import { validatePatientData, updatePatientData, postPatientData, deletePatientData } from "../../utils/patients";
import {regex_email, regex_names_one, regex_names_three, regex_curp, capitalizeText, manage_request_errors} from 'utils';

const Form = ({ data, stateView, token, medicalCenterId, changeView, cleanData }) => {
	const [blockData, setBlockData] = useState("");
	const [status_edit, setStatusEdit] = useState(stateView === StateView.state3);
	const [status_add, setStatusAdd] = useState(stateView === StateView.state2);

	// Modal
	const [open1, setOpen1] = useState(false);
	const [loading, setLoading] = useState(false);

	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [address, setAddress] = useState("");
	const [birthdate, setBirthdate] = useState("");
	const [civilState, setCivilState] = useState("");
	const [sex, setSex] = useState("");
	const [curp, setCurp] = useState("");
	const [files, setFiles] = useState("");

	const cancelEdit = () => {
		setStatusEdit(true);
		setStatusAdd(false);
		setBlockData("disabled");
		if (data.id) {
			maptData();
		}
	};

	const Update = async () => {
		setStatusAdd(true);
		setBlockData("");
		setStatusEdit(false);
	};

	const Delete = async () => {
		changeView(StateView.state4);
		setTimeout(() => {
			deleteRegister();
		}, 2000);
	};

	const cleanLocalData = () => {
		setName("");
		setLastName("");
		setEmail("");
		setPhone("");
		setAddress("");
		setBirthdate("");
		setCivilState("");
		setSex("");
		setCurp("");
		setFiles("");
	};

	const maptData = () => {
		setName(data.name);
		setLastName(data.lastName);
		setEmail(data.email);
		setPhone(data.phone);
		setAddress(data.address);
		setBirthdate(`${data.birthdate}T06:00:00Z`);
		setCivilState(data.civil_status);
		setSex(data.gender);
		setCurp(data.curp);
		setFiles(data.record);
	};

	const patchData = async () => {
		validatePatientData(name, lastName, email, phone, address, birthdate, civilState, sex, curp, files)
		.then(_ => {
			return updatePatientData(data.id, name, lastName, email, phone, address, birthdate, civilState, sex, curp, files, medicalCenterId, token);
		})
		.then(request_response => {console.log(request_response);return request_response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			toast.success(toastText.add);
			setLoading(false);
			cleanLocalData();
			cleanData();
		})
		.catch(error => {
			setLoading(false);
			manage_request_errors(error);
		});
	};

	const addData = async () => {
		validatePatientData(name, lastName, email, phone, address, birthdate, civilState, sex, curp, files)
		.then(_ => {
			return postPatientData(name, lastName, email, phone, address, birthdate, civilState, sex, curp, files, medicalCenterId, token);
		})
		.then(request_response => {return request_response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			toast.success(toastText.add);
			setLoading(false);
			cleanLocalData();
			cleanData();
		})
		.catch(error => {
			setLoading(false);
			manage_request_errors(error);
		});
	};

	const deleteRegister = async () => {
		deletePatientData(data.id, token)
		.then(request_response => {return request_response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			toast.success(toastText.delete);
			changeView(StateView.state1);
			cleanData();
		})
		.catch(error => {
			setLoading(false);
			changeView(StateView.state1);
			manage_request_errors(error);
		});
	};

	const onSubmit = () => {
		setLoading(true);
		if (stateView === StateView.state3) {
			patchData();
		} else {
			addData();
		}
	};

	useEffect(() => {
		setLoading(false);
		if (stateView === StateView.state3) {
			setStatusEdit(true);
			setBlockData("disabled");
			maptData();
		} else {
			setStatusEdit(false);
			setBlockData("");
			cleanLocalData();
		}

		if (stateView === StateView.state2) {
			setStatusAdd(true);
			setBlockData("");
			cleanLocalData();
		} else {
			setBlockData("disabled");
			setStatusAdd(false);
		}
	}, [stateView, data.id]);

	return (
		<div className="card_info">
			<div style={{ height: 30 }} />
			<ModalGlobal
				open={open1}
				onClose={setOpen1}
				title={"Eliminar"}
				text_button1={"Eliminar"}
				text_button2={"Cancelar"}
				button={Delete}
				info={`¿Seguro que deseas eliminar este paciente "${capitalizeText(`${data.name} ${data.lastName}`)}"?`}
				button_color={"#ce0e4f"}
				text_color={"#fff"}
			/>

			<div className="card_scroll">
				<Container>
					<div style={{ backgroundColor: "#f1f4f8", paddingTop: 15, paddingBottom: 15 }}>
						<Container>
							<Input title={"Nombre"} value={name} setData={setName} blockData={blockData} regex={regex_names_one} />
							<Input title={"Apellido"} value={lastName} setData={setLastName} blockData={blockData} regex={regex_names_one} />
							<Input title={"Correo"} value={email} setData={setEmail} blockData={blockData} required={false} regex={regex_email} color={"#648CFA"} />
							<Input title={"Teléfono"} value={phone} setData={setPhone} blockData={blockData} required={false} color={"#648CFA"} />
							<Input title={"Domicilio"} value={address} setData={setAddress} blockData={blockData} required={false} regex={regex_names_three} color={"#648CFA"} />

							<DatePickers
								title={"Fecha de nacimiento"}
								Release={birthdate}
								setData={setBirthdate}
								disabled={blockData}
							/>

							<Select
								title={"Estado civil"}
								value={civilState}
								setData={setCivilState}
								textOption={"Selecciona el estado civil"}
								list={UserStatusCivil}
								blockData={blockData}
							/>

							<SelectV2
								title={"Sexo"}
								value={sex}
								setData={setSex}
								list={UserSex}
								textOption={"Selecciona el sexo"}
								blockData={blockData}
							/>

							<Input title={"Curp"} value={curp} setData={setCurp} blockData={blockData} required={false} regex={regex_curp} color={"#648CFA"} />
							<Input title={"Expediente"} value={files} setData={setFiles} blockData={blockData} />

							<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
								<div style={{ width: 8, height: 8, background: "#648CFA", borderRadius: 50 }} />
								<div style={{ width: 5 }} />
								<p style={{ color: "#648CFA", fontWeight: 600 }}>{"Opcionales"}</p>
							</div>
						</Container>
					</div>
					<ButtonForm
						loading={loading}
						onSubmit={onSubmit}
						button_edit={Update}
						button_delete={() => setOpen1(true)}
						status_edit={status_edit}
						status_add={status_add}
						cancelEdit={cancelEdit}
					/>
				</Container>
				<div style={{ height: 30 }} />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	stateView:			state.Global.stateView,
	token:				state.PanelUser.token,
	data:				state.Patients.dataEdit,
	medicalCenterId:	state.PanelUser.medicalCenterId,
});

export default connect(mapStateToProps, {changeView, cleanData})(Form);