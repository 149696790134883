export const urlApi = `${process.env.REACT_APP_SYNAPBIT_API_URL}/`;

export const urlPath = {
  hospitals: "hospitals",
  allHospitals: "all-hospitals",
  searchHospitals: "search-hospitals",
  users: "users",
  searchUsers: "search-users",
  login: "login",
  patients: "patients",
  allPatients: "all-patients",
  searchPatients: "search-patients",
  request: "request",
  requestNotGradable: "no-graduable",
  research: "research",
  researches: "researches",
  authenticate: "authenticate",
  me: "me",
  file: "file/container",
  fileUpload: "file/upload",
  forgotPassword: "users/forgot",
  recover: "users/recover",
  grader_reports: "grader/reports",
  photographer_reports: "photographer/reports",

	investigation_datasets: "investigation/datasets",
	investigation_requests: "investigation/requests",
	investigation_results: "investigation/results",
	investigation_researches: "investigation/researches",
};

export const generateContainer = async (token) => {
  const url = urlApi + urlPath.file;
  let code = "";
  let flag = false;

  const request = {
    method: "GET",
    headers: {
      token: token,
    },
  };

  await fetch(url, request)
    .then(async (res) => {
      if (res.status === 200) {
        flag = true;
        console.log(res.status);
        return res.json();
      }
    })
    .then((response) => {
      if (flag === true) {
        code = response;
      }
    });
  return code.data;
};

export const get = async (url, token, extra = "") => {
  let data = [];
  let flag = false;
  // console.log("URL: ", url+extra);
  const request = {
    method: "GET",
    headers: {
      token: token,
    },
  };
  await fetch(url + extra, request)
    .then(async (res) => {
      if (res.status === 200) {
        flag = true;
        // console.log(res.status);
        return res.json();
      } else {
        console.log(res);
      }
    })
    .then((response) => {
      if (flag === true) {
        data = response;
      }
    })
    .catch(() => {
      flag = false;
    });
  return { data: data, flag: flag };
};

export const update = async (obj, url, id, token) => {
  const request = {
    method: "PATCH",
    body: JSON.stringify(obj),
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
  };
  const res = await fetch(url + "/" + id, request)
    .then(async (res) => {
      if (res.status === 200) {
        // console.log(res.status);
        return true;
      } else {
        console.log(res);
        return false;
      }
    })
    .catch((err) => {
      return false;
    });
  return res;
};

export const post = async (obj, url, token) => {
  const request = {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
  };

  const res = await fetch(url, request)
    .then(async (res) => {
      if (res.status === 200) {
        // console.log(res.status);
        return true;
      } else {
        console.log(res);
        return false;
      }
    })
    .catch(() => {
      return false;
    });
  return res;
};

export const deleteRegister = async (url, id, token) => {
  const request = {
    method: "DELETE",
    headers: {
      token: token,
    },
  };
  const res = await fetch(url + "/" + id, request)
    .then(async (res) => {
      if (res.status === 200) {
        // console.log(res.status);
        return true;
      } else {
        console.log(res.status);
        return false;
      }
    })
    .catch(() => {
      return false;
    });
  return res;
};

export const new_post = (data, url, token) => {
	const request = {
		method: "POST",
		body: JSON.stringify(data),
		headers: {
			token: token,
			"Content-Type": "application/json",
		},
	};

	return fetch(url, request);
};

export const new_update = (data, url, token) => {
	const request = {
		method: "PATCH",
		body: JSON.stringify(data),
		headers: {
			token: token,
			"Content-Type": "application/json",
		},
	};

	return fetch(url, request);
};

export const new_get = (url, token, extra = "") => {
	const request = {
		method: "GET",
		headers: {
			token: token,
			"Content-Type": "application/json",
		},
	};

	const complete_url = `${url}${extra}`;

	return fetch(complete_url, request);
}

export const new_delete = (url, token) => {
	const request = {
		method: "DELETE",
		headers: {
			token: token,
		},
	};

	return fetch(url, request);
}

export const new_generate_container = (token, many=false, amount=0) => { // Validate where this method is called
	let url = `${urlApi}${urlPath.file}`;

	const request = {
		method: "POST",
		body: JSON.stringify({
			amount: many && amount > 0 ? amount : 1,
		}),
		headers: {
		  token: token,
		  "Content-Type": "application/json"
		},
	};

	return fetch(url, request);
}