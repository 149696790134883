import {useEffect} from 'react';
import {connect}    from 'react-redux';
// Redux
import {getUsers} from 'store/redux/actions/Users';
import Tables from './Tables';
// Values
import {StateView} from 'values';
// Api
import {getData} from 'utils/users';
import {getData as getDataReports} from 'utils/reports';


const Performance = ({stateView, token, page, getUsers}) => {
    const GetData = async() =>{
        const extra = `?page=${page}&limit=100`
        const res = await getData(token, extra)
        try {
            getUsers(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
        } catch (error) { }
    }
    useEffect(() =>{
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    })
    return (
        <div style={{display:'flex', position:'absolute', top:20, left:180}}>
            <div className="container_performance">
                <p style={{fontSize:20, fontFamily:'semi'}}>{'Reportes'}</p>
                <Tables />
            </div>
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.Global.stateView,
    token:          state.PanelUser.token,
    page:           state.Users.page
})

export default connect(mapStateToProps, {getUsers})(Performance)
