import {useState, useEffect}   from 'react';
import {Container}	from '@material-ui/core';
import {connect}	from 'react-redux';

// Redux
import {changeView, cleanData} from '../../store/redux/actions/Global';
// Values
import {StateView}   from 'values';

const Form = ({stateView, data, request_index}) => {

	const [requestData, setRequestData]	= useState(null);

	useEffect(() => {
		setRequestData(data.get_requests[request_index]);
	},[stateView, data.id, request_index])

	return (
		<div className="card_info">
			<div style={{height:30}} />

			<div className="card_scroll">
				<Container>
					<div style={{backgroundColor:'#f1f4f8', paddingTop:15, paddingBottom:15}}>
						<Container>
							{
								(stateView===StateView.state3 && requestData) &&
								<div>
									<div style={{display:'flex', margin:'4px 0px'}}>
										<b style={{margin:'auto'}}>{requestData.image_name}</b>
									</div>
									<div style={{display:'flex', justifyContent: 'center', margin:'4px 0px'}}>
										<img src={requestData.image_url} style={{width:'50%'}}></img>
									</div>
									<div style={{display:'flex', margin:'4px 0px'}}>
										{
											requestData.get_qualities.length > 0 ? 
												<table style={{width:'100%'}}>
													<tr>
														{/* <th>Medico Investigador</th>
														<th>Artefactos</th>
														<th>Nitidez</th>
														<th>Campo de vision</th>
														<th>Evaluacion</th>
														<th>Otros hallazgos</th> */}
													</tr>
													{
														requestData.get_qualities.sort((a, b) => a.id - b.id).map((quality, i) => {
															return (
																<tr>
																	<td>G{quality.get_grader.id}</td>
																	<td>
																		{quality.focusing}, {quality.contrast}, {quality.glow}, {quality.artifacts}
																	</td>
																	<td>
																		{quality.optical_disc}, {quality.taint}, {quality.upper_arch}, {quality.lower_arch}
																	</td>
																	{/* <td>{quality.get_grader.name} {quality.get_grader.last_name}</td>
																	<td>{quality.artifacs}</td>
																	<td>{quality.sharpness}</td>
																	<td>{quality.vision_field}</td>
																	<td>{quality.evaluation}</td> */}
																	<td>
																		<ul>
																			{Boolean(quality.waterfall) && <li>Catarata</li>}
																			{Boolean(quality.hemorrhage) && <li>Hemorragia vítrea</li>}
																			{Boolean(quality.injury) && <li>Lesiones predisponentes</li>}
																			{Boolean(quality.breaking_off) && <li>Desprendimiento de la retina por ruptura</li>}
																			{Boolean(quality.hypertensive) && <li>Retinopatía hipertensiva</li>}
																			{Boolean(quality.membrane) && <li>Membrana epirretiniana</li>}
																			{Boolean(quality.macular_hole) && <li>Agujero macular</li>}
																			{Boolean(quality.drusen) && <li>Drusas/Degeneración macular asociada a la edad</li>}
																			{Boolean(quality.corroidal) && <li>Nevo coroideo</li>}
																			{Boolean(quality.excavation) && <li>Excavación de nervio óptico amplia</li>}
																			{Boolean(quality.myopia) && <li>Miopía degenerativa</li>}
																			{Boolean(quality.occlusion) && <li>Oclusión vascular</li>}
																			{Boolean(quality.hyalosis) && <li>Hialosis asteroidea</li>}
																			{Boolean(quality.vascular) && <li>Otros trastornos vasculares</li>}
																			{Boolean(quality.retina) && <li>Otros trastorno de la retina</li>}
																			{Boolean(quality.optic_nerve) && <li>Otros trastornos del nervio óptico</li>}

																			{
																				!Boolean(quality.waterfall) &&
																				!Boolean(quality.hemorrhage) &&
																				!Boolean(quality.injury) &&
																				!Boolean(quality.breaking_off) &&
																				!Boolean(quality.hypertensive) &&
																				!Boolean(quality.membrane) &&
																				!Boolean(quality.macular_hole) &&
																				!Boolean(quality.drusen) &&
																				!Boolean(quality.corroidal) &&
																				!Boolean(quality.excavation) &&
																				!Boolean(quality.myopia) &&
																				!Boolean(quality.occlusion) &&
																				!Boolean(quality.hyalosis) &&
																				!Boolean(quality.vascular) &&
																				!Boolean(quality.retina) &&
																				!Boolean(quality.optic_nerve) && <li>NA</li>
																			}
																		</ul>
																	</td>
																</tr>
															);
														})
													}
												</table>
												:
												<b style={{width:'100%', textAlign:'center'}}>Sin valoraciones</b>
										}
									</div>
								</div>
							}
						</Container>
					</div>

				</Container>
				<div style={{height:30}} />
			</div>
		</div>
	);
};

const mapStateToProps = state =>({
	stateView:	state.Global.stateView,
	data:		state.InvestigationDatasets.dataEdit,
	request_index: state.InvestigationDatasets.request_index,
})

export default connect(mapStateToProps, {changeView, cleanData})(Form)