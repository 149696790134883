import {stateData, reduxState} from 'values';

export const getPatients = (data, records, page) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_patients,
            data:       data,
            status:     stateData.state2,
            records:    records,
            page:       page,
        })
    }
}

export const getAllPatients = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_all_patients,
            data:       data,
        })
    }
}

export const updatePatients = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.update_patients,
            data:       data,
        })
    }
}