import {useState, useEffect}   from 'react';

import {Ic_image} from 'values';

const DragMultipleImages = ({images, blockData, setImage, option='', styleDrag=1}) => {
	const [imgs, setImgs] = useState([]);
	const [imgsCounter, setImgsCounter] = useState(0);
	const [allowedExtensions] = useState(['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff', 'image/webp', 'image/svg+xml', 'image/x-icon']);

	const handleImage = (imag) => {
		console.log(typeof imag, imag);
		const urls = Array.from(imag.files).map(file => {
			if (allowedExtensions.includes(file.type)) {
				return window.URL.createObjectURL(file);
			}
		});
		setImgs(urls);
		setImage([...imag.files].filter(imgs => allowedExtensions.includes(imgs.type)));
		setImgsCounter([...imag.files].filter(imgs => allowedExtensions.includes(imgs.type)).length);
	}

	const dropHandler = (ev) => {
		ev.preventDefault();
		if (ev.dataTransfer.files && ev.dataTransfer.files.length) {
			handleImage(ev.dataTransfer);
		}
	}

	const dragOverHandler = (ev) => {
		ev.preventDefault();
	}

	useEffect(() => {
		if (images.length > 0) {
			if (typeof images === 'object') {
				const urls = Array.from(images).map(file => {
					return window.URL.createObjectURL(file);
				});
				setImgs(urls);
			}
		} else {
			setImgs([]);
		}
	},[images])

	return (
		<div style={{paddingTop:30}}>
			<label
				htmlFor={blockData === 'disabled' ? '' : `files-upload${option}`}
				style={{cursor:blockData === 'disabled' ? 'default' : 'pointer', position:'relative', display:'flex', flexWrap: 'wrap', justifyContent:'space-around', width:'100%'}}
				onDrop={dropHandler}
				onDragOver={dragOverHandler}>
				{
					imgsCounter > 0 ?
						imgs.map((img, i) => {
							return (
								<img alt="" src={img} style={{width:75, height:75, margin: '2px'}} key={i} />
							);
						})
						:
						<div style={{width:'100%', display:'flex', flexWrap: 'wrap', justifyContent:'center'}}>
							<div style={{width:'100%', display:'flex', justifyContent:'center'}}>
								<img alt="" src={Ic_image} style={{width:130, height:130}} />
							</div>
						</div>
				}
				<div style={{width:'100%'}}>
					<p style={{textAlign: 'center'}}>Arrastre aquí las imágenes o haz clic para abrir el explorador de archivos</p>
				</div>
				{/* <div style={{width:'100%'}} >
					<p>Drag one or more files to this <i>drop zone</i>.</p>
				</div> */}
			</label>
			<div style={{background:'#526290', textAlign:'center', width:'100%'}}>
                <p style={{color:'#fff', fontSize:17, margin:0}}>Imágenes para conjunto de datos</p>
                <small style={{color:'#fff', margin:0}}>** Puedes subir hasta 500 imágenes o 1.5GB, y que cada imagen no pese más de 15MB **</small>
            </div>
			<div style={{width:'100%'}}>
				<p style={{textAlign: 'right', marginBottom: '0px'}}>{imgsCounter} imágenes añadidas.</p>
			</div>
			<input
				type="file"
				id={`files-upload${option}`}
				hidden
				multiple // Permite seleccionar múltiples archivos
				accept="image/*" // Acepta solo archivos de imagen (todos los formatos)
				onChange={e => handleImage(e.target) }
				disabled={blockData}
			/>
		</div>
	);
};

export default DragMultipleImages;