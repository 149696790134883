import { useState, useEffect } from "react";
import { Container, IconButton, Grid } from "@material-ui/core";
import { connect } from "react-redux";

// Redux
import { changeView, cleanData } from "../../store/redux/actions/Global";
// Components
import DragImage from "../request/DragImage";
import Modal from "./ModalInfo";
import CardResponse from "./CardResponse";
// Values
import { Ic_back, Ic_next } from "values";

const DisplayImages = ({ position, option, image1, image2, text1, text2 }) => {
  return (
    <div style={{ display: position === option ? "block" : "none" }}>
      <Grid container>
        <Grid item sm={6}>
          <div style={{ padding: "0 10px" }}>
            <DragImage image={image1} text={text1} width={"100%"} height={350} blockData={"disabled"} expand />
          </div>
        </Grid>
        <Grid item sm={6}>
          <DragImage image={image2} text={text2} width={"100%"} height={350} blockData={"disabled"} expand />
        </Grid>
      </Grid>
    </div>
  );
};

const CardItem = ({ text, title }) => {
  return (
    <Grid item sm={6}>
      <label style={{ textTransform: "uppercase", fontSize: 14 }}>{title}</label>
      <div style={{ width: "95%" }}>
        <CardResponse text={text} />
      </div>
    </Grid>
  );
};

const CardItemColor = ({ text, title, value }) => {
  return (
    <Grid item sm={6}>
      <label style={{ textTransform: "uppercase", fontSize: 14 }}>{title}</label>
      <div style={{ width: "95%", marginBottom: 20, background: "#6dd400", display: "flex", alignItems: "center" }}>
        <p style={{ margin: 0, padding: 10, color: "#fff", fontSize: 22, fontWeight: 600 }}>{"SI"}</p>
        <p style={{ margin: 0, padding: "10px 5px", color: "#fff" }}>{text}</p>
      </div>
    </Grid>
  );
};

const Form = ({ stateView, token, data, optionSearch, patients, changeView, cleanData }) => {
  const [blockData, setBlockData] = useState("");
  const [position, setPosition] = useState(0);
  const [open, setOpen] = useState(false);

  const nextPage = () => {
    const sum = position + 1;
    if (sum <= 2) {
      setPosition(sum);
    }
  };
  const backPage = () => {
    const subtraction = position - 1;
    if (subtraction >= 0) {
      setPosition(subtraction);
    }
  };

  useEffect(() => {
    setPosition(0);
  }, [data]);
  return (
    <>
      <Modal data={data} open={open} closeModal={setOpen} />
      <div className="card_info">
        <div className="card_scroll">
          <div style={{ display: "block" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#ffbd00",
                height: 100,
              }}
            >
              <p style={{ margin: 0, color: "#fff", fontSize: 25 }}>{"Solicitud en valoración"}</p>
            </div>
          </div>
          <Container>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <IconButton onClick={() => backPage()}>
                <img alt="" src={Ic_back} />
              </IconButton>
              <IconButton onClick={() => nextPage()}>
                <img alt="" src={Ic_next} />
              </IconButton>
            </div>

            <DisplayImages
              position={position}
              option={0}
              image1={data.picture_left_eye_a}
              image2={data.picture_left_eye_b}
              text1={"Ojo izquierdo centrado en mácula"}
              text2={"Ojo izquierdo centrado en disco"}
            />

            <DisplayImages
              position={position}
              option={1}
              image1={data.picture_right_eye_a}
              image2={data.picture_right_eye_b}
              text1={"Ojo derecho centrado en mácula"}
              text2={"Ojo derecho centrado en disco"}
            />

            <DisplayImages
              position={position}
              option={2}
              image1={data.previous_segment}
              image2={data.previous_segment_b}
              text1={"Segmento anterior - ojo izquierdo"}
              text2={"Segmento anterior - ojo derecho"}
            />
          </Container>
          <div style={{ display: optionSearch ? "block" : "none", marginTop: 20 }}>
            <div style={{ borderTop: "1px solid #f1f3f4", borderBottom: "1px solid #f1f3f4", padding: "10px 0" }}>
              <Container>
                <p
                  style={{ margin: 0, color: "#648CFA", fontWeight: 600, cursor: "pointer" }}
                  onClick={() => setOpen(!open)}
                >
                  {"Resultados de graduadores"}
                </p>
              </Container>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <p>{"OJO IZQUIERDO"}</p>
              <p>{"OJO DERECHO"}</p>
            </div>
            <Container>
              <Grid container>
                <CardItem title={"Artefactos"} text={data.artifacs_left_eye_a} />
                <CardItem title={"Artefactos"} text={data.artifacs_right_eye_a} />

                <CardItem title={"Artefactos"} text={data.artifacs_right_eye_b} />
                <CardItem title={"Artefactos"} text={data.artifacs_left_eye_b} />

                <CardItem title={"Nitidez"} text={data.sharpness_left_eye_a} />
                <CardItem title={"Nitidez"} text={data.sharpness_right_eye_a} />

                <CardItem title={"Nitidez"} text={data.sharpness_left_eye_b} />
                <CardItem title={"Nitidez"} text={data.sharpness_right_eye_b} />

                <CardItem title={"Campo de visión"} text={data.vision_field_left_eye_a} />
                <CardItem title={"Campo de visión"} text={data.vision_field_right_eye_a} />

                <CardItem title={"Campo de visión"} text={data.vision_field_left_eye_b} />
                <CardItem title={"Campo de visión"} text={data.vision_field_right_eye_b} />

                <CardItem title={"Evaluación general"} text={data.evaluation_left_eye_a} />
                <CardItem title={"Evaluación general"} text={data.evaluation_right_eye_a} />

                <CardItem title={"Evaluación general"} text={data.evaluation_left_eye_b} />
                <CardItem title={"Evaluación general"} text={data.evaluation_right_eye_b} />

                {/* <CardItemColor title={'Otros Hallazgos'} text={'Excavación de nervio óptico'} value={false} />
                                <CardItemColor title={'Otros Hallazgos'} text={'Lesiones predisponentes'} value={true} />
                                <CardItemColor title={''} text={'Oclusión vascular'} value={true} /> */}
              </Grid>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  stateView: state.Global.stateView,
  token: state.PanelUser.token,
  data: state.Research.dataEdit,
  patients: state.Patients.allData,
  optionSearch: state.Research.option,
});

export default connect(mapStateToProps, { changeView, cleanData })(Form);
