import {useState}           from 'react';
import {connect}            from 'react-redux';
import {Container, Modal}   from '@material-ui/core';
// Redux
import {getPatients} from '../../store/redux/actions/Patients';

// Compoents
import ModalData    from './ModalData';
import Input        from '../global/Input';
import DatePickers  from '../global/DatePickers';
// Unike key
import {generateUniqueKey, formatDate2}  from 'utils';
// Value
import {Ic_select, Ic_search}  from 'values';
import {getData}  from '../../utils/patients';


const ModalPatients = ({open, open2, token, setOpen, setOpen2, patients, setId, setName, height=300, getPatients}) => {
    const [search, setSearch]               = useState('')
    const [patientsAux, setPatients]        = useState(patients)
    const [localPatient, setLocalPatient]   = useState('')

    const setData = (id, name, lastName, data) =>{
        // console.log('Data: ', name+" "+lastName);
        // console.log('Id: ', id);
        setId(id)
        setName(name+" "+lastName)
        setOpen(false)
        // setInfo(data)
        setLocalPatient(data)
    }

    const searchData = async() =>{
        if(search){
            const extra = `?search=${search}&limit=1000`
            const res = await getData(token, extra)
            // console.log('res: ', res.data.data.data);
            setPatients(res.data.data.data)
            getPatients(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
        } else{
            const extra = `?page=1&limit=30`
            const res = await getData(token, extra)
            getPatients(res.data.data.data, res.data.data.total, res.data.data.offset)
            setPatients(res.data.data.data)
        }
    }


    return (
        <div>
            <Modal open={open} 
                style={{display:'flex', alignItems:'center', justifyContent:'center',}}
                onClose={() => setOpen(!open)}>
                    <Container maxWidth="md">
                        <div className="card_scroll" style={{background:'#fff', minHeight:height, position:'relative', paddingTop:10, paddingBottom:10}}>
                            <Container>
                                <div style={{display:'flex', alignItems:'center', marginBottom:20, position:'relative'}}>
                                    <p style={{fontSize:18, marginRight:20, marginLeft:15}}>{'Pacientes'}</p>
                                    <input className="input" style={{background:'#f3f5f9', borderRadius:5, height:40}} value={search} onChange={(e) =>setSearch(e.target.value)} />
                                    <img alt="" src={Ic_search} onClick={searchData} style={{cursor:'pointer', position:'absolute', right:10}} />
                                </div>
                                {
                                    patientsAux&&
                                    patientsAux.map((i,j) =>(
                                        <div key={j} style={{background:'#f3f5f9'}}>
                                            <Container>
                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:5}}>
                                                    <p style={{fontSize:16, margin:'10px 0'}}>{i.name} {i.last_name}</p>
                                                    {/* <p>{i.}</p> */}
                                                    <div style={{display:'flex'}}>
                                                        <p style={{marginRight:20}}>{formatDate2(i.birthdate)}</p>
                                                        <img alt="" src={Ic_select} style={{cursor:'pointer'}}
                                                            onClick={() => setData(i.id, i.name, i.last_name, i)} />
                                                    </div>
                                                </div>
                                            </Container>
                                        </div>
                                    ))
                                }
                            </Container>
                        </div>
                    </Container>
            </Modal>
        
            <ModalData open={open2} closeModal={setOpen2}
                data={
                    <div>
                        <p style={{margin:0, marginTop:15, fontSize:18, fontWeight:600}}>{'Datos Personales'}</p>
                        <div style={{background:'#f3f5f9', paddingTop:20, paddingBottom:10, marginTop:20}}>
                            <Container>
                                <Input title={'Nombre'} value={localPatient.name} blockData={'disabled'} />
                                <Input title={'Apellido'} value={localPatient.lastName} blockData={'disabled'} />
                                <Input title={'Correo'} value={localPatient.email} blockData={'disabled'} />
                                <Input title={'Teléfono'} value={localPatient.phone} blockData={'disabled'} />
                                <Input title={'Domicilio'} value={localPatient.address} blockData={'disabled'} />
                                <DatePickers title={'Fecha de nacimiento'} Release={`${localPatient.birthdate}T06:00:00Z`} disabled={'disabled'} />
                                <Input title={'Estado civil'} value={localPatient.civilStatus} blockData={'disabled'} />
                                <Input title={'Sexo'} value={localPatient.gender} blockData={'disabled'} />
                                <Input title={'Curp'} value={localPatient.curp} blockData={'disabled'} />
                                <Input title={'Expediente'} value={localPatient.record} blockData={'disabled'} />
                            </Container>
                        </div>
                    </div>
                } />
        </div>
    );
};

const mapStateToProps = state =>({
    patients:   state.Patients.data,
    token:      state.PanelUser.token,
})

export default connect(mapStateToProps, {getPatients})(ModalPatients)