import {useState, useEffect}   from 'react';
import {connect}    from 'react-redux';
// Redux
import {changeView} from '../../store/redux/actions/Global';
import {updateInvestigationDatasets, updateRequest} from '../../store/redux/actions/InvestigationDatasets';
// Components
// Unike key
import {formatDate3, capitalizeText}      from '../../utils';
// Values
import {StateView, Ic_checked, Ic_no_checked}   from '../../values';

const Tables = ({data, user_id, changeView, updateInvestigationDatasets, updateRequest}) => {
	const [selectedDatasetID, setSelectedDatasetID] = useState(0);
	const [datasetRequests, setDatasetRequests] = useState([]);

	const edit = (item, i) => {
		updateRequest(i);
		changeView(StateView.state3);
	}
	
	const selectDataset = (event) => {
		let dataset_id = event.target.value;
		let dataset_requests = data.find(dataset => dataset.id == dataset_id);
		setSelectedDatasetID(dataset_id);
		updateInvestigationDatasets(dataset_requests);

		if (dataset_id > 0) {
			setDatasetRequests(dataset_requests.get_requests);
		} else {
			setDatasetRequests([]);
		}
	}

	useEffect(() => {
		//
	})

	return (
		<div className="card_info">
			<select name="select" onChange={(event) => selectDataset(event)} style={{width:'75%', margin:'12px'}}>
				<option value="0">Selecciona un dataset</option>
				{
					data &&
					data.map((dataset, i) => (
						<option key={i} value={dataset.id}>{capitalizeText(dataset.name)} - {formatDate3(dataset.created_at)}</option>
					))
				}
			</select>

			<div className='card_scroll'>
				{
					selectedDatasetID > 0 && datasetRequests.length > 0 ?
					datasetRequests.map((request, i) => (
						<div key={i} onClick={() => edit(request, i)} className="table_info" style={{userSelect:'none'}}>
							<div style={{marginLeft:50, width:'75%'}}>
								<label style={{fontFamily:'semi', fontSize:16, cursor:'pointer'}}>{request.image_name}</label>
								<img src={request.get_grades.find(grade => grade.grader_id == user_id) ? Ic_checked : Ic_no_checked} style={{width:16, float:'right'}} />
							</div>
						</div>
					))
					:
					<div style={{userSelect:'none'}}>
						<div style={{marginLeft:50}}>
							<label style={{fontFamily:'semi', fontSize:16}}>Selecciona un dataset</label><br/>
						</div>
					</div>
				}
			</div>

		</div>
	);
};

const mapStateToProps = state => ({
	data:		state.InvestigationDatasets.data,
	user_id:	state.PanelUser.id,
})

export default connect(mapStateToProps, {changeView, updateInvestigationDatasets, updateRequest})(Tables)