import ReactLoading from 'react-loading';

// Values
import {Ic_next_white, Ic_edit, Ic_delete } from 'values'; // Ic_back

const ButtonForm = ({text='Guardar', onSubmit, loading, button_edit, button_delete, status_edit, status_add, cancelEdit}) => {
    return (
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <div style={{display:status_edit?'flex':'none'}}>
                <div className="button_edit_delete" onClick={button_edit} style={{background:'#323232'}}>
                    <img alt="" src={Ic_edit} style={{width:15, objectFit:'scale-down'}} />
                </div>

                <div className="button_edit_delete" onClick={button_delete} style={{marginLeft:15, background:'#b5015a'}}>
                    <img alt="" src={Ic_delete} style={{width:15, objectFit:'scale-down'}} />
                </div>
            </div>

            {/* <div className="button_back" onClick={cancelEdit}>
                <img alt="" src={Ic_back} style={{width:10}} />
            </div> */}

            <div style={{display:status_edit?'none':'flex'}} />

            <div style={{display:status_add?'block':'none'}}>
                <button className="form_button" onClick={onSubmit} disabled={loading}>
                    {text}
                    {
                        !loading?
                            <img alt="" src={Ic_next_white} style={{position:'absolute', right:15, top:15}} />
                        :
                            <div style={{position:'absolute', right:15, top:10}}>
                                <ReactLoading type={"spinningBubbles"} color={"white"} style={{fill:'white', height:30, width:30}} />
                            </div>
                    }
                </button>
            </div>
        </div>
    );
};

export default ButtonForm;