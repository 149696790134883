import {makeStyles} from '@material-ui/core/styles';

// Values
import {Ic_unfold}          from '../../values';

const useStyles = makeStyles(theme => ({
    select:{
        width:'100%',
        height:35,
        fontFamily:'regular',
        // borderRadius:5,
        border:'none',
        fontSize:16,
        paddingLeft:10,
        paddingRight:10,
        appearance:'none',
        // marginTop:6,
        background: `url(${Ic_unfold}) 96.5% / 10px no-repeat #fff`,
        [theme.breakpoints.down('lg')]: {
            background: `url(${Ic_unfold}) 94.5% / 10px no-repeat #fff`,
        }
    }
}));

const SelectQuestion = ({title, value, blockData, setData}) => {
    const classes = useStyles()
    return (
        <div style={{display:'flex', alignItems:'center'}}>
            <p className='title_input' style={{marginTop:14, marginBottom:14,}}>{title}</p>
            <select 
                value={value}
                onChange={e => setData(e.target.value)}
                className={classes.select} disabled={blockData}>
                <option value='NO' style={{fontFamily:'arial'}}>{'NO'}</option>
                <option value='SI' style={{fontFamily:'arial'}}>{'SI'}</option>
            </select>
        </div>
    );
};

export default SelectQuestion;