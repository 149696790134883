import ReactLoading     from 'react-loading';
import {Ic_next_white}  from '../../values';

const Button = ({onSubmit, loading, reset}) => {
    return (
        <div style={{textAlign:'right'}}>
            <p onClick={reset}
                className="login_restore_pass">
                    {'¿Olvidaste tu contraseña?'}
            </p>
            <button className="login_button" onClick={onSubmit} style={{marginTop:10}}>
                {"Entrar"}
                {
                    !loading?
                        <img alt="" src={Ic_next_white} style={{position:'absolute', right:15, top:15}} />
                    :
                        <div style={{position:'absolute', right:15, top:10}}>
                            <ReactLoading type={"spinningBubbles"} color={"white"} style={{fill:'white', height:30, width:30}} />
                        </div>
                }
            </button>
        </div>
    );
};

export default Button;