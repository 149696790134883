import {reduxState} from 'values';

export const saveLogin = (name, lastName, picture, token, rol, medicalCenterId, id, study_type) => {
	return (dispatch) => {
		dispatch({
			type:				reduxState.login,
			name:				name,
			lastName:			lastName,
			picture:			picture,
			token:				token,
			rol:				rol,
			medicalCenterId:	medicalCenterId,
			id:					id,
			study_type:			study_type
		})
	}
} 

export const refreshToken = (token) => {
	return (dispatch) => {
		dispatch({
			type:	reduxState.refresh,
			token:	token
		})
	}
}

export const selfUserUpdate = (name, lastName) => {
	return (dispatch) => {
		dispatch({
			type:		reduxState.selfUserUpdate,
			name:		name,
			lastName:	lastName,
		})
	}
}