import {urlApi, urlPath, get, new_post, new_update, new_delete} from '../api';
import {regex_email, regex_names_one, regex_names_two, regex_names_three, regex_phone_number} from 'utils';

const url = `${urlApi}${urlPath.hospitals}`;

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
}

export const getHospitalsData = async(token, extra="") =>{
    const auxUrl = urlApi + urlPath.allHospitals
    return await get(auxUrl, token, extra)
}

export const getSearchData = async(token, extra="") =>{
    const auxUrl = urlApi + urlPath.searchHospitals
    return await get(auxUrl, token, extra)
}

export const postHospitalData = (name, state, municipality, colony, address, contact, phone, email, assessmentType, studyType, token) => {
	const obj = {
		name,
		state,
		municipality,
		area: colony,
		address,
		contact,
		phone,
		email,
		assessment_type: assessmentType,
		study_type: studyType
	};

	return new_post(obj, url, token);
}

export const updateHospitalData = (id, name, state, municipality, colony, address, contact, phone, email, assessmentType, studyType, token) => {
	const obj = {
		name,
		state,
		municipality,
		area: colony,
		address,
		contact,
		phone,
		email,
		assessment_type: assessmentType,
		study_type: studyType
	};

	return new_update(obj, `${url}/${id}`, token);
}

export const deleteHospital = (id, token) => {
	return new_delete(`${url}/${id}`, token);
}


export const validateHospitalData = (name, state, municipality, colony, address, contact, phone, email, clasificationType, studyType) => {
	return new Promise((resolve, reject) => {
		let success = true;
		let errors = [];

		if (!name.match(regex_names_one)) {
			success = false;
			errors.push('Nombre: Solo letras, numeros, espacios, guion bajo, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (!state.match(regex_names_two)) {
			success = false;
			errors.push('Estado: Solo letras, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (!municipality.match(regex_names_two)) {
			success = false;
			errors.push('Municipio: Solo letras, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (!colony.match(regex_names_one)) {
			success = false;
			errors.push('Colonia: Solo letras, numeros, espacios, guion bajo, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (!address.match(regex_names_three)) {
			success = false;
			errors.push('Direccion: Solo letras, numeros, guion bajo, espacio, punto, #, 2 a 300 caracteres son permitidos.');
		}
		if (!contact.match(regex_names_two)) {
			success = false;
			errors.push('Nombre de contacto: Solo letras, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (!phone.replaceAll(/[-()\s]/g, '').match(regex_phone_number)) {
			success = false;
			errors.push('Numero telefonico: Formatos validos: 5512345678, 15512345678, 525512345678, +525512345678, +5215512345678');
		}
		if (!email.match(regex_email)) {
			success = false;
			errors.push('Correo electronico.');
		}
		if (!['individual', 'parity'].includes(clasificationType)) {
			success = false;
			errors.push('Tipo de clasificacion.');
		}
		if (studyType < 1 || studyType > 7) {
			success = false;
			errors.push('Tipo de estudio no valido.');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}