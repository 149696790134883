
import {Img_picture_next, Img_picture_back} from '../../values';

const Carousel = ({text, next, back, onClick}) => {
    return (
        <div style={{width:'100%', height:30, display:'flex', justifyContent:'space-between'}}>
            <img alt='' src={Img_picture_back} onClick={()=>onClick(back)}
                style={{height:30, cursor:'pointer'}} />

            <p style={{color:'#fff', fontSize:17, margin:0, paddingTop:3}}>{text}</p>
            
            <img alt='' src={Img_picture_next} onClick={()=>onClick(next)}
                style={{height:30, cursor:'pointer'}} />
        </div>
    );
};

export default Carousel;