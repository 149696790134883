import {reduxState} from 'values';

let initialState = {}

try {
	initialState = {
		name:				localStorage.getItem('eyebit-name'),
		lastName:			localStorage.getItem('eyebit-lastName'),
		picture:			localStorage.getItem('eyebit-picture'),
		token:				localStorage.getItem('eyebit-key'),
		rol:				localStorage.getItem('eyebit-rol'),
		medicalCenterId:	localStorage.getItem('eyebit-medicalCenterId'),
		id:					localStorage.getItem('eyebit-user-id'),
		study_type:			localStorage.getItem('eyebit-study-type'),
	}
} catch (error) {
	initialState = {
		name:				localStorage.getItem('eyebit-name'),
		lastName:			localStorage.getItem('eyebit-lastName'),
		picture:			localStorage.getItem('eyebit-picture'),
		token:				localStorage.getItem('eyebit-key'),
		rol:				localStorage.getItem('eyebit-rol'),
		medicalCenterId:	localStorage.getItem('eyebit-medicalCenterId'),
		id:					localStorage.getItem('eyebit-user-id'),
		study_type:			localStorage.getItem('eyebit-study-type'),
	}
}

const reducer = (state = initialState, action) => {
	if (action.type === reduxState.login) {
		return {
			...state,
			name:				action.name,
			lastName:			action.lastName,
			picture:			action.picture,
			token:				action.token,
			rol:				action.rol,
			medicalCenterId:	action.medicalCenterId,
			id:				 	action.id,
			study_type: 		action.study_type,
		}
	}
	if (action.type === reduxState.refresh) {
		return {
			...state,
			token:	  action.token,
		}
	}
	if (action.type === reduxState.selfUserUpdate) {
		return {
			...state,
			name:		action.name,
			lastName:	action.lastName,
		}
	}
	return state
}
export default reducer;