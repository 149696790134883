import React, { useState, } from 'react'; // useEffect

const Input = ({title, value, blockData, setData, type='text', min = 0, max = 999999999999999999999, color='#000', regex=/^.+$/, required=true, placeholder=''}) => {
	const [isValid, setIsValid] = useState(true);
	const [invalidInputStyle, setInvalidInputStyle] = useState({borderColor: '#ff0000', boxShadow: '0 0 5px #ff0000'});

	const handleChange = (e) => {
		const newValue = e.target.value;
		setData(newValue);

		// setIsValid(regex.test(newValue)); // Validar el valor en base a la expresión regular recibida por prop

		// If input required, test against the regex, or if input is not required, input is valid empty or test against regex if input is not empty
		let is_input_valid = (required && regex.test(newValue)) || (!required && (newValue == '' || regex.test(newValue)));
		setIsValid(is_input_valid);
	};

	return (
		<div style={{display:'flex', alignItems:'center'}}>
			<p className='title_input' style={{marginTop:14, marginBottom:14, color:color}}>{title}</p>
			{/* <div style={{display:blockData==='disabled'?'block':'none'}}>
				<p className='title_input'
					style={{marginTop:blockData==='disabled'?3:14, marginBottom:blockData==='disabled'?3:14, fontFamily:'regular'}}>
						{value}
				</p>
			</div> */}
			<div style={{width:'100%'}}>
				{
					type === 'number'?
						<input type={type}
							style={{WebkitAppearance:'textfield'}}
							step="any"
							min={min}
							max={max}
							onChange={e => setData(e.target.value)}
							value={value}
							className='input'
							disabled={blockData}
							placeholder={placeholder} />
					:
						<input type={type}
							onChange={handleChange}
							value={value}
							className='input'
							disabled={blockData}
							style={isValid ? {} : invalidInputStyle}
							placeholder={placeholder} />
				}
			</div>
		</div>
	);
};

export default Input;