import {useState, useEffect}	from 'react';
import {Container}				from '@material-ui/core';
import {connect}				from 'react-redux';
import {toast}					from 'react-toastify';

// Redux
import {changeView, cleanData} from '../../store/redux/actions/Global';
// Components
import Input			from '../global/Input';
import Select			from '../global/Select';
import SelectHospitals	from './SelectHospitals';
import ButtonFormV2		from '../global/ButtonFormV2';
import ModalGlobal		from '../global/ModalGlobal';
import DragImage		from '../global/DragImage';
// Values
import {StateView, UserRoles, toastText, groupList} from 'values'; // translate_inputs
import {capitalizeText, deployImage, regex_names_two, regex_email, manage_request_errors} from 'utils';
import {new_generate_container} from 'utils/api';
// Api
import {validateUpdateData, validatePostUserData, updateUserData, postUserData, deleteUserData} from 'utils/users';

const Form = ({data, stateView, token, hospitals, changeView, cleanData}) => {
	const [blockData, setBlockData]		= useState('')
	const [status_edit, setStatusEdit]	= useState(stateView === StateView.state3)
	const [status_add, setStatusAdd]	= useState(stateView === StateView.state2)

	// Modal
	const [open1, setOpen1]		= useState(false)
	const [loading, setLoading]	= useState(false)

	const [name, setName]			= useState('')
	const [lastName, setLastName]	= useState('')
	const [email, setEmail]			= useState('')
	const [image, setImage]			= useState('')
	const [rol, setRol]				= useState('')
	const [hospital, setHospital]	= useState('')
	const [group, setGroup]			= useState('')

	const cancelAdd = () => {
		setStatusAdd(false);
		changeView(StateView.state1);
	}

	const cancelEdit = () => {
		setStatusEdit(true);
		setStatusAdd(false);
		setBlockData('disabled');
		if (data.id) {
			maptData();
		}
	}

	const Update = async() => {
		setStatusAdd(true);
		setBlockData('');
		setStatusEdit(false);
	}

	const Delete = async() => {
		changeView(StateView.state4);
		setTimeout(() => {
			deleteRegister();
			changeView(StateView.state1);
			cleanData();
		}
		, 2000)
	}

	const cleanLocalData = () => {
		setName('');
		setLastName('');
		setRol('');
		setHospital('');
		setEmail('');
		setImage('');
		setGroup('');
	}

	const maptData = () => {
		setName(data.name);
		setLastName(data.lastName);
		setRol(data.rol);
		setHospital(data.medical_center_id);
		setEmail(data.email);
		setImage(data.picture ?? '');
		setGroup(data.group ?? '');
	}

	const patchData = () => {
		validateUpdateData(name, lastName, rol, hospital, group)
		.then(_ => {
			return new_generate_container(token);
		})
		.then(response => {return response.json();})
		.then(async response => {
			let UrlImage = image;
			let flag = false;

			if (typeof image === 'object') {
				UrlImage = await deployImage(image, response.data, token);
				setImage(UrlImage);
				flag = true;
			}

			return updateUserData(data.id, name, lastName, rol, hospital, UrlImage, email, flag, group, token);
		})
		.then(response => {return response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			console.log(response);
			toast.success(toastText.update);
			setLoading(false);
			cleanLocalData();
			cleanData();
		})
		.catch(error => {
			setLoading(false);
			console.log(error);
			manage_request_errors(error);
		});
	}

	const addData = () => {

		validatePostUserData(name, lastName, email, rol, hospital, group)
		.then(_ => {
			return new_generate_container(token);
		})
		.then(response => {return response.json();})
		.then(async response => {
			let UrlImage = image;

			if (typeof image === 'object') {
				UrlImage = await deployImage(image, response.data, token);
				setImage(UrlImage);
			}

			return postUserData(name, lastName, rol, hospital, UrlImage, email, group, token);
		})
		.then(response => {return response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			cleanLocalData();
			cleanData()
			setLoading(false);
			toast.success(toastText.add);
		})
		.catch(error => {
			setLoading(false);
			console.log(error);
			manage_request_errors(error);
		});
	}

	const deleteRegister = () => {
		deleteUserData(data.id, token)
		.then(request_response => {return request_response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			toast.success(toastText.delete);
		})
		.catch(error => {
			setLoading(false);
			console.log(error);
			manage_request_errors(error);
		});
	}

	const onSubmit = () => {
		setLoading(true)
		if (stateView === StateView.state3) {
			patchData();
		} else {
			addData();
		}
	}

	useEffect(() => {
		setLoading(false)
		if (stateView === StateView.state3) {
			setStatusEdit(true);
			setBlockData('disabled');
			maptData();
		} else{
			setStatusEdit(false);
			setBlockData('');
			cleanLocalData();
		}

		if (stateView === StateView.state2) {
			setStatusAdd(true);
			setBlockData('');
			cleanLocalData();
		} else{
			setBlockData('disabled');
			setStatusAdd(false);
		}
	}, [stateView, data.id])

	return (
		<div className="card_info">
			<div style={{height:30}} />
			<ModalGlobal open={open1} onClose={setOpen1}
				title={'Eliminar'} text_button1={'Eliminar'} text_button2={'Cancelar'} button={Delete}
				info={`¿Seguro que deseas eliminar al usuario "${capitalizeText(`${data.name} ${data.lastName}`)}"?`} button_color={'#ce0e4f'} text_color={'#fff'} />

			<div className="card_scroll">
				<Container>
					<DragImage image={image} setImage={setImage} blockData={blockData} styleDrag={1} />

					<div style={{backgroundColor:'#f1f4f8', paddingTop:15, paddingBottom:15}}>
						<Container>
							<Input regex={regex_names_two} blockData={blockData} title={'Nombre'}	value={name}		setData={setName} />
							<Input regex={regex_names_two} blockData={blockData} title={'Apellido'}	value={lastName}	setData={setLastName} />

							<div style={{display:stateView === StateView.state3 ? 'none' : 'block'}}>
								<Input title={'Correo'} value={email}   setData={setEmail} blockData={blockData} regex={regex_email}/>
							</div>

							<Select title={'Rol'} value={rol} setData={setRol} list={UserRoles} textOption={'Selecciona un rol'} blockData={blockData} />

							<div style={{display: (rol === 'GRADUADOR') ? 'block' : 'none'}}>
								<Select title={'Grupo'} value={group} setData={setGroup} list={groupList} textOption={'Selecciona un grupo'} blockData={blockData} />
							</div>

							<div style={{display: (rol==='FOTÓGRAFO' || rol==='GRADUADOR') ? 'block' : 'none'}}>
								<SelectHospitals title={'Centro Médico'} value={hospital} setData={setHospital}
									list={hospitals} textOption={'Selecciona un hospital'} blockData={blockData}/>
							</div>
						</Container>
					</div>
					<ButtonFormV2
						loading={loading}
						stateView={stateView}
						onSubmit={onSubmit}
						onClick_edit={Update}
						onClick_delete={()=>setOpen1(true)}
						status_edit={status_edit}
						status_add={status_add}
						cancelEdit={cancelEdit}
						cancelAdd={cancelAdd} />

				</Container>
				<div style={{height:30}} />
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	stateView:	state.Global.stateView,
	token:		state.PanelUser.token,
	data:		state.Users.dataEdit,
	hospitals:	state.Hospitals.allData,
})

export default connect(mapStateToProps, {changeView, cleanData})(Form)