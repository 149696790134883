import {useState}   from 'react';
import {connect}    from 'react-redux';
// Redux
import {changeView}                     from '../../store/redux/actions/Global';
import {updateResearch, getResearch}    from '../../store/redux/actions/Research';
// Components
import InputSearch  from '../global/InputSearch';
import LoadingData  from '../global/LoadingData';
// Unike key
import {generateUniqueKey, formatDate2, formatDate4} from 'utils';
// Values
import {StateView, stateData}   from 'values';
// Api
import {getData}  from 'utils/research';

const Tables = ({data, status, id, token, page, records, changeView, updateResearch, getResearch}) => {
    const [search, setSearch] = useState('')

    const edit = (item) => {
        changeView(StateView.state3)
        updateResearch(item)
    }
    return (
        <div className="card_info">
            {
                status===stateData.state2?
                    <div className="card_scroll">
                        {
                            data&&
                            data.map((item,j) =>(
                                <div key={j} onClick={() => edit(item)}
                                    className={id===item.id?'table_info_selected':"table_info"} 
                                    style={{userSelect:'none', position:'relative'}}>
                                    <div style={{marginLeft:50}}>
                                        <label style={{fontFamily:'semi', fontSize:16, cursor:'pointer'}}>{formatDate2(item.created_at)}</label><br/>
                                        <label style={{fontFamily:'regular', cursor:'pointer'}}>{formatDate4(item.created_at)}</label><br/>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                :
                    <LoadingData />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    data:       state.Research.data,
    status:     state.Research.status,
    id:         state.Research.dataEdit.id,
    token:      state.PanelUser.token,
    page:       state.Research.page,
    records:    state.Research.records
})

export default connect(mapStateToProps, {changeView, updateResearch, getResearch})(Tables)