import {useState, useEffect} from 'react';
import 'date-fns';
import {TextField}      from '@material-ui/core';
import {makeStyles}     from '@material-ui/core/styles';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {es}         from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';

// Values
import {Ic_calendar}  from 'values';

export const useStyles = makeStyles(theme => ({
    input:{
        width:'100%',
        height:35,
        borderRadius:0,
        border:'none',
        background:'#fff',
        fontSize:16,
        paddingLeft:10,
        paddingRight:10,
        appearance:'none',
        WebkitAppearance:'textfield',
        resize:'none',
    },
}))

const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
}

const DatePickers = ({date, setDate, disabled}) => {
    const classes = useStyles()

    const [selectedDate, setSelectedDate] = useState(new Date())

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate)
        const fecha = JSON.stringify(newDate)
        if(fecha !== "null"){
            setDate(newDate.toISOString())
        } else{
            setDate("")
        }
    }
    useEffect(() =>{
        if(date){
            setSelectedDate(date)
        } else{
            setSelectedDate(new Date())
        }
    },[date])
    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <KeyboardDatePicker
                    TextFieldComponent={TextFieldComponent}
                    // InputAdornmentProps={{ position: "end" }}
                    className={classes.input}
                    style={{paddingTop:2}}
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    keyboardIcon={<img alt="ic_date" src={Ic_calendar} />}
                    invalidDateMessage={"Fecha incorrecta"}
                    InputProps={{ disableUnderline: true }}
                    disabled={disabled}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default DatePickers;