import {useEffect}  from 'react';
import {connect}    from 'react-redux';
// Redux 
import {getRequest}     from '../../store/redux/actions/Request';
// Components
import Header           from '../global/Header';
import Tables           from './Tables';
import FormCompleted    from './FormCompleted';
// Values
import {StateView}  from '../../values';
// Api
import {getData}            from '../../utils/request';

const Request = ({stateView, token, page, getRequest, option}) => {
    const GetData = async() =>{
        const extra = `?page=${page}&limit=10`
        const res   = await getData(token, extra)
        getRequest(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
    }
    useEffect(() =>{
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    },[])
    return (
        <div style={{display:'flex', position:'absolute', top:20, left:180}}>
            <div className="container_info">
                <Header title={'Solicitudes de valoración'} add={false} />
                <Tables />
            </div>
            {
                stateView===StateView.state3?
                    <div className="container_card">
                        {
                            option?
                            <FormCompleted />
                            :
                            <FormCompleted />
                        }
                    </div>
                :
                    <div/>
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:          state.Global.stateView,
    token:              state.PanelUser.token,
    page:               state.Request.page,
    option:             state.Request.option
})

export default connect(mapStateToProps, {getRequest})(Request)
