import {useState, useEffect}   from 'react';
import {Container, Modal}   from '@material-ui/core';
// values
import {Ic_image} from 'values';

const DragImage = ({image, blockData, setImage, option='', text, width='95%', height=230, expand}) => {
    const [open, setOpen] = useState(false)
    const [img, setImg] = useState("")

    const closeModal = (opt) =>{
        setOpen(opt)

    }
    const openModal = () =>{
        if(expand){
            setOpen(true)
        }
    }

    const handleImage = (imag) => {
        const url = window.URL.createObjectURL(imag.files[0])
        setImg(url)
        setImage(imag.files[0])
    }

    useEffect(() =>{
        if(image){
            if(typeof image==='object'){
                const url = window.URL.createObjectURL(image)
                setImg(url)
            } else{
                setImg(image)
            }
        } else{
            setImg('')
        }
    },[image])
    return (
        <div style={{paddingTop:10}}>
            <Modal open={open} style={{display:'flex', alignItems:'center', justifyContent:'center'}} onClose={() => closeModal(!open)}>
                <Container maxWidth="md">
                    <p style={{margin:'0px', textAlign: 'center', backgroundColor: '#000', color: '#ddd'}}>
                        <b>Presiona "esc" para cerrar o haz clic fuera de la imagen</b>
                    </p>
                    <hr style={{margin: '0px'}}></hr>
                    <img alt="" src={img?img:Ic_image} style={{width:'100%', height:'100%'}} />
                </Container>
            </Modal>
            <label style={{position:'relative', cursor:expand?'pointer':''}} onClick={openModal}>
                <div className="container_drag_image_request" style={{width:width, height:height}}>
                    {
                        img?
                            <img alt="" src={img} className="drag_image_request" />
                        :
                            <div>
                                <img alt="" src={img?img:Ic_image} style={{width:130, height:130}} />
                            </div>
                    }
                </div>
            </label>
            <div style={{background:'#526290', textAlign:'center', width:width}}>
                <p style={{color:'#fff', fontSize:17, margin:0}}>{text}</p>
            </div>
            <input id={`file-upload${option}`} type="file" accept="image/*" style={{display:blockData==='disabled'?'none':'block'}} onChange={e => handleImage(e.target) } />
        </div>
    );
};

export default DragImage;