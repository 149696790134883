import ReactLoading from 'react-loading';

// Values
import {Ic_next_white, Ic_edit, Ic_delete, Ic_back} from 'values';

const ButtonFormV2 = ({text='Guardar', stateView, loading, onSubmit, onClick_edit, onClick_delete, status_edit, status_add, cancelEdit, cancelAdd}) => {
	return (
		<div style={{display:'flex', justifyContent:'end', alignItems:'center'}}>

			<div
				className="button_back"
				// className="button_modal"
				onClick={stateView === 'EDIT_DATA' ? cancelEdit : stateView === 'CREATE_DATA' ? cancelAdd : () => {}}
				style={{
						display: (status_add && (stateView === 'EDIT_DATA' || stateView === 'CREATE_DATA')) ? 'flex' : 'none',
						marginLeft: '8px',
						width: '110px',
						fontFamily: 'semi',
						fontSize: '17px',
						backgroundColor: '#323232',
						color: '#fff',
					}}>
					<span>Cancelar</span>
			</div>

			<div style={{display: status_edit ? 'flex' : 'none'}}>
				<div className="button_edit_delete" onClick={onClick_edit} style={{background:'#323232', marginLeft: '8px'}}>
					<img alt="" src={Ic_edit} style={{width:15, objectFit:'scale-down'}} />
				</div>

				<div className="button_edit_delete" onClick={onClick_delete} style={{marginLeft:15, background:'#b5015a', marginLeft: '8px'}}>
					<img alt="" src={Ic_delete} style={{width:15, objectFit:'scale-down'}} />
				</div>
			</div>

			<div style={{display: status_edit ? 'none' : 'flex'}} />

			<div style={{display: status_add ? 'block' : 'none', marginLeft: '8px'}}>
				<button className="form_button" onClick={onSubmit} disabled={loading}>
					{text}
					{
						!loading ?
							<img alt="" src={Ic_next_white} style={{position:'absolute', right:15, top:15}} />
						:
							<div style={{position:'absolute', right:15, top:10}}>
								<ReactLoading type={"spinningBubbles"} color={"white"} style={{fill:'white', height:30, width:30}} />
							</div>
					}
				</button>
			</div>
		</div>
	);
};

export default ButtonFormV2;