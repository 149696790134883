import { useState, useEffect } from "react";
import { Container, Grid, Modal, Slider } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import ReactLoading from "react-loading";
import Tooltip from '@material-ui/core/Tooltip';

// Redux
import { changeView, cleanData } from "../../store/redux/actions/Global";

// Components
import DragImage from "../graduation_request/DragImage";
import Carousel from "../graduation_request/Carousel";
import CloseModalButton from './../global/CloseModalButton';

// Values
import {Ic_next_white, toastText} from "values";
// Api
import {validate_research_data, update_research_data} from "utils/research";
import {manage_request_errors} from '../../utils';

const useStyles = makeStyles((theme) => ({
	modal_container: {
		background: "#fff",
		height: "95vh",
		position: "relative",
	},
	container_image: {
		height: "95vh",
		width: "100%",
		background: "#000",
	},
	container_find_info: {
		overflowY: "auto",
		height: "60%",
		[theme.breakpoints.down("lg")]: {
			height: "50%",
		},
	},
	custom_slider_wrapper: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		margin: '10px 0px',
	},
	custom_slider_label: {
		margin: 0,
		paddingLeft: 0,
		fontSize: 14,
		userSelect: "none",
		width: '38%',
		[theme.breakpoints.down('xl')] : { // xl => 1920
			fontSize: 14,
		},
		[theme.breakpoints.down('lg')] : { // lg => 1280
			fontSize: 12,
		},
		[theme.breakpoints.down('md')] : { // md => 960
			width: '100%',
		},
	},
	custom_slider_input: {
		width: '52%',
		[theme.breakpoints.down('md')] : { // md => 960
			width: '85%',
			margin: '25px 0px 0px 8px',
		},
	},
	findings_wrapper: {
		marginBottom: '5px',
		width: "95%",
		padding: "2px",
		display: "flex",
		alignItems: "center",
	},
	findings_label_result: {
		margin: 0,
		fontWeight: 600,
		fontSize: 18,
		userSelect: "none",
		[theme.breakpoints.down('md')] : { // md => 960
			fontSize: 16,
		},
		[theme.breakpoints.down('sm')] : { // md => 960
			fontSize: 12,
		},
		[theme.breakpoints.down('xs')] : { // xs => 600
			fontSize: 8,
		},
	},
	findings_text: {
		margin: 0,
		paddingLeft: 10,
		fontSize: 14,
		userSelect: "none",
		[theme.breakpoints.down('md')] : { // md => 960
			fontSize: 12,
		},
		[theme.breakpoints.down('sm')] : { // md => 960
			fontSize: 8,
		},
		[theme.breakpoints.down('xs')] : { // xs => 600
			fontSize: 7,
		},
	},
	enter_to_save: {
		// width: '100%',
		position: 'absolute',
		bottom: '80px',
		marginBottom: 10,
		[theme.breakpoints.down('md')] : { // md => 960
			bottom: '0px',
			position: 'relative',
		},
	},
	save_btn_wrapper: {
		width: '100%',
		position: 'absolute',
		bottom: '20px',
		[theme.breakpoints.down('md')] : { // md => 960
			position: 'relative',
		},
	},
	save_btn: {
		background: "#648CFA",
		margin: '15px 0px',
		paddingLeft: 15,
		position: 'relative',
		color: '#fff',
		border: 'none',
		cursor: 'pointer',
		width: '200px',
		height: '50px',
		fontSize: '17px',
		textAlign: 'left',
		fontFamily: 'semi',
		[theme.breakpoints.down('md')] : { // md => 960
			minWidth: 128,
			width: '85%',
		},
	},
}));

function ValueLabelComponent(props) {
	const { children, open, value } = props;

	return (
		<Tooltip open={open} enterTouchDelay={0} placement="top" title={value} children={children}>
			{children}
		</Tooltip>
	);
}

const CustomSlider = ({text, value, change=() => {}, blockData, position, index}) => {
	const classes = useStyles();
	// const input_marks = [{value:0, label:'0'}, {value:1, label:'1'}, {value:2, label:'2'}, {value:3, label:'3'}, {value:4, label:'4'}, {value:5, label:'5'}];
	const input_marks = [{value:0}, {value:1}, {value:2}, {value:3}, {value:4}, {value:5}];
	const show_labels = blockData ? 'on' : 'auto';

	return (
		<div className={classes.custom_slider_wrapper} style={{display: position === index ? "flex" : "none"}}>
			<p className={classes.custom_slider_label}>{text}</p>
			<div className={classes.custom_slider_input}>
				<Slider
					aria-label={text}
					onChange={(obj, val) => {change(val)}}
					value={value} max={5} min={0} step={1}
					valueLabelDisplay={show_labels}
					marks={input_marks}
					disabled={blockData}
					ValueLabelComponent={ValueLabelComponent}
					/>
			</div>
		</div>
	);
}

const FindInfo = ({option, text, onClick=() => {}, blockData=false}) => {
	const classes = useStyles();
	let bgColor = '';
	if (option) {
		bgColor = (blockData ? 'rgba(17, 70, 271, 0.6)' : '#1146D9');
	} else {
		bgColor = (blockData ? 'rgba(221, 226, 235, 0.5)' : '#dde2eb');
	}

	return (
		<div
			onClick={() => onClick(!option)}
			className={classes.findings_wrapper}
			style={{
				background: bgColor,
				color: option ? '#fff' : '#000',
				cursor: blockData ? 'auto' : "pointer",
			}}
		>
			<p className={classes.findings_label_result}>{option ? "SI" : "NO"}</p>
			<p className={classes.findings_text}>{text}</p>
		</div>
	);
};

const Form = ({stateView, graderId, token, data, changeView, cleanData}) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);

	const [position, setPosition] = useState(0);
	const [option, setOption] = useState(0);
	const [open1, setOpen1] = useState(true);

	const [focusingLeftEyeA, setFocusingLeftEyeA] = useState(5);
	const [contrastLeftEyeA, setContrastLeftEyeA] = useState(5);
	const [glowLeftEyeA, setGlowLeftEyeA] = useState(5);
	const [artifactsLeftEyeA, setArtifactsLeftEyeA] = useState(0);
	const [opticalDiscLeftEyeA, setOpticalDiscLeftEyeA] = useState(5);
	const [taintLeftEyeA, setTaintLeftEyeA] = useState(5);
	const [upperArchLeftEyeA, setUpperArchLeftEyeA] = useState(5);
	const [lowerArchLeftEyeA, setLowerArchLeftEyeA] = useState(5);

	const [focusingLeftEyeB, setFocusingLeftEyeB] = useState(5);
	const [contrastLeftEyeB, setContrastLeftEyeB] = useState(5);
	const [glowLeftEyeB, setGlowLeftEyeB] = useState(5);
	const [artifactsLeftEyeB, setArtifactsLeftEyeB] = useState(0);
	const [opticalDiscLeftEyeB, setOpticalDiscLeftEyeB] = useState(5);
	const [taintLeftEyeB, setTaintLeftEyeB] = useState(5);
	const [upperArchLeftEyeB, setUpperArchLeftEyeB] = useState(5);
	const [lowerArchLeftEyeB, setLowerArchLeftEyeB] = useState(5);

	const [focusingRightEyeA, setFocusingRightEyeA] = useState(5);
	const [contrastRightEyeA, setContrastRightEyeA] = useState(5);
	const [glowRightEyeA, setGlowRightEyeA] = useState(5);
	const [artifactsRightEyeA, setArtifactsRightEyeA] = useState(0);
	const [opticalDiscRightEyeA, setOpticalDiscRightEyeA] = useState(5);
	const [taintRightEyeA, setTaintRightEyeA] = useState(5);
	const [upperArchRightEyeA, setUpperArchRightEyeA] = useState(5);
	const [lowerArchRightEyeA, setLowerArchRightEyeA] = useState(5);

	const [focusingRightEyeB, setFocusingRightEyeB] = useState(5);
	const [contrastRightEyeB, setContrastRightEyeB] = useState(5);
	const [glowRightEyeB, setGlowRightEyeB] = useState(5);
	const [artifactsRightEyeB, setArtifactsRightEyeB] = useState(0);
	const [opticalDiscRightEyeB, setOpticalDiscRightEyeB] = useState(5);
	const [taintRightEyeB, setTaintRightEyeB] = useState(5);
	const [upperArchRightEyeB, setUpperArchRightEyeB] = useState(5);
	const [lowerArchRightEyeB, setLowerArchRightEyeB] = useState(5);

	const [waterfall, setWaterfall] = useState(false);
	const [hemorrhage, setHemorrhage] = useState(false);
	const [injury, setInjury] = useState(false);
	const [breaking_off, setBreaking_off] = useState(false);
	const [hypertensive, setHypertensive] = useState(false);
	const [membrane, setMembrane] = useState(false);
	const [macular_hole, setMacular_hole] = useState(false);
	const [drusen, setDrusen] = useState(false);
	const [corroidal, setCorroidal] = useState(false);
	const [excavation, setExcavation] = useState(false);
	const [myopia, setMyopia] = useState(false);
	const [occlusion, setOcclusion] = useState(false);
	const [hyalosis, setHyalosis] = useState(false);
	const [vascular, setVascular] = useState(false);
	const [retina, setRetina] = useState(false);
	const [optic_nerve, setOptic_nerve] = useState(false);

	const [waterfall2, setWaterfall2] = useState(false);
	const [hemorrhage2, setHemorrhage2] = useState(false);
	const [injury2, setInjury2] = useState(false);
	const [breaking_off2, setBreaking_off2] = useState(false);
	const [hypertensive2, setHypertensive2] = useState(false);
	const [membrane2, setMembrane2] = useState(false);
	const [macular_hole2, setMacular_hole2] = useState(false);
	const [drusen2, setDrusen2] = useState(false);
	const [corroidal2, setCorroidal2] = useState(false);
	const [excavation2, setExcavation2] = useState(false);
	const [myopia2, setMyopia2] = useState(false);
	const [occlusion2, setOcclusion2] = useState(false);
	const [hyalosis2, setHyalosis2] = useState(false);
	const [vascular2, setVascular2] = useState(false);
	const [retina2, setRetina2] = useState(false);
	const [optic_nerve2, setOptic_nerve2] = useState(false);

	const [waterfall3, setWaterfall3] = useState(false);
	const [hemorrhage3, setHemorrhage3] = useState(false);
	const [injury3, setInjury3] = useState(false);
	const [breaking_off3, setBreaking_off3] = useState(false);
	const [hypertensive3, setHypertensive3] = useState(false);
	const [membrane3, setMembrane3] = useState(false);
	const [macular_hole3, setMacular_hole3] = useState(false);
	const [drusen3, setDrusen3] = useState(false);
	const [corroidal3, setCorroidal3] = useState(false);
	const [excavation3, setExcavation3] = useState(false);
	const [myopia3, setMyopia3] = useState(false);
	const [occlusion3, setOcclusion3] = useState(false);
	const [hyalosis3, setHyalosis3] = useState(false);
	const [vascular3, setVascular3] = useState(false);
	const [retina3, setRetina3] = useState(false);
	const [optic_nerve3, setOptic_nerve3] = useState(false);

	const [waterfall4, setWaterfall4] = useState(false);
	const [hemorrhage4, setHemorrhage4] = useState(false);
	const [injury4, setInjury4] = useState(false);
	const [breaking_off4, setBreaking_off4] = useState(false);
	const [hypertensive4, setHypertensive4] = useState(false);
	const [membrane4, setMembrane4] = useState(false);
	const [macular_hole4, setMacular_hole4] = useState(false);
	const [drusen4, setDrusen4] = useState(false);
	const [corroidal4, setCorroidal4] = useState(false);
	const [excavation4, setExcavation4] = useState(false);
	const [myopia4, setMyopia4] = useState(false);
	const [occlusion4, setOcclusion4] = useState(false);
	const [hyalosis4, setHyalosis4] = useState(false);
	const [vascular4, setVascular4] = useState(false);
	const [retina4, setRetina4] = useState(false);
	const [optic_nerve4, setOptic_nerve4] = useState(false);

	const patchData = async () => {

		const dataToSave = {
			// investigation_request: request_id,
			grader_id: graderId,

			focusing_left_eye_a: focusingLeftEyeA,
			contrast_left_eye_a: contrastLeftEyeA,
			glow_left_eye_a: glowLeftEyeA,
			artifacts_left_eye_a: artifactsLeftEyeA,
			optical_disc_left_eye_a: opticalDiscLeftEyeA,
			taint_left_eye_a: taintLeftEyeA,
			upper_arch_left_eye_a: upperArchLeftEyeA,
			lower_arch_left_eye_a: lowerArchLeftEyeA,
			waterfall_left_eye_a: waterfall,
			hemorrhage_left_eye_a: hemorrhage,
			injury_left_eye_a: injury,
			breaking_off_left_eye_a: breaking_off,
			hypertensive_left_eye_a: hypertensive,
			membrane_left_eye_a: membrane,
			macular_hole_left_eye_a: macular_hole,
			drusen_left_eye_a: drusen,
			corroidal_left_eye_a: corroidal,
			excavation_left_eye_a: excavation,
			myopia_left_eye_a: myopia,
			occlusion_left_eye_a: occlusion,
			hyalosis_left_eye_a: hyalosis,
			vascular_left_eye_a: vascular,
			retina_left_eye_a: retina,
			optic_nerve_left_eye_a: optic_nerve,

			focusing_left_eye_b: focusingLeftEyeB,
			contrast_left_eye_b: contrastLeftEyeB,
			glow_left_eye_b: glowLeftEyeB,
			artifacts_left_eye_b: artifactsLeftEyeB,
			optical_disc_left_eye_b: opticalDiscLeftEyeB,
			taint_left_eye_b: taintLeftEyeB,
			upper_arch_left_eye_b: upperArchLeftEyeB,
			lower_arch_left_eye_b: lowerArchLeftEyeB,
			waterfall_left_eye_b: waterfall2,
			hemorrhage_left_eye_b: hemorrhage2,
			injury_left_eye_b: injury2,
			breaking_off_left_eye_b: breaking_off2,
			hypertensive_left_eye_b: hypertensive2,
			membrane_left_eye_b: membrane2,
			macular_hole_left_eye_b: macular_hole2,
			drusen_left_eye_b: drusen2,
			corroidal_left_eye_b: corroidal2,
			excavation_left_eye_b: excavation2,
			myopia_left_eye_b: myopia2,
			occlusion_left_eye_b: occlusion2,
			hyalosis_left_eye_b: hyalosis2,
			vascular_left_eye_b: vascular2,
			retina_left_eye_b: retina2,
			optic_nerve_left_eye_b: optic_nerve2,

			focusing_right_eye_a: focusingRightEyeA,
			contrast_right_eye_a: contrastRightEyeA,
			glow_right_eye_a: glowRightEyeA,
			artifacts_right_eye_a: artifactsRightEyeA,
			optical_disc_right_eye_a: opticalDiscRightEyeA,
			taint_right_eye_a: taintRightEyeA,
			upper_arch_right_eye_a: upperArchRightEyeA,
			lower_arch_right_eye_a: lowerArchRightEyeA,
			waterfall_right_eye_a: waterfall3,
			hemorrhage_right_eye_a: hemorrhage3,
			injury_right_eye_a: injury3,
			breaking_off_right_eye_a: breaking_off3,
			hypertensive_right_eye_a: hypertensive3,
			membrane_right_eye_a: membrane3,
			macular_hole_right_eye_a: macular_hole3,
			drusen_right_eye_a: drusen3,
			corroidal_right_eye_a: corroidal3,
			excavation_right_eye_a: excavation3,
			myopia_right_eye_a: myopia3,
			occlusion_right_eye_a: occlusion3,
			hyalosis_right_eye_a: hyalosis3,
			vascular_right_eye_a: vascular3,
			retina_right_eye_a: retina3,
			optic_nerve_right_eye_a: optic_nerve3,

			focusing_right_eye_b: focusingRightEyeB,
			contrast_right_eye_b: contrastRightEyeB,
			glow_right_eye_b: glowRightEyeB,
			artifacts_right_eye_b: artifactsRightEyeB,
			optical_disc_right_eye_b: opticalDiscRightEyeB,
			taint_right_eye_b: taintRightEyeB,
			upper_arch_right_eye_b: upperArchRightEyeB,
			lower_arch_right_eye_b: lowerArchRightEyeB,
			waterfall_right_eye_b: waterfall4,
			hemorrhage_right_eye_b: hemorrhage4,
			injury_right_eye_b: injury4,
			breaking_off_right_eye_b: breaking_off4,
			hypertensive_right_eye_b: hypertensive4,
			membrane_right_eye_b: membrane4,
			macular_hole_right_eye_b: macular_hole4,
			drusen_right_eye_b: drusen4,
			corroidal_right_eye_b: corroidal4,
			excavation_right_eye_b: excavation4,
			myopia_right_eye_b: myopia4,
			occlusion_right_eye_b: occlusion4,
			hyalosis_right_eye_b: hyalosis4,
			vascular_right_eye_b: vascular4,
			retina_right_eye_b: retina4,
			optic_nerve_right_eye_b: optic_nerve4,

			// token
		};

		validate_research_data(dataToSave)
		.then(_ => {
			return update_research_data(dataToSave, data.id, token);
		})
		.then(response => {return response.json();})
		.then(json_response => {
			if (!json_response.success) {
				throw json_response;
			}

			toast.success(toastText.update);
			setLoading(false);
			cleanData();
		})
		.catch(error => {
			console.log(error);
			setLoading(false);
			manage_request_errors(error);
		});
	};

	const nextPage = () => {
		const sum = option + 1;
		if (sum < 6) {
			setOption(sum);
		}
	};

	const backPage = () => {
		const subtraction = option - 1;
		if (subtraction >= 0) {
			setOption(subtraction);
		}
	};

	const keyEvents = (e) => {
		if (e.keyCode === 13) { // "Enter" key
			patchData();
		} else if (e.keyCode === 27) { // "Esc" key
			closeModal();
		} else if (e.keyCode === 37) { // "Left Arrow" key
			backPage();
		} else if (e.keyCode === 39) { // "Right Arrow" key
			nextPage();
		}
	}

	const closeModal = () => {
		cleanData();
		setOpen1(false);
	};

	useEffect(() => {
		setPosition(0);
		setOpen1(true);
		// filterData(dataEdit.patientId)
	}, []);
	return (
		<div>
			<Modal
				open={open1}
				style={{ display: "flex", alignItems: "center", justifyContent: "center", paddinfRight: 0 }}
				onClose={() => closeModal()}
				onKeyDown={(e) => keyEvents(e)}
			>
				<Container maxWidth="xl">
					<div className={classes.modal_container}>
						<Container maxWidth="xl">
							<Grid container>
								<Grid item xs={2}	style={{position: "relative"}}>
									<div class="card_scroll" style={{height:'95vh'}}>

										{
											(option >= 0 && option <= 3) && <h3>{"Calidad general"}</h3>
										}
										<CustomSlider
											text={'Enfoque'}
											value={focusingLeftEyeA}
											change={setFocusingLeftEyeA}
											blockData={false}
											position={option}
											index={0} />
										<CustomSlider
											text={'Contraste'}
											value={contrastLeftEyeA}
											change={setContrastLeftEyeA}
											blockData={false}
											position={option}
											index={0} />
										<CustomSlider
											text={'Brillo'}
											value={glowLeftEyeA}
											change={setGlowLeftEyeA}
											blockData={false}
											position={option}
											index={0} />
										<CustomSlider
											text={'Artefactos'}
											value={artifactsLeftEyeA}
											change={setArtifactsLeftEyeA}
											blockData={false}
											position={option}
											index={0} />

										<CustomSlider
											text={'Enfoque'}
											value={focusingLeftEyeB}
											change={setFocusingLeftEyeB}
											blockData={false}
											position={option}
											index={1} />
										<CustomSlider
											text={'Contraste'}
											value={contrastLeftEyeB}
											change={setContrastLeftEyeB}
											blockData={false}
											position={option}
											index={1} />
										<CustomSlider
											text={'Brillo'}
											value={glowLeftEyeB}
											change={setGlowLeftEyeB}
											blockData={false}
											position={option}
											index={1} />
										<CustomSlider
											text={'Artefactos'}
											value={artifactsLeftEyeB}
											change={setArtifactsLeftEyeB}
											blockData={false}
											position={option}
											index={1} />

										<CustomSlider
											text={'Enfoque'}
											value={focusingRightEyeA}
											change={setFocusingRightEyeA}
											blockData={false}
											position={option}
											index={2} />
										<CustomSlider
											text={'Contraste'}
											value={contrastRightEyeA}
											change={setContrastRightEyeA}
											blockData={false}
											position={option}
											index={2} />
										<CustomSlider
											text={'Brillo'}
											value={glowRightEyeA}
											change={setGlowRightEyeA}
											blockData={false}
											position={option}
											index={2} />
										<CustomSlider
											text={'Artefactos'}
											value={artifactsRightEyeA}
											change={setArtifactsRightEyeA}
											blockData={false}
											position={option}
											index={2} />

										<CustomSlider
											text={'Enfoque'}
											value={focusingRightEyeB}
											change={setFocusingRightEyeB}
											blockData={false}
											position={option}
											index={3} />
										<CustomSlider
											text={'Contraste'}
											value={contrastRightEyeB}
											change={setContrastRightEyeB}
											blockData={false}
											position={option}
											index={3} />
										<CustomSlider
											text={'Brillo'}
											value={glowRightEyeB}
											change={setGlowRightEyeB}
											blockData={false}
											position={option}
											index={3} />
										<CustomSlider
											text={'Artefactos'}
											value={artifactsRightEyeB}
											change={setArtifactsRightEyeB}
											blockData={false}
											position={option}
											index={3} />

										{
											(option >= 0 && option <= 3) && <h3>{"Resolución de regiones"}</h3>
										}
										<CustomSlider
											text={'Disco óptico'}
											value={opticalDiscLeftEyeA}
											change={setOpticalDiscLeftEyeA}
											blockData={false}
											position={option}
											index={0} />
										<CustomSlider
											text={'Macula'}
											value={taintLeftEyeA}
											change={setTaintLeftEyeA}
											blockData={false}
											position={option}
											index={0} />
										<CustomSlider
											text={'Arcada superior'}
											value={upperArchLeftEyeA}
											change={setUpperArchLeftEyeA}
											blockData={false}
											position={option}
											index={0} />
										<CustomSlider
											text={'Arcada inferior'}
											value={lowerArchLeftEyeA}
											change={setLowerArchLeftEyeA}
											blockData={false}
											position={option}
											index={0} />

										<CustomSlider
											text={'Disco óptico'}
											value={opticalDiscLeftEyeB}
											change={setOpticalDiscLeftEyeB}
											blockData={false}
											position={option}
											index={1} />
										<CustomSlider
											text={'Macula'}
											value={taintLeftEyeB}
											change={setTaintLeftEyeB}
											blockData={false}
											position={option}
											index={1} />
										<CustomSlider
											text={'Arcada superior'}
											value={upperArchLeftEyeB}
											change={setUpperArchLeftEyeB}
											blockData={false}
											position={option}
											index={1} />
										<CustomSlider
											text={'Arcada inferior'}
											value={lowerArchLeftEyeB}
											change={setLowerArchLeftEyeB}
											blockData={false}
											position={option}
											index={1} />

										<CustomSlider
											text={'Disco óptico'}
											value={opticalDiscRightEyeA}
											change={setOpticalDiscRightEyeA}
											blockData={false}
											position={option}
											index={2} />
										<CustomSlider
											text={'Macula'}
											value={taintRightEyeA}
											change={setTaintRightEyeA}
											blockData={false}
											position={option}
											index={2} />
										<CustomSlider
											text={'Arcada superior'}
											value={upperArchRightEyeA}
											change={setUpperArchRightEyeA}
											blockData={false}
											position={option}
											index={2} />
										<CustomSlider
											text={'Arcada inferior'}
											value={lowerArchRightEyeA}
											change={setLowerArchRightEyeA}
											blockData={false}
											position={option}
											index={2} />

										<CustomSlider
											text={'Disco óptico'}
											value={opticalDiscRightEyeB}
											change={setOpticalDiscRightEyeB}
											blockData={false}
											position={option}
											index={3} />
										<CustomSlider
											text={'Macula'}
											value={taintRightEyeB}
											change={setTaintRightEyeB}
											blockData={false}
											position={option}
											index={3} />
										<CustomSlider
											text={'Arcada superior'}
											value={upperArchRightEyeB}
											change={setUpperArchRightEyeB}
											blockData={false}
											position={option}
											index={3} />
										<CustomSlider
											text={'Arcada inferior'}
											value={lowerArchRightEyeB}
											change={setLowerArchRightEyeB}
											blockData={false}
											position={option}
											index={3} />

										<div className={classes.enter_to_save}>
											<small style={{fontSize: '10px'}}>* Presiona "Enter" para guardar</small>
										</div>
										<div className={classes.save_btn_wrapper}>
											<button
												onClick={patchData}
												className={classes.save_btn}
											>
												{"Guardar"}
												{!loading ? (
													<img alt="" src={Ic_next_white} style={{ position: "absolute", right: 15, top: 15 }} />
												) : (
													<div style={{ position: "absolute", right: 15, top: 10 }}>
														<ReactLoading
															type={"spinningBubbles"}
															color={"white"}
															style={{ fill: "white", height: 30, width: 30 }}
														/>
													</div>
												)}
											</button>
										</div>
									</div>
								</Grid>
								<Grid item xs={8}>
									<div className={classes.container_image}>
										<div style={{ display: option === 0 ? "block" : "none" }}>
											<Carousel text={"Ojo izquierdo centrado en mácula"} back={0} next={1} onClick={setOption} />
											<DragImage image={data.picture_left_eye_a} blockData={"disabled"} />
										</div>
										<div style={{ display: option === 1 ? "block" : "none" }}>
											<Carousel text={"Ojo izquierdo centrado en disco"} back={0} next={2} onClick={setOption} />
											<DragImage image={data.picture_left_eye_b} blockData={"disabled"} />
										</div>
										<div style={{ display: option === 2 ? "block" : "none" }}>
											<Carousel text={"Ojo derecho centrado en mácula"} back={1} next={3} onClick={setOption} />
											<DragImage image={data.picture_right_eye_a} blockData={"disabled"} />
										</div>
										<div style={{ display: option === 3 ? "block" : "none" }}>
											<Carousel text={"Ojo derecho centrado en disco"} back={2} next={4} onClick={setOption} />
											<DragImage image={data.picture_right_eye_b} blockData={"disabled"} />
										</div>

										<div style={{ display: option === 4 ? "block" : "none" }}>
											<Carousel text={"Segmento anterior - ojo izquierdo"} back={3} next={5} onClick={setOption} />
											<DragImage image={data.previous_segment} blockData={"disabled"} />
										</div>

										<div style={{ display: option === 5 ? "block" : "none" }}>
											<Carousel text={"Segmento anterior - ojo derecho"} back={4} next={5} onClick={setOption} />
											<DragImage image={data.previous_segment_b} blockData={"disabled"} />
										</div>
									</div>
								</Grid>
								<Grid item xs={2}>
									<CloseModalButton
										closeModal={closeModal}
										/>

									<div style={{display: option === 0 ? "block" : "none", height: '95vh'}}>
										<div class="card_scroll" style={{height: '95vh', marginLeft: '5px'}}>

											<div style={{ display: option === 0 ? "block" : "none" }}>
												<h3>{"Otros hallazgos"}</h3>
												<FindInfo option={waterfall} text="Catarata" onClick={setWaterfall} />
												<FindInfo option={hemorrhage} text="Hemorragia vítrea" onClick={setHemorrhage} />
												<FindInfo option={injury} text="Lesiones predisponentes" onClick={setInjury} />
												<FindInfo
													option={breaking_off}
													text="Desprendimiento de la retina por ruptura"
													onClick={setBreaking_off}
												/>
												<FindInfo option={hypertensive} text="Retinopatía hipertensiva" onClick={setHypertensive} />
												<FindInfo option={membrane} text="Membrana epirretiniana" onClick={setMembrane} />
												<FindInfo option={macular_hole} text="Agujero macular" onClick={setMacular_hole} />
												<FindInfo
													option={drusen}
													text="Drusas/Degeneración macular asociada a la edad"
													onClick={setDrusen}
												/>
												<FindInfo option={corroidal} text="Nevo coroideo" onClick={setCorroidal} />
												<FindInfo option={excavation} text="Excavación de nervio óptico amplia" onClick={setExcavation} />
												<FindInfo option={myopia} text="Miopía degenerativa" onClick={setMyopia} />
												<FindInfo option={occlusion} text="Oclusión vascular" onClick={setOcclusion} />
												<FindInfo option={hyalosis} text="Hialosis asteroidea" onClick={setHyalosis} />
												<FindInfo option={vascular} text="Otros trastornos vasculares" onClick={setVascular} />
												<FindInfo option={retina} text="Otros trastornos de la retina" onClick={setRetina} />
												<FindInfo
													option={optic_nerve}
													text="Otros trastornos del nervio óptico"
													onClick={setOptic_nerve}
												/>
											</div>
										</div>
									</div>

									<div style={{display: option === 1 ? "block" : "none", height: '95vh'}}>
										<div class="card_scroll" style={{height: '95vh', marginLeft: '5px'}}>
											<div style={{ display: option === 1 ? "block" : "none" }}>
												<h3>{"Otros hallazgos"}</h3>
												<FindInfo option={waterfall2} text="Catarata" onClick={setWaterfall2} />
												<FindInfo option={hemorrhage2} text="Hemorragia vítrea" onClick={setHemorrhage2} />
												<FindInfo option={injury2} text="Lesiones predisponentes" onClick={setInjury2} />
												<FindInfo
													option={breaking_off2}
													text="Desprendimiento de la retina por ruptura"
													onClick={setBreaking_off2}
												/>
												<FindInfo option={hypertensive2} text="Retinopatía hipertensiva" onClick={setHypertensive2} />
												<FindInfo option={membrane2} text="Membrana epirretiniana" onClick={setMembrane2} />
												<FindInfo option={macular_hole2} text="Agujero macular" onClick={setMacular_hole2} />
												<FindInfo
													option={drusen2}
													text="Drusas/Degeneración macular asociada a la edad"
													onClick={setDrusen2}
												/>
												<FindInfo option={corroidal2} text="Nevo coroideo" onClick={setCorroidal2} />
												<FindInfo option={excavation2} text="Excavación de nervio óptico amplia" onClick={setExcavation2} />
												<FindInfo option={myopia2} text="Miopía degenerativa" onClick={setMyopia2} />
												<FindInfo option={occlusion2} text="Oclusión vascular" onClick={setOcclusion2} />
												<FindInfo option={hyalosis2} text="Hialosis asteroidea" onClick={setHyalosis2} />
												<FindInfo option={vascular2} text="Otros trastornos vasculares" onClick={setVascular2} />
												<FindInfo option={retina2} text="Otros trastornos de la retina" onClick={setRetina2} />
												<FindInfo
													option={optic_nerve2}
													text="Otros trastornos del nervio óptico"
													onClick={setOptic_nerve2}
												/>
											</div>
										</div>
									</div>

									<div style={{display: option === 2 ? "block" : "none", height: '95vh'}}>
										<div class="card_scroll" style={{height: '95vh', marginLeft: '5px'}}>
											<div style={{ display: option === 2 ? "block" : "none" }}>
												<h3>{"Otros hallazgos"}</h3>
												<FindInfo option={waterfall3} text="Catarata" onClick={setWaterfall3} />
												<FindInfo option={hemorrhage3} text="Hemorragia vítrea" onClick={setHemorrhage3} />
												<FindInfo option={injury3} text="Lesiones predisponentes" onClick={setInjury3} />
												<FindInfo
													option={breaking_off3}
													text="Desprendimiento de la retina por ruptura"
													onClick={setBreaking_off3}
												/>
												<FindInfo option={hypertensive3} text="Retinopatía hipertensiva" onClick={setHypertensive3} />
												<FindInfo option={membrane3} text="Membrana epirretiniana" onClick={setMembrane3} />
												<FindInfo option={macular_hole3} text="Agujero macular" onClick={setMacular_hole3} />
												<FindInfo
													option={drusen3}
													text="Drusas/Degeneración macular asociada a la edad"
													onClick={setDrusen3}
												/>
												<FindInfo option={corroidal3} text="Nevo coroideo" onClick={setCorroidal3} />
												<FindInfo option={excavation3} text="Excavación de nervio óptico amplia" onClick={setExcavation3} />
												<FindInfo option={myopia3} text="Miopía degenerativa" onClick={setMyopia3} />
												<FindInfo option={occlusion3} text="Oclusión vascular" onClick={setOcclusion3} />
												<FindInfo option={hyalosis3} text="Hialosis asteroidea" onClick={setHyalosis3} />
												<FindInfo option={vascular3} text="Otros trastornos vasculares" onClick={setVascular3} />
												<FindInfo option={retina3} text="Otros trastornos de la retina" onClick={setRetina3} />
												<FindInfo
													option={optic_nerve3}
													text="Otros trastornos del nervio óptico"
													onClick={setOptic_nerve3}
												/>
											</div>
										</div>
									</div>

									<div style={{display: option === 3 ? "block" : "none", height: '95vh'}}>
										<div class="card_scroll" style={{height: '95vh', marginLeft: '5px'}}>
											<div style={{ display: option === 3 ? "block" : "none" }}>
												<h3>{"Otros hallazgos"}</h3>
												<FindInfo option={waterfall4} text="Catarata" onClick={setWaterfall4} />
												<FindInfo option={hemorrhage4} text="Hemorragia vítrea" onClick={setHemorrhage4} />
												<FindInfo option={injury4} text="Lesiones predisponentes" onClick={setInjury4} />
												<FindInfo
													option={breaking_off4}
													text="Desprendimiento de la retina por ruptura"
													onClick={setBreaking_off4}
												/>
												<FindInfo option={hypertensive4} text="Retinopatía hipertensiva" onClick={setHypertensive4} />
												<FindInfo option={membrane4} text="Membrana epirretiniana" onClick={setMembrane4} />
												<FindInfo option={macular_hole4} text="Agujero macular" onClick={setMacular_hole4} />
												<FindInfo
													option={drusen4}
													text="Drusas/Degeneración macular asociada a la edad"
													onClick={setDrusen4}
												/>
												<FindInfo option={corroidal4} text="Nevo coroideo" onClick={setCorroidal4} />
												<FindInfo option={excavation4} text="Excavación de nervio óptico amplia" onClick={setExcavation4} />
												<FindInfo option={myopia4} text="Miopía degenerativa" onClick={setMyopia4} />
												<FindInfo option={occlusion4} text="Oclusión vascular" onClick={setOcclusion4} />
												<FindInfo option={hyalosis4} text="Hialosis asteroidea" onClick={setHyalosis4} />
												<FindInfo option={vascular4} text="Otros trastornos vasculares" onClick={setVascular4} />
												<FindInfo option={retina4} text="Otros trastornos de la retina" onClick={setRetina4} />
												<FindInfo
													option={optic_nerve4}
													text="Otros trastornos del nervio óptico"
													onClick={setOptic_nerve4}
												/>
											</div>

										</div>
									</div>
								</Grid>
							</Grid>
						</Container>
					</div>
				</Container>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	stateView: state.Global.stateView,
	token: state.PanelUser.token,
	data: state.Research.dataEdit,
	graderId: state.PanelUser.id,
});

export default connect(mapStateToProps, {changeView, cleanData})(Form);