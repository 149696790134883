import {useState, useEffect}from 'react';
import {makeStyles}         from '@material-ui/core/styles';
import {Container, Modal, Grid}   from '@material-ui/core';

import DragImage    from '../graduation_request/DragImage';
import Carousel     from '../graduation_request/Carousel';
import CardResponse from './CardResponse';

// Values
import {Ic_unfold}          from '../../values';

const useStyles = makeStyles(theme => ({
    select:{
        margin:'15px 0',
        width:'100%',
        height:35,
        fontFamily:'regular',
        border:'none',
        fontSize:16,
        paddingLeft:10,
        paddingRight:10,
        appearance:'none',
        boxShadow:'0px 0px 5px 0px rgba(0,0,0,0.20)',
        background: `url(${Ic_unfold}) 96.5% / 10px no-repeat #fff`,
        [theme.breakpoints.down('lg')]: {
            background: `url(${Ic_unfold}) 94.5% / 10px no-repeat #fff`,
        }
    },
    modal_container:{
        background:'#fff', height:'95vh',  position:'relative', 
    },
    container_image:{
        height:'95vh', width:'100%', background:'#000'
    },
    container_find_info:{
        overflowY:'auto', height:900,
        padding:'15px 1px',
        [theme.breakpoints.down('lg')]: {
            height:750,
        }
    },
    input:{
        border:'none',
        width:'100%',
        background:'#fff',
        paddingLeft:10,
        fontSize:16,
        height:42
    }
}));

const CardColor = ({text}) =>{
    return(
        <div style={{width:'100%', marginBottom:5,  background:'#6dd400', display:'flex', alignItems:'center'}}>
            <p style={{margin:0, padding:10, color:'#fff', fontSize:22, fontWeight:600}}>{'SI'}</p>
            <p style={{margin:0, padding:'10px 5px', color:'#fff'}}>{text}</p>
        </div>
    )
}

const ContainerResponse = ({option, optionValue, text, reponse}) =>{
    return(
        <div style={{display:option===optionValue?'block':'none'}}>
            <label style={{fontSize:14, textTransform:'uppercase'}}>{text}</label>
            <CardResponse text={reponse} />
        </div>
    )
}

const ContainerFindings = ({option, optionValue, response, text}) =>{
    return(
        <div style={{display:option===optionValue?'block':'none'}}>
            <div style={{display:response===1?'block':'none'}}>
                <CardColor text={text} />
            </div>
        </div>
    )
}

const ModalInfo = ({data, open, closeModal}) => {
    const classes = useStyles()
    const [option, setOption] = useState(0)
    const [optionGrader, setOptionGrader] = useState(0)
    const [grader, setGrader] = useState([])

    const close = (opt) =>{
        closeModal(opt)
        setOption(0)
    }

    useEffect(()=>{
        setOption(0)
        setOptionGrader(0)
        try { setGrader(data.map_graders[0]) } 
        catch (error) { setGrader([]) }
    },[data])

    return (
        <div>
            <Modal open={open} 
                style={{display:'flex', alignItems:'center', justifyContent:'center',}}
                onClose={() => close(!open)}>
                    <Container maxWidth="xl">
                        <div className={classes.modal_container}>
                            <Container maxWidth='xl'>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Container style={{paddingLeft:0}}>
                                            <div className={classes.container_find_info}>
                                                <select 
                                                    value={optionGrader}
                                                    onChange={e => setOptionGrader(parseInt(e.target.value))}
                                                    style={{cursor:'pointer'}}
                                                    className={classes.select}>
                                                        {
                                                            data.map_graders&&
                                                            data.map_graders.map((item, index) =>(
                                                                <option key={index} value={index} style={{fontFamily:'arial'}}>{`Graduador ${index+1}`}</option>
                                                            ))
                                                        }
                                                </select>
                                                {
                                                    data.map_graders&&
                                                    data.map_graders.map((i,j)=>(
                                                        <div key={j} style={{display:optionGrader===j?'block':'none'}}>
                                                            <ContainerResponse option={option} optionValue={0} text='Artefactos' reponse={i.artifacs_left_eye_a} />
                                                            <ContainerResponse option={option} optionValue={1} text='Artefactos' reponse={i.artifacs_left_eye_b} />
                                                            <ContainerResponse option={option} optionValue={2} text='Artefactos' reponse={i.artifacs_right_eye_a} />
                                                            <ContainerResponse option={option} optionValue={3} text='Artefactos' reponse={i.artifacs_right_eye_b} />

                                                            <ContainerResponse option={option} optionValue={0} text='Nitidez' reponse={i.sharpness_left_eye_a} />
                                                            <ContainerResponse option={option} optionValue={1} text='Nitidez' reponse={i.sharpness_left_eye_b} />
                                                            <ContainerResponse option={option} optionValue={2} text='Nitidez' reponse={i.sharpness_right_eye_a} />
                                                            <ContainerResponse option={option} optionValue={3} text='Nitidez' reponse={i.sharpness_right_eye_b} />

                                                            <ContainerResponse option={option} optionValue={0} text='Campo de visión' reponse={i.vision_field_left_eye_a} />
                                                            <ContainerResponse option={option} optionValue={1} text='Campo de visión' reponse={i.vision_field_left_eye_b} />
                                                            <ContainerResponse option={option} optionValue={2} text='Campo de visión' reponse={i.vision_field_right_eye_a} />
                                                            <ContainerResponse option={option} optionValue={3} text='Campo de visión' reponse={i.vision_field_right_eye_b} />

                                                            <ContainerResponse option={option} optionValue={0} text='Evaluación general' reponse={i.evaluation_left_eye_a} />
                                                            <ContainerResponse option={option} optionValue={1} text='Evaluación general' reponse={i.evaluation_left_eye_b} />
                                                            <ContainerResponse option={option} optionValue={2} text='Evaluación general' reponse={i.evaluation_right_eye_a} />
                                                            <ContainerResponse option={option} optionValue={3} text='Evaluación general' reponse={i.evaluation_right_eye_b} />

                                                            <label style={{fontSize:14, textTransform:'uppercase'}}>{'OTROS HALLAZGOS'}</label>

                                                            <ContainerFindings option={option} optionValue={0} response={i.waterfall_left_eye_a} text='Catarata' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.waterfall_left_eye_b} text='Catarata' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.waterfall_right_eye_a} text='Catarata' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.waterfall_right_eye_b} text='Catarata' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.hemorrhage_left_eye_a} text='Hemorragia vítrea' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.hemorrhage_left_eye_b} text='Hemorragia vítrea' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.hemorrhage_right_eye_a} text='Hemorragia vítrea' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.hemorrhage_right_eye_b} text='Hemorragia vítrea' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.injury_left_eye_a} text='Lesiones predisponentes' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.injury_left_eye_a} text='Lesiones predisponentes' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.injury_right_eye_a} text='Lesiones predisponentes' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.injury_right_eye_b} text='Lesiones predisponentes' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.breaking_off_left_eye_a} text='Desprendimiento de la retina por ruptura' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.breaking_off_left_eye_b} text='Desprendimiento de la retina por ruptura' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.breaking_off_right_eye_a} text='Desprendimiento de la retina por ruptura' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.breaking_off_right_eye_b} text='Desprendimiento de la retina por ruptura' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.hypertensive_left_eye_a} text='Retinopatía hipertensiva' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.hypertensive_left_eye_b} text='Retinopatía hipertensiva' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.hypertensive_right_eye_a} text='Retinopatía hipertensiva' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.hypertensive_right_eye_b} text='Retinopatía hipertensiva' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.membrane_left_eye_a} text='Membrana epirretiniana' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.membrane_left_eye_b} text='Membrana epirretiniana' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.membrane_right_eye_a} text='Membrana epirretiniana' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.membrane_right_eye_b} text='Membrana epirretiniana' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.macular_hole_left_eye_a} text='Agujero macular' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.macular_hole_left_eye_b} text='Agujero macular' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.macular_hole_right_eye_a} text='Agujero macular' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.macular_hole_right_eye_b} text='Agujero macular' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.drusen_left_eye_a} text='Drusas /Degeneración macular asociada a la edad' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.drusen_left_eye_b} text='Drusas /Degeneración macular asociada a la edad' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.drusen_right_eye_a} text='Drusas /Degeneración macular asociada a la edad' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.drusen_right_eye_b} text='Drusas /Degeneración macular asociada a la edad' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.corroidal_left_eye_a} text='Nevo coroideo' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.corroidal_left_eye_b} text='Nevo coroideo' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.corroidal_right_eye_a} text='Nevo coroideo' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.corroidal_right_eye_b} text='Nevo coroideo' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.excavation_left_eye_a} text='Excavación de nervio óptico amplia' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.excavation_left_eye_b} text='Excavación de nervio óptico amplia' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.excavation_right_eye_a} text='Excavación de nervio óptico amplia' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.excavation_right_eye_b} text='Excavación de nervio óptico amplia' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.myopia_left_eye_a} text='Miopía degenerativa' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.myopia_left_eye_b} text='Miopía degenerativa' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.myopia_left_eye_a} text='Miopía degenerativa' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.myopia_left_eye_b} text='Miopía degenerativa' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.occlusion_left_eye_a} text='Oclusión vascular' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.occlusion_left_eye_b} text='Oclusión vascular' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.occlusion_right_eye_a} text='Oclusión vascular' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.occlusion_right_eye_b} text='Oclusión vascular' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.waterfall_left_eye_a} text='Hialosis asteroidea' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.waterfall_left_eye_b} text='Hialosis asteroidea' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.waterfall_right_eye_a} text='Hialosis asteroidea' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.waterfall_right_eye_b} text='Hialosis asteroidea' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.vascular_left_eye_a} text='Otros trastornos vasculares' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.vascular_left_eye_b} text='Otros trastornos vasculares' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.vascular_right_eye_a} text='Otros trastornos vasculares' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.vascular_right_eye_b} text='Otros trastornos vasculares' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.retina_left_eye_a} text='Otros trastorno de la retina' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.retina_left_eye_b} text='Otros trastorno de la retina' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.retina_right_eye_a} text='Otros trastorno de la retina' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.retina_right_eye_b} text='Otros trastorno de la retina' />

                                                            <ContainerFindings option={option} optionValue={0} response={i.optic_nerve_left_eye_a} text='Otros trastornos del nervio óptico' />
                                                            <ContainerFindings option={option} optionValue={1} response={i.optic_nerve_left_eye_b} text='Otros trastornos del nervio óptico' />
                                                            <ContainerFindings option={option} optionValue={2} response={i.optic_nerve_right_eye_a} text='Otros trastornos del nervio óptico' />
                                                            <ContainerFindings option={option} optionValue={3} response={i.optic_nerve_right_eye_b} text='Otros trastornos del nervio óptico' />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Container>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <div className={classes.container_image}>
                                            <div style={{display:option===0?'block':'none'}}>
                                                <Carousel text={'Ojo izquierdo centrado en mácula'} back={0} next={1} onClick={setOption} />
                                                <DragImage image={data.picture_left_eye_a} blockData={'disabled'} />
                                            </div>
                                            <div style={{display:option===1?'block':'none'}}>
                                                <Carousel text={'Ojo izquierdo centrado en disco'} back={0} next={2} onClick={setOption} />
                                                <DragImage image={data.picture_left_eye_b} blockData={'disabled'} />
                                            </div>
                                            <div style={{display:option===2?'block':'none'}}>
                                                <Carousel text={'Ojo derecho centrado en mácula'} back={1} next={3} onClick={setOption} />
                                                <DragImage image={data.picture_right_eye_a} blockData={'disabled'} />
                                            </div>
                                            <div style={{display:option===3?'block':'none'}}>
                                                <Carousel text={'Ojo derecho centrado en disco'} back={2} next={4} onClick={setOption} />
                                                <DragImage image={data.picture_right_eye_b} blockData={'disabled'} />
                                            </div>
                                            <div style={{display:option===4?'block':'none'}}>
                                                <Carousel text={'Segmento anterior - ojo izquierdo'} back={3} next={5} onClick={setOption} />
                                                <DragImage image={data.picture_right_eye_b} blockData={'disabled'} />
                                            </div>
                                            <div style={{display:option===5?'block':'none'}}>
                                                <Carousel text={'Segmento anterior - ojo derecho'} back={4} next={5} onClick={setOption} />
                                                <DragImage image={data.picture_right_eye_b} blockData={'disabled'} />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    </Container>
                </Modal>
        </div>
    );
};

export default ModalInfo;