import {useState}	from 'react';
import {connect}	from 'react-redux';
// Redux
import {changeView} from '../../store/redux/actions/Global';
import {updateHospitals,getHospitals} from '../../store/redux/actions/Hospitals';
// Components
import InputSearch	from '../global/InputSearch';
import LoadingData	from '../global/LoadingData';
// Unike key
import {capitalizeText} from '../../utils'; // generateUniqueKey,
// Values
import {StateView, stateData} from '../../values';
// Api
import {getData} from '../../utils/hospitals'; // getSearchData,

const Tables = ({data, status, id, token, page, records, changeView, updateHospitals, getHospitals}) => {
	const [search, setSearch] = useState('');

	const searchInfo = async() => {
		if (search) {
			const extra = `?search=${search}`;
			const res = await getData(token, extra);
			getHospitals(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1);
		} else {
			const extra = `?page=1&limit=10`;
			const res = await getData(token, extra);
			getHospitals(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1);
		}
	}

	const GetData = async(event, newPage) => {
		const extra = `?page=${newPage+1}&limit=10`;
		const res = await getData(token, extra);
		getHospitals(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1);
	}

	const edit = (item) => {
		changeView(StateView.state3);
		updateHospitals(item);
	}

	return (
		<div className="card_info">
			{/* <div style={{height:30}} /> */}
			<InputSearch onClickSearch={searchInfo}
				value={search} setData={setSearch}
				page={page} records={records} setPage={GetData} />
			{
				status===stateData.state2?
					<div className="card_scroll">
						{
							data &&
							data.map((item,j) => (
								<div key={j} onClick={() => edit(item)}
									className={id === item.id ? 'table_info_selected' : "table_info"}
									style={{userSelect:'none'}}>
									<div style={{marginLeft:50}}>
										<label style={{fontFamily:'semi', fontSize:16, cursor:'pointer'}}>
											{capitalizeText(item.name)}
										</label><br/>
										<label style={{fontFamily:'regular', cursor:'pointer'}}>
											{capitalizeText(item.municipality)}, {capitalizeText(item.state)}
										</label><br/>
										<label style={{fontFamily:'regular', cursor:'pointer'}}>
											{item.phone}
										</label>
									</div>
								</div>
							))
						}
					</div>
				:
				<LoadingData />
			}
		</div>
	);
};

const mapStateToProps = state =>({
	data:		state.Hospitals.data,
	status:		state.Hospitals.status,
	id:			state.Hospitals.dataEdit.id,
	token:		state.PanelUser.token,
	page:		state.Hospitals.page,
	records:	state.Hospitals.records
})

export default connect(mapStateToProps, {changeView, updateHospitals, getHospitals})(Tables)