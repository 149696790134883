import {Tooltip} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
// Values
import {Ic_checked, Ic_no_checked, Ic_checked_disabled, Img_warning} from '../../values';

const CustomTooltip = withStyles((theme) => ({
	tooltip: {
		background: "rgba(0, 0, 0, 0.9)",
		width: 285,
		height: 150,
		borderRadius: 9,
		color: "#fff",
		fontSize: 12,
		fontFamily: "regular",
	},
}))(Tooltip);

const CheckLevel = ({check, value, setCheck, text, blockData}) => {
	let image = Ic_no_checked;

	if (check) {
		if (blockData) {
			image = Ic_checked_disabled;
		} else {
			image = Ic_checked;
		}
	}

	return(
		<div style={{display:'flex', flexWrap: 'wrap', alignItems:'center', margin: '6px 8px'}}>
			<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
				<img alt='' src={image} onClick={() => setCheck(value)} style={{width:25, cursor:'pointer', userSelect:'none'}} />
			</div>
			<label style={{width: '100%', fontSize:15, textAlign: 'center'}}>{text}</label>
		</div>
	)
}

const SelectEdema = ({value, setData, blockData=false}) => {

	const edema_level_key_shortcuts = () => {
		return (
			<div>
				Para definir el nivel de edema macular puedes colocar el puntero sobre el valor correspondiente o hacer uso de las teclas “A”, ”B” y ”C”
				<ul>
					<li>{'A = No hay presencia'}</li>
					<li>{'B = Hay presencia / No referible'}</li>
					<li>{'C = Hay presencia / Referible'}</li>
				</ul>
			</div>
		);
	}

	return (
		<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
			<h3 style={{width: '100%'}}>
				Edema macular
				{
					!blockData &&
					<CustomTooltip title={edema_level_key_shortcuts()} placement="right">
						<img alt="" src={Img_warning} style={{ width: 16, marginLeft: 8 }} />
					</CustomTooltip>
				}
			</h3>
			<CheckLevel check={value === 'M0' ? true : false} value={'M0'} setCheck={setData} blockData={blockData} text='A' />
			<CheckLevel check={value === 'M1' ? true : false} value={'M1'} setCheck={setData} blockData={blockData} text='B' />
			<CheckLevel check={value === 'M2' ? true : false} value={'M2'} setCheck={setData} blockData={blockData} text='C' />
		</div>
	);
};

export default SelectEdema;