
const InputInfo = ({title, value, blockData, setData, type='text', info='', min=0, max= 999999999999999999999, color='#000', strong, disabled, placeholder='' }) => {
    return (
        <div style={{display:'flex', alignItems:'center', opacity:disabled?0.6:1}}>
            <p className='title_input' style={{marginTop:14, marginBottom:14, color:color, fontWeight:strong?600:500}}>{title}</p>
            <div style={{width:'100%', position:'relative'}}>
                {
                    type === 'number'?
                        <input type={type}
                            style={{WebkitAppearance:'textfield'}}
                            step="any"
                            min={min}
                            max={max}
                            onChange={e => setData(e.target.value)}
                            value={value} 
                            className='input'
                            disabled={blockData||disabled}
                            placeholder={placeholder} />
                    :
                        <input type={type}
                            style={{WebkitAppearance:'textfield'}}
                            onChange={e => setData(e.target.value)}
                            value={value} 
                            className='input'
                            disabled={blockData||disabled}
                            placeholder={placeholder} />
                }
                <p style={{position:'absolute', top:-7, right:20, fontWeight:600}}>{info}</p>
            </div>
        </div>
    );
};

export default InputInfo;