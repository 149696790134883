const Button = ({add}) => {
    return (
        <div>
            <button className="button_modal" onClick={add}>
                {"Agregar"}
            </button>
        </div>
    );
};

export default Button;