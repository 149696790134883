import {urlApi, urlPath} from './api';
import uniqid  from 'uniqid';
import {toast} from 'react-toastify';
import {toastText, translate_inputs} from './../values';


export const emailformat = /^([\w.-]+)@([\w-]+)((\.(\w){2,3})+)$/; // TODO: Remove this variable
export const phoneformat = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const regex_email = /^([\w.-]+)@([\w-]+)((\.(\w){2,3})+)$/;
export const regex_phone_number = /^(\+?52)?(1)?\d{10}$/; // e.i. 5512345678, +525512345678, 15512345678. Remove spaces, dashed/hyphens before comparing
export const regex_names_one = /^[\wáéíóúñ .]{2,300}$/i; // Regex with letters, letters with spanish accents, case insensitive, numbers, underscore, space, point, 2-300 chars
export const regex_names_two = /^[a-záéíóúñ .]{2,300}$/i; // Regex with letters, letters with spanish accents, case insensitive, space, point, 2-300 chars
export const regex_names_three = /^[\wáéíóúñ .#]{2,300}$/i; // Regex with letters, letters with spanish accents, case insensitive, numbers, underscore, space, point, hashtag, 2-300 chars
export const regex_curp = /^[A-Z]{4}\d{6}[HM][A-Z]{5}[0-9]{2}$/i;

// Regexs to validate full or parcial password rules
export const regex_full_password = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const regex_low_case_passwd = /^(?=.*[a-z])[^<>]*$/;
export const regex_upp_case_passwd = /^(?=.*[A-Z])[^<>]*$/;
export const regex_num_passwd = /^(?=.*\d)[^<>]*$/;
export const regex_spe_char_passwd = /^(?=.*[@$!%*?&])[^<>]*$/;
export const regex_8_chars_pass = /^.{8,}$/;

/**
 * This function capitalizes every word in a string
 * @param {String} text Text to format
 * @returns {String} Formatted text
 */
export const capitalizeText = (text) => {
    return text.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase());
}

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") =>{
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (error) {
        console.log(error)
    }
}

/**
 * This function formats a received date string
 * @param {String} date Date in string format, example date string: "2023-08-04T16:57:26", "2023-08-04", ...
 * @returns {String} Date string with next example format: "VIE, 4 AGOSTO DE 2023, 10:57"
 */
export const formatDate1 = (date) => {
    // https://medium.com/swlh/use-tolocaledatestring-to-format-javascript-dates-2959108ea020
    // const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric', second:'numeric' }
    const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric'};
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions);
    return fecha.toUpperCase();
    // return fecha.replace('de',' ').toUpperCase();
    // return fecha.replace('de',' ').charAt(0).toUpperCase() + fecha.slice(1)
}

/**
 * This function formats a received date string
 * @param {String} date Date in string format, example date string: "2023-08-04T16:57:26", "2023-08-04", ...
 * @returns {String} Date string with next example format: "4 AGOSTO DE 2023"
 */
export const formatDate2 = (date) => {
    const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'};
    let date_string = date.length == 10 ? `${date}T06:00:00Z` : date;
    const fecha = new Date(date_string).toLocaleDateString('es-MX', dateOptions);
    return fecha.toUpperCase();
    // return fecha.replace('de',' ').charAt(0).toUpperCase() + fecha.slice(1)
}

/**
 * This function formats a received date string
 * @param {String} date Date in string format, example date string: "2023-08-04T16:57:26", "2023-08-04", ...
 * @returns {String} Date string with next example format: "4 ago 2023"
 */
export const formatDate3 = (date) => {
    const dateOptions = {year: 'numeric', month: 'short', day: 'numeric'};
    let date_string = date.length == 10 ? `${date}T06:00:00Z` : date;
    const fecha = new Date(date_string).toLocaleDateString('es-MX', dateOptions);
    return fecha;
    // return fecha.replace('de',' ').charAt(0).toUpperCase() + fecha.slice(1)
}

/**
 * This function formats a received date string
 * @param {String} date Date in string format, example date string: "2023-08-04T16:57:26", "2023-08-04", ...
 * @returns {String} Date string with next example format: "10:57 am"
 */
export const formatDate4 = (date) => {
    // const dateOptions = {hour:'numeric', minute:'numeric', second:'numeric'}
    // const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    let hours       = new Date(date).getHours();
    let minutes     = new Date(date).getMinutes();
    let ampm        = hours >= 12 ? 'pm' : 'am';
    hours           = hours % 12;
    hours           = hours ? hours : 12;
    minutes         = minutes < 10 ? '0'+minutes : minutes;
    const fecha     = `${hours}:${minutes} ${ampm}`;

    return fecha;
}

/**
 * This function formats a received date string
 * @param {String} date Date in string format, example date string: "2023-08-04T16:57:26", "2023-08-04", ...
 * @returns {String} Date string with next example format: "VIE, 4 AGOSTO DE 2023, 10:57"
 */
export const formatDate5 = (date) => {
    // https://medium.com/swlh/use-tolocaledatestring-to-format-javascript-dates-2959108ea020
    // const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric', second:'numeric' }
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour:'numeric', minute:'numeric'};
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions);
    return fecha.toUpperCase();
}

/**
 * This funcion returns a date string from a received Date object
 * @param {Date} date Date object to work with
 * @returns Date string in format "yyyy-mm-dd"
 */
export const date_to_string = (date) => {
    return `${date.getFullYear()}-${date.getMonth() <= 8 ? `0${date.getMonth()+1}` : date.getMonth()+1}-${date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()}`;
}

export const generateUniqueKey = (count=3) =>{
    let characters  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let date        = new Date()
    let res         = ''

    for (let i = 0; i < count; i++) {
        res += characters[Math.floor(Math.random() * characters.length)]
    }
    return `${res}${date.getTime()}`
}

// export const deployImage = async(image, key, token) =>{
//     console.log("Deploy image");
//     const url = urlApi+urlPath.fileUpload
//     let urlImage = ""

//     let myHeaders = new Headers();
//     myHeaders.append('token',token)
//     // myHeaders.append("Content-Type", "multipart/form-data");

//     let formdata = new FormData();    
//     formdata.append("file", image, 'image.jpg');
//     formdata.append("key", key);

//     const request = {
//         method:"POST",
//         body: formdata,
//         credentials: 'include',
//         headers:myHeaders
//     }
    
//     console.log('url: ', url);
//     console.log('image: ', image);
//     console.log('key: ', key);
//     console.log('token: ', token);

//     await fetch(url, request)
//     .then((res) =>{
//         console.log(res.status);
//         if(res.status === 200){
//             console.log(res.status);
//         }
//     })
//     .then((res)=>{
//         urlImage = res.url
//     })
//     .catch((err)=>{
//         console.log('err: ', err);
//     })

//     return urlImage
// }

export const deployImage = async(image, key, token) =>{
    console.log("Deploy image");
    const url = urlApi+urlPath.fileUpload
    let urlImage = ""
    let myHeaders = new Headers();
    myHeaders.append('token',token)
    let formdata = new FormData();

    // formdata.append("file", image, 'image.jpg');
    formdata.append("file", image, `${uniqid()}.jpg`);
    formdata.append("key", key);
    const request = {
        method:"POST",
        body: formdata,
        headers:myHeaders
    }
    
    // console.log('url: ', url);
    // console.log('image: ', image);
    // console.log('key: ', key);
    // console.log('token: ', token);

    await fetch(url, request)
    .then((res) =>{
        console.log(res.status);
        if(res.status === 200){
            return res.json()
        }
    })
    .then((res)=>{
        // console.log("Res: ", res)
        urlImage = res.url
    })
    .catch((err)=>{
        console.log('err: ', err);
    })
    return urlImage
}


const createImage = url =>
    new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
    })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    const newImage = await new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(URL.createObjectURL(file))
        }, 'image/jpeg')

    })

    // const newImage = new File([imageBlob], 'user', {type: 'image/jpeg', lastModified: Date.now()})

    return newImage
}

export const manage_request_errors = (error) => {
	if (error.errors && error.message && error.message === 'The data to send is invalid') { // Manejo de errores en validaciones de datos locales
		toast.warn(
			<div>
				Hay inconsistencias con algunos datos ingresados, corrigelos:
				<ul>
					{
						error.errors.map((error, i) => {
							return (<li key={i}>{error}</li>)
						})
					}
				</ul>
			</div>
		);
	} else if (error.errors && error.message && error.message === 'The received data was invalid.') { // Manejo de errores en servidor
		if (error.errors) { // Errores en validaciones de datos
			toast.warn(
				<div>
					{toastText.inputNotValid}
					<ul>
						{
							Object.keys(error.errors).map((err, i) => {
								return (<li key={i}>{translate_inputs[err]}: {error.errors[err].join(', ')}</li>)
							})
						}
					</ul>
				</div>
			);
		} else {
			console.log(error);
			toast.warn(toastText.warning);
		}
	} else { // Error inesperado
		console.log(error);
		toast.warn(toastText.warning);
	}
}