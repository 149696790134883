import ReactLoading from "react-loading";

// Values
import { Img_logo_splash } from "../../values";

const LoadingScreen = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "#263746",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <img alt="" src={Img_logo_splash} style={{ marginTop: 200 }} />
      <div style={{ width: "100%", textAlign: "center", position: "relative" }}>
        <div style={{ position: "absolute", left: "50%", top: -50, transform: "translate(-50%, 0)" }}>
          <ReactLoading type={"spinningBubbles"} color={"white"} style={{ fill: "white", height: 40, width: 40 }} />
        </div>
        <p style={{ color: "#fff", fontFamily: "semi" }}>{"Cargando"}</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
