import {reduxState, stateData} from 'values';

let initialState = {
	data:			[],
	// allData:		[],
	status:			stateData.state1,
	dataEdit:		[],
	records:		0,
	page:			1,
	request_index:	-1,
}

const reducer = (state = initialState, action) => {

	if (action.type === reduxState.get_investigation_datasets) {
		return {
			...state,
			data:		action.data,
			records:	action.records,
			page:		action.page,
			status:		action.status,
		}
	}

	if (action.type === reduxState.update_investigation_datasets) {
		return {
			...state,
			dataEdit: action.data
		}
	}

	if (action.type === reduxState.update_investigation_request) {
		return {
			...state,
			request_index: action.index,
		}
	}

	return state;
}

export default reducer;