import {useState, useEffect}   from 'react';

import {Ic_image} from 'values';

const DragImage = ({image, blockData, setImage, option='', styleDrag=1}) => {
    const [img, setImg] = useState(image)
    const handleImage = (imag) => {
        const url = window.URL.createObjectURL(imag.files[0])
        setImg(url)
        setImage(imag.files[0])
    }

    useEffect(() =>{
        if(image){
            if(typeof image==='object'){     
                const url = window.URL.createObjectURL(image)
                setImg(url)
            } else{
                setImg(image)
            }
        } else{
            setImg('')
        }
    },[image])
    return (
        <div style={{paddingTop:30}}>
            <label htmlFor={blockData === 'disabled' ? '' : `file-upload${option}`} style={{cursor:blockData==='disabled'?'default':'pointer', position:'relative'}}>
                <div className="container_drag_image">
                    <div style={{display:styleDrag===1?'block':'none'}}>
                        <div className="container_drag_image_white_ring">
                            {
                                img?
                                    <div className="drag_image_content">
                                        <img alt="" src={img} className="drag_image" />
                                    </div>
                                :
                                    <div className="drag_image_content">
                                        <img alt="" src={img ? img : Ic_image} style={{width:130, height:130}} />
                                    </div>
                            }
                        </div>
                    </div>
                    <div style={{display:styleDrag===2?'block':'none'}}>
                        <div>
                            {
                                img?
                                    <div className="drag_image_content2">
                                        <img alt="" src={img} style={{width:'100%', height:'100%', objectFit:'scale-down'}} />
                                    </div>
                                :
                                    <div className="drag_image_content">
                                        <img alt="" src={Ic_image} style={{width:220, height:220}} />
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </label>
            <input id={`file-upload${option}`} type="file" accept="image/*" style={{display:'none'}} onChange={e => handleImage(e.target) } />
        </div>
    );
};

export default DragImage;