import {urlApi, urlPath, get, post, update, new_post} from '../api';

const url = urlApi + urlPath.request

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const printedRequest = async(id, token) =>{
    const obj = {}
    const request = {
        method:"PATCH",
        body: JSON.stringify(obj),
        headers:{
            'token': token,
            'Content-Type': 'application/json'
        }
    }
    const res = await fetch(url+'/'+id+'/printed', request)
    .then(async(res) =>{
        if(res.status === 200){
            console.log(res.status);
            return true
        } else{
            console.log(res);
            return false
        }
    })
    .catch((err) =>{
        return false
    })
    return res
}

export const postRequestData = async(
	segments_set, images_paths, patientId, medicalCenterId, height, weight, systolicBloodPressure, diastolicBloodPressure, diabetesTime, insulin, oralHypoglycemic,
	mydriasisUse, hemoglobinGlycated, glucoseInVenousBlood, capillaryBloodGlucose, visualAcuityLeftA, visualAcuityLeftB, visualAcuityRightA, visualAcuityRightB,
	visualAcuityBoth, useGlasses, visualAcuityGlassesLeft, visualAcuityGlassesRight, visualAcuityGlassesBoth, waist, heartRate, breathingFrequency,
	temperature, cigarretes, creatinine, microalbuminuria, glomerularFiltrationRate,triglycerides, cholesterol,ldl, hdl, token
) => {

	const obj = {
		patientId: patientId ? parseInt(patientId) : '',
		medicalCenterId: medicalCenterId ? parseInt(medicalCenterId) : '',
		height: height ? parseFloat(height) : '',
		weight: weight ? parseFloat(weight) : '',
		systolicBloodPressure,
		diastolicBloodPressure,
		diabetesTime,
		insulin,
		oralHypoglycemic,
		mydriasisUse,
		hemoglobinGlycated,
		glucoseInVenousBlood,
		capillaryBloodGlucose,
		visualAcuityLeftA,
		visualAcuityLeftB,
		visualAcuityRightA,
		visualAcuityRightB,
		visualAcuityBoth,
		visualAcuityGlassesLeft,
		visualAcuityGlassesRight,
		visualAcuityGlassesBoth,
		waist: waist ? parseFloat(waist) : '',
		heartRate: heartRate ? parseFloat(heartRate) : '',
		breathingFrequency: breathingFrequency ? parseFloat(breathingFrequency) : '',
		temperature: temperature ? parseFloat(temperature) : '',
		cigarretes,
		creatinine,
		microalbuminuria,
		glomerularFiltrationRate,
		triglycerides,
		cholesterol,
		ldl,
		hdl,
		coments: 'Sin comentarios'
	}

	let paths_length = images_paths.length;
	images_paths.map((path, i) => {
		if (segments_set && i == paths_length-1) {
			obj.previous_segment_left_eye = path;
		} else if (segments_set && i == paths_length-2) {
			obj.previous_segment_right_eye = path;
		} else if (i == 0) {
			obj.optical_disk_centered_right_eye = path;
		} else if (i == 1) {
			obj.optical_disk_centered_left_eye = path;
		} else if (i == 2) {
			obj.macula_centered_right_eye = path;
		} else if (i == 3) {
			obj.macula_centered_left_eye = path;
		} else if (i == 4) {
			obj.temporal_macula_right_eye = path;
		} else if (i == 5) {
			obj.temporal_macula_left_eye = path;
		} else if (i == 6) {
			obj.upper_temporal_tangential_right_eye = path;
		} else if (i == 7) {
			obj.upper_temporal_tangential_left_eye = path;
		} else if (i == 8) {
			obj.lower_temporal_tangential_right_eye = path;
		} else if (i == 9) {
			obj.lower_temporal_tangential_left_eye = path;
		} else if (i == 10) {
			obj.upper_nasal_tangential_right_eye = path;
		} else if (i == 11) {
			obj.upper_nasal_tangential_left_eye = path;
		} else if (i == 12) {
			obj.lower_nasal_tangential_right_eye = path;
		} else if (i == 13) {
			obj.lower_nasal_tangential_left_eye = path;
		}
	});

	return new_post(obj, url, token);
}

export const validateRequestData = (
	pictureDiskCenteredRightEye, pictureDiskCenteredLeftEye, pictureMaculaCenteredRightEye, pictureMaculaCenteredLeftEye,
	pictureTempMaculaRightEye, pictureTempMaculaLeftEye, pictureUpperTempTangRightEye, pictureUpperTempTangLeftEye,
	pictureLowerTempTangRightEye, pictureLowerTempTangLeftEye, pictureUpperNasalTangRightEye, pictureUpperNasalTangLeftEye,
	pictureLowerNasalTangRightEye, pictureLowerNasalTangLeftEye, previousSegmentLeftEye, previousSegmentRightEye,
	study_type, patientId, height, weight, systolicBloodPressure, diastolicBloodPressure, diabetesTime, insulin, oralHypoglycemic, mydriasisUse, hemoglobinGlycated,
	glucoseInVenousBlood, capillaryBloodGlucose, visualAcuityLeftA, visualAcuityLeftB, visualAcuityRightA, visualAcuityRightB, visualAcuityBoth,
	useGlasses, visualAcuityGlassesLeft, visualAcuityGlassesRight, visualAcuityGlassesBoth, waist, heartRate, breathingFrequency, temperature, cigarretes,
	creatinine,	microalbuminuria, glomerularFiltrationRate, triglycerides, cholesterol, ldl, hdl
) => {
	return new Promise((resolve, reject) => {
		let success = true;
		let errors = [];

		if (!pictureDiskCenteredRightEye || typeof pictureDiskCenteredRightEye !== 'object') {
			success = false;
			errors.push('Insertar imagen ojo derecho centrado en disco.');
		}
		if (!pictureDiskCenteredLeftEye || typeof pictureDiskCenteredLeftEye !== 'object') {
			success = false;
			errors.push('Insertar imagen ojo izquierdo centrado en disco.');
		}
		if (study_type >= 2 && (!pictureMaculaCenteredRightEye || typeof pictureMaculaCenteredRightEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo derecho centrado en macula.');
		}
		if (study_type >= 2 && (!pictureMaculaCenteredLeftEye || typeof pictureMaculaCenteredLeftEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo izquierdo centrado en macula.');
		}
		if (study_type >= 3 && (!pictureTempMaculaRightEye || typeof pictureTempMaculaRightEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo derecho temporal a la macula.');
		}
		if (study_type >= 3 && (!pictureTempMaculaLeftEye || typeof pictureTempMaculaLeftEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo izquierdo temporal a la macula.');
		}
		if (study_type >= 4 && (!pictureUpperTempTangRightEye || typeof pictureUpperTempTangRightEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo derecho tangencial temporal superior.');
		}
		if (study_type >= 4 && (!pictureUpperTempTangLeftEye || typeof pictureUpperTempTangLeftEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo izquierdo tangencial temporal superior.');
		}
		if (study_type >= 5 && (!pictureLowerTempTangRightEye || typeof pictureLowerTempTangRightEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo derecho tangencial temporal inferior.');
		}
		if (study_type >= 5 && (!pictureLowerTempTangLeftEye || typeof pictureLowerTempTangLeftEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo izquierdo tangencial temporal inferior.');
		}
		if (study_type >= 6 && (!pictureUpperNasalTangRightEye || typeof pictureUpperNasalTangRightEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo derecho tangencial nasal superior.');
		}
		if (study_type >= 6 && (!pictureUpperNasalTangLeftEye || typeof pictureUpperNasalTangLeftEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo izquierdo tangencial nasal superior.');
		}
		if (study_type >= 7 && (!pictureLowerNasalTangRightEye || typeof pictureLowerNasalTangRightEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo derecho tangencial nasal inferior.');
		}
		if (study_type >= 7 && (!pictureLowerNasalTangLeftEye || typeof pictureLowerNasalTangLeftEye !== 'object')) {
			success = false;
			errors.push('Insertar imagen ojo izquierdo tangencial nasal inferior.');
		}
		if (previousSegmentLeftEye && typeof previousSegmentLeftEye !== 'object') {
			success = false;
			errors.push('No valida imagen ojo izquierdo de segmento anterior.');
		}
		if (previousSegmentRightEye && typeof previousSegmentRightEye !== 'object') {
			success = false;
			errors.push('No valida imagen ojo derecho de segmento anterior.');
		}
		if (!patientId || typeof patientId !== 'number' || patientId < 1) {
			success = false;
			errors.push('Selecciona al paciente.');
		}
		if (!height || height < 140 || height > 210) {
			success = false;
			errors.push('Ingresa la estatura entre 140 y 210 centimetros.');
		}
		if (!weight || weight < 30 || weight > 300) {
			success = false;
			errors.push('Ingresa el peso entre 30 y 300 kilogramos.');
		}
		// CONTROL VALUES
		if (!systolicBloodPressure || systolicBloodPressure < 90 || systolicBloodPressure > 200) {
			success = false;
			errors.push('Ingresa la tension arterial sistólica entre 90 y 200 mmHg.');
		}
		if (!diastolicBloodPressure || diastolicBloodPressure < 60 || diastolicBloodPressure > 120) {
			success = false;
			errors.push('Ingresa la tension arterial diastólica entre 60 y 120 mmHg.');
		}
		if (!diabetesTime || diabetesTime < 0 || diabetesTime > 100) {
			success = false;
			errors.push('Ingresa un tiempo con diabetes valido, desde 0 a 100 años.');
		}
		if (!insulin || !['SI', 'NO'].includes(insulin)) {
			success = false;
			errors.push('Indica si hay uso de insulina.');
		}
		if (!oralHypoglycemic || !['SI', 'NO'].includes(oralHypoglycemic)) {
			success = false;
			errors.push('Indica si hay hipoglucemiantes orales.');
		}
		if (!mydriasisUse || !['SI', 'NO'].includes(mydriasisUse)) {
			success = false;
			errors.push('Indica si hay uso de midriasis.');
		}
		if (!hemoglobinGlycated || hemoglobinGlycated < 4 || hemoglobinGlycated > 15) {
			success = false;
			errors.push('Ingresa la hemoglobina glucosilada, desde el 4 al 15.');
		}
		if (!glucoseInVenousBlood || glucoseInVenousBlood < 70 || glucoseInVenousBlood > 130) {
			success = false;
			errors.push('Ingresa la glucosa en sangre venosa, desde el 70 a 130 mmHg.');
		}
		if (!capillaryBloodGlucose || capillaryBloodGlucose < 0 || capillaryBloodGlucose > 100) {
			success = false;
			errors.push('Ingresa un tiempo con glucemia capilar valido, desde 0 a 100 años.');
		}
		// VISUAL ACUITY
		if (!visualAcuityLeftA) {
			success = false;
			errors.push('Parece que algo anda mal con la agudeza visual del ojo izquierdo.');
		}
		if (!visualAcuityLeftB) {
			success = false;
			errors.push('Parece que algo anda mal en la agudeza visual del ojo izquierdo con estenopeico.');
		}
		if (!visualAcuityRightA) {
			success = false;
			errors.push('Parece que algo anda mal con la agudeza visual del ojo derecho.');
		}
		if (!visualAcuityRightB) {
			success = false;
			errors.push('Parece que algo anda mal en la agudeza visual del ojo derecho con estenopeico.');
		}
		if (!visualAcuityBoth) {
			success = false;
			errors.push('Parece que algo anda mal en la agudeza visual de ambos ojos.');
		}
		if (useGlasses && useGlasses === 'SI') {
			if (!visualAcuityGlassesLeft) {
				success = false;
				errors.push('Parece que algo anda mal en la agudeza visual del ojo izquierdo con estenopeico usando lentes.');
			}
			if (!visualAcuityGlassesRight) {
				success = false;
				errors.push('Parece que algo anda mal en la agudeza visual del ojo derecho con estenopeico usando lentes.');
			}
			if (!visualAcuityGlassesBoth) {
				success = false;
				errors.push('Parece que algo anda mal en la agudeza visual de ambos ojos usando lentes.');
			}
		}
		// OPTIONAL INPUTS
		if (waist && (isNaN(waist) || waist < 40 || waist > 300)) {
			success = false;
			errors.push('Ingresa un valor para la cintura valido, desde 40 a 300 centimetros.');
		}
		if (heartRate && (isNaN(heartRate) || heartRate < 60 || heartRate > 120)) {
			success = false;
			errors.push('Ingresa un valor para la frecuencia cardiaca, desde 60 a 120 latidos por minuto.');
		}
		if (breathingFrequency && (isNaN(breathingFrequency) || breathingFrequency < 12 || breathingFrequency > 20)) {
			success = false;
			errors.push('Ingresa un valor para la frecuencia respiratorio, desde 12 a 20 respiraciones por minuto.');
		}
		if (temperature && (isNaN(temperature) || temperature < 36.5 || temperature > 37.5)) {
			success = false;
			errors.push('Ingresa un valor para la temperatura, desde 36.5 a 37.5 grados celsius.');
		}
		if (cigarretes && (isNaN(cigarretes) || cigarretes < 0)) {
			success = false;
			errors.push('Ingresa un valor para llos cigarros por dia.');
		}
		if (creatinine && (isNaN(creatinine) || creatinine < 0.6 || creatinine > 10)) {
			success = false;
			errors.push('Ingresa un valor para la creatinina, desde 0.6 a 10 mg/dL.');
		}
		if (microalbuminuria && (isNaN(microalbuminuria) || microalbuminuria < 30)) {
			success = false;
			errors.push('Ingresa un valor para la microalbuminuria, desde 30 mg/g.');
		}
		if (glomerularFiltrationRate && (isNaN(glomerularFiltrationRate) || glomerularFiltrationRate < 90)) {
			success = false;
			errors.push('Ingresa un valor para la tasa de filtrado glomerular, desde 90 mL/min/1.73m².');
		}
		if (triglycerides && (isNaN(triglycerides) || triglycerides < 0.5 || triglycerides > 2)) {
			success = false;
			errors.push('Ingresa un valor para los trigliceridos, desde 0.5 a 2.0 en mmol/L.');
		}
		if (cholesterol && (isNaN(cholesterol) || cholesterol < 125 || cholesterol > 200)) {
			success = false;
			errors.push('Ingresa un valor para la colesterol, desde 125 a 200 mg/dL.');
		}
		if (ldl && (isNaN(ldl) || ldl < 1 || ldl > 4)) {
			success = false;
			errors.push('Ingresa un valor para ldl, desde 1 a 4 en mmol/L.');
		}
		if (hdl && (isNaN(hdl) || hdl < 0.9 || hdl > 2.5)) {
			success = false;
			errors.push('Ingresa un valor para hdl, desde 0.9 a 2.5 en mmol/L.');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}