import {useState, useEffect} from 'react';
import {Container} from '@material-ui/core';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import ReactLoading from 'react-loading';

// Redux
import {changeView, cleanData} from '../../store/redux/actions/Global';
// Components
import Input from './Input';
// Values
import {toastText, Ic_next_white, translate_inputs, Ic_checked, Ic_no_checked} from 'values';
import {
	regex_full_password,
	regex_low_case_passwd,
	regex_upp_case_passwd,
	regex_num_passwd,
	regex_spe_char_passwd,
	regex_8_chars_pass
} from 'utils';
// Api
import {validateSelfUserPasswdUpdate, selfUserUpdateData} from 'utils/users';

const FormProfile = ({token}) => {

	// Modal
	const [loading, setLoading]	= useState(false)

	const [currentPassword, setCurrentPassword] = useState('')
	const [newPassword, setNewPassword]			= useState('')
	const [newPasswordConf, setNewPasswordConf] = useState('')

	const [passWithLower, setPassWithLower] = useState(false);
	const [passWithUpper, setPassWithUpper] = useState(false);
	const [passWithNums, setPassWithNums] = useState(false);
	const [passWithSpeChars, setPassWithSpeChars] = useState(false);
	const [passWith8CharsLength, setPassWith8CharsLength] = useState(false);

	const [eyeCurrent, setEyeCurrent] = useState(false);
	const [eyeNew, setEyeNew] = useState(false);
	const [eyeNewConf, setEyeNewConf] = useState(false);

	const cleanLocalData = () => {
		setCurrentPassword('');
		setNewPassword('');
		setNewPasswordConf('');
	}

	const patchData = async() => {

		validateSelfUserPasswdUpdate(newPassword, newPasswordConf)
		.then(_ => {
			return selfUserUpdateData({current_password: currentPassword, password: newPassword, password_confirmation: newPasswordConf}, 'password', token);
		})
		.then(response => {return response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			setLoading(false);
			toast.success(toastText.update);
			cleanLocalData();
		})
		.catch(error => {
			setLoading(false);
			console.log(error);

			if (error.errors && error.message && error.message === 'The data to send is invalid') { // Manejo de errores en validaciones locales
				for (let value of error.errors) {
					toast.warn(`${toastText.inputNotValid}${value}`);
				}
			} else if (error.errors && error.message && error.message === 'The received data was invalid.') { // Error en los datos enviados al servidor
				if (error.errors) {
					toast.warn(
						<div>
							{toastText.inputNotValid}
							<ul>
								{
									Object.keys(error.errors).map((err, i) => {
										return (<li key={i}>{translate_inputs[err]}: {error.errors[err].join(', ')}</li>)
									})
								}
							</ul>
						</div>
					);
				} else {
					toast.warn(toastText.warning);
				}
			} else { // Error inesperado
				toast.warn(toastText.warning);
			}
		});
	}

	const onSubmit = () => {
		setLoading(true);
		patchData();
	}

	useEffect(() => {
		setLoading(false);

		setPassWithLower(newPassword.match(regex_low_case_passwd));
		setPassWithUpper(newPassword.match(regex_upp_case_passwd));
		setPassWithNums(newPassword.match(regex_num_passwd));
		setPassWithSpeChars(newPassword.match(regex_spe_char_passwd));
		setPassWith8CharsLength(newPassword.match(regex_8_chars_pass));
	}, [newPassword])

	return (
		<div className="card_info">
			<div style={{height:30}} />

			<div className="card_scroll">
				<Container>

					<div style={{backgroundColor:'#f1f4f8', paddingTop:15, paddingBottom:15}}>
						<Container>
							<Input
								title={'Contraseña'}
								type={'password'}
								value={currentPassword}
								view={true}
								eye={eyeCurrent}
								setEye={setEyeCurrent}
								setData={setCurrentPassword} />
							<Input
								title={'Contraseña nueva'}
								type={'password'}
								value={newPassword}
								view={true}
								eye={eyeNew}
								setEye={setEyeNew}
								setData={setNewPassword}
								regex={regex_full_password} />
							<Input
								title={'Confirmar contraseña'}
								type={'password'}
								value={newPasswordConf}
								view={true}
								eye={eyeNewConf}
								setEye={setEyeNewConf}
								setData={setNewPasswordConf}
								regex={regex_full_password} />

						</Container>
						<Container>
							<div>
								<b>** La contraseña debe cumplir con los siguientes criterios **</b>
							</div>
							<div>
								<img src={passWithLower ? Ic_checked : Ic_no_checked} style={{width:16, margin:'-4px 8px'}} />
								<label>La contraseña incluye minúsculas</label>
							</div>
							<div>
								<img src={passWithUpper ? Ic_checked : Ic_no_checked} style={{width:16, margin:'-4px 8px'}} />
								<label>La contraseña incluye mayúsculas</label>
							</div>
							<div>
								<img src={passWithNums ? Ic_checked : Ic_no_checked} style={{width:16, margin:'-4px 8px'}} />
								<label>La contraseña incluye números</label>
							</div>
							<div>
								<img src={passWithSpeChars ? Ic_checked : Ic_no_checked} style={{width:16, margin:'-4px 8px'}} />
								<label>La contraseña incluye caracteres especiales. Caracteres permitidos: @$!%*?&</label>
							</div>
							<div>
								<img src={passWith8CharsLength ? Ic_checked : Ic_no_checked} style={{width:16, margin:'-4px 8px'}} />
								<label>La contraseña tiene una longitud mínima de 8 caracteres</label>
							</div>
						</Container>
					</div>
					<button className="form_button" onClick={onSubmit} disabled={loading}>
						Guardar
						{
							!loading?
								<img alt="" src={Ic_next_white} style={{position:'absolute', right:15, top:15}} />
							:
								<div style={{position:'absolute', right:15, top:10}}>
									<ReactLoading type={"spinningBubbles"} color={"white"} style={{fill:'white', height:30, width:30}} />
								</div>
						}
					</button>

				</Container>
				<div style={{height:30}} />
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	token:		state.PanelUser.token,
})

export default connect(mapStateToProps, {changeView, cleanData})(FormProfile)