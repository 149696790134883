import {reduxState, stateData} from '../../../values';

const initialState = {
    data:           [],
    status:         stateData.state1,
    dataEdit:       [],
    records:        1,
    page:           1
};

const reducer = (state = initialState, action) =>{
    if(action.type === reduxState.get_users){
        return {
            ...state,
            data:       action.data,
            status:     action.status,
            records:    action.records,
            page:       action.page
        }
    }
    if(action.type === reduxState.update_users){
        return {
            ...state,
            dataEdit: action.data
        }
    }
    return state
}
export default reducer;