import {urlApi, urlPath, get} from '../api';

export const getData = async(token, extra="", type) =>{
    let url = urlApi + urlPath.grader_reports

    if(type===false){
        url = urlApi + urlPath.grader_reports
    } else{
        url = urlApi + urlPath.photographer_reports
    }

    return await get(url, token, extra)
} 
