import {useState}           from 'react';
import {Container, Modal}   from '@material-ui/core';

import {Img_logo_icon} from 'values';

const ModalGlobal = ({open, onClose, height=300, title, info, text_button1, text_button2, button, closeOutside=true, button_color1='#265db5', text_color1='#fff', button_color2='#323232', text_color2='#fff'}) => {
    const [statusModal, setStatusModal] = useState(true)
    const closeModal = (val) =>{
        setStatusModal(false)
        setTimeout(()=> 
            closeTime(val)
        , 600)
    }

    const closeTime = (val) =>{
        onClose(val)
        setStatusModal(true)
    }

    const actionButton = () =>{
        setStatusModal(false)
        setTimeout(()=> 
            closeTimeAction()
        , 600)
    }

    const closeTimeAction = () =>{
        setStatusModal(true)
        onClose(false)
        button()
    }

    const dontCloseOutside = () =>{}
    return (
        <div>
            <Modal open={open} 
                style={{display:'flex', alignItems:'center', justifyContent:'center',}}
                onClose={() => closeOutside?closeModal(!open): dontCloseOutside()}>
                    <Container maxWidth="sm">
                    <div className={statusModal?'modal_in':'modal_out'} style={{background:'white', minHeight:height, borderRadius:20, position:'relative'}}>
                        <Container style={{paddingTop:20, paddingBottom:20, paddingLeft:20, paddingRight:20}}>
                            <div style={{minHeight:`calc(${height}px - 20px)`, borderRadius:10, position:'relative'}}>
                                <div style={{paddingTop:5, paddingRight:10, marginBottom:30, display:'flex', justifyContent:'flex-end'}}>
                                    <img alt="Img_logo_icon" src={Img_logo_icon} style={{width:50}} />
                                </div>
                                <Container>
                                    <label style={{fontFamily:'semi', color:'#29376b', fontSize:18}}>{title}</label><br/>
                                    <label style={{fontFamily:'regular', color:'#29376b'}}>{info}</label>
                                </Container>
                                <div style={{position:'absolute', right:20, bottom:15}}>
                                    <button onClick={() => closeModal(!open)} className="button_modal" 
                                        style={{display:text_button2?'inline':'none', backgroundColor:button_color2, color:text_color2}}>
                                        {text_button2}
                                    </button>
                                    <button onClick={actionButton} className="button_modal" 
                                        style={{marginLeft:30, display:text_button1?'inline':'none', backgroundColor:button_color1, color:text_color1}}>
                                        {text_button1}
                                    </button>
                                </div>
                            </div>
                        </Container>
                    </div>
                    </Container>
            </Modal>
        </div>
    );
};

export default ModalGlobal;