import {useState, useEffect, useRef} from 'react';
import {Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import uniqid from 'uniqid';
import { toast } from 'react-toastify';

// Upload and export excel
import * as FileSaver   from 'file-saver';
import * as XLSX        from 'xlsx';

// Components
import Select       from './Select';
import DatePickers  from './DatePickers';
import LoadingData  from 'components/global/LoadingData';

// Data
import {Ic_download, Ic_search_performance} from '../../values';
import { formatDate3, date_to_string, formatDate5 } from 'utils';
// Api
import {getData} from 'utils/reports';

const useStyles = makeStyles(theme => ({
    button:{
        height:40, width:70, margin:'5px 10px', 
        cursor:'pointer', display:'flex', 
        justifyContent:'center', alignItems:'center',
        '&:active':{
            transform: 'scale(0.96)',
        },
    }
}));


const Menu = ({option, onClick}) =>{
    return(
        <div style={{display:'flex', alignItems:'center'}}>
            <p style={{fontWeight:!option?600:500, margin:0, cursor:'pointer', paddingRight:10}} onClick={()=>onClick(false)}>
                {'Graduadores'}
            </p>
            <p style={{fontWeight:option?600:500, margin:0, cursor:'pointer'}} onClick={()=>onClick(true)}>
                {'Fotógrafos'}
            </p>
        </div>
    )
}

const ButtonSearchDownload = ({image, color1='#0d2262', color2='#6dd400', onClick, opt}) =>{
    const classes = useStyles()
    return(
        <div className={classes.button} style={{background:opt?color1:color2}} onClick={onClick}>
            <img alt='' src={image} draggable={false} />
        </div>
    )
}

const Tables = ({data, status, token}) => {
	const [menu, setMenu]		= useState(false)
	const prevMenuRef 			= useRef(menu);
	const [user, setUser]		= useState('')
	const [date1, setDate1]		= useState(`${(new Date().getFullYear())}-${((new Date().getMonth()+1)>=10?(new Date().getMonth()+1):'0'+(new Date().getMonth()+1))}-01T01:00:00`)
	const [date2, setDate2]		= useState(new Date().setMonth(new Date().getMonth()))
	const [limit, setLimit]		= useState(100)
	const [list, setList]		= useState([])

	const [loading, setLoading] = useState(false)

	const [listSearchData, setSearchData] = useState([])

	const generateExcel = () => {
		const dataFormat = [];
		if (listSearchData.length !== 0) {
			listSearchData.forEach((i, j) => {
				let obj = null;
				if (menu) { // Fotografos
					obj = {
						Id_Solicitud:			i.id,
						Creada_El:				formatDate5(i.created_at),
						Nombre_Paciente:		`${i.patient.name} ${i.patient.last_name}`,
						Id_Paciente:			i.patient.id,
						Valoraciones_Totales:	i.all_graders.length,
						Status:					i.printed ?
													'Entregada' :
													i.completed ?
														'Completada' :
														'En Valoración',
					};
				} else { // Graduadores
					obj = {
						Id_Solicitud:		i.id,
						Nombre_Paciente:	`${i.patient.name} ${i.patient.last_name}`,
						Id_Paciente:		i.patient.id,
						Fecha_inicio:		formatDate5(i.all_graders.find(el => el.grader_id == user).start_valoration),
						Fecha_finalización:	(i.completed === 1 && i.all_graders.find(el => el.grader_id == user)) ?
												formatDate5(i.all_graders.find(el => el.grader_id == user).end_valoration)
												: 'No finalizado aún',
					};
				}
				dataFormat.push(obj);
			})

			const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
			const fileExtension = '.csv';
			const ws = XLSX.utils.json_to_sheet(dataFormat);
			const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
			const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
			const dataBlob = new Blob([excelBuffer], {type: fileType});
			FileSaver.saveAs(dataBlob, `${menu ? 'Fotógrafo' : 'Graduador'} ${formatDate3(new Date())}` + fileExtension);
		} else{
			toast.warn('El archivo no puede ser generado, por que no hay datos para imprimir.')
		}
	}

	const searchData = async() => {
		if (user) {
			setLoading(true);
			setSearchData([]);
			const startDate = date_to_string(new Date(date1));
			const endDate = date_to_string(new Date(date2));

			const extra = `?start=${startDate}&end=${endDate}&user=${user}&offset=1&limit=100`;
			const res = await getData(token, extra, menu);
			setSearchData(res.data.success.data);
			setLoading(false);
		} else {
			toast.warn(`Por favor selecciona a un ${menu ? 'Fotógrafo' : 'Graduador'} antes de hacer una busqueda.`);
		}
	}

	useEffect(() => {
		setSearchData([]);
		const usersList = [];

		data.forEach(user => {
			if ((!menu && user.rol === 'GRADUADOR') || (menu && user.rol === 'FOTÓGRAFO')) {usersList.push(user)}
		})
		setList(usersList);


		if ((menu != prevMenuRef.current) && (usersList[0] && usersList[0].id)) {
			setUser(usersList[0].id);
		}
		prevMenuRef.current = menu;
	}, [menu, data, user]);

    return (
        <>
            <Menu option={menu} onClick={setMenu} />
            <div className='card_info'>
                <br />
                <Container maxWidth='xl'>
                    <div style={{background:'#f3f5f9', padding:5}}>
                        <Container  maxWidth='xl'>
                            <Grid container>
                                <Grid item sm={5}>
                                    <Select value={user}
                                        title={menu ? 'Fotógrafo' : 'Graduador'} textOption={`Selecciona a un ${menu ? 'Fotógrafo' : 'Graduador'}`}
                                        list={list} setData={setUser} />
                                </Grid>
                                <Grid item sm={5}>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:6}}>
                                        <DatePickers date={date1} setDate={setDate1} />
                                        <div style={{width:15}} />
                                        <DatePickers date={date2} setDate={setDate2} />
                                    </div>
                                </Grid>
                                <Grid item sm={2}>
                                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                                        <ButtonSearchDownload image={Ic_search_performance} onClick={searchData} opt />
                                        <ButtonSearchDownload image={Ic_download} onClick={generateExcel} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </Container>
				{
					menu ?
						<TableContainer >
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="center">{'Id Solicitud'}</TableCell>
										<TableCell align="center">{'Creada el'}</TableCell>
										<TableCell align="center">{'Nombre'}</TableCell>
										<TableCell align="center">{'Id Paciente'}</TableCell>
										<TableCell align="center">{'Status'}</TableCell>
										<TableCell align="center">{'Valoraciones totales'}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										listSearchData &&
										listSearchData.map((i, j) => (
											<TableRow key={uniqid()}>
												<TableCell align="center" style={{border:0}}>
													{i.id}
												</TableCell>
												<TableCell align="center" style={{border:0}}>
													{formatDate3(i.created_at)}
												</TableCell>
												<TableCell align="center" style={{border:0}}>
													{i.patient.name} {i.patient.last_name}
												</TableCell>
												<TableCell align="center" style={{border:0}}>
													{i.patient.id}
												</TableCell>
												<TableCell align="center" style={{border:0}}>
													{
														i.printed ?
															'Entregada' :
															i.completed ?
																'Completada' :
																'En Valoración'
													}
												</TableCell>
												<TableCell align="center" style={{border:0}}>
													{i.all_graders.length}
												</TableCell>
											</TableRow>
										))
									}
								</TableBody>
							</Table>
						</TableContainer>
						:
						<TableContainer >
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="center">{'Id Solicitud'}</TableCell>
										<TableCell align="center">{'Nombre'}</TableCell>
										<TableCell align="center">{'Id Paciente'}</TableCell>
										<TableCell align="center">{'Fecha inicio'}</TableCell>
										<TableCell align="center">{'Fecha finalización'}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										listSearchData &&
										listSearchData.map((i, j) => (
											<TableRow key={uniqid()}>
												<TableCell align="center" style={{border:0}}>
													{i.id}
												</TableCell>
												<TableCell align="center" style={{border:0}}>
													{i.patient.name} {i.patient.last_name}
												</TableCell>
												<TableCell align="center" style={{border:0}}>
													{i.patient.id}
												</TableCell>
												<TableCell align="center" style={{border:0}}>
													{
														i.all_graders.find(el => el.grader_id == user) ?
															formatDate5(i.all_graders.find(el => el.grader_id == user).start_valoration)
															: ''
													}
												</TableCell>
												<TableCell align="center" style={{border:0}}>
													{
														(i.completed === 1 && i.all_graders.find(el => el.grader_id == user)) ?
															formatDate5(i.all_graders.find(el => el.grader_id == user).end_valoration)
															: 'No finalizado aún'
													}
												</TableCell>
											</TableRow>
										))
									}
								</TableBody>
							</Table>
						</TableContainer>
				}
                <div style={{borderBottom:'1px solid silver'}} />
                {
                    loading&&<LoadingData />
                }
            </div>
        </>
    );
};

const mapStateToProps = state =>({
    data:       state.Users.data,
    status:     state.Users.status,
    token:      state.PanelUser.token,
})

export default connect(mapStateToProps, {})(Tables)