import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	icon_button: {
		position: 'absolute',
		right: '0px',
	}
}));

const CloseModalButton = ({closeModal}) => {
	const classes = useStyles();

	return (
		<IconButton
			size='small'
			edge="end"
			color="inherit"
			onClick={() => closeModal()}
			aria-label="close"
			classes={{
				root: classes.icon_button
			}}
			>
				<CloseIcon />
		</IconButton>
	)
}

export default CloseModalButton;