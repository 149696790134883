import React, { useState, useEffect } from "react";
import { Container, Grid, Modal } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";

// Redux
import { changeView, cleanData } from "../../store/redux/actions/Global";
// Components
import DragImage from "./DragImage";
import ButtonGrade from "../global/ButtonGrade";
import Input from "../global/Input";
import ModaInfo from "../request/ModalData";
import Carousel from "./Carousel";
import LevelRetinopathyV2 from "./LevelRetinopathyV2";
import SelectEdemaV2 from "./SelectEdemaV2";

// Values
import { toastText } from "values"; // Ic_back, Ic_next, levelRetinopathy, typeEdema, 
// Api
import { manage_request_errors } from "utils";
import { validateRequestData, updateRequestData, updateData2 } from "utils/graders";

const useStyles = makeStyles((theme) => ({
	modal_container: { background: "#fff", height: "95vh", position: "relative" },
	container_image: { height: "95vh", width: "100%", background: "#000" },
}));

const Form = ({ data, stateView, token, id, changeView, cleanData }) => {
	const classes = useStyles();

	// Modal
	const [open1, setOpen1] = useState(true);
	const [open2, setOpen2] = useState(false);
	const [option, setOption] = useState(0);

	const [loading1, setLoading1] = useState(false);
	const [loading2, setLoading2] = useState(false);

	const [studyType, setStudyType] = useState(1);

	// GraderId
	// rl => Retinopathy Level
	const [rlOpticalDiskCenteredRight, setRLOpticalDiskCenteredRight] = useState(0);
	const [rlOpticalDiskCenteredLeft, setRLOpticalDiskCenteredLeft] = useState(0);
	const [rlMaculaCenteredRight, setRLMaculaCenteredRight] = useState(0);
	const [rlMaculaCenteredLeft, setRLMaculaCenteredLeft] = useState(0);
	const [rlTemporalMaculaRight, setRLTemporalMaculaRight] = useState(0);
	const [rlTemporalMaculaLeft, setRLTemporalMaculaLeft] = useState(0);
	const [rlUpTempTangentialRight, setRLUpTempTangentialRight] = useState(0);
	const [rlUpTempTangentialLeft, setRLUpTempTangentialLeft] = useState(0);
	const [rlLoTempTangentialRight, setRLLoTempTangentialRight] = useState(0);
	const [rlLoTempTangentialLeft, setRLLoTempTangentialLeft] = useState(0);
	const [rlUpNasalTangentialRight, setRLUpNasalTangentialRight] = useState(0);
	const [rlUpNasalTangentialLeft, setRLUpNasalTangentialLeft] = useState(0);
	const [rlLoNasalTangentialRight, setRLLoNasalTangentialRight] = useState(0);
	const [rlLoNasalTangentialLeft, setRLLoNasalTangentialLeft] = useState(0);
	// me => Macular Edema
	const [meOpticalDiskCenteredRight, setMEOpticalDiskCenteredRight] = useState("M0");
	const [meOpticalDiskCenteredLeft, setMEOpticalDiskCenteredLeft] = useState("M0");
	const [meMaculaCenteredRight, setMEMaculaCenteredRight] = useState("M0");
	const [meMaculaCenteredLeft, setMEMaculaCenteredLeft] = useState("M0");
	const [meTemporalMaculaRight, setMETemporalMaculaRight] = useState("M0");
	const [meTemporalMaculaLeft, setMETemporalMaculaLeft] = useState("M0");
	const [meUpTempTangentialRight, setMEUpTempTangentialRight] = useState("M0");
	const [meUpTempTangentialLeft, setMEUpTempTangentialLeft] = useState("M0");
	const [meLoTempTangentialRight, setMELoTempTangentialRight] = useState("M0");
	const [meLoTempTangentialLeft, setMELoTempTangentialLeft] = useState("M0");
	const [meUpNasalTangentialRight, setMEUpNasalTangentialRight] = useState("M0");
	const [meUpNasalTangentialLeft, setMEUpNasalTangentialLeft] = useState("M0");
	const [meLoNasalTangentialRight, setMELoNasalTangentialRight] = useState("M0");
	const [meLoNasalTangentialLeft, setMELoNasalTangentialLeft] = useState("M0");

	const [coments, setComents] = useState("");

	const nextPage = () => {
		const sum = option + 1;
		if (sum < ((studyType+1)*2)) { // 6
			setOption(sum);
		}
	};

	const backPage = () => {
		const subtraction = option - 1;
		if (subtraction >= 0) {
			setOption(subtraction);
		}
	};

	const keyEvents = (e) => {
		if (e.keyCode === 13) { // "Enter" key
			onSubmit();
		} else if (e.keyCode === 27) { // "Esc" key
			closeModal();
		} else if (e.keyCode === 37) { // "Left Arrow" key
			backPage();
		} else if (e.keyCode === 39) { // "Right Arrow" key
			nextPage();
		} else if ((e.keyCode >= 48 && e.keyCode <= 54) || (e.keyCode >= 96 && e.keyCode <= 102)) { // Keys to set retinopathy level

			if (option === 0) { setRLOpticalDiskCenteredRight(Number(e.key)); }
			else if (option === 1) { setRLOpticalDiskCenteredLeft(Number(e.key)); }
			else if (option === 2) { setRLMaculaCenteredRight(Number(e.key)); }
			else if (option === 3) { setRLMaculaCenteredLeft(Number(e.key)); }
			else if (option === 4) { setRLTemporalMaculaRight(Number(e.key)); }
			else if (option === 5) { setRLTemporalMaculaLeft(Number(e.key)); }
			else if (option === 6) { setRLUpTempTangentialRight(Number(e.key)); }
			else if (option === 7) { setRLUpTempTangentialLeft(Number(e.key)); }
			else if (option === 8) { setRLLoTempTangentialRight(Number(e.key)); }
			else if (option === 9) { setRLLoTempTangentialLeft(Number(e.key)); }
			else if (option === 10) { setRLUpNasalTangentialRight(Number(e.key)); }
			else if (option === 11) { setRLUpNasalTangentialLeft(Number(e.key)); }
			else if (option === 12) { setRLLoNasalTangentialRight(Number(e.key)); }
			else if (option === 13) { setRLLoNasalTangentialLeft(Number(e.key)); }

		} else if (e.keyCode >= 65 && e.keyCode <= 67) { // Keys to set macular edema
			let macularEdemaValue;

			if (e.keyCode === 65) {
				macularEdemaValue = 'M0';
			} else if (e.keyCode === 66) {
				macularEdemaValue = 'M1';
			} else if (e.keyCode === 67) {
				macularEdemaValue = 'M2';
			}

			if (option === 0) { setMEOpticalDiskCenteredRight(macularEdemaValue); }
			else if (option === 1) { setMEOpticalDiskCenteredLeft(macularEdemaValue); }
			else if (option === 2) { setMEMaculaCenteredRight(macularEdemaValue); }
			else if (option === 3) { setMEMaculaCenteredLeft(macularEdemaValue); }
			else if (option === 4) { setMETemporalMaculaRight(macularEdemaValue); }
			else if (option === 5) { setMETemporalMaculaLeft(macularEdemaValue); }
			else if (option === 6) { setMEUpTempTangentialRight(macularEdemaValue); }
			else if (option === 7) { setMEUpTempTangentialLeft(macularEdemaValue); }
			else if (option === 8) { setMELoTempTangentialRight(macularEdemaValue); }
			else if (option === 9) { setMELoTempTangentialLeft(macularEdemaValue); }
			else if (option === 10) { setMEUpNasalTangentialRight(macularEdemaValue); }
			else if (option === 11) { setMEUpNasalTangentialLeft(macularEdemaValue); }
			else if (option === 12) { setMELoNasalTangentialRight(macularEdemaValue); }
			else if (option === 13) { setMELoNasalTangentialLeft(macularEdemaValue); }
		}
	};

	const rejectedRequest = async () => {
		setLoading2(true);
		const res = await updateData2(data.id, token);
		if (res) {
			toast.success(toastText.update);
			setLoading2(false);
			cleanData();
		} else {
			toast.warn(toastText.warning);
			setLoading2(false);
		}
	};

	const onSubmit = async () => {
		setLoading1(true);

		validateRequestData(
			rlOpticalDiskCenteredRight,
			rlOpticalDiskCenteredLeft,
			rlMaculaCenteredRight,
			rlMaculaCenteredLeft,
			rlTemporalMaculaRight,
			rlTemporalMaculaLeft,
			rlUpTempTangentialRight,
			rlUpTempTangentialLeft,
			rlLoTempTangentialRight,
			rlLoTempTangentialLeft,
			rlUpNasalTangentialRight,
			rlUpNasalTangentialLeft,
			rlLoNasalTangentialRight,
			rlLoNasalTangentialLeft,
			meOpticalDiskCenteredRight,
			meOpticalDiskCenteredLeft,
			meMaculaCenteredRight,
			meMaculaCenteredLeft,
			meTemporalMaculaRight,
			meTemporalMaculaLeft,
			meUpTempTangentialRight,
			meUpTempTangentialLeft,
			meLoTempTangentialRight,
			meLoTempTangentialLeft,
			meUpNasalTangentialRight,
			meUpNasalTangentialLeft,
			meLoNasalTangentialRight,
			meLoNasalTangentialLeft,
		)
		.then(_ => {
			return updateRequestData(
				data.id,
				rlOpticalDiskCenteredRight,
				rlOpticalDiskCenteredLeft,
				rlMaculaCenteredRight,
				rlMaculaCenteredLeft,
				rlTemporalMaculaRight,
				rlTemporalMaculaLeft,
				rlUpTempTangentialRight,
				rlUpTempTangentialLeft,
				rlLoTempTangentialRight,
				rlLoTempTangentialLeft,
				rlUpNasalTangentialRight,
				rlUpNasalTangentialLeft,
				rlLoNasalTangentialRight,
				rlLoNasalTangentialLeft,
				meOpticalDiskCenteredRight,
				meOpticalDiskCenteredLeft,
				meMaculaCenteredRight,
				meMaculaCenteredLeft,
				meTemporalMaculaRight,
				meTemporalMaculaLeft,
				meUpTempTangentialRight,
				meUpTempTangentialLeft,
				meLoTempTangentialRight,
				meLoTempTangentialLeft,
				meUpNasalTangentialRight,
				meUpNasalTangentialLeft,
				meLoNasalTangentialRight,
				meLoNasalTangentialLeft,
				studyType,
				coments,
				id,
				token
			);
		})
		.then(response => {return response.json();})
		.then(json_response => {
			if ("success" in json_response && !json_response.success) {
				throw json_response;
			}

			console.log(json_response);
			toast.success(toastText.update);
			setLoading1(false);
			cleanData();
		})
		.catch(error => {
			setLoading1(false);
			console.log(error);
			manage_request_errors(error);
		});
	};

	const closeModal = () => {
		cleanData();
		setOpen1(false);
	};

	useEffect(() => {
		setOption(0);
		setOpen1(true);
		setStudyType(data.study_type);

		setRLOpticalDiskCenteredRight(0);
		setRLOpticalDiskCenteredLeft(0);
		setRLMaculaCenteredRight(0);
		setRLMaculaCenteredLeft(0);
		setRLTemporalMaculaRight(0);
		setRLTemporalMaculaLeft(0);
		setRLUpTempTangentialRight(0);
		setRLUpTempTangentialLeft(0);
		setRLLoTempTangentialRight(0);
		setRLLoTempTangentialLeft(0);
		setRLUpNasalTangentialRight(0);
		setRLUpNasalTangentialLeft(0);
		setRLLoNasalTangentialRight(0);
		setRLLoNasalTangentialLeft(0);

		setMEOpticalDiskCenteredRight('M0');
		setMEOpticalDiskCenteredLeft('M0');
		setMEMaculaCenteredRight('M0');
		setMEMaculaCenteredLeft('M0');
		setMETemporalMaculaRight('M0');
		setMETemporalMaculaLeft('M0');
		setMEUpTempTangentialRight('M0');
		setMEUpTempTangentialLeft('M0');
		setMELoTempTangentialRight('M0');
		setMELoTempTangentialLeft('M0');
		setMEUpNasalTangentialRight('M0');
		setMEUpNasalTangentialLeft('M0');
		setMELoNasalTangentialRight('M0');
		setMELoNasalTangentialLeft('M0');

		setComents("");
		console.log(data);
	}, [data]);
	return (
		<div>
			<ModaInfo
				open={open2}
				closeModal={setOpen2}
				data={
					<div>
						<p style={{ margin: 0, marginTop: 15, fontSize: 18, fontWeight: 600 }}>{"Datos Clínicos"}</p>
						<div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20 }}>
							<Container>
								<Input title={"Estatura (cm)"} value={data.height} blockData={"disabled"} />
								<Input title={"Peso (kg)"} value={data.weight} blockData={"disabled"} />
							</Container>
							<div style={{ background: "#dde2eb", padding: "5px 0" }}>
								<p style={{ margin: 0, paddingLeft: 20, fontWeight: 600 }}>{"Valores de control"}</p>
							</div>
							<Container>
								<Input title={"Tensión Arterial Sistólica (mmHg)"} value={data.systolic_blood_pressure} blockData={"disabled"} />
								<Input title={"Tensión Arterial Diastólica (mmHg)"} value={data.diastolic_blood_pressure} blockData={"disabled"} />
								<Input title={"Tiempo con diabetes años"} value={data.diabetes_time} blockData={"disabled"} />
								<Input title={"Usa Insulina"} value={data.insulin} blockData={"disabled"} />
								<Input title={"Hipoglucemiantes orales"} value={data.oral_hypoglycemic} blockData={"disabled"} />
								<Input title={"Se uso midriasis"} value={data.mydriasis_use} blockData={"disabled"} />
								<Input title={"Hemoglobina glucosilada HbA1c(%)"} value={data.hemoglobin_glycated} blockData={"disabled"} />
								<Input
									title={"Glucosa en sangre venosa (8 horas ayuno) mmHg"}
									value={data.glucose_in_venous_blood}
									blockData={"disabled"}
								/>
								<Input title={"Glucemia capilar"} value={data.capillary_blood_glucose} blockData={"disabled"} />
							</Container>
							<div style={{ background: "#dde2eb", padding: "5px 0" }}>
								<p style={{ margin: 0, paddingLeft: 20, fontWeight: 600 }}>{"Agudeza visual"}</p>
							</div>
							<Container>
								<Input title={"Ojo Izquierdo"} value={data.visual_acuity_left_a} blockData={"disabled"} />
								<Input title={"Ojo Izquierdo (con estenopeico)"} value={data.visual_acuity_left_b} blockData={"disabled"} />
								<Input title={"Ojo Derecho"} value={data.visual_acuity_right_a} blockData={"disabled"} />
								<Input title={"Ojo Derecho (con estenopeico)"} value={data.visual_acuity_right_b} blockData={"disabled"} />
								<Input title={"Ambos Ojos"} value={data.visual_acuity_both} blockData={"disabled"} />
								<Input title={"Uso de lentes"} value={data.use_glasses} blockData={"disabled"} />
								<Input title={"Ojo Izquierdo (con lentes)"} value={data.visual_acuity_glasses_left} blockData={"disabled"} />
								<Input title={"Ojo Derecho (con lentes)"} value={data.visual_acuity_glasses_right} blockData={"disabled"} />
								<Input title={"Uso de lentes (con lentes)"} value={data.visual_acuity_glasses_both} blockData={"disabled"} />
							</Container>
						</div>
						<div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20 }}>
							<Container>
								<Input title={"Cintura (cm)"} value={data.waist} blockData={"disabled"} />
								<Input title={"Frecuencia Cardiaca (latidos/min)"} value={data.heart_rate} blockData={"disabled"} />
								<Input title={"Frecuencia Respiratoria (resp/min)"} value={data.breathing_frequency} blockData={"disabled"} />
								<Input title={"Temperatura ºC"} value={data.temperature} blockData={"disabled"} />
								<Input title={"Cigarros x día"} value={data.cigarretes} blockData={"disabled"} />
								<Input title={"Creatinina"} value={data.creatinine} blockData={"disabled"} />
								<Input title={"Microalbuminuria"} value={data.microalbuminuria} blockData={"disabled"} />
								<Input title={"Tasa de filtrado glomerular"} value={data.glomerular_filtration_rate} blockData={"disabled"} />
								<Input title={"Triglicéridos (mmol/L)"} value={data.triglycerides} blockData={"disabled"} />
								<Input title={"Colesterol (mg/dl)"} value={data.cholesterol} blockData={"disabled"} />
								<Input title={"LDL (mmol/L)"} value={data.ldl} blockData={"disabled"} />
								<Input title={"HDL (mmol/L)"} value={data.hdl} blockData={"disabled"} />
							</Container>
						</div>
					</div>
				}
			/>

			<Modal
				open={open1}
				style={{ display: "flex", alignItems: "center", justifyContent: "center", paddinfRight: 0 }}
				onClose={() => closeModal()}
				onKeyDown={(e) => keyEvents(e)}
			>
				<Container maxWidth="xl">
					<div className={classes.modal_container}>
						<Container maxWidth="xl">
							<Grid container>
								<Grid item xs={3} style={{ position: "relative" }}>
									<p
										style={{ margin: 0, color: "#648CFA", fontWeight: 600, cursor: "pointer", paddingTop: 15 }}
										onClick={() => setOpen2(true)}
									>
										{"Ver datos clínicos"}
									</p>
									<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
										<p style={{ fontWeight: 600, fontSize: 16 }}>
											{
												`Ojo ${option % 2 == 0 ? 'derecho' : ' izquierdo'} `
											}
										</p>
									</div>

									{
										option === 0 &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlOpticalDiskCenteredRight}
												setLevelValue={setRLOpticalDiskCenteredRight}
												blockData={false} />
											<SelectEdemaV2
												value={meOpticalDiskCenteredRight}
												setData={setMEOpticalDiskCenteredRight}
												blockData={false} />
										</div>
									}
									{
										option === 1 &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlOpticalDiskCenteredLeft}
												setLevelValue={setRLOpticalDiskCenteredLeft}
												blockData={false} />
											<SelectEdemaV2
												value={meOpticalDiskCenteredLeft}
												setData={setMEOpticalDiskCenteredLeft}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 2 && option === 2) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlMaculaCenteredRight}
												setLevelValue={setRLMaculaCenteredRight}
												blockData={false} />
											<SelectEdemaV2
												value={meMaculaCenteredRight}
												setData={setMEMaculaCenteredRight}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 2 && option === 3) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlMaculaCenteredLeft}
												setLevelValue={setRLMaculaCenteredLeft}
												blockData={false} />
											<SelectEdemaV2
												value={meMaculaCenteredLeft}
												setData={setMEMaculaCenteredLeft}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 3 && option === 4) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlTemporalMaculaRight}
												setLevelValue={setRLTemporalMaculaRight}
												blockData={false} />
											<SelectEdemaV2
												value={meTemporalMaculaRight}
												setData={setMETemporalMaculaRight}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 3 && option === 5) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlTemporalMaculaLeft}
												setLevelValue={setRLTemporalMaculaLeft}
												blockData={false} />
											<SelectEdemaV2
												value={meTemporalMaculaLeft}
												setData={setMETemporalMaculaLeft}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 4 && option === 6) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlUpTempTangentialRight}
												setLevelValue={setRLUpTempTangentialRight}
												blockData={false} />
											<SelectEdemaV2
												value={meUpTempTangentialRight}
												setData={setMEUpTempTangentialRight}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 4 && option === 7) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlUpTempTangentialLeft}
												setLevelValue={setRLUpTempTangentialLeft}
												blockData={false} />
											<SelectEdemaV2
												value={meUpTempTangentialLeft}
												setData={setMEUpTempTangentialLeft}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 5 && option === 8) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlLoTempTangentialRight}
												setLevelValue={setRLLoTempTangentialRight}
												blockData={false} />
											<SelectEdemaV2
												value={meLoTempTangentialRight}
												setData={setMELoTempTangentialRight}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 5 && option === 9) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlLoTempTangentialLeft}
												setLevelValue={setRLLoTempTangentialLeft}
												blockData={false} />
											<SelectEdemaV2
												value={meLoTempTangentialLeft}
												setData={setMELoTempTangentialLeft}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 6 && option === 10) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlUpNasalTangentialRight}
												setLevelValue={setRLUpNasalTangentialRight}
												blockData={false} />
											<SelectEdemaV2
												value={meUpNasalTangentialRight}
												setData={setMEUpNasalTangentialRight}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 6 && option === 11) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlUpNasalTangentialLeft}
												setLevelValue={setRLUpNasalTangentialLeft}
												blockData={false} />
											<SelectEdemaV2
												value={meUpNasalTangentialLeft}
												setData={setMEUpNasalTangentialLeft}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 7 && option === 12) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlLoNasalTangentialRight}
												setLevelValue={setRLLoNasalTangentialRight}
												blockData={false} />
											<SelectEdemaV2
												value={meLoNasalTangentialRight}
												setData={setMELoNasalTangentialRight}
												blockData={false} />
										</div>
									}
									{
										(studyType >= 7 && option === 13) &&
										<div>
											<LevelRetinopathyV2
												levelValue={rlLoNasalTangentialLeft}
												setLevelValue={setRLLoNasalTangentialLeft}
												blockData={false} />
											<SelectEdemaV2
												value={meLoNasalTangentialLeft}
												setData={setMELoNasalTangentialLeft}
												blockData={false} />
										</div>
									}

									<ButtonGrade loading1={loading1} loading2={loading2} onClick1={onSubmit} onClick2={rejectedRequest} />
								</Grid>
								<Grid item xs={9}>
									<div className={classes.container_image}>
										<div style={{ display: option === 0 ? "block" : "none" }}>
											<Carousel text={"Ojo derecho centrado en disco"} back={0} next={1} onClick={setOption} />
											<DragImage image={data.optical_disk_centered_right_eye} blockData={"disabled"} />
										</div>
										<div style={{ display: option === 1 ? "block" : "none" }}>
											<Carousel text={"Ojo izquierdo centrado en disco"} back={0} next={2} onClick={setOption} />
											<DragImage image={data.optical_disk_centered_left_eye} blockData={"disabled"} />
										</div>
										{
											studyType >= 2 &&
											<React.Fragment>
												<div style={{ display: option === 2 ? "block" : "none" }}>
													<Carousel text={"Ojo derecho centrado en mácula"} back={1} next={3} onClick={setOption} />
													<DragImage image={data.macula_centered_right_eye} blockData={"disabled"} />
												</div>
												<div style={{ display: option === 3 ? "block" : "none" }}>
													<Carousel text={"Ojo izquierdo centrado en mácula"} back={2} next={4} onClick={setOption} />
													<DragImage image={data.macula_centered_left_eye} blockData={"disabled"} />
												</div>
											</React.Fragment>
										}
										{
											studyType >= 3 &&
											<React.Fragment>
												<div style={{ display: option === 4 ? "block" : "none" }}>
													<Carousel text={"Ojo derecho mácula temporal"} back={3} next={5} onClick={setOption} />
													<DragImage image={data.temporal_macula_right_eye} blockData={"disabled"} />
												</div>
												<div style={{ display: option === 5 ? "block" : "none" }}>
													<Carousel text={"Ojo izquierdo mácula temporal"} back={4} next={6} onClick={setOption} />
													<DragImage image={data.temporal_macula_left_eye} blockData={"disabled"} />
												</div>
											</React.Fragment>
										}
										{
											studyType >= 4 &&
											<React.Fragment>
												<div style={{ display: option === 6 ? "block" : "none" }}>
													<Carousel text={"Ojo derecho tangencial temporal superior"} back={5} next={7} onClick={setOption} />
													<DragImage image={data.upper_temporal_tangential_right_eye} blockData={"disabled"} />
												</div>
												<div style={{ display: option === 7 ? "block" : "none" }}>
													<Carousel text={"Ojo izquierdo tangencial temporal superior"} back={6} next={8} onClick={setOption} />
													<DragImage image={data.upper_temporal_tangential_left_eye} blockData={"disabled"} />
												</div>
											</React.Fragment>
										}
										{
											studyType >= 5 &&
											<React.Fragment>
												<div style={{ display: option === 8 ? "block" : "none" }}>
													<Carousel text={"Ojo derecho tangencial temporal inferior"} back={7} next={9} onClick={setOption} />
													<DragImage image={data.lower_temporal_tangential_right_eye} blockData={"disabled"} />
												</div>
												<div style={{ display: option === 9 ? "block" : "none" }}>
													<Carousel text={"Ojo izquierdo tangencial temporal inferior"} back={8} next={10} onClick={setOption} />
													<DragImage image={data.lower_temporal_tangential_left_eye} blockData={"disabled"} />
												</div>
											</React.Fragment>
										}
										{
											studyType >= 6 &&
											<React.Fragment>
												<div style={{ display: option === 10 ? "block" : "none" }}>
													<Carousel text={"Ojo derecho tangencial nasal superior"} back={9} next={11} onClick={setOption} />
													<DragImage image={data.upper_nasal_tangential_right_eye} blockData={"disabled"} />
												</div>
												<div style={{ display: option === 11 ? "block" : "none" }}>
													<Carousel text={"Ojo izquierdo tangencial nasal superior"} back={10} next={12} onClick={setOption} />
													<DragImage image={data.upper_nasal_tangential_left_eye} blockData={"disabled"} />
												</div>
											</React.Fragment>
										}
										{
											studyType >= 7 &&
											<React.Fragment>
												<div style={{ display: option === 12 ? "block" : "none" }}>
													<Carousel text={"Ojo derecho tangencial nasal inferior"} back={11} next={13} onClick={setOption} />
													<DragImage image={data.lower_nasal_tangential_right_eye} blockData={"disabled"} />
												</div>
												<div style={{ display: option === 13 ? "block" : "none" }}>
													<Carousel text={"Ojo izquierdo tangencial nasal inferior"} back={12} next={14} onClick={setOption} />
													<DragImage image={data.lower_nasal_tangential_left_eye} blockData={"disabled"} />
												</div>
											</React.Fragment>
										}

										<div style={{ display: option === (studyType*2) ? "block" : "none" }}>
											<Carousel text={"Segmento anterior - ojo derecho"} back={((studyType*2)-1)} next={((studyType*2)+1)} onClick={setOption} />
											<DragImage image={data.previous_segment_right_eye} blockData={"disabled"} />
										</div>
										<div style={{ display: option === ((studyType*2)+1) ? "block" : "none" }}>
											<Carousel text={"Segmento anterior - ojo izquierdo"} back={((studyType*2))} next={((studyType*2)+1)} onClick={setOption} />
											<DragImage image={data.previous_segment_left_eye} blockData={"disabled"} />
										</div>

									</div>
								</Grid>
							</Grid>
						</Container>
					</div>
				</Container>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	stateView: state.Global.stateView,
	token: state.PanelUser.token,
	data: state.Grader.dataEdit,
	id: state.PanelUser.id,
});
export default connect(mapStateToProps, { changeView, cleanData })(Form);
