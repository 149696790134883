import ReactLoading from 'react-loading';

const CardDelete = ({color='#ce0e4f', text='Eliminando'}) => {
    return (
        <div className="container_card">
            <div style={{backgroundColor:color, height:180, borderRadius:20, position:'relative'}}>
                <div style={{position:'absolute', left:'50%', top:60,  transform:'translate(-50%, 0)'}}>
                    <ReactLoading type={"spinningBubbles"} color={"white"} style={{fill:'white', height:40, width:40}} />
                </div>
                <p style={{color:'#fff', position:'absolute', bottom:15, left:'50%', transform:'translate(-50%, 0)', fontSize:20, marginBottom:5, fontFamily:'semi'}}>
                    {text}
                </p>
            </div>
        </div>
    );
};

export default CardDelete;