import {stateData, reduxState} from 'values';

export const getInvestigationDatasets = (data, total, page) => {
	return (dispatch) => {
		dispatch({
			type:		reduxState.get_investigation_datasets,
			data:		data,
			status:		stateData.state2,
			records:	total,
			page:		page,
		});
	}
}

export const updateInvestigationDatasets = (data) => {
	return (dispatch) => {
		dispatch({
			type:	reduxState.update_investigation_datasets,
			data:	data,
		});
	}
}

export const updateRequest = (index) => {
	return (dispatch) => {
		dispatch({
			type:	reduxState.update_investigation_request,
			index:	index
		});
	}
}