import {useState}           from 'react';
import {Container}          from '@material-ui/core';
import {connect}            from 'react-redux';

// Redux
import {changeView, cleanData} from '../../store/redux/actions/Global';
import {updateResearch, getResearch, typeResearch, changeStatusResearch} from 'store/redux/actions/Research';
// Components
import InputSearch  from '../global/InputSearch';
import LoadingData  from '../global/LoadingData';
// Values
import {StateView, Ic_check, Ic_uncheck, stateData} from '../../values';
// Api
import {getData}    from '../../utils/researches';
import {formatDate3, formatDate4} from 'utils';

const SelectOptions = ({opt, val, text, color1='#0055af', color2='#bac0cd', setOption}) =>{
    return(
        <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={() =>setOption(val)}>
            <img alt="" src={opt===val?Ic_check:Ic_uncheck} style={{marginRight:10}} />
            <p style={{color:opt===val?color1:color2, userSelect:'none'}}>{text}</p>
        </div>
    )
}

const Tables = ({data, status, id, token, page, records, changeView, updateResearch, getResearch, typeResearch, cleanData}) => {
    const [option, setOption] = useState(0)
    const [search, setSearch] = useState('')
    const [completed, setCompletes] = useState(false)

    const GetDataFilter = async(val) =>{
        setOption(val)

        cleanData()
        let searchCompleted = false
        if(val===1){
            searchCompleted = true
        }
        // changeView(StateView.state2)
        // setCompletes(searchCompleted)
        const extra = `?page=${page}&searchCompleted=${searchCompleted}&limit=10`
        const res = await getData(token, extra)
        getResearch(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
        typeResearch(searchCompleted)
    }

    const searchInfo = async() =>{
        if(search){
            const extra = `?search=${search}&searchCompleted=${completed}&b64=1`
            const res = await getData(token, extra)
            getResearch(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
        } else{
            const extra = `?page=1&b64=1&searchCompleted=${completed}&limit=10`
            const res = await getData(token, extra)
            getResearch(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
        }
    }

    const GetData = async(event, newPage) =>{
        const extra = `?page=${newPage+1}&limit=10`
        const res = await getData(token, extra)
        getResearch(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
    }

    const edit = (item) => {
        changeView(StateView.state3)
        updateResearch(item)
    }
    return (
        <div className="card_info">
            <InputSearch onClickSearch={searchInfo} value={search} setData={setSearch} 
                page={page} records={records} setPage={GetData} />
            {
                status===stateData.state2?
                    <div className="card_scroll">
                        <div style={{background:'#f1f3f4', marginTop:15, marginBottom:15}}>
                            <Container>
                                <div style={{display:'flex', justifyContent:'space-around'}}>
                                    <SelectOptions opt={option} val={0} text={'En valoración'}  setOption={GetDataFilter} />
                                    <SelectOptions opt={option} val={1} text={'Completas'}      setOption={GetDataFilter} />
                                </div>
                            </Container>
                        </div>
                        {
                            data&&
                            data.map((item, j) =>(
                                <div key={j} onClick={() => edit(item)}
                                    className={id===item.id?'table_info_selected':"table_info"} 
                                    style={{userSelect:'none', position:'relative'}}>
                                    <div style={{marginLeft:50}}>
                                        <label style={{fontFamily:'semi', fontSize:16, cursor:'pointer'}}>{formatDate3(item.created_at)}</label><br/>
                                        <label style={{fontFamily:'regular', cursor:'pointer'}}>{formatDate4(item.created_at)}</label><br/>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                :
                    <LoadingData />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    data:               state.Research.data,
    status:             state.Research.status,
    id:                 state.Research.dataEdit.id,
    token:              state.PanelUser.token,
    page:               state.Research.page,
    records:            state.Research.records
})

export default connect(mapStateToProps, {changeView, updateResearch, getResearch, typeResearch, cleanData})(Tables)