import {useEffect}  from 'react';
import {connect}    from 'react-redux';

// Redux 
import {getRequest}     from '../../store/redux/actions/Request';
import {getPatients}    from '../../store/redux/actions/Patients';
// Components
import CardValoration   from '../global/CardDelete';
import Header           from '../global/Header';
import Tables           from './Tables';
import Form             from './Form';
import Response         from './Response';
// Values
import {StateView}  from '../../values';
// Api
import {getData}    from '../../utils/request';
import {getData as getPatientsData}    from '../../utils/patients';

const Request = ({stateView, token, page, medicalCenterId, dataEdit, getRequest, getPatients}) => {
    const GetData = async() =>{
        const extra = `?searchCompleted=false&searchPrinted=false&limit=10`
        let extra2 = `?limit=1`
        let total = 0
        const res   = await getData(token, extra)
        let res2  = await getPatientsData(token, extra2)

        try {
            total = res2.data.data.total
            let extra2 = `?limit=${total}`
            res2  = await getPatientsData(token, extra2)
            getRequest(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
            getPatients(res2.data.data.data, res2.data.data.total, res2.data.data.offset)
        } catch (error) { }
    }
    useEffect(() =>{
        console.log('UseEffect');
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    },[])
    return (
        <div style={{display:'flex', position:'absolute', top:20, left:180}}>
            <div className="container_info">
                <Header title={'Solicitudes de valoración'} />
                <Tables />
            </div>

            {
                stateView===StateView.state1?
                    <div />
                :
                stateView===StateView.state2?
                    <div className="container_card">
                        <Form />
                    </div>
                :
                    dataEdit.completed===0?
                        dataEdit.printed===0?
                            <CardValoration text={'Solicitud en valoración'} color={'#ffbd00'} />
                        :
                            <div className="container_card">
                                <Response />
                            </div>
                    :
                        <div className="container_card">
                            <Response />
                        </div>
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:          state.Global.stateView,
    token:              state.PanelUser.token,
    page:               state.Request.page,
    medicalCenterId:    state.PanelUser.medicalCenterId,
    dataEdit:           state.Request.dataEdit
})

export default connect(mapStateToProps, {getRequest, getPatients})(Request)
