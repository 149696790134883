import {useState, useEffect}   from 'react';
import {Container}	from '@material-ui/core';
import {connect}	from 'react-redux';
import {toast}		from 'react-toastify';

// Redux
import {changeView, cleanData} from '../../store/redux/actions/Global';
// Components
import Input		from '../global/Input';
import ButtonForm	from '../global/ButtonForm';
import ButtonFormV2	from '../global/ButtonFormV2';
import ModalGlobal	from '../global/ModalGlobal';
import DragMultipleImages from './DragMultipleImages';
// Values
import {StateView, toastText, translate_inputs, Ic_delete}   from 'values';
// Api
import {
	validateDatasetData,
	post_investigation_dataset,
	update_investigation_dataset,
	delete_investigation_dataset,
	delete_investigation_image,
	// generate_research_folder_in_storage,
	// upload_research_folder_images_in_storage,
	// post_investigation_request
} from 'utils/investigation';
import {regex_names_one, manage_request_errors} from 'utils';

const Form = ({stateView, token, data, changeView, cleanData}) => {
	const [blockData, setBlockData]		= useState('')
	const [status_edit, setStatusEdit]	= useState(stateView === StateView.state3)
	const [status_add, setStatusAdd]	= useState(stateView === StateView.state2)

	// Modal
	const [open1, setOpen1]		= useState(false)
	const [open2, setOpen2]		= useState(false)
	const [loading, setLoading]	= useState(false)

	const [name, setName] = useState('');
	const [imageToDelete, setImageToDelete] = useState(null);
	const [picturesToUpload, setPicturesToUpload] = useState([]);
	const [Uploadedpictures, setUploadedPictures] = useState([]);

	const cancelAdd = () => {
		setStatusAdd(false);
		changeView(StateView.state1);
	}

	const cancelEdit = () => {
		setStatusEdit(true);
		setStatusAdd(false);
		setBlockData('disabled');
		if (data.id) {
			maptData();
		}
	}

	const Update = async() => {
		setStatusAdd(true);
		setBlockData('');
		setStatusEdit(false);
	}

	const DeleteDataset = async() => {
		changeView(StateView.state4);
		setTimeout(() => {
			deleteDatasetRegister();
			changeView(StateView.state1);
			cleanData();
		}, 2000)
	}

	const DeleteImage = async() => {
		changeView(StateView.state4);
		setTimeout(() => {
			deleteImageRegister();
			changeView(StateView.state1);
			// cleanData();
		}, 2000)
	}

	const maptData = () => {
		setName(data.name);
		setUploadedPictures(data.get_requests);
	}

	const cleanLocalData = () => {
		setName('');
		setPicturesToUpload([]);
		setUploadedPictures([]);
	}

	const patchData = async() => {
		validateDatasetData(name)
		.then(_ => {
			console.log(_);
			return update_investigation_dataset(data.id, name, token);
			// return post_investigation_dataset(formdata, token);
		})
		.then(request_response => {return request_response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			toast.success(toastText.update);
			setLoading(false);
			cleanLocalData();
			cleanData();
		})
		.catch(error => {
			console.log(error);
			setLoading(false);
			manage_request_errors(error);
		});
	}

	const addData = async () => {
		validateDatasetData(name, picturesToUpload)
		.then(_ => {

			let formdata = new FormData();
			formdata.append("name", name);

			picturesToUpload.forEach((image) => {
				formdata.append('images[]', image, image.name);
			});

			return post_investigation_dataset(formdata, token);
		})
		.then(request_response => {return request_response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			toast.success(toastText.add);
			setLoading(false);
			cleanLocalData();
			cleanData();
		})
		.catch(error => {
			console.log(error);
			setLoading(false);
			manage_request_errors(error);
		});
	}

	const deleteDatasetRegister = async() => {
		delete_investigation_dataset(data.id, token)
		.then(request_response => {return request_response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}
		})
		.catch(error => {
			console.log(error);
			setLoading(false);
			manage_request_errors(error);
		});
	}

	const deleteImageRegister = async() => {
		delete_investigation_image(imageToDelete.id, token)
		.then(request_response => {return request_response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			data.get_requests = data.get_requests.filter(imgs => imgs.id !== imageToDelete.id);
			setUploadedPictures(data.get_requests);
		})
		.catch(error => {
			console.log(error);
			setLoading(false);
			manage_request_errors(error);
		});
	}

	const onSubmit = () => {
		setLoading(true);
		if (stateView === StateView.state3) {
			patchData();
		} else {
			addData();
		}
	}

	useEffect(() => {
		setLoading(false)
		if (stateView === StateView.state3) {
			setStatusEdit(true)
			setBlockData('disabled')
			maptData()
		} else {
			setStatusEdit(false)
			setBlockData('')
			cleanLocalData()
		}

		if (stateView === StateView.state2) {
			setStatusAdd(true)
			setBlockData('')
			cleanLocalData()
		} else {
			setBlockData('disabled')
			setStatusAdd(false)
		}
	},[stateView, data.id])

	return (
		<div className="card_info">
			<div style={{height:30}} />
			<ModalGlobal open={open1} onClose={setOpen1} 
				title={'Eliminar'} text_button1={'Eliminar'} text_button2={'Cancelar'} button={DeleteDataset}
				info={`¿Seguro que deseas eliminar este conjunto de datos: "${name}"?`} button_color={'#ce0e4f'} text_color={'#fff'} />
			<ModalGlobal open={open2} onClose={setOpen2} 
				title={'Eliminar'} text_button1={'Eliminar'} text_button2={'Cancelar'} button={DeleteImage}
				info={`¿Seguro que deseas eliminar la imagen: "${imageToDelete ? imageToDelete.image_name : ''}" del conjunto de datos "${name}"?`} button_color={'#ce0e4f'} text_color={'#fff'} />

			<div className="card_scroll">
				<Container>
					<div style={{backgroundColor:'#f1f4f8', paddingTop:15, paddingBottom:15}}>
						<Container>
							<Input blockData={blockData} setData={setName} title={'Nombre'} value={name} regex={regex_names_one} />
							{
								stateView === StateView.state2 &&
								<DragMultipleImages
									images={picturesToUpload}
									setImage={setPicturesToUpload}
									option={1}
									blockData={blockData}/>
							}
							{
								stateView === StateView.state3 &&
								<div>
									<ButtonFormV2
										loading={loading}
										stateView={stateView}
										onSubmit={onSubmit}
										onClick_edit={Update}
										onClick_delete={()=>setOpen1(true)}
										status_edit={status_edit}
										status_add={status_add}
										cancelEdit={cancelEdit}
										cancelAdd={cancelAdd} />

									<div style={{margin: '14px 0px'}}>
										{
											Uploadedpictures.map((image, i) => {
												return (
													<div key={i} style={{display:'flex', margin:'4px 0px', alignItems: 'center'}}>
														<img src={image.image_url} style={{height:'60px',width:'60px'}}></img>
														<p style={{margin:'auto'}}>{image.image_name}</p>
														<div className="button_edit_delete" onClick={()=>{setOpen2(true);setImageToDelete(image)}} style={{margin:'0px',background:'#b5015a',height:'40px',width:'60px'}}>
															<img alt="" src={Ic_delete} style={{width:15, objectFit:'scale-down'}} />
														</div>
													</div>
												)
											})
										}
									</div>
								</div>
							}
						</Container>
					</div>
					{
						stateView === StateView.state2 &&
						<ButtonFormV2
							loading={loading}
							stateView={stateView}
							onSubmit={onSubmit}
							onClick_edit={Update}
							onClick_delete={()=>setOpen1(true)}
							status_edit={status_edit}
							status_add={status_add}
							cancelEdit={cancelEdit}
							cancelAdd={cancelAdd} />
					}

				</Container>
				<div style={{height:30}} />
			</div>
		</div>
	);
};

const mapStateToProps = state =>({
	stateView:	state.Global.stateView,
	token:		state.PanelUser.token,
	data:		state.InvestigationDatasets.dataEdit,
})

export default connect(mapStateToProps, {changeView, cleanData})(Form)