import {useState, useEffect}   from 'react';
import {connect}    from 'react-redux';
// Redux
import {changeView} from '../../store/redux/actions/Global';
import {updateInvestigationDatasets, updateRequest} from '../../store/redux/actions/InvestigationDatasets';
// Components
// Unike key
import {formatDate3, capitalizeText}      from '../../utils';
// Values
import {StateView, Ic_checked, Ic_no_checked}   from '../../values';

const Tables = ({data, request_index, changeView, updateInvestigationDatasets, updateRequest}) => {
	const [selectedDatasetID, setSelectedDatasetID] = useState(0);
	const [datasetRequests, setDatasetRequests] = useState([]);

	const edit = (item, i) => {
		updateRequest(i);
		changeView(StateView.state3);
	}

	const selectDataset = (event) => {
		let dataset_id = event.target.value;
		let dataset_requests = data.find(dataset => dataset.id == dataset_id);
		setSelectedDatasetID(dataset_id);
		updateInvestigationDatasets(dataset_requests);

		updateRequest(-1);
		changeView(StateView.state1);

		if (dataset_id > 0) {
			setDatasetRequests(dataset_requests.get_requests);
		} else {
			setDatasetRequests([]);
		}
	}

	const changeRequestWithArrows = (e) => {
		if (selectedDatasetID > 0) {
			if (request_index >= 1 && e.keyCode === 38) {
				updateRequest(request_index - 1);
			} else if (request_index < (datasetRequests.length - 1) && e.keyCode === 40) {
				updateRequest(request_index + 1);
			}
		}
	};

	useEffect(() => {
		//
	})

	return (
		<div className="card_info" onKeyDown={changeRequestWithArrows} tabIndex="0">
			<select name="select" onChange={(event) => selectDataset(event)} style={{width:'75%', margin:'12px'}}>
				<option value="0">Selecciona un dataset</option>
				{
					data &&
					data.map((dataset, i) => (
						<option key={i} value={dataset.id}>{capitalizeText(dataset.name)} - {formatDate3(dataset.created_at)}</option>
					))
				}
			</select>

			<div className='card_scroll'>
				{
					selectedDatasetID > 0 && datasetRequests.length > 0 ?
					datasetRequests.map((request, i) => (
						<div key={i} onClick={() => edit(request, i)} className={i === request_index ? 'table_info_selected' : "table_info"} style={{userSelect:'none'}}>
							<div style={{marginLeft:50, width:'75%'}}>
								<label style={{fontFamily:'semi', fontSize:16, cursor:'pointer'}}>{request.image_name}</label>
								<img src={request.get_qualities.length ? Ic_checked : Ic_no_checked} style={{width:16, float:'right'}} />
							</div>
						</div>
					))
					:
					<div style={{userSelect:'none'}}>
						<div style={{marginLeft:50}}>
							<label style={{fontFamily:'semi', fontSize:16}}>Selecciona un dataset</label><br/>
						</div>
					</div>
				}
			</div>

		</div>
	);
};

const mapStateToProps = state => ({
	data:			state.InvestigationDatasets.data,
	request_index:	state.InvestigationDatasets.request_index
})

export default connect(mapStateToProps, {changeView, updateInvestigationDatasets, updateRequest})(Tables)