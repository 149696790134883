import {useEffect}	from 'react';
import {connect}	from 'react-redux';
// Redux
import {getInvestigationDatasets} from '../../store/redux/actions/InvestigationDatasets';
// Components
import Header		from '../global/Header';
import Tables		from './Tables';
import Form			from './Form';
// Values
import {StateView}	from '../../values';
// Api
import {get_investigation_datasets}	from '../../utils/investigation';

const InvestigationDatabases = ({stateView, token, page, getInvestigationDatasets}) => {

	const GetData = async () =>{
		const extra = `?page=${page}&limit=10`;

		get_investigation_datasets(token, extra)
		.then(response => {
			return response.json();
		})
		.then(json_response => {
			const investigation_datasets = json_response.data;
			getInvestigationDatasets(investigation_datasets.data, investigation_datasets.total, investigation_datasets.offset || 1);
		})
		.catch(error => {
			console.log(error);
		});
	}

	useEffect(() => {
		if (token) {
			if (stateView === StateView.state1) { // StateView.state1 means "VIEW_DATA"
				GetData();
			}
		}
	})

	return (
		<div style={{display:'flex', position:'absolute', top:20, left:180}}>
			<div className="container_info">
				<Header title={'Base de datos'} add={false} />
				<Tables />
			</div>
			{ // StateView.state1 means "VIEW_DATA", StateView.state2 means "CREATE_DATA", StateView.state3 means "EDIT_DATA", StateView.state4 means "DELETE_DATA"
				stateView===StateView.state1 ?
				<div />
				:
				stateView===StateView.state4 ?
				<div></div>
				:
				<div className="container_card">
					<Form />
				</div>
			}
		</div>
	);
};

const mapStateToProps = state => ({
	stateView:	state.Global.stateView,
	token:		state.PanelUser.token,
	page:		state.InvestigationDatasets.page
})

export default connect(mapStateToProps, {getInvestigationDatasets})(InvestigationDatabases)