import ReactLoading from "react-loading";

const LoadingData = () => {
    return (
        <div style={{position:'relative'}}>
            <div style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%)'}}>
                <ReactLoading type={"bars"} color={"white"} style={{fill:'#132327', height:70, width:70}} />
            </div>
        </div>
    );
};

export default LoadingData;