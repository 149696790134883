import {useState, useEffect} from 'react';
import {Container} from '@material-ui/core';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import ReactLoading from 'react-loading';

// Redux
// import {changeView, cleanData} from '../../store/redux/actions/Global';
import {updateUsers}  from '../../store/redux/actions/Users';
import {selfUserUpdate} from "./../../store/redux/actions/PanelUser";
// Components
import Input			from '../global/Input';
import DragImage		from '../global/DragImage';
// Values
import {toastText, Ic_next_white, translate_inputs}	from 'values';
import {deployImage, regex_names_two}	from 'utils';
import {new_generate_container}  from 'utils/api';
// Api
import {validateSelfUserUpdate, selfUserUpdateData} from 'utils/users';

const FormProfile = ({data, token, updateUsers, selfUserUpdate}) => {

	// Modal
	const [loadingImage, setLoadingImage]   = useState(false);
	const [loadingData, setLoadingData]		= useState(false);

	const [name, setName]			= useState('')
	const [lastName, setLastName]	= useState('')
	const [image, setImage]			= useState('')

	const maptData = () => {
		setName(data.name)
		setLastName(data.lastName)
		setImage(data.picture ?? '')
		// setImage(data.picture?data.picture:'')
	}

	const patchImage = () => {
		if (typeof image === 'object') {
			let UrlImage = '';
			new_generate_container(token)
			.then(response => {return response.json();})
			.then(async response => {

				UrlImage = await deployImage(image, response.data, token);
				// let base_url = data.picture.split('/temp/')[0];
				// let credentials_url = data.picture.split('?')[1];
				// let new_image_url = `${base_url}${UrlImage}?${credentials_url}`;
				// setImage(new_image_url);

				selfUserUpdateData({picture: UrlImage}, 'image', token);
				setLoadingImage(false);
				toast.success(toastText.update);
			})
			.catch(error => {
				console.log(error);
			});
		} else {
			setLoadingImage(false);
			toast.warn(toastText.warning);
		}
	}

	const patchData = async() => {

		validateSelfUserUpdate(name, lastName)
		.then(_ => {
			return selfUserUpdateData({name, lastName}, 'personal_info', token);
		})
		.then(response => {return response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			selfUserUpdate(name, lastName);
			updateUsers({...response.data, name: name, lastName: lastName});
			setLoadingData(false);
			toast.success(toastText.update);

			localStorage.setItem('eyebit-name', name);
			localStorage.setItem('eyebit-lastName', lastName);
		})
		.catch(error => {
			setLoadingData(false);
			console.log(error);

			if (error.errors && error.message && error.message === 'The data to send is invalid') { // Manejo de errores en validaciones locales
				for (let value of error.errors) {
					toast.warn(`${toastText.inputNotValid}${value}`);
				}
			} else if (error.errors && error.message && error.message === 'The received data was invalid.') { // Error en los datos enviados al servidor
				if (error.errors) {
					toast.warn(
						<div>
							{toastText.inputNotValid}
							<ul>
								{
									Object.keys(error.errors).map((err, i) => {
										return (<li key={i}>{translate_inputs[err]}: {error.errors[err].join(', ')}</li>)
									})
								}
							</ul>
						</div>
					);
				} else {
					toast.warn(toastText.warning);
				}
			} else { // Error inesperado
				toast.warn(toastText.warning);
			}
		});
	}

	const onSumbmitImage = () => {
		setLoadingImage(true);
		patchImage();
	}

	const onSubmitData = () =>{
		setLoadingData(true);
		patchData();
	}

	useEffect(() =>{
		setLoadingImage(false);
		setLoadingData(false);
		maptData();
	}, [data])

	return (
		<div className="card_info">
			<div style={{height:30}} />

			<div className="card_scroll">
				<Container>
					<DragImage image={image} setImage={setImage} styleDrag={1} />
					<button className="form_button" onClick={onSumbmitImage} disabled={loadingImage}>
						Guardar
						{
							!loadingImage?
								<img alt="" src={Ic_next_white} style={{position:'absolute', right:15, top:15}} />
							:
								<div style={{position:'absolute', right:15, top:10}}>
									<ReactLoading type={"spinningBubbles"} color={"white"} style={{fill:'white', height:30, width:30}} />
								</div>
						}
					</button>

					<div style={{backgroundColor:'#f1f4f8', paddingTop:15, paddingBottom:15, marginTop: '30px'}}>
						<Container>
							<Input regex={regex_names_two} title={'Nombre'}		value={name}		setData={setName}		/>
							<Input regex={regex_names_two} title={'Apellido'}	value={lastName}	setData={setLastName}	/>
						</Container>
					</div>
					<button className="form_button" onClick={onSubmitData} disabled={loadingData}>
						Guardar
						{
							!loadingData?
								<img alt="" src={Ic_next_white} style={{position:'absolute', right:15, top:15}} />
							:
								<div style={{position:'absolute', right:15, top:10}}>
									<ReactLoading type={"spinningBubbles"} color={"white"} style={{fill:'white', height:30, width:30}} />
								</div>
						}
					</button>

				</Container>
				<div style={{height:30}} />
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	token:	state.PanelUser.token,
	data:	state.Users.dataEdit,
})

export default connect(mapStateToProps, {updateUsers, selfUserUpdate})(FormProfile)