import { useState, useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// import Resizer              from 'react-image-file-resizer';

// Redux
import { changeView, cleanData } from "../../store/redux/actions/Global";
// Components
import DragImage from "./DragImage";
// import DragImage    from '../global/DragImage';
import Input from "../global/Input";
import InputInfo from "../global/InputInfo";
import SelectQuestion from "./SelectQuestion";
import ButtonForm from "../global/ButtonForm";
import Modal from "./ModalPatients";
// Values
import { StateView, toastText } from "values";
import { deployImage, manage_request_errors } from "utils";
import { new_generate_container } from "utils/api";
// Api
import { validateRequestData, postRequestData } from "utils/request";

const Form = ({ data, stateView, token, changeView, medicalCenterId, study_type, cleanData }) => {
	const [blockData, setBlockData] = useState("");
	const [status_edit, setStatusEdit] = useState(stateView === StateView.state3);
	const [status_add, setStatusAdd] = useState(stateView === StateView.state2);

	// Modal
	const [open1, setOpen1] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [open3, setOpen3] = useState(false);
	const [loading, setLoading] = useState(false);

	const [pictureDiskCenteredRightEye, setPictureDiskCenteredRightEye] = useState("");
	const [pictureDiskCenteredLeftEye, setPictureDiskCenteredLeftEye] = useState("");
	const [pictureMaculaCenteredRightEye, setPictureMaculaCenteredRightEye] = useState("");
	const [pictureMaculaCenteredLeftEye, setPictureMaculaCenteredLeftEye] = useState("");
	const [pictureTempMaculaRightEye, setPictureTempMaculaRightEye] = useState("");
	const [pictureTempMaculaLeftEye, setPictureTempMaculaLeftEye] = useState("");
	const [pictureUpperTempTangRightEye, setPictureUpperTempTangRightEye] = useState("");
	const [pictureUpperTempTangLeftEye, setPictureUpperTempTangLeftEye] = useState("");
	const [pictureLowerTempTangRightEye, setPictureLowerTempTangRightEye] = useState("");
	const [pictureLowerTempTangLeftEye, setPictureLowerTempTangLeftEye] = useState("");
	const [pictureUpperNasalTangRightEye, setPictureUpperNasalTangRightEye] = useState("");
	const [pictureUpperNasalTangLeftEye, setPictureUpperNasalTangLeftEye] = useState("");
	const [pictureLowerNasalTangRightEye, setPictureLowerNasalTangRightEye] = useState("");
	const [pictureLowerNasalTangLeftEye, setPictureLowerNasalTangLeftEye] = useState("");
	const [previousSegmentLeftEye, setPreviousSegmentLeftEye] = useState("");
	const [previousSegmentRightEye, setPreviousSegmentRightEye] = useState("");

	const [patientId, setPatientId] = useState("");
	const [patientName, setPatientName] = useState("");

	const [height, setHeight] = useState("");
	const [weight, setWeight] = useState("");

	const [systolicBloodPressure, setSystolicBloodPressure] = useState("");
	const [diastolicBloodPressure, setDiastolicBloodPressure] = useState("");
	const [diabetesTime, setDiabetesTime] = useState("");
	const [insulin, setInsulin] = useState("NO");
	const [oralHypoglycemic, setOralHypoglycemic] = useState("NO");
	const [mydriasisUse, setMydriasisUse] = useState("NO");
	const [hemoglobinGlycated, setHemoglobinGlycated] = useState("");
	const [glucoseInVenousBlood, setGlucoseInVenousBlood] = useState("");
	const [capillaryBloodGlucose, setCapillaryBloodGlucose] = useState("");
	const [visualAcuityLeftA, setVisualAcuityLeftA] = useState("");
	const [visualAcuityLeftB, setVisualAcuityLeftB] = useState("");
	const [visualAcuityRightA, setVisualAcuityRightA] = useState("");
	const [visualAcuityRightB, setVisualAcuityRightB] = useState("");
	const [visualAcuityBoth, setVisualAcuityBoth] = useState("");

	const [useGlasses, setUseGlasses] = useState("NO");
	const [visualAcuityGlassesLeft, setVisualAcuityGlassesLeft] = useState("");
	const [visualAcuityGlassesRight, setVisualAcuityGlassesRight] = useState("");
	const [visualAcuityGlassesBoth, setVisualAcuityGlassesBoth] = useState("");

	const [waist, setWaist] = useState("");
	const [heartRate, setHeartRate] = useState("");
	const [breathingFrequency, setBreathingFrequency] = useState("");
	const [temperature, setTemperature] = useState("");
	const [cigarretes, setCigarretes] = useState("");
	const [creatinine, setCreatinine] = useState("");
	const [microalbuminuria, setMicroalbuminuria] = useState("");
	const [glomerularFiltrationRate, setGlomerularFiltrationRate] = useState("");
	const [triglycerides, setTriglycerides] = useState("");
	const [cholesterol, setCholesterol] = useState("");
	const [ldl, setLDL] = useState("");
	const [hdl, setHdl] = useState("");

	const cancelEdit = () => {
		setStatusEdit(true);
		setStatusAdd(false);
		setBlockData("disabled");
	};
	const Update = async () => {
		setStatusAdd(true);
		setBlockData("");
		setStatusEdit(false);
	};

	const patchData = async () => {

		const segments_set = (previousSegmentLeftEye && previousSegmentRightEye);

		validateRequestData(
			pictureDiskCenteredRightEye,
			pictureDiskCenteredLeftEye,
			pictureMaculaCenteredRightEye,
			pictureMaculaCenteredLeftEye,
			pictureTempMaculaRightEye,
			pictureTempMaculaLeftEye,
			pictureUpperTempTangRightEye,
			pictureUpperTempTangLeftEye,
			pictureLowerTempTangRightEye,
			pictureLowerTempTangLeftEye,
			pictureUpperNasalTangRightEye,
			pictureUpperNasalTangLeftEye,
			pictureLowerNasalTangRightEye,
			pictureLowerNasalTangLeftEye,
			previousSegmentLeftEye,
			previousSegmentRightEye,
			study_type,
			patientId, height, weight, systolicBloodPressure, diastolicBloodPressure, diabetesTime, insulin,
			oralHypoglycemic, mydriasisUse, hemoglobinGlycated, glucoseInVenousBlood, capillaryBloodGlucose,
			visualAcuityLeftA, visualAcuityLeftB, visualAcuityRightA, visualAcuityRightB, visualAcuityBoth,
			useGlasses, visualAcuityGlassesLeft, visualAcuityGlassesRight, visualAcuityGlassesBoth,
			waist, heartRate, breathingFrequency, temperature, cigarretes, creatinine,
			microalbuminuria, glomerularFiltrationRate, triglycerides, cholesterol, ldl, hdl
		)
		.then(response => {
			let previous_segments_auxiliar = segments_set ? 1 : 0;
			let amount_of_containers = ((parseInt(study_type) + previous_segments_auxiliar) * 2);
			return new_generate_container(token, true, amount_of_containers);
		})
		.then(request_response => {return request_response.json();})
		.then(response => {
			console.log(response);
			let images_deploys = [];
			let uuids_length = response.data.length;

			images_deploys = response.data.map((uuid, i) => {
				if (segments_set && i == uuids_length-1) {
					return deployImage(previousSegmentLeftEye, uuid, token);
				} else if (segments_set && i == uuids_length-2) {
					return deployImage(previousSegmentRightEye, uuid, token);
				} else if (i == 0) {
					return deployImage(pictureDiskCenteredRightEye, uuid, token);
				} else if (i == 1) {
					return deployImage(pictureDiskCenteredLeftEye, uuid, token);
				} else if (i == 2) {
					return deployImage(pictureMaculaCenteredRightEye, uuid, token);
				} else if (i == 3) {
					return deployImage(pictureMaculaCenteredLeftEye, uuid, token);
				} else if (i == 4) {
					return deployImage(pictureTempMaculaRightEye, uuid, token);
				} else if (i == 5) {
					return deployImage(pictureTempMaculaLeftEye, uuid, token);
				} else if (i == 6) {
					return deployImage(pictureUpperTempTangRightEye, uuid, token);
				} else if (i == 7) {
					return deployImage(pictureUpperTempTangLeftEye, uuid, token);
				} else if (i == 8) {
					return deployImage(pictureLowerTempTangRightEye, uuid, token);
				} else if (i == 9) {
					return deployImage(pictureLowerTempTangLeftEye, uuid, token);
				} else if (i == 10) {
					return deployImage(pictureUpperNasalTangRightEye, uuid, token);
				} else if (i == 11) {
					return deployImage(pictureUpperNasalTangLeftEye, uuid, token);
				} else if (i == 12) {
					return deployImage(pictureLowerNasalTangRightEye, uuid, token);
				} else if (i == 13) {
					return deployImage(pictureLowerNasalTangLeftEye, uuid, token);
				}
			});

			return Promise.all(images_deploys);
		})
		.then(response => {
			return postRequestData(
				segments_set,
				response,
				patientId,
				medicalCenterId,
				height,
				weight,
				systolicBloodPressure,
				diastolicBloodPressure,
				diabetesTime,
				insulin,
				oralHypoglycemic,
				mydriasisUse,
				hemoglobinGlycated,
				glucoseInVenousBlood,
				capillaryBloodGlucose,
				visualAcuityLeftA,
				visualAcuityLeftB,
				visualAcuityRightA,
				visualAcuityRightB,
				visualAcuityBoth,
				useGlasses,
				visualAcuityGlassesLeft,
				visualAcuityGlassesRight,
				visualAcuityGlassesBoth,
				waist,
				heartRate,
				breathingFrequency,
				temperature,
				cigarretes,
				creatinine,
				microalbuminuria,
				glomerularFiltrationRate,
				triglycerides,
				cholesterol,
				ldl,
				hdl,
				token
			);
		})
		.then(response => {return response.json()})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			toast.success(toastText.add);
			setLoading(false);
			cleanData();
		})
		.catch(error => {
			setLoading(false);
			manage_request_errors(error);
		});
	};

	const onSubmit = () => {
		setLoading(true);
		patchData();
	};

	return (
		<div className="card_info">
			<div style={{ height: 30 }} />

			<Modal
				open={open2}
				open2={open3}
				setOpen={setOpen2}
				setOpen2={setOpen3}
				setId={setPatientId}
				setName={setPatientName}
			/>

			<div className="card_scroll">
				<Container>
					<Grid container>
						{/* Optical Disk Centered | Centrada en disco optico */}
						<Grid container sm={12}>
							<h4 style={{width: '100%', margin: '10px 0px 0px', textAlign: 'center'}}>Centrado en disco optico</h4>
							<Grid item sm={6}>
								<DragImage
									image={pictureDiskCenteredRightEye}
									setImage={setPictureDiskCenteredRightEye}
									// text={"Ojo derecho centrado en disco optico"}
									text={"Ojo derecho"}
									option={1}
									blockData={blockData}
								/>
							</Grid>
							<Grid item sm={6}>
								<DragImage
									image={pictureDiskCenteredLeftEye}
									setImage={setPictureDiskCenteredLeftEye}
									// text={"Ojo izquierdo centrado en disco optico"}
									text={"Ojo izquierdo"}
									option={2}
									blockData={blockData}
								/>
							</Grid>
						</Grid>
						{/* Macula Centered | Centrada en macula */}
						{ study_type >= 2 &&
							<Grid container sm={12}>
								<h4 style={{width: '100%', margin: '10px 0px 0px', textAlign: 'center'}}>Centrado en mácula</h4>
								<Grid item sm={6}>
									<DragImage
										image={pictureMaculaCenteredRightEye}
										setImage={setPictureMaculaCenteredRightEye}
										// text={"Ojo derecho centrado en mácula"}
										text={"Ojo derecho"}
										option={3}
										blockData={blockData}
									/>
								</Grid>
								<Grid item sm={6}>
									<DragImage
										image={pictureMaculaCenteredLeftEye}
										setImage={setPictureMaculaCenteredLeftEye}
										// text={"Ojo izquierdo centrado en mácula"}
										text={"Ojo izquierdo"}
										option={4}
										blockData={blockData}
									/>
								</Grid>
							</Grid>
						}
						{/* Temporal Macula | Temporal a la mácula */}
						{ study_type >= 3 &&
							<Grid container sm={12}>
								<h4 style={{width: '100%', margin: '10px 0px 0px', textAlign: 'center'}}>Temporal a la mácula</h4>
								<Grid item sm={6}>
									<DragImage
										image={pictureTempMaculaRightEye}
										setImage={setPictureTempMaculaRightEye}
										// text={"Ojo derecho mácula temporal"}
										text={"Ojo derecho"}
										option={5}
										blockData={blockData}
									/>
								</Grid>
								<Grid item sm={6}>
									<DragImage
										image={pictureTempMaculaLeftEye}
										setImage={setPictureTempMaculaLeftEye}
										// text={"Ojo izquierdo mácula temporal"}
										text={"Ojo izquierdo"}
										option={6}
										blockData={blockData}
									/>
								</Grid>
							</Grid>
						}
						{/* Upper Temporal Tangential | Tangencial Temporal Superior */}
						{ study_type >= 4 &&
							<Grid container sm={12}>
								<h4 style={{width: '100%', margin: '10px 0px 0px', textAlign: 'center'}}>Tangengial temporal superior</h4>
								<Grid item sm={6}>
									<DragImage
										image={pictureUpperTempTangRightEye}
										setImage={setPictureUpperTempTangRightEye}
										// text={"Ojo derecho tangengial temporal superior"}
										text={"Ojo derecho"}
										option={7}
										blockData={blockData}
									/>
								</Grid>
								<Grid item sm={6}>
									<DragImage
										image={pictureUpperTempTangLeftEye}
										setImage={setPictureUpperTempTangLeftEye}
										// text={"Ojo izquierdo tangengial temporal superior"}
										text={"Ojo izquierdo"}
										option={8}
										blockData={blockData}
									/>
								</Grid>
							</Grid>
						}
						{/* Lower Temporal Tangential | Tangencial Temporal Inferior */}
						{ study_type >= 5 &&
							<Grid container sm={12}>
								<h4 style={{width: '100%', margin: '10px 0px 0px', textAlign: 'center'}}>Tangengial temporal inferior</h4>
								<Grid item sm={6}>
									<DragImage
										image={pictureLowerTempTangRightEye}
										setImage={setPictureLowerTempTangRightEye}
										// text={"Ojo derecho tangengial temporal inferior"}
										text={"Ojo derecho"}
										option={9}
										blockData={blockData}
									/>
								</Grid>
								<Grid item sm={6}>
									<DragImage
										image={pictureLowerTempTangLeftEye}
										setImage={setPictureLowerTempTangLeftEye}
										// text={"Ojo izquierdo tangengial temporal inferior"}
										text={"Ojo izquierdo"}
										option={10}
										blockData={blockData}
									/>
								</Grid>
							</Grid>
						}
						{/* Upper Nasal Tangential | Tangencial Nasal Superior */}
						{ study_type >= 6 &&
							<Grid container sm={12}>
								<h4 style={{width: '100%', margin: '10px 0px 0px', textAlign: 'center'}}>Tangengial nasal superior</h4>
								<Grid item sm={6}>
									<DragImage
										image={pictureUpperNasalTangRightEye}
										setImage={setPictureUpperNasalTangRightEye}
										// text={"Ojo derecho tangengial nasal superior"}
										text={"Ojo derecho"}
										option={11}
										blockData={blockData}
									/>
								</Grid>
								<Grid item sm={6}>
									<DragImage
										image={pictureUpperNasalTangLeftEye}
										setImage={setPictureUpperNasalTangLeftEye}
										// text={"Ojo izquierdo tangengial nasal superior"}
										text={"Ojo izquierdo"}
										option={12}
										blockData={blockData}
									/>
								</Grid>
							</Grid>
						}
						{/* Lower Nasal Tangential | Tangencial Nasal Inferior */}
						{ study_type >= 7 &&
							<Grid container sm={12}>
								<h4 style={{width: '100%', margin: '10px 0px 0px', textAlign: 'center'}}>Tangengial nasal inferior</h4>
								<Grid item sm={6}>
									<DragImage
										image={pictureLowerNasalTangRightEye}
										setImage={setPictureLowerNasalTangRightEye}
										// text={"Ojo derecho tangengial nasal inferior"}
										text={"Ojo derecho"}
										option={13}
										blockData={blockData}
									/>
								</Grid>
								<Grid item sm={6}>
									<DragImage
										image={pictureLowerNasalTangLeftEye}
										setImage={setPictureLowerNasalTangLeftEye}
										// text={"Ojo izquierdo tangengial nasal inferior"}
										text={"Ojo izquierdo"}
										option={14}
										blockData={blockData}
									/>
								</Grid>
							</Grid>
						}
						{/* Previous Segment */}
						<Grid container sm={12}>
							<h4 style={{width: '100%', margin: '10px 0px 0px', textAlign: 'center'}}>Segmento anterior</h4>
							<Grid item sm={6}>
								<DragImage
									image={previousSegmentRightEye}
									setImage={setPreviousSegmentRightEye}
									// text={"Segmento Anterior - Ojo derecho"}
									text={"Ojo derecho"}
									option={15}
									blockData={blockData}
								/>
							</Grid>
							<Grid item sm={6}>
								<DragImage
									image={previousSegmentLeftEye}
									setImage={setPreviousSegmentLeftEye}
									// text={"Segmento Anterior - Ojo izquierdo"}
									text={"Ojo izquierdo"}
									option={16}
									blockData={blockData}
								/>
							</Grid>
						</Grid>
					</Grid>

					<div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20, position: "relative" }} >
						<Container>
							<div
								onClick={() => setOpen2(true)}
								style={{ height: 50, width: "96%", position: "absolute", top: 20, zIndex: 9, cursor: "pointer" }}
							/>
							<Input title={"Paciente"} value={patientName} setData={setPatientName} blockData={"disabled"} placeholder="Seleccionar paciente..." />
							<div style={{ textAlign: "end" }}>
								<p style={{ color: "#648CFA", fontWeight: 600, cursor: "pointer" }} onClick={() => setOpen3(true)}>
									{"Ver personales"}
								</p>
							</div>
						</Container>
					</div>

					<ul>
						{" "}
						<li>
							<p>{"Datos obligatorios"}</p>
						</li>{" "}
					</ul>

					<div style={{ background: "#f3f5f9", paddingTop: 20, marginTop: 20 }}>
						<Container>
							<Input
								type="number"
								min={140}
								max={210}
								title={"Estatura (cm)"}
								value={height}
								setData={setHeight}
								blockData={blockData}
								placeholder="140 a 210 cm" />
							<Input
								type="number"
								min={30}
								max={300}
								title={"Peso (kg)"}
								value={weight}
								setData={setWeight}
								blockData={blockData}
								placeholder="30 a 300 kg" />
							<br />
						</Container>
					</div>
					<div style={{ background: "#dde2eb", padding: "5px 0" }}>
						<p style={{ margin: 0, paddingLeft: 20, fontWeight: 600 }}>{"Valores de control"}</p>
					</div>
					<div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10 }}>
						<Container>
							<InputInfo
								type="number"
								min={90}
								max={200}
								title={"Tensión Arterial Sistólica"}
								value={systolicBloodPressure}
								setData={setSystolicBloodPressure}
								info="mmHg"
								blockData={blockData}
								placeholder="90 a 200 mmHg"
							/>
							<InputInfo
								type="number"
								min={60}
								max={120}
								title={"Tensión Arterial Diastólica"}
								value={diastolicBloodPressure}
								setData={setDiastolicBloodPressure}
								info="mmHg"
								blockData={blockData}
								placeholder="60 a 120 mmHg"
							/>
							<InputInfo
								type="number"
								min={0}
								max={100}
								title={"Tiempo con diabetes"}
								value={diabetesTime}
								setData={setDiabetesTime}
								info="años"
								blockData={blockData}
								placeholder="0 a 100"
							/>

							<SelectQuestion title={"Usa Insulina"} value={insulin} setData={setInsulin} blockData={blockData} />
							<SelectQuestion
								title={"Hipoglucemiantes orales"}
								value={oralHypoglycemic}
								setData={setOralHypoglycemic}
								blockData={blockData}
							/>
							<SelectQuestion
								title={"Se uso midriasis"}
								value={mydriasisUse}
								setData={setMydriasisUse}
								blockData={blockData}
							/>

							<InputInfo
								type="number"
								min={4}
								max={15}
								title={"Hemoglobina glucosilada"}
								value={hemoglobinGlycated}
								setData={setHemoglobinGlycated}
								info="HbA1c (%)"
								blockData={blockData}
								placeholder="4 a 15 %"
							/>
							<InputInfo
								type="number"
								min={70}
								max={130}
								title={"Glucosa en sangre venosa (8 horas ayuno)"}
								value={glucoseInVenousBlood}
								setData={setGlucoseInVenousBlood}
								info="mmHg"
								blockData={blockData}
								placeholder="70 a 130 mmHg"
							/>
							<InputInfo
								type="number"
								min={0}
								max={100}
								title={"Glucemia capilar"}
								value={capillaryBloodGlucose}
								setData={setCapillaryBloodGlucose}
								info="años"
								blockData={blockData}
								placeholder="0 a 100"
							/>
						</Container>
					</div>
					<div style={{ background: "#dde2eb", padding: "5px 0" }}>
						<p style={{ margin: 0, paddingLeft: 20, fontWeight: 600 }}>{"Agudeza visual"}</p>
					</div>

					<div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10 }}>
						<Container>
							<Input
								title={"Ojo Izquierdo"}
								value={visualAcuityLeftA}
								setData={setVisualAcuityLeftA}
								blockData={blockData}
								placeholder="holamundo"
							/>
							<InputInfo
								title={"Ojo Izquierdo"}
								value={visualAcuityLeftB}
								setData={setVisualAcuityLeftB}
								info="(con estenopeico)"
								strong
								blockData={blockData}
								placeholder="holamundo"
							/>
							<Input
								title={"Ojo Derecho"}
								value={visualAcuityRightA}
								setData={setVisualAcuityRightA}
								blockData={blockData}
								placeholder="holamundo"
							/>
							<InputInfo
								title={"Ojo Derecho"}
								value={visualAcuityRightB}
								setData={setVisualAcuityRightB}
								info="(con estenopeico)"
								strong
								blockData={blockData}
								placeholder="holamundo"
							/>

							<Input
								title={"Ambos Ojos"}
								value={visualAcuityBoth}
								setData={setVisualAcuityBoth}
								blockData={blockData}
								placeholder="holamundo"
							/>

							{/* No enviar */}
							<SelectQuestion
								title={"Uso de lentes"}
								value={useGlasses}
								setData={setUseGlasses}
								blockData={blockData}
							/>

							<InputInfo
								title={"Ojo Izquierdo"}
								value={visualAcuityGlassesLeft}
								setData={setVisualAcuityGlassesLeft}
								info="(con lentes)"
								blockData={blockData}
								disabled={useGlasses === "NO" ? true : false}
							/>

							<InputInfo
								title={"Ojo Derecho"}
								value={visualAcuityGlassesRight}
								setData={setVisualAcuityGlassesRight}
								info="(con lentes)"
								blockData={blockData}
								disabled={useGlasses === "NO" ? true : false}
							/>

							<InputInfo
								title={"Ambos Ojos"}
								value={visualAcuityGlassesBoth}
								setData={setVisualAcuityGlassesBoth}
								info="(con lentes)"
								blockData={blockData}
								disabled={useGlasses === "NO" ? true : false}
							/>
						</Container>
					</div>

					<ul>
						<li>
							<p style={{ color: "#648CFA" }}>{"Datos Opcionales"}</p>
						</li>
					</ul>
					<div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20 }}>
						<Container>
							<Input title={"Cintura (cm)"} value={waist} setData={setWaist} color={"#648CFA"} blockData={blockData} placeholder="40 a 300" />
							<InputInfo
								title={"Frecuencia Cardiaca"}
								value={heartRate}
								setData={setHeartRate}
								info="(latidos/min)"
								color={"#648CFA"}
								blockData={blockData}
								placeholder="60 a 120"
							/>
							<InputInfo
								title={"Frecuencia Respiratoria"}
								value={breathingFrequency}
								setData={setBreathingFrequency}
								info="(resp/min)"
								color={"#648CFA"}
								blockData={blockData}
								placeholder="12 a 20"
							/>
							<InputInfo
								title={"Temperatura"}
								value={temperature}
								setData={setTemperature}
								info="ºC"
								color={"#648CFA"}
								blockData={blockData}
								placeholder="36.5 a 37.5"
							/>
							<Input
								title={"Cigarros x día"}
								value={cigarretes}
								setData={setCigarretes}
								color={"#648CFA"}
								blockData={blockData}
								placeholder="Desde 0"
							/>
							<InputInfo
								title={"Creatinina"}
								value={creatinine}
								setData={setCreatinine}
								info="mg/dL"
								color={"#648CFA"}
								blockData={blockData}
								placeholder="de 0.6 a 10"
							/>
							<InputInfo
								title={"Microalbuminuria"}
								value={microalbuminuria}
								setData={setMicroalbuminuria}
								info="mg/g"
								color={"#648CFA"}
								blockData={blockData}
								placeholder="desde 30"
							/>
							<InputInfo
								title={"Tasa de filtrado glomerular"}
								value={glomerularFiltrationRate}
								setData={setGlomerularFiltrationRate}
								info="hola"
								color={"#648CFA"}
								blockData={blockData}
								placeholder="desde 90"
							/>
							<InputInfo
								title={"Triglicéridos"}
								value={triglycerides}
								setData={setTriglycerides}
								info="(mmol/L)"
								color={"#648CFA"}
								blockData={blockData}
								placeholder="de 0.5 a 2.0"
							/>
							<InputInfo
								title={"Colesterol"}
								value={cholesterol}
								setData={setCholesterol}
								info="(mg/dl)"
								color={"#648CFA"}
								blockData={blockData}
								placeholder="de 125 a 200"
							/>
							<InputInfo
								title={"LDL"}
								value={ldl}
								setData={setLDL}
								info="(mmol/L)"
								color={"#648CFA"}
								blockData={blockData}
								placeholder="de 1.0 a 4.0"
							/>
							<InputInfo
								title={"HDL"}
								value={hdl}
								setData={setHdl}
								info="(mmol/L)"
								color={"#648CFA"}
								blockData={blockData}
								placeholder="de 0.9 a 2.5"
							/>
						</Container>
					</div>

					<ButtonForm
						loading={loading}
						onSubmit={onSubmit}
						button_edit={Update}
						button_delete={() => setOpen1(true)}
						status_edit={status_edit}
						status_add={status_add}
						cancelEdit={cancelEdit}
					/>
				</Container>
				<div style={{ height: 30 }} />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	stateView:			state.Global.stateView,
	token:				state.PanelUser.token,
	data:				state.Users.dataEdit,
	medicalCenterId:	state.PanelUser.medicalCenterId,
	study_type:			state.PanelUser.study_type,
});

export default connect(mapStateToProps, { changeView, cleanData })(Form);
