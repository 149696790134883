import {useState}   from 'react';
import {connect}    from 'react-redux';
// import uniqid  from 'uniqid';

// Redux
import {changeView}             from '../../store/redux/actions/Global';
import {updateGraders, getGraders}  from '../../store/redux/actions/Grader';
// Components
// import InputSearch  from '../global/InputSearch';
import LoadingData  from '../global/LoadingData';
// Unike key
import {formatDate2, formatDate4}      from '../../utils';
// Values
import {StateView, stateData}   from '../../values';
// Api
// import {getSearchData, getData}  from '../../utils/users';
import {startGrader} from 'utils/graders';

const Tables = ({data, status, id, userId, token, page, records, changeView, updateGraders, getGraders}) => {
    const [search, setSearch] = useState('')

    const edit = async(item) => {
        // console.log('Id: ', item.id);
        await startGrader(item.id, token)
        changeView(StateView.state3)
        updateGraders(item)
    }
    return (
        <div className="card_info">
            {
                status===stateData.state2?
                    <div className="card_scroll">
                        {
                            data&&
                            data.map((item,j) =>(
                                <div key={j} onClick={() => edit(item)}
                                    className={id===item.id?'table_info_selected':"table_info"}
                                    style={{userSelect:'none'}}>
                                    <div style={{marginRight:20, marginLeft:28}}>
                                        <label style={{fontFamily:'semi', fontSize:16, cursor:'pointer'}}>{formatDate2(item.created_at)}</label><br/>
                                        <label style={{fontFamily:'regular', cursor:'pointer'}}>{formatDate4(item.created_at)}</label><br/>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                :
                    <LoadingData />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    data:       state.Grader.data,
    status:     state.Grader.status,
    id:         state.Grader.dataEdit.id,
    token:      state.PanelUser.token,
    page:       state.Grader.page,
    records:    state.Grader.records,
    userId:     state.PanelUser.id
})

export default connect(mapStateToProps, {changeView, updateGraders, getGraders})(Tables)