import { useState, useEffect } from "react";
import { Container, IconButton, Grid } from "@material-ui/core";
import { connect } from "react-redux";
// import { toast } from "react-toastify";

// Redux
import { changeView, cleanData } from "../../store/redux/actions/Global";
// Components
import DragImage from "../request/DragImage";
import Modal from "../request/ModalData";
import ModalGrader from "./ModalGrader";
import Input from "../global/Input";
// import Select from "../global/Select";
// Values
import { Ic_back, Ic_next } from "values";
import { formatDate3 } from "utils";

const DisplayImages = ({ position, option, image1, image2, text1, text2 }) => {
  return (
    <div style={{ display: position === option ? "block" : "none" }}>
      <Grid container>
        <Grid item sm={6}>
          <div style={{ padding: "0 10px" }}>
            <DragImage image={image1} text={text1} width={"100%"} height={350} blockData={"disabled"} expand />
          </div>
        </Grid>
        <Grid item sm={6}>
          <DragImage image={image2} text={text2} width={"100%"} height={350} blockData={"disabled"} expand />
        </Grid>
      </Grid>
    </div>
  );
};

const CardEdema = ({ background, level, edema, text }) => {
  return (
    <Grid item sm={6}>
      <div style={{ textAlign: "center" }}>
        <p style={{ margin: 0 }}>{text}</p>
        <div style={{ background: background, paddingTop: 20, paddingBottom: 10, textAlign: "center", width: "95%" }}>
          <p style={{ margin: 0, fontWeight: 600, fontSize: 60 }}>{`R${level}`}</p>
        </div>
        <p style={{ fontSize: 18, margin: 0 }}>{edema ? "CON EDEMA MACULAR" : "SIN EDEMA MACULAR"}</p>
      </div>
    </Grid>
  );
};

const FormCompleted = ({ stateView, token, data, patients, changeView, cleanData }) => {
  // const [blockData, setBlockData] = useState("");
  const [position, setPosition] = useState(0);
  // const [image, setImage] = useState("");
  const [personalData, setPersonalData] = useState("");

  const [r_p_l_l, setColor1] = useState("");
  const [r_p_l_r, setColor2] = useState("");

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [defaultColor, setDefaultColor] = useState("#87F0AF");
  const [message, setMessage] = useState("");

  const nextPage = () => {
    const sum = position + 1;
    if (sum <= 2) {
      setPosition(sum);
    }
  };
  const backPage = () => {
    const subtraction = position - 1;
    if (subtraction >= 0) {
      setPosition(subtraction);
    }
  };

  const filterData = (id) => {
    let obj = {};
    patients.forEach((i) => {
      if (i.id === id) {
        obj = i;
      }
    });
    setPersonalData(obj);
  };

  const setNewColor = () => {
    setColor1(
      parseInt(data.retinopathy_level_left) === 0 || parseInt(data.retinopathy_level_left) === 1
        ? "#87F0AF"
        : parseInt(data.retinopathy_level_left) === 2
        ? "#F9CB4C"
        : "#E96262"
    );
    setColor2(
      parseInt(data.retinopathy_level_right) === 0 || parseInt(data.retinopathy_level_right) === 1
        ? "#87F0AF"
        : parseInt(data.retinopathy_level_right) === 2
        ? "#F9CB4C"
        : "#E96262"
    );
    generateMessage(data.retinopathy_level_left, data.retinopathy_level_left);
  };

  const standBy = () => {};

  const generateMessage = (value_left, value_right) => {
    let obj = { m1: "", m2: "", color: "#87F0AF" };
    const message1 = "Revalorar en 12 meses.";
    // const message2 = 'Revalorar en 12 meses.'
    const message3 = "Revalorar en 6 meses(o referir al oftalmólogo si no es factible).";
    const message4 = "Referir al oftalmólogo.";
    const message5 = "Referir al oftalmólogo.";
    const message6 = "Revalorar en 12 meses del otro ojo.";
    const message7 = "1) Falla técnica - Tomar imágenes de nuevo.";
    const message8 = "2) Hallazgo fisiológico que ni permita ver el fondo de ojo - Referir al oftalmólogo.";

    let highest_level = 0;
    if (parseInt(value_left) >= parseInt(value_right)) {
      highest_level = parseInt(value_left);
    } else {
      highest_level = parseInt(value_right);
    }

    if (highest_level === 0 || highest_level === 1) {
      obj = { m1: message1, m2: "", color: "#87F0AF" };
    } else if (highest_level === 2) {
      obj = { m1: message3, m2: "", color: "#F9CB4C" };
    } else if (highest_level === 3) {
      obj = { m1: message4, m2: "", color: "#E96262" };
    } else if (highest_level === 4) {
      obj = { m1: message5, m2: "", color: "#E96262" };
    } else if (highest_level === 5) {
      obj = { m1: message6, m2: "", color: "#E96262" };
    } else if (highest_level === 6) {
      obj = { m1: message7, m2: message8, color: "#E96262" };
    }

    setDefaultColor(obj.color);
    setMessage(`${obj.m1} ${obj.m2}`);
  };

  useEffect(() => {
    setPosition(0);
    filterData(data.patientId);
  }, []);

  useEffect(() => {
    setPosition(0);
    setNewColor();
  }, [data]);
  return (
    <div className="card_info">
      <Modal
        open={open1}
        closeModal={setOpen1}
        data={
          <div>
            <p style={{ margin: 0, marginTop: 15, fontSize: 18, fontWeight: 600 }}>{"Datos Personales"}</p>
            <div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20 }}>
              <Container>
                <Input title={"Nombre"} value={data.patient ? data.patient.name : ""} blockData={"disabled"} />
                <Input title={"Apellido"} value={data.patient ? data.patient.last_name : ""} blockData={"disabled"} />
                <Input title={"Correo"} value={data.patient ? data.patient.email : ""} blockData={"disabled"} />
                <Input title={"Teléfono"} value={data.patient ? data.patient.phone : ""} blockData={"disabled"} />
                <Input title={"Domicilio"} value={data.patient ? data.patient.address : ""} blockData={"disabled"} />

                {/* <Select title={'Fecha de nacimiento'} list={[formatDate3(data.patient.birthdate)]} blockData={'disabled'} />
                                <Select title={'Estado civil'} list={[data.patient.civil_status]} blockData={'disabled'} />
                                <Select title={'Sexo'} list={[data.patient.gender==='MALE'?'Masculino':'Femenino']} blockData={'disabled'} /> */}

                <Input
                  title={"Fecha de nacimiento"}
                  value={data.patient ? formatDate3(data.patient.birthdate) : ""}
                  blockData={"disabled"}
                />
                <Input
                  title={"Estado civil"}
                  value={data.patient ? data.patient.civil_status : ""}
                  blockData={"disabled"}
                />
                <Input
                  title={"Sexo"}
                  value={data.patient ? (data.patient.gender === "MALE" ? "Masculino" : "Femenino") : ""}
                  blockData={"disabled"}
                />

                <Input title={"Curp"} value={data.patient ? data.patient.curp : ""} blockData={"disabled"} />
                <Input title={"Expediente"} value={data.patient ? data.patient.record : ""} blockData={"disabled"} />
              </Container>
            </div>
          </div>
        }
      />

      <Modal
        open={open2}
        closeModal={setOpen2}
        data={
          <div>
            <p style={{ margin: 0, marginTop: 15, fontSize: 18, fontWeight: 600 }}>{"Datos Clínicos"}</p>
            <div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20 }}>
              <Container>
                <Input title={"Estatura (cm)"} value={data.height} blockData={"disabled"} />
                <Input title={"Peso (kg)"} value={data.weight} blockData={"disabled"} />
              </Container>
              <div style={{ background: "#dde2eb", padding: "5px 0" }}>
                <p style={{ margin: 0, paddingLeft: 20, fontWeight: 600 }}>{"Valores de control"}</p>
              </div>
              <Container>
                <Input title={"Tensión Arterial Sistólica (mmHg)"} value={data.systolic_blood_pressure} blockData={"disabled"} />
                <Input title={"Tensión Arterial Diastólica (mmHg)"} value={data.diastolic_blood_pressure} blockData={"disabled"} />
                <Input title={"Tiempo con diabetes años"} value={data.diabetes_time} blockData={"disabled"} />
                <Input title={"Usa Insulina"} value={data.insulin} blockData={"disabled"} />
                <Input title={"Hipoglucemiantes orales"} value={data.oral_hypoglycemic} blockData={"disabled"} />
                <Input title={"Se uso midriasis"} value={data.mydriasis_use} blockData={"disabled"} />
                <Input title={"Hemoglobina glucosilada HbA1c(%)"} value={data.hemoglobin_glycated} blockData={"disabled"} />
                <Input
                  title={"Glucosa en sangre venosa (8 horas ayuno) mmHg"}
                  value={data.glucose_in_venous_blood}
                  blockData={"disabled"}
                />
                <Input title={"Glucemia capilar"} value={data.capillary_blood_glucose} blockData={"disabled"} />
              </Container>
              <div style={{ background: "#dde2eb", padding: "5px 0" }}>
                <p style={{ margin: 0, paddingLeft: 20, fontWeight: 600 }}>{"Agudeza visual"}</p>
              </div>
              <Container>
                <Input title={"Ojo Izquierdo"} value={data.visual_acuity_left_a} blockData={"disabled"} />
                <Input title={"Ojo Izquierdo (con estenopeico)"} value={data.visual_acuity_left_b} blockData={"disabled"} />
                <Input title={"Ojo Derecho"} value={data.visual_acuity_right_a} blockData={"disabled"} />
                <Input title={"Ojo Derecho (con estenopeico)"} value={data.visual_acuity_right_b} blockData={"disabled"} />
                <Input title={"Ambos Ojos"} value={data.visual_acuity_both} blockData={"disabled"} />
                <Input title={"Uso de lentes"} value={data.use_glasses} blockData={"disabled"} />
                <Input title={"Ojo Izquierdo (con lentes)"} value={data.visual_acuity_glasses_left} blockData={"disabled"} />
                <Input title={"Ojo Derecho (con lentes)"} value={data.visual_acuity_glasses_right} blockData={"disabled"} />
                <Input title={"Uso de lentes (con lentes)"} value={data.visual_acuity_glasses_both} blockData={"disabled"} />
              </Container>
            </div>
            <div style={{ background: "#f3f5f9", paddingTop: 20, paddingBottom: 10, marginTop: 20 }}>
              <Container>
                <Input title={"Cintura (cm)"} value={data.waist} blockData={"disabled"} />
                <Input title={"Frecuencia Cardiaca (latidos/min)"} value={data.heart_rate} blockData={"disabled"} />
                <Input title={"Frecuencia Respiratoria (resp/min)"} value={data.breathing_frequency} blockData={"disabled"} />
                <Input title={"Temperatura ºC"} value={data.temperature} blockData={"disabled"} />
                <Input title={"Cigarros x día"} value={data.cigarretes} blockData={"disabled"} />
                <Input title={"Creatinina"} value={data.creatinine} blockData={"disabled"} />
                <Input title={"Microalbuminuria"} value={data.microalbuminuria} blockData={"disabled"} />
                <Input title={"Tasa de filtrado glomerular"} value={data.glomerular_filtration_rate} blockData={"disabled"} />
                <Input title={"Triglicéridos (mmol/L)"} value={data.triglycerides} blockData={"disabled"} />
                <Input title={"Colesterol (mg/dl)"} value={data.cholesterol} blockData={"disabled"} />
                <Input title={"LDL (mmol/L)"} value={data.ldl} blockData={"disabled"} />
                <Input title={"HDL (mmol/L)"} value={data.hdl} blockData={"disabled"} />
              </Container>
            </div>
          </div>
        }
      />

      <ModalGrader data={data} open={open3} closeModal={setOpen3} />

      <div className="card_scroll">
        <div style={{ display: data.completed ? "none" : "block" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ffbd00",
              height: 100,
            }}
          >
            <p style={{ margin: 0, color: "#fff", fontSize: 25 }}>{"Solicitud en valoración"}</p>
          </div>
        </div>
        <Container>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <IconButton onClick={() => backPage()}>
              <img alt="" src={Ic_back} />
            </IconButton>
            <IconButton onClick={() => nextPage()}>
              <img alt="" src={Ic_next} />
            </IconButton>
          </div>

          <DisplayImages
            position={position}
            option={0}
            image1={data.picture_left_eye_a}
            image2={data.picture_left_eye_b}
            text1={"Ojo izquierdo centrado en mácula"}
            text2={"Ojo izquierdo centrado en disco"}
          />

          <DisplayImages
            position={position}
            option={1}
            image1={data.picture_right_eye_a}
            image2={data.picture_right_eye_b}
            text1={"Ojo derecho centrado en mácula"}
            text2={"Ojo derecho centrado en disco"}
          />

          <DisplayImages
            position={position}
            option={2}
            image1={data.previous_segment}
            image2={data.previous_segment_b}
            text1={"Segmento anterior - ojo izquierdo"}
            text2={"Segmento anterior - ojo derecho"}
          />

          <div style={{ display: data.completed ? "block" : "none" }}>
            <div style={{ display: data.notGradable ? "none" : "block" }}>
              <Grid container>
                <CardEdema
                  background={r_p_l_l}
                  level={data.retinopathy_level_left}
                  edema={data.macularEdemaLeft}
                  text={"OJO IZQUIERDO"}
                />

                <CardEdema
                  background={r_p_l_r}
                  level={data.retinopathy_level_right}
                  edema={data.macularEdemaRight}
                  text={"OJO DERECHO"}
                />
              </Grid>
            </div>

            <div
              style={{
                background: "#d50b5a",
                paddingTop: 20,
                paddingBottom: 10,
                marginTop: 20,
                textAlign: "center",
                width: "97.5%",
                display: data.not_graduable ? "block" : "none",
              }}
            >
              <p style={{ margin: 0, fontWeight: 600, fontSize: 38, color: "#fff" }}>{"NO GRADUABLE"}</p>
            </div>

            <div
              style={{
                borderLeft: `5px solid ${defaultColor}`,
                background: "#f7f8fb",
                width: "65%",
                padding: "1px 0",
                marginTop: 20,
                display: data.not_graduable ? "none" : "block",
              }}
            >
              <Container>
                <p>{message}</p>
              </Container>
            </div>

            <div style={{ width: "97.5%", marginTop: 30 }}>
              <p style={{ margin: 0, fontWeight: 600 }}>{"Comentarios"}</p>
              {data.mapGraders && data.mapGraders.map((i) => <p style={{ margin: 0 }}>{i.coments}</p>)}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "97.5%",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <div>
              <p
                style={{ margin: 0, color: "#648CFA", fontWeight: 600, cursor: "pointer" }}
                onClick={() => (data.patient ? setOpen1(true) : standBy())}
              >
                {"Ver datos personales"}
              </p>
              <p
                style={{ margin: 0, color: "#648CFA", fontWeight: 600, cursor: "pointer" }}
                onClick={() => setOpen2(true)}
              >
                {"Ver datos clínicos"}
              </p>
              <p
                style={{ margin: 0, color: "#648CFA", fontWeight: 600, cursor: "pointer" }}
                onClick={() => setOpen3(true)}
              >
                {"Resultados de graduadores"}
              </p>
            </div>
            <div>
              <p style={{ margin: 0, fontSize: 18 }}>{data.patient ? data.patient.name : ""}</p>
              <p style={{ margin: 0 }}>{data.patient ? data.patient.email : ""}</p>
              <p style={{ margin: 0 }}>{data.patient ? data.patient.phone : ""}</p>
            </div>
          </div>

          {/* <div style={{display:data.completed?'block':'none'}}>
                        <p style={{margin:0, fontWeight:600, width:200}}>{'Tiempo de graduacón'}</p>
                        <p style={{margin:0, fontWeight:600, width:200}}>{'Tiempo a entrega'}</p>
                    </div> */}
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  stateView: state.Global.stateView,
  token: state.PanelUser.token,
  data: state.Request.dataEdit,
  patients: state.Patients.allData,
});

export default connect(mapStateToProps, { changeView, cleanData })(FormCompleted);
