import {urlApi, urlPath, get, new_update, new_post, new_delete} from '../api';
import {
	regex_names_two,
	regex_full_password,
	regex_email,
} from 'utils';

const url = `${urlApi}${urlPath.users}`;

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const getSearchData = async(token, extra="") =>{
    const auxUrl = urlApi + urlPath.searchUsers
    return await get(auxUrl, token, extra)
} 

export const postUserData = async(name, lastName, rol, medicalCenterId, picture, email, group, token) => {
	const obj = {
		name, lastName, email, rol, medicalCenterId, picture, group
	}

	return new_post(obj, url, token);
}

export const updateUserData = async(id, name, lastName, rol, medicalCenterId, picture, email, flag, group, token) => {
	let obj = {
		name, lastName, rol, medicalCenterId, email, group
	}

	if (flag) {
		obj.picture = picture
	}

	return new_update(obj, `${url}/${id}`, token);
}

export const deleteUserData = async(id, token) => {
	return new_delete(`${url}/${id}`, token);
}

export const validatePostUserData = (name, lastName, email, rol, hospital, group) => {
	return new Promise((resolve, reject) => {
		let success = true;
		let errors = [];

		if (!name.match(regex_names_two)) {
			success = false;
			errors.push('Nombre: Solo letras, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (!lastName.match(regex_names_two)) {
			success = false;
			errors.push('Apellido: Solo letras, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (!email.match(regex_email)) {
			success = false;
			errors.push('Correo electronico.');
		}
		if (!['ADMINISTRADOR', 'FOTÓGRAFO', 'GRADUADOR', 'INVESTIGADOR', 'GRADUADOR INVESTIGADOR'].includes(rol)) {
			success = false;
			errors.push('Parece que algo anda mal con el rol.');
		}
		if ((rol == 'FOTÓGRAFO' || rol == 'GRADUADOR') && !hospital) {
			success = false;
			errors.push('Parece que algo anda mal con el hospital.');
		}
		if (rol == 'GRADUADOR' && !group) {
			success = false;
			errors.push('Parece que algo anda mal con el grupo.');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}

export const validateUpdateData = (name, lastName, rol, hospital, group) => {
	return new Promise((resolve, reject) => {
		let success = true;
		let errors = [];

		if (!name.match(regex_names_two)) {
			success = false;
			errors.push('Nombre: Solo letras, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (!lastName.match(regex_names_two)) {
			success = false;
			errors.push('Apellido: Solo letras, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (!['ADMINISTRADOR', 'FOTÓGRAFO', 'GRADUADOR', 'INVESTIGADOR', 'GRADUADOR INVESTIGADOR'].includes(rol)) {
			success = false;
			errors.push('Parece que algo anda mal con el rol.');
		}
		if ((rol == 'FOTÓGRAFO' || rol == 'GRADUADOR') && !hospital) {
			success = false;
			errors.push('Parece que algo anda mal con el hospital.');
		}
		if (rol == 'GRADUADOR' && !group) {
			success = false;
			errors.push('Parece que algo anda mal con el grupo.');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}

export const validateSelfUserUpdate = (name, lastName) => {
	let success = true;
	let errors = [];

	return new Promise((resolve, reject) => {
		if (!name.match(regex_names_two)) {
			success = false;
			errors.push('Nombre(s).');
		}
		if (!lastName.match(regex_names_two)) {
			success = false;
			errors.push('Apellido(s).');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}

export const selfUserUpdateData = async(params, self_update_type, token) => {
	let obj = {
		...params, self_update_type
	}

	return new_update(obj, url, token);
}

export const validateSelfUserPasswdUpdate = (new_pass, new_pass_confirmation) => {
	let success = true;
	let errors = [];

	return new Promise((resolve, reject) => {
		if (!new_pass.match(regex_full_password)) {
			success = false;
			errors.push('La contraseña no cumple con los parametros de seguridad.');
		}
		if (new_pass !== new_pass_confirmation) {
			success = false;
			errors.push('Las contraseñas no coinciden.');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}