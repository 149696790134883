import {stateData, reduxState} from 'values';

export const getUsers = (data, request, page) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_users,
            data:       data,
            status:     stateData.state2,
            records:    request,
            page:       page,
        })
    }
}

export const updateUsers = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.update_users,
            data:       data,
        })
    }
}