import React, { useState, } from 'react'; // useEffect
import {Ic_visibility, Ic_visibility_off} from 'values';

const Input = ({title, value, setData, view=false, eye, setEye, color='#000', regex=/^.+$/}) =>{
	const [isValid, setIsValid] = useState(true);
	const [invalidInputStyle, setInvalidInputStyle] = useState({borderColor: '#ff0000', boxShadow: '0 0 5px #ff0000'});

	const handleChange = (e) => {
		const newValue = e.target.value;
		setData(newValue);

		setIsValid(regex.test(newValue)); // Validar el valor en base a la expresión regular recibida por prop
	};

	return(
		<div style={{position:'relative'}}>
			<img alt="" style={{display:view?'block':'none', position:'absolute', right:10, top:5, cursor:'pointer', width:20, marginTop:'10px'}}
				src={eye?Ic_visibility:Ic_visibility_off} onClick={()=> setEye(!eye)} />
			<label style={{display: 'flex', alignItems: 'center'}}>
				<p className='title_input' style={{marginTop:14, marginBottom:14, color:color}}>{title}</p>
				<div style={{width:'100%'}}>
					<input 
						type={!eye ? 'password' : 'text'}
						onChange={handleChange}
						value={value}
						className='input'
						style={isValid ? {} : invalidInputStyle} />
				</div>
			</label>
		</div>
	)
}

export default Input;