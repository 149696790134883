import {Container, Modal}   from '@material-ui/core';

const ModalData = ({data, open, closeModal, height=300}) => {
    return (
        <div>
            <Modal open={open} 
                style={{display:'flex', alignItems:'center', justifyContent:'center',}}
                onClose={() => closeModal(!open)}>
                    <Container maxWidth="md">
                        <div className="card_scroll" style={{background:'#fff', minHeight:height, position:'relative', paddingTop:10, paddingBottom:10}}>
                            <Container>
                                {data}
                            </Container>
                        </div>
                    </Container>
                </Modal>
        </div>
    );
};

export default ModalData;