import {urlApi, urlPath}    from '../api';

export const verifyLogin = async(email, password) => {
    const authenticate_url_api  = urlApi+urlPath.authenticate
    const my_credentials_url_api  = urlApi+urlPath.me

    const obj   = {email, password}
    let flag1   = false
    let flag2   = false
    let token   = ""
    let data    = {}

    if (email) {
        const request = {
            method:"POST",
            body: JSON.stringify(obj),
            headers:{
                'Content-Type': 'application/json'
            }
        }

        await fetch(authenticate_url_api, request)
        .then((response) => {
            if (response.status===200) {
                token = response.headers.get('X-Api-Key')
                flag1 = true
            }
        })
        .catch(error => {

        });
    }
    if (flag1) {
        const request = {
            method:"GET",
            headers:{
                'token': token
            }
        }

        await fetch(my_credentials_url_api, request)
        .then((response) => {
            if (response.status===200) {
                flag2 = true
                return response.json()
            }
        })
        .then((res) => {
            if (flag2===true) {
                data.name               = res.data.name
                data.lastName           = res.data.last_name
                data.picture            = res.data.picture
                data.rol                = res.data.rol
                data.medicalCenterId    = res.data.medical_center_id
                data.id                 = res.data.id
                data.study_type         = res.data.medical_center_study_type ? res.data.medical_center_study_type.study_type : null
            }
        })
    }

    return {flag:flag2, token, data}
}

export const forgotPassword = async(email) =>{
    const url = urlApi + urlPath.forgotPassword
    const obj = {email}
    let data = {}
    let flag = false

    const request = {
        method:"POST",
        body: JSON.stringify(obj),
        headers:{ 'Content-Type': 'application/json' }
    }
    await fetch(url, request)
    .then((res) =>{
        if(res.status === 200){
            flag = true
            return res.json()
        } else{
            console.log(res);
        }
    })
    .then((response) =>{
        if(flag === true){
            data = response 
        }
    })
    console.log('Data: ', data);
    return flag
}

export const recoverPassword = async(id, password) => {
	const url = `${urlApi}${urlPath.recover}/${id}`;
	const obj = {password};
	let flag = false;

	const request = {
		method:"POST",
		body: JSON.stringify(obj),
		headers: {'Content-Type': 'application/json'}
	}

	await fetch(url, request)
	.then((res) => {
		if (res.status === 200) {
			flag = true;
		} else {
			console.log(res);
		}
	});

	return flag;
}

export const me = (token) => {
	const my_credentials_url_api = `${urlApi}${urlPath.me}`;
	const request = {
		method:"GET",
		headers:{
			'token': token
		}
	}

	return fetch(my_credentials_url_api, request);
}