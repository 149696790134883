import {urlApi, urlPath, get, update, post, deleteRegister, new_post, new_update, new_delete} from '../api';
import {regex_email, regex_phone_number, regex_names_one, regex_names_three, regex_curp} from 'utils';

const url = urlApi + urlPath.patients

export const getData = async(token, extra="") =>{
    return await get(url, token, extra)
} 

export const getPatientsData = async(token, extra="") =>{
    const auxUrl = urlApi + urlPath.patients
    return await get(auxUrl, token, extra)
} 

export const getSearchData = async(token, extra="") =>{
    const auxUrl = urlApi + urlPath.searchPatients
    return await get(auxUrl, token, extra)
} 

export const postPatientData = (name, lastName, email, phone, address, birthdate, civilState, sex, curp, files, medicalCenterId, token) => {
	const obj = {
		name, lastName, email, phone, address, birthdate, 
		civilStatus:civilState, gender:sex, curp, record:files, medicalCenterId
	}
	return new_post(obj, url, token);
}

export const updatePatientData = (id, name, lastName, email, phone, address, birthdate, civilState, sex, curp, files, medicalCenterId, token) => {
	const obj = {
		name, lastName, email, phone, address, birthdate, 
		civilStatus:civilState, gender:sex, curp, record:files, medicalCenterId
	}
	return new_update(obj, `${url}/${id}`, token);
}

export const deletePatientData = (id, token) => {
	return new_delete(`${url}/${id}`, token);
}

export const validatePatientData = (name, lastName, email, phone, address, birthdate, civilState, sex, curp, files) => {
	return new Promise((resolve, reject) => {
		let success = true;
		let errors = [];

		if (!name.match(regex_names_one)) {
			success = false;
			errors.push('Nombre: Solo letras, numeros, espacios, guion bajo, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (!lastName.match(regex_names_one)) {
			success = false;
			errors.push('Apellido(s): Solo letras, numeros, espacios, guion bajo, espacio, punto, 2 a 300 caracteres son permitidos.');
		}
		if (email != '' && !email.match(regex_email)) {
			success = false;
			errors.push('Correo electronico.');
		}
		if (phone != '' && !phone.replaceAll(/[-()\s]/g, '').match(regex_phone_number)) {
			success = false;
			errors.push('Numero telefonico: Formatos validos: 5512345678, 15512345678, 525512345678, +525512345678, +5215512345678');
		}
		if (address != '' && !address.match(regex_names_three)) {
			success = false;
			errors.push('Direccion: Solo letras, numeros, guion bajo, espacio, punto, #, 2 a 300 caracteres son permitidos.');
		}
		if (!birthdate) {
			success = false;
			errors.push('Fecha de nacimiento: Custom text.');
		}
		if (!['Soltero', 'Casado', 'Divorciado'].includes(civilState)) {
			success = false;
			errors.push('Estado civil.');
		}
		if (!['MALE', 'FEMALE'].includes(sex)) {
			success = false;
			errors.push('Sexo.');
		}
		if (curp != '' && !curp.match(regex_curp)) {
			success = false;
			errors.push('CURP.');
		}
		if (!files) {
			success = false;
			errors.push('Expediente: Custom text.');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}