import { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Drawer, AppBar, List, CssBaseline, Tooltip } from "@material-ui/core";

// Redux
import { cleanData } from "../../store/redux/actions/Global";
// Components
import Modal from "./ModalGlobal";
// Values
import {
//   User,
  Img_logo_white_bar,
  Ic_research,
  Ic_patient,
  Ic_request,
  Ic_medical_center,
  Ic_users,
  Ic_reports,
  Ic_folder,
  Ic_folder_managed,
  Ic_visivility,
  Ic_symptoms,
  Ic_table_rows,
  Ic_account,
  Img_eyebit_lab,
} from "../../values";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    background: "rgba(0, 0, 0, 0.8)",
    width: 120,
    height: 30,
    borderRadius: 9,
    color: "#fff",
    fontSize: 16,
    textAlign: "center",
    fontFamily: "regular",
  },
}))(Tooltip);

const drawerWidth = 120;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#fafafa",
  },
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    borderRight: 0,
    background: "#263746",
    // borderTopRightRadius:50,
    // boxShadow:' 0px 0px 5px -4px rgba(0,0,0,0.75)',
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    // boxShadow:' 0px 0px 5px -4px rgba(0,0,0,0.75)',
    width: drawerWidth,
    borderRight: 0,
    background: "#263746",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // marginTop:30,
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#fafafa",
    // minHeight:'100vh'
  },
  listScroll: {
    overflowY: "hidden",
    overflowX: "hidden",
    // overflowY:'hidden',
    // height:'100vh',
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(205, 205, 205, 0.5) #f0f0f0",
    // '&:hover':{
    //     overflowY:'auto',
    // },
    "&::-webkit-scrollbar": {
      // height:6,
      width: 6,
      background: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cdcdcd",
    },
  },
  button_open: {
    minWidth: 150,
    marginLeft: -15,
    cursor: "pointer",
    paddingTop: 10,
    paddingBottom: 10,
  },
  button_close: {
    minWidth: 150,
    marginLeft: 70,
    cursor: "pointer",
    paddingTop: 10,
    paddingBottom: 10,
  },
  menu_selected: {
    background: "#4aa3ff",
    width: 50,
    height: 50,
    borderRadius: 12,
    display: "flex",
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
    "&:active": {
      transform: "scale(0.95)",
    },
  },
  menu_unselected: {
    width: 50,
    height: 50,
    borderRadius: 12,
    display: "flex",
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
    "&:active": {
      transform: "scale(0.95)",
      background: "#4aa3ff",
    },
  },
}));

const ItemMenu = ({ title, path, image, location, onClick, width = 18 }) => {
  const classes = useStyles();
  return (
    <CustomTooltip title={title} placement="right" onClick={() => onClick(path)}>
      <div className={location === path ? classes.menu_selected : classes.menu_unselected}>
        <img alt="" src={image} style={{ width: width }} />
      </div>
    </CustomTooltip>
  );
};

const Menu = ({userRol, name, lastName, picture, cleanData}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [locationView, setLocation] = useState(location.pathname.substr(1)); //.split("/")[1]
  const [openModal, setOpenModal] = useState(false);
  const [version] = useState("V 1.0.0");
  const [eyebitLabTitle] = useState(userRol === 'INVESTIGADOR' || userRol === 'GRADUADOR INVESTIGADOR');

  const ChangePage = (page) => {
    cleanData();
    history.push(`/${page}`);
  };

  const logOut = () => {
    window.localStorage.removeItem("eyebit-name");
    window.localStorage.removeItem("eyebit-lastName");
    window.localStorage.removeItem("eyebit-picture");
    window.localStorage.removeItem("eyebit-key");
    window.localStorage.removeItem("eyebit-rol");
    window.localStorage.removeItem("eyebit-medicalCenterId");
    window.localStorage.removeItem("eyebit-user-id");
    window.localStorage.clear();
    window.location.href = "/";
  };

  useEffect(() => {
    setLocation(location.pathname.substr(1)); //.split("/")[1]
  });
  return (
    // <div style={{ display: locationView === "restore-password" ? "none" : locationView === "" ? "none" : "block" }}>
    <div style={{ display: locationView.search('restore-password') != -1 ? "none" : locationView === "" ? "none" : "block" }}>
      <div
        className={classes.root}
        // style={{display:locationView==='restore-password'?'none':locationView===''?'none':'block'}}
      >
        <Modal
          open={openModal}
          onClose={setOpenModal}
          title={"Cerrar sesión"}
          text_button1={"Cerrar"}
          text_button2={"Cancelar"}
          button={logOut}
          info={`¿Seguro que deseas cerrar sesión?`}
        />

        <CssBaseline />
        <AppBar
          style={{ background: "white", boxShadow: " 0px 0px 0px 0px rgba(0,0,0,0.75)" }}
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        ></AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.listScroll} id="scroll">
            <div style={{ position: "absolute", bottom: 5, left: "50%", transform: "translate(-50%, 0)" }}>
              <label style={{ color: "#fff", fontSize: 12 }}>{version}</label>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomTooltip title="" placement="right">
                <img alt="" src={Img_logo_white_bar} style={{width: 70, marginTop: 40}} />
              </CustomTooltip>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {eyebitLabTitle && <img alt="" src={Img_eyebit_lab} style={{width: 60, marginLeft: 7, marginTop: 10}} />}
            </div>
            <div style={{ height: "100%", display: "flex", justifyContent: "center"}}>
              <List>
                {userRol === "ADMINISTRADOR" ? (
                  <>
                    <ItemMenu
                      title={"Instituciones"}
                      path={"hospitals"}
                      image={Ic_medical_center}
                      location={locationView}
                      onClick={ChangePage}
                    />
                    <ItemMenu
                      title={"Usuarios"}
                      path={"users"}
                      image={Ic_users}
                      location={locationView}
                      onClick={ChangePage}
                    />
                    <ItemMenu
                      title={"Solicitudes"}
                      path={"requestAdmin"}
                      image={Ic_request}
                      location={locationView}
                      width={22}
                      onClick={ChangePage}
                    />
                    {/* <ItemMenu
                      title={"Investigación"}
                      path={"researchAdmin"}
                      image={Ic_research}
                      location={locationView}
                      onClick={ChangePage}
                    /> */}
                    <ItemMenu
                      title={"Reportes"}
                      path={"reports"}
                      image={Ic_reports}
                      location={locationView}
                      onClick={ChangePage}
                    />
                  </>
                ) : userRol === 'INVESTIGADOR' ? (
                  <>
                    <ItemMenu
                      title={"Conjuntos"}
                      path={"datasets"}
                      image={Ic_folder_managed}
                      location={locationView}
                      width={24}
                      onClick={ChangePage}
                    />
                    <ItemMenu
                      title={"Clasificación"}
                      path={"clasification"}
                      image={Ic_visivility}
                      location={locationView}
                      width={24}
                      onClick={ChangePage}
                    />
                    <ItemMenu
                      title={"Calidad"}
                      path={"quality"}
                      image={Ic_symptoms}
                      location={locationView}
                      width={24}
                      onClick={ChangePage}
                    />
                    <ItemMenu
                      title={"Base datos"}
                      path={"databases"}
                      image={Ic_table_rows}
                      location={locationView}
                      width={24}
                      onClick={ChangePage}
                    />
                  </>
                ) : userRol === 'GRADUADOR INVESTIGADOR' ? (
                  <>
                    <ItemMenu
                      title={"Clasificación"}
                      path={"datasets/grade"}
                      image={Ic_visivility}
                      location={locationView}
                      width={24}
                      onClick={ChangePage}
                    />
                    <ItemMenu
                      title={"Calidad"}
                      path={"datasets/quality"}
                      image={Ic_symptoms}
                      location={locationView}
                      width={24}
                      onClick={ChangePage}
                    />
                  </>
                ) : userRol === "FOTÓGRAFO" ? (
                  <>
                    <ItemMenu
                      title={"Pacientes"}
                      path={"patients"}
                      image={Ic_patient}
                      location={locationView}
                      onClick={ChangePage}
                    />
                    <ItemMenu
                      title={"Solicitudes"}
                      path={"request"}
                      image={Ic_request}
                      location={locationView}
                      width={22}
                      onClick={ChangePage}
                    />
                  </>
                ) : userRol === "GRADUADOR" ? (
                  <>
                    <ItemMenu
                      title={"Graduación"}
                      path={"grader"}
                      image={Ic_request}
                      location={locationView}
                      width={22}
                      onClick={ChangePage}
                    />
                    {/* <ItemMenu
                      title={"Investigación"}
                      path={"research"}
                      image={Ic_research}
                      location={locationView}
                      onClick={ChangePage}
                    /> */}
                  </>
                ) : <></> }

                <ItemMenu
                  title={"Mi cuenta"}
                  path={"account"}
                  image={Ic_account}
                  location={locationView}
                  width={24}
                  onClick={ChangePage}
                />

              </List>
            </div>
          </div>
        </Drawer>

        <div style={{ position: "absolute", right: 50, top: 20, zIndex: 100 }}>
          <div style={{ display: "flex" }}>
            <div style={{ textAlign: "right", marginRight: 25, lineHeight: 1, paddingTop: 13 }}>
              <label style={{ color: "#000", fontFamily: "regular" }}>
                {name} {lastName}
              </label>
              <br />
              <label
                onClick={() => setOpenModal(true)}
                style={{ color: "#226bb9", fontFamily: "semi", fontSize: 14, cursor: "pointer" }}
              >
                {"cerrar sesión"}
              </label>
            </div>
            {picture === "null" ? (
              <div style={{ width: 50, height: 50, background: "#dcdfe3", borderRadius: 15 }} />
            ) : picture ? (
              <img alt="user" src={picture} style={{ height: 50, width: 50, borderRadius: 15 }} />
            ) : (
              <div style={{ width: 50, height: 50, background: "#dcdfe3", borderRadius: 15 }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userRol: state.PanelUser.rol,
  name: state.PanelUser.name,
  lastName: state.PanelUser.lastName,
  picture: state.PanelUser.picture,
});

export default connect(mapStateToProps, { cleanData })(Menu);
