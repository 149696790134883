import {useState}	from 'react';
import {connect}	from 'react-redux';
// Redux
import {changeView}				from '../../store/redux/actions/Global';
import {updateUsers, getUsers}	from '../../store/redux/actions/Users';
// Components
import InputSearch	from '../global/InputSearch';
import LoadingData	from '../global/LoadingData';

// Unike key
import {capitalizeText} from 'utils'; // generateUniqueKey,
// Values
import {StateView, stateData} from 'values';
// Api
import {getSearchData, getData} from 'utils/users';

const Tables = ({data, status, id, token, page, records, changeView, updateUsers, getUsers}) => {
	const [search, setSearch] = useState('')

	const searchInfo = async() => {
		if (search) {
			const extra = `?search=${search}`;
			const res = await getData(token, extra);
			getUsers(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1);
		} else {
			const extra = `?page=1&limit=10`;
			const res = await getData(token, extra);
			getUsers(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1);
		}
	}

	const GetData = async(event, newPage) => {
		const extra = `?page=${newPage+1}&limit=10`;
		const res = await getData(token, extra);
		getUsers(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1);
	}

	const edit = (item) => {
		changeView(StateView.state3)
		updateUsers(item)
	}

	const custom_id = (rol, user_id) => {
		switch(rol) {
			case 'ADMINISTRADOR':
				return `ADMR${user_id}`;
			case 'FOTÓGRAFO':
				return `FOT${user_id}`;
			case 'GRADUADOR':
				return `GRAD${user_id}`;
			case 'INVESTIGADOR':
				return `INV${user_id}`;
			case 'GRADUADOR INVESTIGADOR':
				return `GINV${user_id}`;
		}
	}

	return (
		<div className="card_info">
			<InputSearch onClickSearch={searchInfo}
				value={search} setData={setSearch}
				page={page} records={records} setPage={GetData} />
			{
				status === stateData.state2 ?
					<div className="card_scroll">
						{
							data &&
							data.map((item, j) => (
								<div key={j} onClick={() => edit(item)}
									className={id===item.id?'table_info_selected':"table_info"}
									style={{userSelect:'none'}}>
									<div style={{marginRight:20, marginLeft:id===item.id?20:28}}>
										{
											item.picture?
												<img alt="" src={item.picture} style={{width:80, borderRadius:50}} />
												:
												<div style={{width:80, height:80, borderRadius:50, background:'#dcdfe3'}} />
										}
									</div>
									<div>
										<label style={{fontFamily:'semi', fontSize:16, cursor:'pointer'}}>
											{capitalizeText(`${item.name} ${item.lastName}`)}
										</label><br/>
										<label style={{fontFamily:'semi', cursor:'pointer'}}>
											{item.email}
										</label><br/>
										<label style={{fontFamily:'regular', cursor:'pointer'}}>
											{item.rol}
										</label><br/>
										<label style={{fontFamily:'regular', cursor:'pointer'}}>
											<small><b>{custom_id(item.rol, item.id)}</b></small>
										</label><br/>
									</div>
								</div>
							))
						}
					</div>
					:
					<LoadingData />
			}
		</div>
	);
};

const mapStateToProps = state =>({
	data:		state.Users.data,
	status:		state.Users.status,
	id:			state.Users.dataEdit.id,
	token:		state.PanelUser.token,
	page:		state.Users.page,
	records:	state.Users.records
})

export default connect(mapStateToProps, {changeView, updateUsers, getUsers})(Tables)