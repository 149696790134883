// import {useState} from 'react';
import {connect} from 'react-redux';
// Redux
import {changeView} from '../../store/redux/actions/Global';
// Values
import {StateView} from 'values';

const Tables = ({menuOption, setMenuOption, changeView}) => {

	const edit = (option) => {
		changeView(StateView.state3);
		setMenuOption(option);
	}

	return (
		<div className="card_info">
			<div onClick={() => edit('profile')} className={menuOption === 'profile' ? 'table_info_selected' : "table_info"} style={{userSelect:'none'}}> 
				<div>
					<label style={{margin: '12px', fontFamily:'semi', fontSize:16, cursor:'pointer'}}>Mi perfil</label><br/>
				</div>
			</div>

			<div onClick={() => edit('password')} className={menuOption === 'password' ? 'table_info_selected' : "table_info"} style={{userSelect:'none'}}> 
				<div>
					<label style={{margin: '12px', fontFamily:'semi', fontSize:16, cursor:'pointer'}}>Cambiar contraseña</label><br/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {changeView})(Tables)