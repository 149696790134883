import {urlApi, urlPath, new_post, new_get, new_update, new_delete} from '../api';
import {regex_names_one} from 'utils';

const url_inv_dataset = `${urlApi}${urlPath.investigation_datasets}`;
const url_inv_requests = `${urlApi}${urlPath.investigation_requests}`;
const url_inv_results = `${urlApi}${urlPath.investigation_results}`;
const irl_inv_researches = `${urlApi}${urlPath.investigation_researches}`;

export const get_investigation_datasets = (token, extra) => {
	return new_get(url_inv_dataset, token, extra);
}

export const post_investigation_dataset = (formdata, token) => {
	let myHeaders = new Headers();
	myHeaders.append('token', token)

	const request = {
		method:		"POST",
		body:		formdata,
		headers:	myHeaders
	}

	return fetch(url_inv_dataset, request);
}

export const update_investigation_dataset = (id, name, token) => {
	const obj = {name};

	return new_update(obj, `${url_inv_dataset}/${id}`, token);
}

export const delete_investigation_dataset = async(id, token) => {
	return new_delete(`${url_inv_dataset}/${id}`, token);
}

export const delete_investigation_image = async(id, token) => {
	return new_delete(`${url_inv_requests}/${id}`, token);
}

export const validateDatasetData = (name, pictures=null) => {
	return new Promise((resolve, reject) => {
		let success = true;
		let errors = [];

		if (!name.match(regex_names_one)) {
			success = false;
			errors.push('Nombre: Solo letras, numeros, espacios, guion bajo, espacio, punto, 2 a 300 caracteres son permitidos.');
		}

		if (pictures && pictures.length < 1) {
			success = false;
			errors.push('Imagenes: Agrega el conjunto de imagenes del conjunto de datos.');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}

export const validateRequestData = (retinopathyLevel, macularEdema) => {
	return new Promise((resolve, reject) => {
		let success = true;
		let errors = [];

		if (![0,1,2,3,4,5,6].includes(retinopathyLevel)) {
			success = false;
			errors.push('El nivel de retinopatia especificado.');
		}
		console.log(macularEdema)
		if (!['M0', 'M1', 'M2'].includes(macularEdema)) {
			success = false;
			errors.push('El edema macular especificado.');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}

export const validateResearchData = (data) => {
	return new Promise((resolve, reject) => {
		let success = true;
		let errors = [];

		if (isNaN(data.focusing) || (data.focusing < 0 || data.focusing > 5)) {
			success = false;
			errors.push('Enfoque: El valor debe ser numerico entre 0 y 5.');
		}
		if (isNaN(data.contrast) || (data.contrast < 0 || data.contrast > 5)) {
			success = false;
			errors.push('Contraste: El valor debe ser numerico entre 0 y 5.');
		}
		if (isNaN(data.glow) || (data.glow < 0 || data.glow > 5)) {
			success = false;
			errors.push('Billo: El valor debe ser numerico entre 0 y 5.');
		}
		if (isNaN(data.artifacts) || (data.artifacts < 0 || data.artifacts > 5)) {
			success = false;
			errors.push('Artefactos: El valor debe ser numerico entre 0 y 5.');
		}
		if (isNaN(data.optical_disc) || (data.optical_disc < 0 || data.optical_disc > 5)) {
			success = false;
			errors.push('Disco optico: El valor debe ser numerico entre 0 y 5.');
		}
		if (isNaN(data.taint) || (data.taint < 0 || data.taint > 5)) {
			success = false;
			errors.push('Macula: El valor debe ser numerico entre 0 y 5.');
		}
		if (isNaN(data.upper_arch) || (data.upper_arch < 0 || data.upper_arch > 5)) {
			success = false;
			errors.push('Arcada superior: El valor debe ser numerico entre 0 y 5.');
		}
		if (isNaN(data.lower_arch) || (data.lower_arch < 0 || data.lower_arch > 5)) {
			success = false;
			errors.push('Arcada inferior: El valor debe ser numerico entre 0 y 5.');
		}
		if (typeof data.waterfall !== 'boolean') {
			success = false;
			errors.push('Catarata: Valor no valido');
		}
		if (typeof data.hemorrhage !== 'boolean') {
			success = false;
			errors.push('Hemorragia vítrea: Valor no valido');
		}
		if (typeof data.injury !== 'boolean') {
			success = false;
			errors.push('Lesiones predisponentes: Valor no valido');
		}
		if (typeof data.breaking_off !== 'boolean') {
			success = false;
			errors.push('Desprendimiento de la retina por ruptura: Valor no valido');
		}
		if (typeof data.hypertensive !== 'boolean') {
			success = false;
			errors.push('Retinopatía hipertensiva: Valor no valido');
		}
		if (typeof data.membrane !== 'boolean') {
			success = false;
			errors.push('Membrana epirretiniana: Valor no valido');
		}
		if (typeof data.macular_hole !== 'boolean') {
			success = false;
			errors.push('Agujero macular: Valor no valido');
		}
		if (typeof data.drusen !== 'boolean') {
			success = false;
			errors.push('Drusas/Degeneración macular asociada a la edad: Valor no valido');
		}
		if (typeof data.corroidal !== 'boolean') {
			success = false;
			errors.push('Nevo coroideo: Valor no valido');
		}
		if (typeof data.excavation !== 'boolean') {
			success = false;
			errors.push('Excavación de nervio óptico amplia: Valor no valido');
		}
		if (typeof data.myopia !== 'boolean') {
			success = false;
			errors.push('Miopía degenerativa: Valor no valido');
		}
		if (typeof data.occlusion !== 'boolean') {
			success = false;
			errors.push('Oclusión vascular: Valor no valido');
		}
		if (typeof data.hyalosis !== 'boolean') {
			success = false;
			errors.push('Hialosis asteroidea: Valor no valido');
		}
		if (typeof data.vascular !== 'boolean') {
			success = false;
			errors.push('Otros trastornos vasculares: Valor no valido');
		}
		if (typeof data.retina !== 'boolean') {
			success = false;
			errors.push('Otros trastornos de la retina: Valor no valido');
		}
		if (typeof data.optic_nerve !== 'boolean') {
			success = false;
			errors.push('Otros trastornos del nervio óptico: Valor no valido');
		}

		return success ? resolve({success}) : reject({success, errors, message: 'The data to send is invalid'});
	});
}

export const generate_research_folder_in_storage = (reasearch_name, type, token) => {
	const obj = {
		reasearch_name,
		type
	};

	return new_post(obj, `${urlApi}directory`, token);
}

export const upload_research_folder_images_in_storage = (formdata, token) => {
	let myHeaders = new Headers();
	myHeaders.append('token', token)

	const request = {
		method:"POST",
		body: formdata,
		headers: myHeaders
	}

	return fetch(`${urlApi}files/upload`, request);
}

export const post_investigation_request = (image_name, image_path, dataset_id, token) => {
	const data = {
		image_path, dataset_id
	};// image_name, 

	return new_post(data, url_inv_requests, token);
}

export const updateRequest = (retinopathy_level, macular_edema, grader_id, investigation_request, token) => {
	const data = {
		retinopathy_level,
		macular_edema,
		grader_id,
		investigation_request
	};

	return new_post(data, url_inv_results, token);
}

export const updateResearch = (data, token) => {

	return new_post(data, irl_inv_researches, token);
}