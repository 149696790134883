import {useState, useEffect}   from 'react';
import {Container}	from '@material-ui/core';
import {connect}	from 'react-redux';

// Redux
import {changeView, cleanData} from '../../store/redux/actions/Global';
// Values
import {StateView}   from 'values';

const Form = ({stateView, data, request_index}) => {

	const [requestData, setRequestData]	= useState(null);

	const convert_edema = (edema_level) => {
		switch (edema_level) {
			case 1:
			case '1':
				return 'B';
			case 2:
			case '2':
				return 'C';
			default:
				return 'A';
		}
	}

	useEffect(() => {
		setRequestData(data.get_requests[request_index]);
	},[stateView, data.id, request_index])

	return (
		<div className="card_info">
			<div style={{height:30}} />

			<div className="card_scroll">
				<Container>
					<div style={{backgroundColor:'#f1f4f8', paddingTop:15, paddingBottom:15}}>
						<Container>
							{
								(stateView===StateView.state3 && requestData) &&
								<div>
									<div style={{display:'flex', margin:'4px 0px'}}>
										<b style={{margin:'auto'}}>{requestData.image_name}</b>
									</div>
									<div style={{display:'flex', justifyContent: 'center', margin:'4px 0px'}}>
										<img src={requestData.image_url} style={{width:'50%'}}></img>
									</div>
									<div style={{display:'flex', margin:'4px 0px'}}>
										{
											requestData.get_grades.length > 0 ?
												<table style={{width:'100%'}}>
													{/* <tr>
														<th>Medico Investigador</th>
														<th>Nivel de Retinopatia</th>
														<th>Edema Macular</th>
													</tr> */}
													{
														requestData.get_grades.sort((a, b) => a.id - b.id).map((grade, i) => {
															return (
																<tr>
																	<td>G{grade.get_grader.id} - {grade.retinopathy_level}, {convert_edema(grade.macular_edema)}</td>
																	{/* <td>G{grade.get_grader.id}</td>
																	<td>{grade.retinopathy_level}</td>
																	<td>{convert_edema(grade.macular_edema)}</td> */}
																</tr>
															);
														})
													}
												</table>
												:
												<b style={{width:'100%', textAlign:'center'}}>Sin valoraciones</b>
										}
									</div>
								</div>
							}
						</Container>
					</div>

				</Container>
				<div style={{height:30}} />
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	stateView:		state.Global.stateView,
	token:			state.PanelUser.token,
	data:			state.InvestigationDatasets.dataEdit,
	request_index: state.InvestigationDatasets.request_index,
})

export default connect(mapStateToProps, {changeView, cleanData})(Form)