import {stateData, reduxState} from 'values';

export const getHospitals = (data, request, page) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_hospitals,
            data:       data,
            status:     stateData.state2,
            records:    request,
            page:       page,
        })
    }
}

export const getAllHospitals = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_all_hospitals,
            data:       data,
        })
    }
}

export const updateHospitals = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.update_hospitals,
            data:       data,
        })
    }
}