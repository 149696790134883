import ReactLoading from 'react-loading';

// Values
import {Ic_next_white} from 'values';

const ButtonDownload = ({text='Imprimir', onSubmit, loading}) => {
    return (
        <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
            <button className="form_button" onClick={onSubmit}>
                {text}
                {
                    !loading?
                        <img alt="" src={Ic_next_white} style={{position:'absolute', right:15, top:15}} />
                    :
                        <div style={{position:'absolute', right:15, top:10}}>
                            <ReactLoading type={"spinningBubbles"} color={"white"} style={{fill:'white', height:30, width:30}} />
                        </div>
                }
            </button>
        </div>
    );
};

export default ButtonDownload;