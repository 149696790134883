import {Tooltip} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import {Ic_checked, Ic_no_checked, Ic_checked_disabled, Img_warning} from '../../values';

const CustomTooltip = withStyles((theme) => ({
	tooltip: {
		background: "rgba(0, 0, 0, 0.9)",
		width: 325,
		height: 175,
		borderRadius: 9,
		color: "#fff",
		fontSize: 12,
		fontFamily: "regular",
	},
}))(Tooltip);

const CheckLevel = ({check, value, setCheck, text, blockData}) => {
	let image = Ic_no_checked;

	if (check) {
		if (blockData) {
			image = Ic_checked_disabled;
		} else {
			image = Ic_checked;
		}
	}

	return(
		<div style={{display:'flex', flexWrap: 'wrap', alignItems:'center', margin: '6px 8px'}}>
			<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
				<img alt='' src={image} onClick={() => setCheck(value)} style={{width:25, cursor:'pointer', userSelect:'none'}} />
			</div>
			<label style={{width: '100%', fontSize:15, textAlign: 'center'}}>{text}</label>
		</div>
	)
}

const LevelRetinopathyV2 = ({levelValue, setLevelValue, blockData=false}) => {

	const retinopathy_level_key_shortcuts = () => {
		return (
			<div>
				Para definir el nivel de retinopatía diabética puedes colocar el puntero sobre el valor correspondiente o hacer uso de las teclas del “0” al “6”
				<ul>
					<li>R0 = No aparente</li>
					<li>R1 = NP leve</li>
					<li>R2 = NP moderada</li>
					<li>R3 = NP severa</li>
					<li>R4 = Proliferativa</li>
				</ul>
			</div>
		);
	}

	return (
		<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
			<h3 style={{width: '100%'}}>
				Retinopatía
				{
					!blockData &&
					<CustomTooltip title={retinopathy_level_key_shortcuts()} placement="right">
						<img alt="" src={Img_warning} style={{ width: 16, marginLeft: 8 }} />
					</CustomTooltip>
				}
			</h3>
			<CheckLevel check={levelValue === 0 ? true : false} value={0} setCheck={setLevelValue} blockData={blockData} text='R0' />
			<CheckLevel check={levelValue === 1 ? true : false} value={1} setCheck={setLevelValue} blockData={blockData} text='R1' />
			<CheckLevel check={levelValue === 2 ? true : false} value={2} setCheck={setLevelValue} blockData={blockData} text='R2' />
			<CheckLevel check={levelValue === 3 ? true : false} value={3} setCheck={setLevelValue} blockData={blockData} text='R3' />
			<CheckLevel check={levelValue === 4 ? true : false} value={4} setCheck={setLevelValue} blockData={blockData} text='R4' />
			<CheckLevel check={levelValue === 5 ? true : false} value={5} setCheck={setLevelValue} blockData={blockData} text='Sin foto R5' />
			<CheckLevel check={levelValue === 6 ? true : false} value={6} setCheck={setLevelValue} blockData={blockData} text='No valorable R6' />

		</div>
	);
};

export default LevelRetinopathyV2;