import {reduxState, StateView}  from 'values';

export const changeView = (view) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.view,
            view:       view,
        })
    }
}

export const cleanData = () =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.view,
            view:       StateView.state1,
        })
        dispatch({
            type:       reduxState.update_hospitals,
            data:       [],
        })
        dispatch({
            type:       reduxState.update_users,
            data:       [],
        })
        dispatch({
            type:       reduxState.update_patients,
            data:       [],
        })

        dispatch({
            type:       reduxState.update_request,
            data:       [],
        })

        dispatch({
            type:       reduxState.update_grader,
            data:       [],
        })

        dispatch({
            type:       reduxState.update_research,
            data:       [],
        })
    }
}