import {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Tabs, Tab, Box} from '@material-ui/core';
import {connect} from 'react-redux';
import {formatDate3, capitalizeText} from '../../utils';
import XLSX from 'xlsx';
import {toast} from 'react-toastify';

const useStyles = makeStyles(theme => ({
	fc_fixed: {
		position: 'sticky',
		left: '0',
		backgroundColor: '#D1D4D8',
		zIndex: '2',
	},
}));

const Form = ({stateView, data}) => {
	const classes = useStyles();

	const [tabValue, setTabValue] = useState(0);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const generateFile = () => {

		let graders_clasification = [];
		let graders_quality = [];

		for (let request of data.get_requests) {
			for (let grade of request.get_grades) {
				if (!graders_clasification.find(g => g.id == grade.grader_id)) {
					graders_clasification.push({id: grade.grader_id});
				}
			}
			for (let grade of request.get_qualities) {
				if (!graders_quality.find(g => g.id == grade.grader_id)) {
					graders_quality.push({id: grade.grader_id});
				}
			}
		}

		const radioButtons = document.getElementsByName('dataset_file_type');

		let file_extension = '';

		for (const radioButton of radioButtons) {
			if (radioButton.checked) {
				file_extension = radioButton.value;
				break;
			}
		}

		const clasification_table = document.getElementById(`clasification_table_${data.id}`);
		const quality_table = document.getElementById(`quality_table_${data.id}`);

		if (file_extension === 'xlsx') {
			const xlsx_workbook = XLSX.utils.book_new();
			let xlsx_downloadable = false;

			if (graders_clasification.length > 0 && clasification_table !== null) {
				const sheet_one = XLSX.utils.table_to_sheet(clasification_table);
				XLSX.utils.book_append_sheet(xlsx_workbook, sheet_one, 'Clasificacion');
				xlsx_downloadable = true;
			}

			if (graders_quality.length > 0 && quality_table !== null) {
				const sheet_two = XLSX.utils.table_to_sheet(quality_table);
				XLSX.utils.book_append_sheet(xlsx_workbook, sheet_two, 'Calidad');
				xlsx_downloadable = true;
			}

			if (xlsx_downloadable) {
				XLSX.writeFile(xlsx_workbook, `${capitalizeText(data.name)} - ${formatDate3(data.created_at)}.xlsx`);
			} else {
				toast.warn('Dataset sin informacion aun.');
			}
		} else if (file_extension === 'csv') {

			if (graders_clasification.length > 0 || graders_quality.length > 0) {
				if (graders_clasification.length > 0 && clasification_table !== null) {
					const csv_grade_workbook = XLSX.utils.book_new();
					const sheet_one = XLSX.utils.table_to_sheet(clasification_table);
					XLSX.utils.book_append_sheet(csv_grade_workbook, sheet_one, 'Clasificacion');
					XLSX.writeFile(csv_grade_workbook, `Graduacion - ${capitalizeText(data.name)} - ${formatDate3(data.created_at)}.csv`);
				}

				if (graders_quality.length > 0 && quality_table !== null) {
					const csv_quality_workbook = XLSX.utils.book_new();
					const sheet_two = XLSX.utils.table_to_sheet(quality_table);
					XLSX.utils.book_append_sheet(csv_quality_workbook, sheet_two, 'Calidad');
					XLSX.writeFile(csv_quality_workbook, `Calidad - ${capitalizeText(data.name)} - ${formatDate3(data.created_at)}.csv`);
				}
			} else {
				toast.warn('Dataset sin informacion aun.');
			}
		}
	}

	const translate_macular_edema = (macular_edema) => {
		switch(macular_edema) {
			case 0:
			case '0':
				return 'A';
			case 1:
			case '1':
				return 'B';
			case 2:
			case '2':
				return 'C';
		}
	}

	function CustomTabPanelClasification(props) {
		const { value, index, requests, dataset_id } = props;

		let graders = [];

		for (let request of requests) {
			for (let grade of request.get_grades) {
				if (!graders.find(g => g.id == grade.grader_id)) {
					graders.push({id: grade.grader_id, name: `${grade.get_grader.name} ${grade.get_grader.last_name}`});
				}
			}
		}
	  
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				style={{overflowX: 'auto'}}>
					{
						value === index && graders.length == 0 ?
							<b>Sin graduaciones.</b>
							:
							<table style={{margin: '8px auto', width: '100%', borderCollapse: 'collapse'}} id={`clasification_table_${dataset_id}`}>
								<thead>
									<tr>
										<th rowSpan={2} className={classes.fc_fixed}>Solicitud</th>
										<th colSpan={graders.length}>Nivel de Retinopatía</th>
										<th colSpan={graders.length}>Edema Macular</th>
									</tr>
									<tr>
										{
											[...graders, ...graders].map((grader, i) => {
												return (
													<th key={i}>G{grader.id}</th>
												)
											})
										}
									</tr>
								</thead>
								<tbody>
									{
										requests.map((request, i) => {
											return (
												<tr key={i}>
													<th className={classes.fc_fixed}>{request.image_name}</th>
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_grades.find(grade => grade.grader_id == grader.id) ?
																			`${request.get_grades.find(grade => grade.grader_id == grader.id).retinopathy_level}` : ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_grades.find(grade => grade.grader_id == grader.id) ?
																			`${translate_macular_edema(request.get_grades.find(grade => grade.grader_id == grader.id).macular_edema)}` :
																			''
																	}
																</td>
															)
														})
													}
												</tr>
											)
										})
									}
								</tbody>
							</table>
					}
			</div>
		);
	}

	function CustomTabPanelQuality(props) {
		const { value, index, requests, dataset_id } = props;

		let graders = [];

		for (let request of requests) {
			for (let grade of request.get_qualities) {
				if (!graders.find(g => g.id == grade.grader_id)) {
					graders.push({id: grade.grader_id, name: `${grade.get_grader.name} ${grade.get_grader.last_name}`});
				}
			}
		}
	  
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				style={{overflowX: 'auto'}}>
					{
						value === index && graders.length == 0 ?
							<b>Sin valuaciones de calidad.</b>
							:
							<table style={{margin: '8px auto', width: '100%', borderCollapse: 'collapse'}} id={`quality_table_${dataset_id}`}>
								<thead>
									<tr>
										<th rowSpan={2} className={classes.fc_fixed}>Solicitud</th>
										<th colSpan={graders.length}>Enfoque</th>
										<th colSpan={graders.length}>Contraste</th>
										<th colSpan={graders.length}>Billo</th>
										<th colSpan={graders.length}>Artefactos</th>
										<th colSpan={graders.length}>Disco óptico</th>
										<th colSpan={graders.length}>Macula</th>
										<th colSpan={graders.length}>Arcada superior</th>
										<th colSpan={graders.length}>Arcada inferior</th>
										{/* <th colSpan={graders.length}>Artefactos visibles</th>
										<th colSpan={graders.length}>nitidez</th>
										<th colSpan={graders.length}>Macula y disco optico visibles</th>
										<th colSpan={graders.length}>Evaluable</th> */}
										<th colSpan={graders.length}>Catarata</th>
										<th colSpan={graders.length}>Hemorragia vítrea</th>
										<th colSpan={graders.length}>Lesiones predisponentes</th>
										<th colSpan={graders.length}>Desprendimiento de la retina por ruptura</th>
										<th colSpan={graders.length}>Retinopatía hipertensiva</th>
										<th colSpan={graders.length}>Membrana epirretiniana</th>
										<th colSpan={graders.length}>Agujero macular</th>
										<th colSpan={graders.length}>Drusas/Degeneración macular asociada a la edad</th>
										<th colSpan={graders.length}>Nevo coroideo</th>
										<th colSpan={graders.length}>Excavación de nervio óptico amplia</th>
										<th colSpan={graders.length}>Miopía degenerativa</th>
										<th colSpan={graders.length}>Oclusión vascular</th>
										<th colSpan={graders.length}>Hialosis asteroidea</th>
										<th colSpan={graders.length}>Otros trastornos vasculares</th>
										<th colSpan={graders.length}>Otros trastorno de la retina</th>
										<th colSpan={graders.length}>Otros trastornos del nervio óptico</th>
									</tr>
									<tr>
										{
											[...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders, ...graders].map((grader, i) => {
												return (
													<th key={i} style={{padding: '0px 5px'}}>G{grader.id}</th>
												)
											})
										}
									</tr>
								</thead>
								<tbody>
									{
										requests.map((request, i) => {
											return (
												<tr key={i}>
													<th className={classes.fc_fixed}>{request.image_name}</th>
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			`${request.get_qualities.find(grade => grade.grader_id == grader.id).focusing}` : ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			`${request.get_qualities.find(grade => grade.grader_id == grader.id).contrast}` : ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			`${request.get_qualities.find(grade => grade.grader_id == grader.id).glow}` : ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			`${request.get_qualities.find(grade => grade.grader_id == grader.id).artifacts}` : ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			`${request.get_qualities.find(grade => grade.grader_id == grader.id).optical_disc}` : ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			`${request.get_qualities.find(grade => grade.grader_id == grader.id).taint}` : ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			`${request.get_qualities.find(grade => grade.grader_id == grader.id).upper_arch}` : ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			`${request.get_qualities.find(grade => grade.grader_id == grader.id).lower_arch}` : ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).waterfall) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).hemorrhage) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).injury) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).breaking_off) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).hypertensive) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).membrane) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).macular_hole) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).drusen) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).corroidal) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).excavation) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).myopia) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).occlusion) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).hyalosis) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).vascular) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).retina) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
													{
														graders.map((grader, i) => {
															return (
																<td key={i}>
																	{
																		request.get_qualities.find(grade => grade.grader_id == grader.id) ?
																			Boolean(request.get_qualities.find(grade => grade.grader_id == grader.id).optic_nerve) ? 'Si' : 'No'
																			: ''
																	}
																</td>
															)
														})
													}
												</tr>
											)
										})
									}
								</tbody>
							</table>

					}
			</div>
		);
	}

	useEffect(() => {
		
	},[stateView, data.id])

	return (
		<div className="card_info">
			<div style={{height:30}} />

			<div className="card_scroll">
				<Container>
					<div style={{backgroundColor:'#f1f4f8', paddingTop:15, paddingBottom:15}}>
						<Container>
							<Box sx={{ width: '100%' }}>
								<Tabs
									value={tabValue}
									onChange={handleTabChange}
									textColor="secondary"
									variant="fullWidth"
								>
									<Tab value={0} label="Clasificación" />
									<Tab value={1} label="Calidad" />
								</Tabs>
							</Box>
							<CustomTabPanelClasification value={tabValue} index={0} requests={data.get_requests} dataset_id={data.id} />
							<CustomTabPanelQuality value={tabValue} index={1} requests={data.get_requests} dataset_id={data.id} />

							<div style={{display: 'flex', alignItems: 'center', margin: '8px'}}>
								<div>
									<input type="radio" id="xlsx" name="dataset_file_type" value="xlsx" readOnly checked/>
									<label htmlFor="xlsx">.XLSX</label><br/>
									<input type="radio" id="csv" name="dataset_file_type" value="csv" readOnly/>
									<label htmlFor="csv">.CSV</label><br/>
								</div>
								<button className="form_button" style={{margin: '8px'}} onClick={generateFile}>
									Descargar
								</button>
							</div>
						</Container>
					</div>

				</Container>
				<div style={{height:30}} />
			</div>
		</div>
	);
};

const mapStateToProps = state =>({
	stateView:	state.Global.stateView,
	data:		state.InvestigationDatasets.dataEdit,
})

export default connect(mapStateToProps, {})(Form)