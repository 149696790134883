import {useEffect}  from 'react';
import {connect}    from 'react-redux';

// Redux
import {getResearch}   from '../../store/redux/actions/Research';
// Components
import Header           from '../global/Header';
import Tables           from './Tables';
import Form             from './Form';
// Values
import {StateView}  from 'values';
// Api
import {getData}    from 'utils/researches';

const Research = ({stateView, token, page, id, getResearch}) => {
    const GetData = async() =>{
        const extra = `?searchCompleted=false&page=${page}&limit=10`
        const res = await getData(token, extra)
        try {
            getResearch(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
        } catch (error) { }
    }
    useEffect(() =>{
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    })
    return (
        <div style={{display:'flex', position:'absolute', top:20, left:180}}>
            <div className="container_info">
                <Header title={'Solicitudes de investigación'} add={false} />
                <Tables />
            </div>
            {
                stateView===StateView.state3?
                    <div className="container_card">
                        <Form />
                    </div>
                :
                    <div/>
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.Global.stateView,
    token:          state.PanelUser.token,
    page:           state.Research.page,
    id:             state.PanelUser.id
})

export default connect(mapStateToProps, {getResearch})(Research)
