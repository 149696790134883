import {useState}   from 'react';
import {connect}    from 'react-redux';
// Redux
import {changeView} from '../../store/redux/actions/Global';
import {updatePatients, getPatients}    from '../../store/redux/actions/Patients';
// Components
import InputSearch  from '../global/InputSearch';
import LoadingData  from '../global/LoadingData';
// Unike key
import {formatDate2, capitalizeText}  from '../../utils'; // generateUniqueKey, 
// Values
import {StateView, stateData} from '../../values';
// Api
import {getSearchData, getData}  from '../../utils/patients';

const Tables = ({data, status, token, page, records, id, medicalCenterId, changeView, updatePatients, getPatients}) => {
	const [search, setSearch] = useState('')

	const searchInfo = async() => {
		if (search) {
			const extra = `?search=${search}&limit=10`;
			const res = await getData(token, extra);
			getPatients(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1);
		} else {
			const extra = `?page=1&limit=10`;
			const res = await getData(token, extra);
			getPatients(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1);
		}
	}

	const GetData = async(event, newPage) => {
		const extra = `?page=${newPage+1}&limit=10`;
		const res = await getData(token, extra);
		getPatients(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1);
	}

	const edit = (item) => {
		changeView(StateView.state3);
		updatePatients(item);
	}
	return (
		<div className="card_info">
			<InputSearch onClickSearch={searchInfo}
				value={search} setData={setSearch}
				page={page} records={records} setPage={GetData} />

			{
				status===stateData.state2?
					<div className="card_scroll">
						{
							data&&
							data.map((patient, j) => (
								<div key={j} onClick={() => edit(patient)}
									className={id===patient.id?'table_info_selected':"table_info"}
									style={{userSelect:'none'}}>
									<div style={{marginLeft:50}}>
										<label style={{fontFamily:'semi', fontSize:16, cursor:'pointer'}}>
											{capitalizeText(`${patient.name} ${patient.lastName}`)}
										</label><br/>
										<label style={{fontFamily:'semi', cursor:'pointer'}}>{patient.email}</label><br/>
										<label style={{fontFamily:'regular', cursor:'pointer'}}>{formatDate2(patient.birthdate)}</label><br/>
									</div>
								</div>
							))
						}
					</div>
				:
				<LoadingData />
			}
		</div>
	);
};

const mapStateToProps = state => ({
	data:				state.Patients.data,
	status:				state.Patients.status,
	id:					state.Patients.dataEdit.id,
	token:				state.PanelUser.token,
	page:				state.Patients.page,
	records:			state.Patients.records,
	medicalCenterId:	state.PanelUser.medicalCenterId
})

export default connect(mapStateToProps, {changeView, updatePatients, getPatients})(Tables)