import {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {connect}    from 'react-redux';

import './App.css';
import './css/Login.css';
import './fonts/Fonts.css';

// Components
import RestorePassword		from './components/restore_password/RestorePassword';
import LoadingScreen		from './components/global/LoadingScreen';
import Login				from './components/login/Login';
import Menu					from './components/global/Menu';
import Hospitals			from './components/hospitals/Hospitals';
import Users				from './components/users/Users';
import Patients				from './components/patients/Patients';
import Request			 	from './components/request/Request';
import GraduationRequest	from './components/graduation_request/GraduationRequest';
import Research				from './components/research/Research';
import ResearchAdmin		from './components/research_admin/Research';
import Performance			from './components/performance/Performance';
import Account				from './components/account/Account';

import Admin_Request		from './components/admin_request/Request';
// INVESTIGATOR
import InvestigationDatasets from './components/investigation_datasets/InvestigationDatasets';
import InvestigationClasification from './components/investigation_clasification/InvestigationClasification';
import InvestigationQuality from './components/investigation_quality/InvestigationQuality';
import InvestigationDatabases from './components/investigation_databases/InvestigationDatabases';
// GRADER INVESTIGATOR
import InvestigationGraduationRequest from './components/investigation_graduation_request/InvestigationGraduationRequest';
import InvestigationQualityRequest from './components/investigation_quality_request/InvestigationQualityRequest';
import {UserRoles} from './values';

const Default = () => {
	return( <div></div> )
}

function App({userRol}) {
	const [loading, setLoading]	= useState(false);
	const [flag, setFlag]		= useState(true);
	const [rol, setRol]			= useState('');

	const [roles]				= useState(UserRoles);
	const [adminRoutes]			= useState(['/hospitals', '/users', '/requestAdmin', '/reports', '/account']); // '/researchAdmin'
	const [investigatorRoutes]	= useState(['/datasets', '/clasification', '/quality', '/databases', '/account']);
	const [graderInvRoutes]		= useState(['/datasets/grade', '/datasets/quality', '/account']);
	const [graderRoutes]		= useState(['/grader', '/account']); // '/research'
	const [photographerRoutes]	= useState(['/patients', '/request', '/account']);

	const redirect = (permissions, force=false) => {
		if (window.location.pathname === '/' || force) {
			if		(permissions==='ADMINISTRADOR')				{window.location.href = "/hospitals";}
			else if	(permissions==='INVESTIGADOR')				{window.location.href = "/datasets";}
			else if	(permissions==='GRADUADOR INVESTIGADOR')	{window.location.href = "/datasets/grade";}
			else if	(permissions==='GRADUADOR')					{window.location.href = "/grader";}
			else if	(permissions==='FOTÓGRAFO')					{window.location.href = "/patients";}
		}
	}

	const verifyUser = async() => {
		if (roles.includes(userRol)) {
			setRol(userRol)
			setFlag(true)
			setTimeout(() =>
			redirect(userRol)
			, 2000)
		} else {
			setFlag(false)
			setTimeout(() =>
				setLoading(true)
			, 2000)
		}
	}

	useEffect(() => {
		if (
			(userRol === 'ADMINISTRADOR' && !adminRoutes.includes(window.location.pathname)) ||
			(userRol === 'INVESTIGADOR' && !investigatorRoutes.includes(window.location.pathname)) ||
			(userRol === 'GRADUADOR INVESTIGADOR' && !graderInvRoutes.includes(window.location.pathname)) ||
			(userRol === 'GRADUADOR' && !graderRoutes.includes(window.location.pathname)) ||
			(userRol === 'FOTÓGRAFO' && !photographerRoutes.includes(window.location.pathname))
		) {
			redirect(userRol, true);
		}
	})

	useEffect(() => {
		verifyUser()
	},[userRol])

	return (
		<Router>
			<ToastContainer />
			<Route exact path="/"						component={loading?Login:LoadingScreen} />
			<Route exact path="/restore-password/:id"	component={RestorePassword} />
			<Menu />

			{
				flag ?
				<>
					<Menu />

					{
						rol==='ADMINISTRADOR' ?
							<>
								<Route exact path="/hospitals"		component={Hospitals} />
								<Route exact path="/users"			component={Users} />
								<Route exact path="/requestAdmin"	component={Admin_Request} />
								{/* <Route exact path="/researchAdmin"	component={ResearchAdmin} /> */}
								<Route exact path="/reports"		component={Performance} />
							</>
						:
						rol==='INVESTIGADOR' ?
							<>
								<Route exact path="/datasets"		component={InvestigationDatasets} />
								<Route exact path="/clasification"	component={InvestigationClasification} />
								<Route exact path="/quality"		component={InvestigationQuality} />
								<Route exact path="/databases"		component={InvestigationDatabases} />
							</>
						:
						rol==='GRADUADOR INVESTIGADOR' ?
							<>
								<Route exact path="/datasets/grade"		component={InvestigationGraduationRequest} />
								<Route exact path="/datasets/quality"	component={InvestigationQualityRequest} />
							</>
						:
						rol==='FOTÓGRAFO' ?
							<>
								<Route exact path="/patients"		component={Patients} />
								<Route exact path="/request"		component={Request} />
							</>
						:
						rol==='GRADUADOR' ?
							<>
								<Route exact path="/grader"			component={GraduationRequest} />
								{/* <Route exact path="/research"		component={Research} /> */}
							</>
						: <></>
					}
					<Route exact path="/account"					component={Account} />
				</>
				:
				<>
					<Route path="*">
						<Default />
					</Route>
				</>
			}
		</Router>
	);
}

const mapStateToProps = state => ({
	userRol: state.PanelUser.rol,
})

export default connect(mapStateToProps, {}) (App)
