import React, { useState, useEffect } from "react";
import { Container, Grid, Modal } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import ReactLoading from "react-loading";

// Redux
import {cleanData} from "../../store/redux/actions/Global";
// Components
import DragImage from "./../graduation_request/DragImage";
import ButtonGrade from "../global/ButtonGrade";
import Carousel from "./../graduation_request/Carousel";
import LevelRetinopathyV2 from "./../graduation_request/LevelRetinopathyV2";
import SelectEdemaV2 from "./../graduation_request/SelectEdemaV2";
import CloseModalButton from './../global/CloseModalButton';

import {capitalizeText}      from '../../utils';
// Values
import {Ic_next_white, toastText, translate_inputs} from "values";
import {validateRequestData, updateRequest} from "utils/investigation"

const useStyles = makeStyles((theme) => ({
	modal_container: {
		background: "#fff",
		height: "95vh",
		position: "relative"
	},
	container_image: {
		height: "95vh",
		width: "100%",
		background: "#000"
	},
	image_pagination: {
		position: 'absolute',
		bottom: '105px',
		marginBottom: 10,
		[theme.breakpoints.down('md')] : { // md => 960
			marginTop: 20,
			bottom: '0px',
			position: 'relative',
		},
	},
	enter_to_save: {
		// width: '100%',
		position: 'absolute',
		bottom: '80px',
		marginBottom: 10,
		[theme.breakpoints.down('md')] : { // md => 960
			bottom: '0px',
			position: 'relative',
		},
	},
	save_btn_wrapper: {
		width: '100%',
		position: 'absolute',
		bottom: '20px',
		[theme.breakpoints.down('md')] : { // md => 960
			position: 'relative',
		},
	},
	save_btn: {
		background: "#648CFA",
		margin: '15px 0px',
		paddingLeft: 15,
		position: 'relative',
		color: '#fff',
		border: 'none',
		cursor: 'pointer',
		width: '200px',
		height: '50px',
		fontSize: '17px',
		textAlign: 'left',
		fontFamily: 'semi',
		[theme.breakpoints.down('md')] : { // md => 960
			minWidth: 128,
			width: '85%',
		},
	},
}));

const Form = ({data, token, request_i, user_id, cleanData}) => {
	const classes = useStyles();

	// Modal
	const [open1, setOpen1] = useState(true);
	const [option, setOption] = useState(0);

	const [loading1, setLoading1] = useState(false);

	// GraderId
	const [retinopathyLevel, setRetinopathyLevel] = useState(0);
	const [macularEdema, setMacularEdema] = useState("M0");
	const [coments, setComents] = useState("");

	const nextPage = () => {
		const sum = option + 1;
		if (sum < data.get_requests.length) {
			setOption(sum);
		}
	};
	const backPage = () => {
		const subtraction = option - 1;
		if (subtraction >= 0) {
			setOption(subtraction);
		}
	};

	const keyEvents = (e) => {
		if (e.keyCode === 13) { // "Enter" key
			let grade = data.get_requests[option].get_grades.find(grade => grade.grader_id == user_id);
			if (grade === undefined) {
				onSubmit(data.get_requests[option].id);
			}
		} else if (e.keyCode === 27) { // "Esc" key
			closeModal();
		} else if (e.keyCode === 37 || e.keyCode === 39) {
			setRetinopathyLevel(0);
			setMacularEdema("M0");
			if (e.keyCode === 37) { // "Left Arrow" key
				backPage();
			} else if (e.keyCode === 39) { // "Right Arrow" key
				nextPage();
			}
		} else if ((e.keyCode >= 48 && e.keyCode <= 54) || (e.keyCode >= 96 && e.keyCode <= 102)) { // Keys to set retinopathy level
			setRetinopathyLevel(Number(e.key));
		} else if (e.keyCode >= 65 && e.keyCode <= 67) { // Keys to set macular edema
			let macularEdemaValue;
			if (e.keyCode === 65) {
				macularEdemaValue = 'M0';
			} else if (e.keyCode === 66) {
				macularEdemaValue = 'M1';
			} else if (e.keyCode === 67) {
				macularEdemaValue = 'M2';
			}
			setMacularEdema(macularEdemaValue);;
		}
	};

	const onSubmit = async (request_id) => {
		setLoading1(true);
		
		validateRequestData(retinopathyLevel, macularEdema)
		.then(_ => {
			return updateRequest(retinopathyLevel, macularEdema, user_id, request_id, token);
		})
		.then(response => {return response.json();})
		.then(json_response => {
			if (!json_response.success) {
				throw json_response;
			}

			data.get_requests.find(req => req.id == request_id).get_grades.push(json_response.data);
			setLoading1(false);
			// cleanData();
			setRetinopathyLevel(0);
			setMacularEdema("M0");
			toast.success(toastText.update);
		})
		.catch(error => {
			console.log(error);
			setLoading1(false);

			if (error.errors && error.message && error.message === 'The data to send is invalid') { // Manejo de errores en validaciones locales
				for (let value of error.errors) {
					toast.warn(`${toastText.inputNotValid}${value}`);
				}
			} else if (error.errors && error.message && error.message === 'The received data was invalid.') { // Error en los datos enviados al servidor
				if (error.errors) {
					toast.warn(
						<div>
							{toastText.inputNotValid}
							<ul>
								{
									Object.keys(error.errors).map((err, i) => {
										return (<li key={i}>{translate_inputs[err]}: {error.errors[err].join(', ')}</li>)
									})
								}
							</ul>
						</div>
					);
				} else {
					toast.warn(toastText.warning);
				}
			} else { // Error inesperado
				toast.warn(toastText.warning);
			}
		})
	};

	const closeModal = () => {
		cleanData();
		setOpen1(false);
	};

	useEffect(() => {
		setOption(request_i >= 0 && request_i <= data.get_requests.length ? request_i : 0);
		setOpen1(true);
		setRetinopathyLevel(0);
		setMacularEdema("M0");
		setComents("");
	}, [data]);

	return (
		<div>

			<Modal
				open={open1}
				style={{display: "flex", alignItems: "center", justifyContent: "center", paddinfRight: 0}}
				onClose={() => closeModal()}
				onKeyDown={(e) => keyEvents(e)}
			>
				<Container maxWidth="xl">
					<div className={classes.modal_container}>
						<Container maxWidth="xl">
							<Grid container>
								<Grid item xs={3} style={{position: "relative"}}>

									{
										data.get_requests.map((request, i) => {
											let request_graded = request.get_grades.find(grade => grade.grader_id == user_id);
											return (
												<React.Fragment key={i}>
													<div class="card_scroll" style={{display: option === i ? "block" : "none", height:'95vh'}}>
														<LevelRetinopathyV2
															levelValue={request_graded ? Number(request_graded.retinopathy_level) : retinopathyLevel}
															setLevelValue={request_graded ? () => {} : setRetinopathyLevel}
															blockData={request_graded ? true : false} />

														<SelectEdemaV2
															value={request_graded ? `M${request_graded.macular_edema}` : macularEdema}
															setData={request_graded ? () => {} : setMacularEdema}
															blockData={request_graded ? true : false} />

														<div className={classes.image_pagination}>
															<h4 style={{margin: '0px'}}>Imagen {i+1} de {data.get_requests.length}</h4>
														</div>

														{
															!request_graded &&
															<>
																<div className={classes.enter_to_save}>
																	<small style={{fontSize: '10px'}}>* Presiona "Enter" para guardar</small>
																</div>
																<div className={classes.save_btn_wrapper}>
																	<button
																		onClick={() => onSubmit(request.id)}
																		className={classes.save_btn}
																	>
																		{"Guardar"}
																		{!loading1 ? (
																			<img alt="" src={Ic_next_white} style={{ position: "absolute", right: 15, top: 15 }} />
																		) : (
																			<div style={{ position: "absolute", right: 15, top: 10 }}>
																				<ReactLoading
																					type={"spinningBubbles"}
																					color={"white"}
																					style={{ fill: "white", height: 30, width: 30 }}
																				/>
																			</div>
																		)}
																	</button>
																</div>
															</>
														}
													</div>
												</React.Fragment>
											)
										})
									}
								</Grid>
								<Grid item xs={9}>

									<CloseModalButton
										closeModal={closeModal}
										/>

									<div className={classes.container_image}>
										{
											data.get_requests.map((request, i) => {
												return (
													<div style={{display: option === i ? "block" : "none"}} key={i}>
														<Carousel
															text={`${request.image_name} - ${capitalizeText(data.name)}`}
															back={i > 0 ? i-1 : 0}
															next={i < data.get_requests.length-1 ? i+1 : data.get_requests.length-1}
															onClick={setOption} />
														<DragImage image={request.image_url} blockData={"disabled"} />
													</div>
												)
											})
										}
									</div>
								</Grid>
							</Grid>
						</Container>
					</div>
				</Container>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	token:		state.PanelUser.token,
	data:		state.InvestigationDatasets.dataEdit,
	request_i:	state.InvestigationDatasets.request_index,
	user_id:	state.PanelUser.id,
});

export default connect(mapStateToProps, {cleanData})(Form);