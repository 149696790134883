import ReactLoading from 'react-loading';
import {Ic_next_white} from 'values';

const Button = ({onSubmit, loading}) => {
    return (
        <div style={{textAlign:'right'}}>
            <button style={{marginTop:40}} className="login_button_restore" onClick={onSubmit}>
                {"Restablecer"}
                {
                    !loading?
                        <img alt="" src={Ic_next_white} style={{position:'absolute', right:15, top:15}} />
                    :
                        <div style={{position:'absolute', right:15, top:10}}>
                            <ReactLoading type={"spinningBubbles"} color={"white"} style={{fill:'white', height:30, width:30}} />
                        </div>
                }
            </button>
        </div>
    );
};

export default Button;