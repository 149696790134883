import {useState}   from 'react';
import {connect}    from 'react-redux';
// Redux
import {changeView} from '../../store/redux/actions/Global';
import {getInvestigationDatasets, updateInvestigationDatasets} from '../../store/redux/actions/InvestigationDatasets';
// Components
import InputSearch  from '../global/InputSearch';
import LoadingData  from '../global/LoadingData';
// Unike key
import {formatDate1, capitalizeText}      from '../../utils';
// Values
import {StateView, stateData}   from '../../values';
// Api
import {get_investigation_datasets}  from '../../utils/investigation'; // getSearchData, 

const Tables = ({stateView, data, status, id, token, page, records, changeView, updateInvestigationDatasets, getInvestigationDatasets}) => {
	const [search, setSearch] = useState('')

	const searchInfo = async() => {
		const extra = search ? `?search=${search}` : `?page=1&limit=10`;

		get_investigation_datasets(token, extra)
		.then(response => {
			return response.json();
		})
		.then(json_response => {
			const investigation_datasets = json_response.data;
			getInvestigationDatasets(investigation_datasets.data, investigation_datasets.total, investigation_datasets.offset || 1);
		})
		.catch(error => {
			console.log(error);
		});
	}

	const GetData = async(event, newPage) =>{
		const extra = `?page=${newPage+1}&limit=10`;

		get_investigation_datasets(token, extra)
		.then(response => {
			return response.json();
		})
		.then(json_response => {
			const investigation_datasets = json_response.data;
			getInvestigationDatasets(investigation_datasets.data, investigation_datasets.total, investigation_datasets.offset || 1);
		})
		.catch(error => {
			console.log(error);
		});
	}

	const edit = (item) => {
		changeView(StateView.state3);
		updateInvestigationDatasets(item);
	}

	return (
		<div className="card_info">
			{/* <div style={{height:30}} /> */}
			<InputSearch
				onClickSearch={searchInfo}
				value={search}
				setData={setSearch} 
				page={page}
				records={records}
				limitSearch={10}
				setPage={GetData} />
			{
				status===stateData.state2 ?
					<div className="card_scroll">
						{
							data &&
							data.map((item,j) =>(
								<div key={j} onClick={() => edit(item)}
									className={id === item.id && stateView === StateView.state3 ? 'table_info_selected' : "table_info"}
									style={{userSelect:'none'}}>
									<div style={{marginLeft:50}}>
										<label style={{fontFamily:'semi', fontSize:16, cursor:'pointer'}}>{capitalizeText(item.name)}</label><br/>
										<label style={{fontFamily:'regular', cursor:'pointer'}}>{formatDate1(item.created_at)}</label><br/>
									</div>
								</div>
							))
						}
					</div>
				:
				<LoadingData />
			}

		</div>
	);
};

const mapStateToProps = state => ({
	stateView:	state.Global.stateView,
	data:		state.InvestigationDatasets.data,
	status:		state.InvestigationDatasets.status,
	id:         state.InvestigationDatasets.dataEdit.id,
	token:		state.PanelUser.token,
	page:		state.InvestigationDatasets.page,
	records:	state.InvestigationDatasets.records
})

export default connect(mapStateToProps, {changeView, updateInvestigationDatasets, getInvestigationDatasets})(Tables)