import {useState}           from 'react';
import {Container}          from '@material-ui/core';
import {connect}            from 'react-redux';
// Redux
import {changeView} from '../../store/redux/actions/Global';
import {updateRequest, getRequest, changeStatusRequst}  from '../../store/redux/actions/Request';
// Components
import InputSearch          from '../global/InputSearch';
import LoadingData          from '../global/LoadingData';
// Values
import {StateView, Ic_check, Ic_uncheck, stateData} from '../../values';
// Api
import {getData}    from '../../utils/request';

const SelectOptions = ({opt, val, text, color1='#0055af', color2='#bac0cd', setOption}) =>{
    return(
        <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={() =>setOption(val)}>
            <img alt="" src={opt===val?Ic_check:Ic_uncheck} style={{marginRight:10}} />
            <p style={{color:opt===val?color1:color2, userSelect:'none'}}>{text}</p>
        </div>
    )
}

const Tables = ({data, status, id, token, page, records, changeStatusRequst, changeView, updateRequest, getRequest}) => {
    const [option, setOption] = useState(0)
    const [search, setSearch] = useState('')

    const [auxCompleted, setAuxCompleted]   = useState(false)
    const [auxPrinted, setAuxPrinted]       = useState(false)

    const searchInfo = async() =>{
        changeStatusRequst()
        if(search){
            const extra = `?search=${search}&b64=1&limit=10`
            const res = await getData(token, extra)
            getRequest(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
        } else{
            const extra = `?page=1&b64=1&limit=10`
            const res = await getData(token, extra)
            getRequest(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
        }
    }

    const GetData = async(event, newPage) =>{
        changeStatusRequst()
        const extra = `?page=${newPage+1}&searchCompleted=${auxCompleted}&searchPrinted=${auxPrinted}&limit=10&b64=1`
        const res = await getData(token, extra)
        getRequest(res.data.data.data, res.data.data.total, res.data.data.offset)
    }

    const GetDataFilter = async(val) =>{
        setOption(val)
        changeStatusRequst()
        let searchCompleted = false
        let searchPrinted   = false

        if(val===1){
            searchCompleted = true
        }

        if(val===2){
            searchCompleted = true
            searchPrinted   = true
        }

        setAuxCompleted(searchCompleted)
        setAuxPrinted(searchPrinted)
        const extra = `?page=${page}&searchCompleted=${searchCompleted}&searchPrinted=${searchPrinted}&b64=1&limit=10`
        const res = await getData(token, extra)
        getRequest(res.data.data.data, res.data.data.total, res.data.data.offset ?? 1)
    }

    const edit = (item) => {
        changeView(StateView.state3)
        updateRequest(item)
    }
    return (
        <div className="card_info">
            <InputSearch onClickSearch={searchInfo} 
                value={search} setData={setSearch} 
                page={page} records={records} setPage={GetData} />

            {
                status===stateData.state2?
                    <div className="card_scroll">
                        <div style={{background:'#f1f3f4', marginTop:15, marginBottom:15}}>
                            <Container>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <SelectOptions opt={option} val={0} text={'En valoración'}  setOption={GetDataFilter} />
                                    <SelectOptions opt={option} val={1} text={'Completas'}      setOption={GetDataFilter} />
                                    <SelectOptions opt={option} val={2} text={'Entregadas'}     setOption={GetDataFilter} />
                                </div>
                            </Container>
                        </div>
                        {
                            data&&
                            data.map((item, j) =>(
                                <div key={j} onClick={() => edit(item)}
                                    className={id===item.id?'table_info_selected':"table_info"} 
                                    style={{userSelect:'none', position:'relative'}}>
                                    <div style={{marginLeft:50}}>
                                        <label style={{fontFamily:'semi', fontSize:16, cursor:'pointer'}}>{item.patient_name} {item.patient_last_name}</label><br/>
                                        <label style={{fontFamily:'regular', cursor:'pointer'}}>{item.patient_email}</label><br/>
                                        <label style={{fontFamily:'regular', cursor:'pointer'}}>{item.patient_phone}</label><br/>
                                    </div>
                                    <div style={{display:item.not_graduable?'block':'none', marginBottom:50}}>
                                        <div className="container_circle_request">
                                            <span style={{fontSize:20, fontWeight:600, color:'#fff'}}>{'X'}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            ))
                        }
                    </div>
                :
                    <LoadingData />
            }
        </div>
    );
};

const mapStateToProps = state =>({
    data:               state.Request.data,
    status:             state.Request.status,
    id:                 state.Request.dataEdit.id,
    medicalCenterId:    state.PanelUser.medicalCenterId,
    token:              state.PanelUser.token,
    page:               state.Request.page,
    records:            state.Request.records
})

export default connect(mapStateToProps, {changeView, updateRequest, getRequest, changeStatusRequst})(Tables)