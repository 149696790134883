import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunkMiddleware  from 'redux-thunk';

import Global       from './reducers/Global';
import PanelUser    from './reducers/PanelUser';
import Hospitals    from './reducers/Hospitals';
import Users        from './reducers/Users';
import Patients     from './reducers/Patients';
import Request      from './reducers/Request';
import Grader       from './reducers/Grader';
import Research     from './reducers/Research';
import InvestigationDatasets from './reducers/InvestigationDatasets';

const reducers = combineReducers({
    Global,
    PanelUser,
    Hospitals,
    Users,
    Patients,
    Request,
    Grader,
    Research,
    InvestigationDatasets,
})

const store = createStore(
    reducers,
    compose(
        applyMiddleware(thunkMiddleware),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() || compose
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
)

export default store;