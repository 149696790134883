import {makeStyles} from '@material-ui/core/styles';

// Values
import {Ic_unfold}          from '../../values';

const useStyles = makeStyles(theme => ({
    select:{
        width:'100%',
        height:35,
        fontFamily:'regular',
        // borderRadius:5,
        border:'none',
        fontSize:16,
        paddingLeft:10,
        paddingRight:10,
        appearance:'none',
        // marginTop:6,
        background: `url(${Ic_unfold}) 96.5% / 10px no-repeat #fff`,
        [theme.breakpoints.down('lg')]: {
            background: `url(${Ic_unfold}) 94.5% / 10px no-repeat #fff`,
        }
    }
}));

const InputSelect = ({title, value, setData, blockData, list, textOption, color='#000'}) => {
    const classes = useStyles()

    return (
        <div style={{display:'flex', alignItems:'center'}}>
            <p className='title_input' style={{marginTop:14, marginBottom:14, color:color}}>{title}</p>
            <select 
                value={value}
                onChange={e => setData(e.target.value)}
                className={classes.select} disabled={blockData}>
                <option value="" disabled hidden>{textOption}</option>
                {
                    list&&
                    list.map((i, j) =>(
                        <option key={j} value={i.id} style={{fontFamily:'arial'}}>{i.name}</option>
                    ))
                }
            </select>
        </div>
    );
};

export default InputSelect;