import {useState, useEffect} from 'react';
import {makeStyles}         from '@material-ui/core/styles';
import {Container, Modal, Grid}   from '@material-ui/core';

// import Input        from '../global/Input';
import DragImage    from '../graduation_request/DragImage';
// import Select       from '../global/SelectV2';
import Carousel     from '../graduation_request/Carousel';


// Values
import {Ic_unfold}          from '../../values';

const useStyles = makeStyles(theme => ({
    select:{
        margin:'15px 0',
        width:'100%',
        height:35,
        fontFamily:'regular',
        border:'none',
        fontSize:16,
        paddingLeft:10,
        paddingRight:10,
        appearance:'none',
        boxShadow:'0px 0px 5px 0px rgba(0,0,0,0.20)',
        background: `url(${Ic_unfold}) 96.5% / 10px no-repeat #fff`,
        [theme.breakpoints.down('lg')]: {
            background: `url(${Ic_unfold}) 94.5% / 10px no-repeat #fff`,
        }
    },
    modal_container:{
        background:'#fff', height:'95vh',  position:'relative', 
    },
    container_image:{
        height:'95vh', width:'100%', background:'#000'
    },
    container_find_info:{
        overflowY:'auto', height:'60%',
        padding:'0 1px',
        [theme.breakpoints.down('lg')]: {
            height:'50%',
        }
    },
    input:{
        border:'none',
        width:'100%',
        background:'#fff',
        paddingLeft:10,
        fontSize:16,
        height:42
    }
}));

const ModalGrader = ({data, open, closeModal, height=600}) => {
    const classes = useStyles()
    const [option, setOption] = useState(0)
    const [optionGrader, setOptionGrader] = useState(0)
    const [grader, setGrader] = useState([])

    const setData = (value) =>{
        setOptionGrader(value)
        try { setGrader(data.all_graders[value]) } 
        catch (error) { setGrader([]) }
    }

    const close = (opt) =>{
        closeModal(opt)
        setOption(0)
    }
    useEffect(()=>{
        setOption(0)
        setOptionGrader(0)
        try { setGrader(data.all_graders[0]) } 
        catch (error) { setGrader([]) }
    },[data])
    return (
        <div>
            <Modal open={open} 
                style={{display:'flex', alignItems:'center', justifyContent:'center',}}
                onClose={() => close(!open)}>
                    <Container maxWidth="xl">
                        <div className={classes.modal_container}>
                            <Container maxWidth='xl'>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Container style={{paddingLeft:0}}>
                                            <div className={classes.container_find_info}>
                                                <div style={{display:data.all_graders&&data.all_graders.length!==0?'block':'none'}}>
                                                    <select 
                                                        value={optionGrader}
                                                        onChange={e => setData(e.target.value)}
                                                        style={{cursor:'pointer'}}
                                                        className={classes.select}>
                                                            {
                                                                data.all_graders&&
                                                                data.all_graders.map((item, index) =>(
                                                                    <option key={index} value={index} style={{fontFamily:'arial'}}>{`Graduador ${index+1}`}</option>
                                                                ))
                                                            }
                                                    </select>
                                                    <div style={{background:'#edf3f9', padding:'10px 10px 30px'}}>
                                                        <p>{'Nivel de retinopatia izquiedo 1'}</p>
                                                        <input className={classes.input} value={grader?`R${grader.retinopathy_level_left}`:''} disabled />
                                                        <p>{'Nivel de retinopatia izquiedo 2'}</p>
                                                        <input className={classes.input} value={grader?`R${grader.retinopathy_level_left2}`:''} disabled />

                                                        <p>{'Nivel de retinopatia derecho 1'}</p>
                                                        <input className={classes.input} value={grader?`R${grader.retinopathy_level_right}`:''} disabled />
                                                        <p>{'Nivel de retinopatia derecho 2'}</p>
                                                        <input className={classes.input} value={grader?`R${grader.retinopathy_level_right}`:''} disabled />

                                                        <p>{'Edema macular izquiedo 1'}</p>
                                                        <input className={classes.input} value={grader?grader.macular_edema_left?'Sin edema macular':'Con edema macular':''} disabled />
                                                        <p>{'Edema macular izquiedo 2'}</p>
                                                        <input className={classes.input} value={grader?grader.macular_edema_left2?'Sin edema macular':'Con edema macular':''} disabled />

                                                        <p>{'Edema macular izquiedo 1'}</p>
                                                        <input className={classes.input} value={grader?grader.macular_edema_right?'Sin edema macular':'Con edema macular':''} disabled />
                                                        <p>{'Edema macular izquiedo 2'}</p>
                                                        <input className={classes.input} value={grader?grader.macular_edema_right2?'Sin edema macular':'Con edema macular':''} disabled />
                                                    </div>
                                                </div>

                                                <div style={{display:data.all_graders&&data.all_graders.length===0?'block':'none'}}>
                                                    <p style={{fontWeight:600, fontSize:20}}>{'Aun no se ha evaluado'}</p>
                                                </div>
                                            </div>
                                        </Container>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <div className={classes.container_image}>
                                            <div style={{display:option===0?'block':'none'}}>
                                                <Carousel text={'Ojo izquierdo centrado en mácula'} back={0} next={1} onClick={setOption} />
                                                <DragImage image={data.picture_left_eye_a} blockData={'disabled'} />
                                            </div>
                                            <div style={{display:option===1?'block':'none'}}>
                                                <Carousel text={'Ojo izquierdo centrado en disco'} back={0} next={2} onClick={setOption} />
                                                <DragImage image={data.picture_left_eye_b} blockData={'disabled'} />
                                            </div>
                                            <div style={{display:option===2?'block':'none'}}>
                                                <Carousel text={'Ojo derecho centrado en mácula'} back={1} next={3} onClick={setOption} />
                                                <DragImage image={data.picture_right_eye_a} blockData={'disabled'} />
                                            </div>
                                            <div style={{display:option===3?'block':'none'}}>
                                                <Carousel text={'Ojo derecho centrado en disco'} back={2} next={4} onClick={setOption} />
                                                <DragImage image={data.picture_right_eye_b} blockData={'disabled'} />
                                            </div>
                                            <div style={{display:option===4?'block':'none'}}>
                                                <Carousel text={'Segmento anterior - ojo izquierdo'} back={3} next={5} onClick={setOption} />
                                                <DragImage image={data.previous_segment} blockData={'disabled'} />
                                            </div>
                                            <div style={{display:option===5?'block':'none'}}>
                                                <Carousel text={'Segmento anterior - ojo derecho'} back={4} next={5} onClick={setOption} />
                                                <DragImage image={data.previous_segment_b} blockData={'disabled'} />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    </Container>
                </Modal>
        </div>
    );
};

export default ModalGrader;