import {useState}   from 'react';
import {Container}  from '@material-ui/core';
import {connect}    from 'react-redux';
import {toast}      from 'react-toastify';
// Redux
import {saveLogin}  from '../../store/redux/actions/PanelUser';
// Components
import Input        from './Input';
import Button       from './Button';
import ModalGlobal  from '../global/ModalGlobal';
// Utils-Values
import {emailformat}    from '../../utils';
import {Img_logo_small, toastText} from '../../values';
// Api
import {verifyLogin, forgotPassword} from '../../utils/login';

const Login = ({saveLogin}) => {
    const [email, setEmail]         = useState('')
    const [password, setPassword]   = useState('')
    const [eye, setEye]             = useState(false)
    const [loading, setLoading]     = useState(false)

    const [status1, setStatus1]     = useState(0)// 0 nada, 1 Ok, 2 error
    const [status2, setStatus2]     = useState(0)

    // Modal
    const [open1, setOpen1]   = useState(false)
    const [open2, setOpen2]   = useState(false)
    const [open3, setOpen3]   = useState(false)
    const [open4, setOpen4]   = useState(false)

    const standBy = (event) =>{
        console.log("Stand-By");
    }

    const openModal = () =>{
        if(email.match(emailformat)){
            setOpen1(true)
        } else{
            setOpen2(true)
        }
    }

    const resetPassword = async() =>{
        if(email.match(emailformat)){
            setOpen1(false)
            
            const res = await forgotPassword(email)
            if(res){
                setOpen4(true)
            } else{
                setOpen1(false)
                setOpen3(true)
            }
        } else{
            setStatus1(2)
        }
    }

    const validateData = (value, option) =>{
        if(option===1){
            setEmail(value)
            if(value.match(emailformat)){
                setStatus1(1)
            } else{
                setStatus1(2)
            }
        } else{
            setPassword(value)
            if(value.length >= 6){
                setStatus2(1)
            } else{
                setStatus2(2)
            }
        }
    }

    const login = async() =>{
        const res = await verifyLogin(email, password)
        if(res.flag){
            localStorage.setItem('eyebit-name', res.data.name)
            localStorage.setItem('eyebit-lastName', res.data.lastName)
            localStorage.setItem('eyebit-picture', res.data.picture)
            localStorage.setItem('eyebit-key', res.token)
            localStorage.setItem('eyebit-rol', res.data.rol)
            localStorage.setItem('eyebit-medicalCenterId', res.data.medicalCenterId)
            localStorage.setItem('eyebit-user-id', res.data.id)
            localStorage.setItem('eyebit-study-type', res.data.study_type);
            saveLogin(res.data.name, res.data.lastName, res.data.picture, res.token, res.data.rol, res.data.id, res.data.study_type)
        } else {
            toast.warn(toastText.failLogin)
            setLoading(false)
        }
    }

    const onSubmit = () =>{
        if(status1===1 && status2===1){
            setLoading(true)
            login()
        }
        if(status1 !== 1){
            setStatus1(2)
        }
        if(status2 !== 1){
            setStatus2(2)
        }
    }

    return (
        <div className="container_login">
            <ModalGlobal open={open1} onClose={setOpen1} 
                title={'Enviar correo de recuperación'} text_button1={'Enviar'} text_button2={'Cancelar'} button={resetPassword}
                info={`¿Deseas enviar un correo a ${email} para restablecer la contraseña?`} 
                button_color1='#265db5' text_color1='#fff' button_color2='#323232' text_color2='#fff' />

            <ModalGlobal open={open2} onClose={setOpen2} 
                title={'Oops'} text_button1={''} text_button2={'Ok'} button={null}
                info={`Para restabler la contraseña, primero ingresa tu correo electronico en el campo de email`}
                button_color1='#265db5' text_color1='#fff' button_color2='#323232' text_color2='#fff' />
            
            <ModalGlobal open={open3} onClose={setOpen3} 
                title={'Oops'} text_button1={''} text_button2={'Ok'} button={null}
                info={`Parece que surgio un problema, revisa tus datos.`} 
                button_color1='#265db5' text_color1='#fff' button_color2='#323232' text_color2='#fff' />
            
            <ModalGlobal open={open4} onClose={setOpen4} 
                title={''} text_button1={''} text_button2={'Ok'} button={null}
                info={`Se envío a ${email} un correo para restablecer la contraseña.`} 
                button_color1='#265db5' text_color1='#fff' button_color2='#323232' text_color2='#fff' />


            <Container maxWidth="sm">
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div className="login_card">
                        <Container>
                            <div style={{paddingTop:50, marginBottom:80, display:'flex', justifyContent:'center'}}>
                                <img alt="" src={Img_logo_small} style={{width:200}} />
                            </div>

                            <Input text={'Correo'} type={'email'} changeData={validateData} opt={1} status={status1} />
                            <div style={{height:15}} />

                            <Input text={'Contraseña'} type={eye?'text':'password'} view={true} eye={eye} setEye={setEye} 
                                changeData={validateData} opt={2} status={status2} />
                            <div style={{height:15}} />
                            <Button onSubmit={loading?standBy:onSubmit} reset={openModal} loading={loading} />
                        </Container>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default connect(null, {saveLogin})(Login)