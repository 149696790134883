import {connect}    from 'react-redux';
// Redux
import {changeView, cleanData} from '../../store/redux/actions/Global'
// Components
import Button from './Button';
// Values
import {StateView}  from '../../values';

const Header = ({title, stateView, changeView, cleanData, add=true}) => {
    const ChangeView = () =>{
        cleanData()
        changeView(StateView.state2)
    }
    return (
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <p style={{fontSize:20, fontFamily:'semi'}}>{title}</p>
            <div style={{display:add?'block':'none'}}>
                {
                    stateView===StateView.state1?
                        <Button add={ChangeView} />
                    :
                    stateView===StateView.state3?
                        <Button add={ChangeView} />
                    :<div />
                }
            </div>
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.Global.stateView,
})

export default connect(mapStateToProps, {changeView, cleanData})(Header)