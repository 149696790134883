import {useEffect}  from 'react';
import {connect}    from 'react-redux';
// Redux
import {getPatients}   from '../../store/redux/actions/Patients';
// Components
import CardDelete   from '../global/CardDelete';
import Header       from '../global/Header';
import Tables       from './Tables';
import Form         from './Form';
// Values
import {StateView}  from 'values';
// Api
import {getData}    from 'utils/patients';

const Patients = ({stateView, token, page, medicalCenterId, getPatients}) => {
    const GetData = async() =>{
        const extra = `?page=${page}&medicalCenterId=${medicalCenterId}&limit=10`
        const res = await getData(token, extra)
        try {
            getPatients(res.data.data.data, res.data.data.total, res.data.data.offset?res.data.data.offset:1)
        } catch (error) { }
    }
    useEffect(() =>{
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    })
    return (
        <div style={{display:'flex', position:'absolute', top:20, left:180}}>
            <div className="container_info">
                <Header title={'Pacientes'} />
                <Tables />
            </div>
            {
                stateView===StateView.state1?<div />:
                stateView===StateView.state4?<CardDelete />:
                <div className="container_card">
                    <Form />
                </div>
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:          state.Global.stateView,
    token:              state.PanelUser.token,
    page:               state.Patients.page,
    medicalCenterId:    state.PanelUser.medicalCenterId
})

export default connect(mapStateToProps, {getPatients})(Patients)
