import {useEffect}  from 'react';
import {connect}    from 'react-redux';
// Redux
import {getUsers}           from '../../store/redux/actions/Users';
import {getAllHospitals}    from '../../store/redux/actions/Hospitals';
// Components
import CardDelete   from '../global/CardDelete';
import Header       from '../global/Header';
import Tables       from './Tables';
import Form         from './Form';
// Values
import {StateView}  from '../../values';
// Api
import {getData}            from '../../utils/users';
import {getHospitalsData, getData as getHospitals}   from '../../utils/hospitals';

const Users = ({stateView, token, page, getUsers, getAllHospitals}) => {
    const GetData = async() =>{
        const extra = `?page=${page}&limit=10`
        const res = await getData(token, extra)
        const res2 = await getHospitals(token)
        try {
            getUsers(res.data.data.data, res.data.data.total, res.data.data.offset)
            getAllHospitals(res2.data.data.data)
        } catch (error) { }
    }
    useEffect(() =>{
        if(token){
            if(stateView === StateView.state1){
                GetData()
            }
        }
    })
    return (
        <div style={{display:'flex', position:'absolute', top:20, left:180}}>
            <div className="container_info">
                <Header title={'Usuarios'} />
                <Tables />
            </div>
            {
                stateView===StateView.state1?<div />:
                stateView===StateView.state4?<CardDelete />:
                <div className="container_card">
                    <Form />
                </div>
            }
        </div>
    );
};

const mapStateToProps = state =>({
    stateView:      state.Global.stateView,
    token:          state.PanelUser.token,
    page:           state.Users.page
})

export default connect(mapStateToProps, {getUsers, getAllHospitals})(Users)
