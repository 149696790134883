import {useState, useEffect}	from 'react';
import {Container, RadioGroup, FormControlLabel, Radio}				from '@material-ui/core';
import {connect}				from 'react-redux';
import {toast}					from 'react-toastify';
// Redux
import {changeView, cleanData} from '../../store/redux/actions/Global';
// Components
import Input		from '../global/Input';
import ButtonFormV2	from '../global/ButtonFormV2';
import ModalGlobal	from '../global/ModalGlobal';
import Select		from '../global/Select';
// Values
import {StateView, toastText, hospital_study_types} from 'values'; // translate_inputs
// Api
import {validateHospitalData, postHospitalData, updateHospitalData, deleteHospital} from 'utils/hospitals';
import {regex_email, regex_names_one, regex_names_two, regex_names_three, manage_request_errors} from 'utils'; // , regex_phone_number, phoneformat

const Form = ({data, stateView, token, changeView, cleanData}) => {
	const [blockData, setBlockData]		= useState('')
	const [status_edit, setStatusEdit]  = useState(stateView === StateView.state3)
	const [status_add, setStatusAdd]	= useState(stateView === StateView.state2)

	// Modal
	const [open1, setOpen1]		= useState(false)
	const [loading, setLoading]	= useState(false)

	const [name, setName]						= useState('');
	const [state, setState]						= useState('');
	const [municipality, setMunicipality]		= useState('');
	const [colony, setColony]					= useState('');
	const [address, setAddress]					= useState('');
	const [contact, setContact]					= useState('');
	const [phone, setPhone]						= useState('');
	const [email, setEmail]						= useState('');
	const [assessmentType, setAssessmentType]	= useState('individual');
	const [studyType, setStudyType]				= useState(1);
	const [studyTypeAux, setStudyTypeAux]		= useState('1 Campo');

	const cancelAdd = () => {
		setStatusAdd(false);
		changeView(StateView.state1);
	}

	const cancelEdit = () => {
		setStatusEdit(true);
		setStatusAdd(false);
		setBlockData('disabled');
		if (data.id) {
			maptData();
		}
	}

	const Update = async() => {
		setStatusAdd(true);
		setBlockData('');
		setStatusEdit(false);
	}

	const Delete = async() => {
		deleteRegister();
	}

	const handleRadioChange = (e) => {
		setAssessmentType(e.target.value);
	}

	const handleSelectStudyTypeChange = (value) => {
		setStudyTypeAux(value);
		setStudyType(parseInt(value[0]));
	}

	const maptData = () => {
		setName(data.name);
		setState(data.state);
		setMunicipality(data.municipality);
		setColony(data.area);
		setAddress(data.address);
		setContact(data.contact);
		setPhone(data.phone);
		setEmail(data.email);
		setAssessmentType(data.assessment_type);
		setStudyTypeAux(`${data.study_type} Campo${data.study_type >= 2 ? 's' : ''}`);
		setStudyType(data.study_type);
	}

	const cleanLocalData = () => {
		setName('');
		setState('');
		setMunicipality('');
		setColony('');
		setAddress('');
		setContact('');
		setPhone('');
		setEmail('');
		setAssessmentType('individual');
		setStudyTypeAux('1 Campo');
		setStudyType(1);
	}

	const patchData = async() => {

		validateHospitalData(name, state, municipality, colony, address, contact, phone, email, assessmentType, studyType)
		.then(_ => {
			return updateHospitalData(data.id, name, state, municipality, colony, address, contact, phone, email, assessmentType, studyType, token);
		})
		.then(response => {return response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			toast.success(toastText.update);
			setLoading(false);
			cleanLocalData();
			cleanData();
		})
		.catch(error => {
			console.log(error);
			setLoading(false);
			manage_request_errors(error);
		});
	}

	const addData = async () => {
		validateHospitalData(name, state, municipality, colony, address, contact, phone, email, assessmentType, studyType)
		.then(_ => {
			return postHospitalData(name, state, municipality, colony, address, contact, phone, email, assessmentType, studyType, token);
		})
		.then(request_response => {return request_response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			toast.success(toastText.add);
			setLoading(false);
			cleanLocalData();
			cleanData();
		})
		.catch(error => {
			setLoading(false);
			console.log(error);
			manage_request_errors(error);
		});
	}

	const deleteRegister = async() => {
		changeView(StateView.state4);

		deleteHospital(data.id, token)
		.then(request_response => {return request_response.json();})
		.then(response => {
			if ("success" in response && !response.success) {
				throw response;
			}

			toast.success(toastText.delete);
			changeView(StateView.state1);
			cleanData();
		})
		.catch(error => {
			setLoading(false);
			changeView(StateView.state1);
			console.log(error);
			manage_request_errors(error);
		});
	}

	const onSubmit = () => {
		setLoading(true);
		if (stateView === StateView.state3) {
			patchData();
		} else {
			addData();
		}
	}

	useEffect(() => {
		setLoading(false)
		if (stateView === StateView.state3) {
			setStatusEdit(true);
			setBlockData('disabled');
			maptData();
		} else {
			setStatusEdit(false);
			setBlockData('');
			cleanLocalData();
		}

		if (stateView === StateView.state2) {
			setStatusAdd(true);
			setBlockData('');
			cleanLocalData();
		} else {
			setBlockData('disabled');
			setStatusAdd(false);
		}
	},[stateView, data.id]);

	return (
		<div className="card_info">
			<div style={{height:30}} />
			<ModalGlobal open={open1} onClose={setOpen1}
				title={'Eliminar'} text_button1={'Eliminar'} text_button2={'Cancelar'} button={Delete}
				info={`¿Seguro que deseas eliminar la institución de salud "${data.name}"?`} button_color={'#ce0e4f'} text_color={'#fff'} />

			<div className="card_scroll">
				<Container>
					<div style={{backgroundColor:'#f1f4f8', paddingTop:15, paddingBottom:15}}>
						<Container>
							<Input blockData={blockData} setData={setName} title={'Nombre'} value={name} regex={regex_names_one} />
							<Input blockData={blockData} setData={setState} title={'Estado'} value={state} regex={regex_names_two} />
							<Input blockData={blockData} setData={setMunicipality} title={'Municipio'} value={municipality} regex={regex_names_two} />
							<Input blockData={blockData} setData={setColony} title={'Colonia'} value={colony} regex={regex_names_one} />
							<Input blockData={blockData} setData={setAddress} title={'Calle y número'} value={address} regex={regex_names_three}/>
							<Input blockData={blockData} setData={setContact} title={'Contacto'} value={contact} regex={regex_names_two} />
							<Input blockData={blockData} setData={setPhone} title={'Teléfono'} value={phone} />
							<Input blockData={blockData} setData={setEmail} title={'Correo'} value={email} regex={regex_email}/>
							<div style={{display:'flex', alignItems:'center'}}>
								<p className='title_input' style={{marginTop:14, marginBottom:14, color:'#000'}}>Tipo de valoración</p>
								<div style={{width: '100%'}}>
									<FormControlLabel
										value="individual"
										control={
											<Radio size={'small'} checked={assessmentType === 'individual'} onChange={handleRadioChange} disabled={blockData === 'disabled'} />
										}
										label="Individual" />
									<FormControlLabel
										value="parity"
										control={
											<Radio size={'small'} checked={assessmentType === 'parity'} onChange={handleRadioChange} disabled={blockData === 'disabled'} />
										}
										label="Paridad" />
								</div>
							</div>
							<div>
								<Select title={'Tipo de estudio'} value={studyTypeAux} setData={handleSelectStudyTypeChange} list={hospital_study_types} textOption={'Selecciona un tipo de estudio'} blockData={blockData} />
							</div>
						</Container>
					</div>
					<ButtonFormV2
						loading={loading}
						stateView={stateView}
						onSubmit={onSubmit}
						onClick_edit={Update}
						onClick_delete={()=>setOpen1(true)}
						status_edit={status_edit}
						status_add={status_add}
						cancelEdit={cancelEdit}
						cancelAdd={cancelAdd} />

				</Container>
				<div style={{height:30}} />
			</div>
		</div>
	);
};

const mapStateToProps = state =>({
	stateView:	state.Global.stateView,
	token:		state.PanelUser.token,
	data:		state.Hospitals.dataEdit,
})

export default connect(mapStateToProps, {changeView, cleanData})(Form)