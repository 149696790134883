import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Container, Tooltip} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import {toast} from 'react-toastify';

// Components
import Input    from './Input';
import Button   from './Button';

// Values
import {Img_logo_small, Img_success, Img_warning} from '../../values';
import {regex_full_password} from './../../utils';
// Api
import {recoverPassword} from '../../utils/login';

const CustomTooltip = withStyles((theme) => ({
	tooltip: {
		background: "rgba(0, 0, 0, 0.8)",
		width: 240,
		height: 220,
		borderRadius: 9,
		color: "#fff",
		fontSize: 12,
		fontFamily: "regular",
	},
}))(Tooltip);

const RestorePassword = () => {
    let { id } = useParams();

    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [eye1, setEye1]             = useState(false)
    const [eye2, setEye2]             = useState(false)
    const [loading, setLoading]     = useState(false)
    const [status1, setStatus1]     = useState(0)// 0 nada, 1 Ok, 2 error
    const [status2, setStatus2]     = useState(0)
    const [response, setResponse]   = useState(0)

	const passwordPolicyText = () => {
		return (
			<div>
				La contraseña debe de cumplir con los siguientes parámetros:
				<ul>
					<li>Debe contener al menos una letra mayúscula.</li>
					<li>Debe contener al menos una letra minúscula.</li>
					<li>Debe contener al menos un número.</li>
					<li>Debe contener al menos un carácter especial: @$!%*?&</li>
					<li>Debe tener una longitud mínima de 8 caracteres.</li>
				</ul>
			</div>
		);
	}

	const validateData = (value, option) => {
		if (option===1) {
			setPassword1(value);
			if (value.match(regex_full_password)) {
				setStatus1(1);
				if (value === password2) {
					setStatus1(1);
					setStatus2(1);
				}
			} else {
				setStatus1(2);
			}
		} else {
			setPassword2(value);
			if (value.match(regex_full_password)) {
				setStatus2(1);
				if (value === password1) {
					setStatus1(1);
					setStatus2(1);
				}
			} else {
				setStatus2(2);
			}
		}
	}

    const standBy = (event) =>{
        console.log("Stand-By");
    }

    const passwordsAreEquals = () =>{
        let flag = false
        if(password1===password2){
            flag = true
        }
        return flag
    }

	const reset = async() => {
		console.log(id);
		const res = await recoverPassword(id, password1);
		if(res){
			setResponse(1);
			setLoading(false);
		} else{
			setResponse(2);
			setLoading(false);
		}
	}

	const onSubmit = () => {
		if (status1 === 1 && status2 === 1) {
			setLoading(true);
			const res = passwordsAreEquals();
			if (res) {
				setStatus1(1);
				setStatus2(1);
				reset();
			} else {
				toast.warn('Las contraseñas tienen que coincidir.');
				setStatus1(2);
				setStatus2(2);
				setLoading(false);
			}
		}
		if (status1 !== 1) {
			setStatus1(2);
		}
		if (status2 !== 1) {
			setStatus2(2);
		}
	}

    return (
        <div className="container_login">
            <Container maxWidth="sm" style={{zIndex:5, marginTop:-100}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div className="login_card">
                        <Container>
                            <div style={{paddingTop:30, marginBottom:50, display:'flex', justifyContent:'center'}}>
                                <img alt="Notend" src={Img_logo_small} style={{width:200}} />
                            </div>
                            <div style={{display:response===0?'block':'none'}}>
                                <Container>
                                    <label style={{fontFamily:'semi'}}>{"Ingresa tu nueva contraseña"}</label>

									<CustomTooltip title={passwordPolicyText()} placement="right">
											<img alt="" src={Img_warning} style={{ width: 16, marginLeft: 8 }} />
									</CustomTooltip>

                                    <div style={{height:15}} />
                                </Container>

                                <div style={{borderRadius:10, paddingTop:40, paddingBottom:15}}>
                                    <Container>
                                        <Input text={'Contraseña'} type={eye1?'text':'password'} view={true} eye={eye1} setEye={setEye1} 
                                            changeData={validateData} opt={1} status={status1} />
                                        <div style={{height:40}} />

                                        <Input text={'Confirmar contraseña'} type={eye2?'text':'password'} view={true} eye={eye2} setEye={setEye2} 
                                            changeData={validateData} opt={2} status={status2} />
                                        {/* <div style={{height:20}} /> */}
                                    </Container>
                                </div>

                                <Button onSubmit={loading?standBy:onSubmit} loading={loading} />
                            </div>


                            <div style={{textAlign:'center', display:response===1?'block':'none', position:'relative'}}>
                                <div style={{position:'absolute', width:'100%', top:-20}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:180}}>
                                        <img alt="Img_success" src={Img_success} style={{width:100}} />
                                    </div>
                                    <p style={{color:'#6d7c92', fontFamily:'semi', fontSize:20, marginTop:60}}>{"La contraseña se ha cambiado con éxito"}</p>
                                </div>
                                <div style={{height:70}} />
                            </div>


                            <div style={{textAlign:'center', display:response===2?'block':'none', position:'relative'}}>
                                <div style={{position:'absolute', width:'100%', top:-20}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:180}}>
                                        <img alt="Img_warning" src={Img_warning} style={{width:100}} />
                                    </div>
                                    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                                        <p style={{color:'#6d7c92', fontFamily:'bold', fontSize:20, width:300, marginTop:60}}>{"Lo sentimos, no se pudo completar la acción, vuelve a solicitar el cambio"}</p>
                                    </div>
                                </div>
                                <div style={{height:70}} />
                            </div>

                        </Container>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default RestorePassword;