import React, { useState, useEffect } from "react";
import { Container, Grid, Modal, Slider } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import ReactLoading from "react-loading";
import Tooltip from '@material-ui/core/Tooltip';

// Redux
import { changeView, cleanData } from "../../store/redux/actions/Global";

import {capitalizeText, manage_request_errors} from '../../utils';

// Components
import DragImage from "../graduation_request/DragImage";
// import Select from "../global/SelectV2";
import Carousel from "../graduation_request/Carousel";
import CloseModalButton from './../global/CloseModalButton';

// Values
import {
	// Ic_back,
	// Ic_next,
	Ic_next_white,
	toastText,
} from "values";
// Api
import {validateResearchData, updateResearch} from "utils/investigation"; // , updateData

const useStyles = makeStyles((theme) => ({
	modal_container: {
		background: "#fff",
		height: "95vh",
		position: "relative",
	},
	container_image: {
		height: "95vh",
		width: "100%",
		background: "#000",
	},
	container_find_info: {
		overflowY: "auto",
		height: "55%",
		[theme.breakpoints.down("lg")]: {
			height: "55%",
		},
	},
	custom_slider_wrapper: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		margin: '10px 0px',
	},
	custom_slider_label: {
		margin: 0,
		paddingLeft: 0,
		fontSize: 14,
		userSelect: "none",
		width: '38%',
		[theme.breakpoints.down('xl')] : { // xl => 1920
			fontSize: 14,
		},
		[theme.breakpoints.down('lg')] : { // lg => 1280
			fontSize: 12,
		},
		[theme.breakpoints.down('md')] : { // md => 960
			width: '100%',
		},
	},
	custom_slider_input: {
		width: '52%',
		[theme.breakpoints.down('md')] : { // md => 960
			width: '85%',
			margin: '25px 0px 0px 8px',
		},
	},
	findings_wrapper: {
		marginBottom: '5px',
		width: "95%",
		padding: "2px",
		display: "flex",
		alignItems: "center",
	},
	findings_label_result: {
		margin: 0,
		fontWeight: 600,
		fontSize: 18,
		userSelect: "none",
		[theme.breakpoints.down('md')] : { // md => 960
			fontSize: 16,
		},
		[theme.breakpoints.down('sm')] : { // md => 960
			fontSize: 12,
		},
		[theme.breakpoints.down('xs')] : { // xs => 600
			fontSize: 8,
		},
	},
	findings_text: {
		margin: 0,
		paddingLeft: 10,
		fontSize: 14,
		userSelect: "none",
		[theme.breakpoints.down('md')] : { // md => 960
			fontSize: 12,
		},
		[theme.breakpoints.down('sm')] : { // md => 960
			fontSize: 8,
		},
		[theme.breakpoints.down('xs')] : { // xs => 600
			fontSize: 7,
		},
	},
	image_pagination: {
		position: 'absolute',
		bottom: '105px',
		marginBottom: 10,
		[theme.breakpoints.down('md')] : { // md => 960
			marginTop: 20,
			bottom: '0px',
			position: 'relative',
		},
	},
	enter_to_save: {
		// width: '100%',
		position: 'absolute',
		bottom: '80px',
		marginBottom: 10,
		[theme.breakpoints.down('md')] : { // md => 960
			bottom: '0px',
			position: 'relative',
		},
	},
	save_btn_wrapper: {
		width: '100%',
		position: 'absolute',
		bottom: '20px',
		[theme.breakpoints.down('md')] : { // md => 960
			position: 'relative',
		},
	},
	save_btn: {
		background: "#648CFA",
		margin: '15px 0px',
		paddingLeft: 15,
		position: 'relative',
		color: '#fff',
		border: 'none',
		cursor: 'pointer',
		width: '200px',
		height: '50px',
		fontSize: '17px',
		textAlign: 'left',
		fontFamily: 'semi',
		[theme.breakpoints.down('md')] : { // md => 960
			minWidth: 128,
			width: '85%',
		},
	},
}));

function ValueLabelComponent(props) {
	const { children, open, value } = props;

	return (
		<Tooltip open={open} enterTouchDelay={0} placement="top" title={value} children={children}>
			{children}
		</Tooltip>
	);
}

const CustomSlider = ({text, value, change=() => {}, blockData}) => {
	const classes = useStyles();
	// const input_marks = [{value:0, label:'0'}, {value:1, label:'1'}, {value:2, label:'2'}, {value:3, label:'3'}, {value:4, label:'4'}, {value:5, label:'5'}];
	const input_marks = [{value:0}, {value:1}, {value:2}, {value:3}, {value:4}, {value:5}];
	const show_labels = blockData ? 'on' : 'auto';

	return (
		<div className={classes.custom_slider_wrapper}>
			<p className={classes.custom_slider_label}>{text}</p>
			<div className={classes.custom_slider_input}>
				<Slider
					aria-label={text}
					onChange={(obj, val) => {change(val)}}
					value={value} max={5} min={0} step={1}
					valueLabelDisplay={show_labels}
					marks={input_marks}
					disabled={blockData}
					ValueLabelComponent={ValueLabelComponent}
					/>
			</div>
		</div>
	);
}

const FindInfo = ({ option, text, onClick=() => {}, blockData=false }) => {
	const classes = useStyles();
	let bgColor = '';
	if (option) {
		bgColor = (blockData ? 'rgba(17, 70, 271, 0.6)' : '#1146D9');
	} else {
		bgColor = (blockData ? 'rgba(221, 226, 235, 0.5)' : '#dde2eb');
	}

	return (
		<div
			onClick={() => onClick(!option)}
			className={classes.findings_wrapper}
			style={{
				background: bgColor,
				color: option ? '#fff' : '#000',
				cursor: blockData ? 'auto' : "pointer",
			}}
		>
			<p className={classes.findings_label_result}>{option ? "SI" : "NO"}</p>
			<p className={classes.findings_text}>{text}</p>
		</div>
	);
};

const Form = ({ stateView, user_id, token, data, request_i, changeView, cleanData }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);

	const [position, setPosition] = useState(0);
	const [option, setOption] = useState(0);
	const [open1, setOpen1] = useState(true);

	const [focusing, setFocusing] = useState(5);
	const [contrast, setContrast] = useState(5);
	const [glow, setGlow] = useState(5);
	const [artifacts, setArtifacts] = useState(0);
	const [opticalDisc, setOpticalDisc] = useState(5);
	const [taint, setTaint] = useState(5);
	const [upperArch, setUpperArch] = useState(5);
	const [lowerArch, setLowerArch] = useState(5);

	const [waterfall, setWaterfall] = useState(false);
	const [hemorrhage, setHemorrhage] = useState(false);
	const [injury, setInjury] = useState(false);
	const [breaking_off, setBreaking_off] = useState(false);
	const [hypertensive, setHypertensive] = useState(false);
	const [membrane, setMembrane] = useState(false);
	const [macular_hole, setMacular_hole] = useState(false);
	const [drusen, setDrusen] = useState(false);
	const [corroidal, setCorroidal] = useState(false);
	const [excavation, setExcavation] = useState(false);
	const [myopia, setMyopia] = useState(false);
	const [occlusion, setOcclusion] = useState(false);
	const [hyalosis, setHyalosis] = useState(false);
	const [vascular, setVascular] = useState(false);
	const [retina, setRetina] = useState(false);
	const [optic_nerve, setOptic_nerve] = useState(false);

	const patchData = async (request_id) => {

		const dataToSave = {
			investigation_request: request_id,
			grader_id: user_id,
			focusing,
			contrast,
			glow,
			artifacts,
			optical_disc: opticalDisc,
			taint,
			upper_arch: upperArch,
			lower_arch: lowerArch,
			waterfall,
			hemorrhage,
			injury,
			breaking_off,
			hypertensive,
			membrane,
			macular_hole,
			drusen,
			corroidal,
			excavation,
			myopia,
			occlusion,
			hyalosis,
			vascular,
			retina,
			optic_nerve,
			token
		};

		setLoading(true);
		validateResearchData(dataToSave)
		.then(_ => {
			return updateResearch(dataToSave, token);
		})
		.then(response => {return response.json();})
		.then(json_response => {
			if (!json_response.success) {
				throw json_response;
			}

			data.get_requests.find(req => req.id == request_id).get_qualities.push(json_response.data);
			toast.success(toastText.update);
			setLoading(false);
			// cleanData();
		})
		.catch(error => {
			console.log(error);
			setLoading(false);
			manage_request_errors(error);
		});
	};

	const nextPage = () => {
		const sum = option + 1;
		if (sum < data.get_requests.length) {
			setOption(sum);
		}
	};

	const backPage = () => {
		const subtraction = option - 1;
		if (subtraction >= 0) {
			setOption(subtraction);
		}
	};

	const cleanLocalData = () => {
		setFocusing(5);
		setContrast(5);
		setGlow(5);
		setArtifacts(0);
		setOpticalDisc(5);
		setTaint(5);
		setUpperArch(5);
		setLowerArch(5);
		setWaterfall(false);
		setHemorrhage(false);
		setInjury(false);
		setBreaking_off(false);
		setHypertensive(false);
		setMembrane(false);
		setMacular_hole(false);
		setDrusen(false);
		setCorroidal(false);
		setExcavation(false);
		setMyopia(false);
		setOcclusion(false);
		setHyalosis(false);
		setVascular(false);
		setRetina(false);
		setOptic_nerve(false);
	}

	const keyEvents = (e) => {
		if (e.keyCode === 13) { // "Enter" key
			let quality = data.get_requests[option].get_qualities.find(quality => quality.grader_id == user_id);
			if (quality === undefined) {
				patchData(data.get_requests[option].id);
			}
		} else if (e.keyCode === 27) { // "Esc" key
			closeModal();
		} else if (e.keyCode === 37 || e.keyCode === 39) {
			cleanLocalData();
			if (e.keyCode === 37) { // "Left Arrow" key
				backPage();
			} else if (e.keyCode === 39) { // "Right Arrow" key
				nextPage();
			}
		}
	}

	const closeModal = () => {
		cleanData();
		setOpen1(false);
	};

	useEffect(() => {
		setOption(request_i >= 0 && request_i <= data.get_requests.length ? request_i : 0);
		setPosition(0);
		setOpen1(true);
		// filterData(dataEdit.patientId)
	}, [data]);

	return (
		<div>
			<Modal
				open={open1}
				style={{ display: "flex", alignItems: "center", justifyContent: "center", paddinfRight: 0 }}
				onClose={() => closeModal()}
				onKeyDown={(e) => keyEvents(e)}
			>
				<Container maxWidth="xl">
					<div className={classes.modal_container}>
						<Container maxWidth="xl">
							<Grid container>
								<Grid item xs={2} style={{position: "relative"}}>
									{
										data.get_requests.map((request, i) => {
											let request_qualified = request.get_qualities.find(grade => grade.grader_id == user_id);
											return (
												<React.Fragment key={i}>
													<div class="card_scroll" style={{display: option === i ? "block" : "none", height:'95vh'}}>

														<h3>{"Calidad general"}</h3>
														<CustomSlider
															text={'Enfoque'}
															value={request_qualified ? request_qualified.focusing : focusing}
															change={request_qualified ? () => {} : setFocusing}
															blockData={request_qualified ? true : false} />
														<CustomSlider
															text={'Contraste'}
															value={request_qualified ? request_qualified.contrast : contrast}
															change={request_qualified ? () => {} : setContrast}
															blockData={request_qualified ? true : false} />
														<CustomSlider
															text={'Billo'}
															value={request_qualified ? request_qualified.glow : glow}
															change={request_qualified ? () => {} : setGlow}
															blockData={request_qualified ? true : false} />
														<CustomSlider
															text={'Artefactos'}
															value={request_qualified ? request_qualified.artifacts : artifacts}
															change={request_qualified ? () => {} : setArtifacts}
															blockData={request_qualified ? true : false} />

														<h3>{"Resolución de regiones"}</h3>
														<CustomSlider
															text={'Disco óptico'}
															value={request_qualified ? request_qualified.optical_disc : opticalDisc}
															change={request_qualified ? () => {} : setOpticalDisc}
															blockData={request_qualified ? true : false} />
														<CustomSlider
															text={'Macula'}
															value={request_qualified ? request_qualified.taint : taint}
															change={request_qualified ? () => {} : setTaint}
															blockData={request_qualified ? true : false} />
														<CustomSlider
															text={'Arcada superior'}
															value={request_qualified ? request_qualified.upper_arch : upperArch}
															change={request_qualified ? () => {} : setUpperArch}
															blockData={request_qualified ? true : false} />
														<CustomSlider
															text={'Arcada inferior'}
															value={request_qualified ? request_qualified.lower_arch : lowerArch}
															change={request_qualified ? () => {} : setLowerArch}
															blockData={request_qualified ? true : false} />

														<div className={classes.image_pagination}>
															<h4 style={{margin: '0px'}}>Imagen {i+1} de {data.get_requests.length}</h4>
														</div>

														{
															!request_qualified &&
															<>
																<div className={classes.enter_to_save}>
																	<small style={{fontSize: '10px'}}>* Presiona "Enter" para guardar</small>
																</div>
																<div className={classes.save_btn_wrapper}>
																	<button
																		onClick={() => patchData(request.id)}
																		className={classes.save_btn}
																	>
																		{"Guardar"}
																		{!loading ? (
																			<img alt="" src={Ic_next_white} style={{ position: "absolute", right: 15, top: 15 }} />
																		) : (
																			<div style={{ position: "absolute", right: 15, top: 10 }}>
																				<ReactLoading
																					type={"spinningBubbles"}
																					color={"white"}
																					style={{ fill: "white", height: 30, width: 30 }}
																				/>
																			</div>
																		)}
																	</button>
																</div>
															</>
														}
													</div>
												</React.Fragment>
											)
										})
									}
								</Grid>
								<Grid item xs={8}>
									<div className={classes.container_image}>
										{
											data.get_requests.map((request, i) => {
												return (
													<div style={{display: option === i ? "block" : "none"}} key={i}>
														<Carousel
															text={`${request.image_name} - ${capitalizeText(data.name)}`}
															back={i > 0 ? i-1 : 0}
															next={i < data.get_requests.length-1 ? i+1 : data.get_requests.length-1}
															onClick={setOption} />
														<DragImage image={request.image_url} blockData={"disabled"} />
													</div>
												)
											})
										}
									</div>
								</Grid>
								<Grid item xs={2}>

									<CloseModalButton
										closeModal={closeModal}
										/>

									{
										data.get_requests.map((request, i) => {
											let request_qualified = request.get_qualities.find(grade => grade.grader_id == user_id);
											return (
												<React.Fragment key={i}>
													<div style={{display: option === i ? "block" : "none", height: '95vh'}}>
														{
															request_qualified ?
															<div class="card_scroll" style={{height: '95vh', marginLeft: '5px'}}>
																<h3>{"Otros hallazgos"}</h3>
																<FindInfo option={request_qualified.waterfall} text="Catarata" blockData={true} />
																<FindInfo option={request_qualified.hemorrhage} text="Hemorragia vítrea" blockData={true} />
																<FindInfo option={request_qualified.injury} text="Lesiones predisponentes" blockData={true} />
																<FindInfo option={request_qualified.breaking_off} text="Desprendimiento de la retina por ruptura" blockData={true} />
																<FindInfo option={request_qualified.hypertensive} text="Retinopatía hipertensiva" blockData={true} />
																<FindInfo option={request_qualified.membrane} text="Membrana epirretiniana" blockData={true} />
																<FindInfo option={request_qualified.macular_hole} text="Agujero macular" blockData={true} />
																<FindInfo option={request_qualified.drusen} text="Drusas/Degeneración macular asociada a la edad" blockData={true} />
																<FindInfo option={request_qualified.corroidal} text="Nevo coroideo" blockData={true} />
																<FindInfo option={request_qualified.excavation} text="Excavación de nervio óptico amplia" blockData={true} />
																<FindInfo option={request_qualified.myopia} text="Miopía degenerativa" blockData={true} />
																<FindInfo option={request_qualified.occlusion} text="Oclusión vascular" blockData={true} />
																<FindInfo option={request_qualified.hyalosis} text="Hialosis asteroidea" blockData={true} />
																<FindInfo option={request_qualified.vascular} text="Otros trastornos vasculares" blockData={true} />
																<FindInfo option={request_qualified.retina} text="Otros trastornos de la retina" blockData={true} />
																<FindInfo option={request_qualified.optic_nerve} text="Otros trastornos del nervio óptico" blockData={true} />
															</div>
															:
															<div  class="card_scroll" style={{height: '95vh', marginLeft: '5px'}}>
																<div style={{ display: option === i ? "block" : "none" }}>
																	<h3>{"Otros hallazgos"}</h3>
																	<FindInfo option={waterfall} text="Catarata" onClick={setWaterfall} />
																	<FindInfo option={hemorrhage} text="Hemorragia vítrea" onClick={setHemorrhage} />
																	<FindInfo option={injury} text="Lesiones predisponentes" onClick={setInjury} />
																	<FindInfo
																		option={breaking_off}
																		text="Desprendimiento de la retina por ruptura"
																		onClick={setBreaking_off}
																	/>
																	<FindInfo option={hypertensive} text="Retinopatía hipertensiva" onClick={setHypertensive} />
																	<FindInfo option={membrane} text="Membrana epirretiniana" onClick={setMembrane} />
																	<FindInfo option={macular_hole} text="Agujero macular" onClick={setMacular_hole} />
																	<FindInfo
																		option={drusen}
																		text="Drusas/Degeneración macular asociada a la edad"
																		onClick={setDrusen}
																	/>
																	<FindInfo option={corroidal} text="Nevo coroideo" onClick={setCorroidal} />
																	<FindInfo option={excavation} text="Excavación de nervio óptico amplia" onClick={setExcavation} />
																	<FindInfo option={myopia} text="Miopía degenerativa" onClick={setMyopia} />
																	<FindInfo option={occlusion} text="Oclusión vascular" onClick={setOcclusion} />
																	<FindInfo option={hyalosis} text="Hialosis asteroidea" onClick={setHyalosis} />
																	<FindInfo option={vascular} text="Otros trastornos vasculares" onClick={setVascular} />
																	<FindInfo option={retina} text="Otros trastornos de la retina" onClick={setRetina} />
																	<FindInfo
																		option={optic_nerve}
																		text="Otros trastornos del nervio óptico"
																		onClick={setOptic_nerve}
																	/>
																</div>
															</div>
														}
													</div>
												</React.Fragment>
											)
										})
									}
								</Grid>
							</Grid>
						</Container>
					</div>
				</Container>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	stateView:	state.Global.stateView,
	token:		state.PanelUser.token,
	data:		state.InvestigationDatasets.dataEdit,
	request_i:	state.InvestigationDatasets.request_index,
	user_id:	state.PanelUser.id,
});

export default connect(mapStateToProps, {changeView, cleanData})(Form);
