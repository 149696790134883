import {useState} from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import {Ic_search} from 'values';
import { useEffect } from 'react';

const InputSearch = ({value, setData, onClickSearch, page, records, setPage, limitSearch=10}) => {
	const [showCloseBtn, setShowCloseBtn] = useState(value !== '');
	const [searchStringCleaned, setSearchStringCleaned] = useState(false);

	const key_events = (e) => {
		if (e.keyCode === 13) {
			onClickSearch();
		} else if (e.keyCode === 27) {
			setData('');
			setSearchStringCleaned(true);
		}
	}

	useEffect(() => {
		setShowCloseBtn(value !== '');

		if (searchStringCleaned) {
			setSearchStringCleaned(false);
			onClickSearch();
		}
	}, [value]);

	return (
		<>
		<div style={{display:'flex', justifyContent:'start', paddingTop:'8px'}}>
			<img alt="" src={Ic_search} style={{cursor:'pointer', width:'24px', margin: '0px 8px'}} onClick={onClickSearch} />

			<input
				type={'text'}
				onChange={e => setData(e.target.value)}
				onKeyDown={(e) => key_events(e)}
				value={value}
				style={{background:'#fafafa', width:'80%'}}
				className='input_search'
				placeholder="Buscar..." />

			{ showCloseBtn &&
				<IconButton
					size='small'
					edge="end"
					color="inherit"
					onClick={() => {setData(''); setSearchStringCleaned(true)}}
					aria-label="close"
					>
						<CloseIcon />
				</IconButton>
			}
		</div>
		<div style={{display:'flex', justifyContent:'center'}}>
			<TablePagination
				component="div"
				count={records}
				page={page-1}
				onPageChange={setPage}
				rowsPerPage={limitSearch}

				// labelRowsPerPage=''
				rowsPerPageOptions={[]}
				labelDisplayedRows={
					({ from, to, count }) => {
						return '' + from + '-' + to + ' de ' + count
					}
				}
				/>
		</div>
		</>
	);
};

export default InputSearch;