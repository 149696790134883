import {stateData, reduxState} from 'values';

export const getResearch = (data, records, page) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_research,
            data:       data,
            status:     stateData.state2,
            records:    records,
            page:       page,
        })
    }
}

export const updateResearch = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.update_research,
            data:       data,
        })
    }
}

export const changeStatusResearch = () =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.status_research,
            status:     stateData.state1,
        })
    }
}

export const typeResearch = (option) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.type_research,
            option:     option,
        })
    }
}